import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'services-content',
  templateUrl: './services-content.component.html',
  styleUrls: ['./services-content.component.scss']
})
export class ServicesContentComponent implements OnInit {

  constructor(public root: RootService) { }

  ess1: boolean = false;
  ess2: boolean = false;
  ess3: boolean = false;


  ma1: boolean = false;
  ma2: boolean = false;
  ma3: boolean = false;

  cs1: boolean = false;
  cs2: boolean = false;
  cs3: boolean = false;

  ds1: boolean = false;
  ds2: boolean = false;
  ds3: boolean = false;

  sc1: boolean = false;
  sc2: boolean = false;
  sc3: boolean = false;


  et1: boolean = false;
  et2: boolean = false;
  et3: boolean = false;



  ngOnInit(): void {
  }


  // ess() {
  //   this.ess1 = true;
  //   this.ess2 = true;
  //   this.ess3 = true;
  // }

}
