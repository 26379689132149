import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  newTitle: string = 'About ekZero: Your Digital Partner | IT Company | ekZero';
  longDescription: string = 'ekZero - Harnessing power of digital technology and creating market leading products, solutions, and services';
  longKeyword: string = 'ekZero, Virtual partner, digital platforms, digital products, digital solutions, technology consulting company, IT consulting services, Product Development';


  constructor(private title: Title,
    private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle(this.newTitle);
    this.meta.addTag({ name: "description", content: this.longDescription });
    this.meta.addTag({ name: "keywords", content: this.longKeyword });

  }

}
