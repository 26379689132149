<div class="ecs-portfolio-container">
    <div class="portfolio-heading">
        <span class="portfolio-heading-text">
            Our Portfolio
        </span>
    </div>
    <div class="portfolio-subheading">
        <span class="portfolio-subheading-text">
            Explore some of our best works including web solutions and cloud services.
        </span>
    </div>
    <div class="tab-panel">
        <div class="d-flex align-items-center title-block">
            <div class="icon-block">
                <div class="icon web-app"></div>
            </div>
            <div class="text-block">
                <span class="text">WEB APP</span>
            </div>
        </div>
        <div class="portfolio-section">
            <div class="customer-logos slider">
                <!-- <div class="card-width slide">
                    <a class="why-choose-card" target = "_black" href="http://www.planipedia.org/">
                        <img [src]="root.imageUrl() + '/planipedia.png' ">
                    </a>
                </div> -->
                <div class="card-width slide">
                    <a class="why-choose-card" target="_black" href="https://www.riskprofiling.com/">
                        <img [src]="root.imageUrl() + '/finametrica.png'">
                    </a>
                </div>
                <div class="card-width slide">
                    <a class="why-choose-card" target="_black" href="https://www.morningstar.com/">
                        <img [src]="root.imageUrl() + '/morningstar.png'">
                    </a>
                </div>
                <div class="card-width slide">
                    <a class="why-choose-card" target="_black" href="https://mdm.ca/md-financial-management">
                        <img [src]="root.imageUrl() + '/md-financial-management.png'">
                    </a>
                </div>
                <div class="card-width slide">
                    <a class="why-choose-card" target="_black" href="https://www.atb.com/personal/">
                        <img [src]="root.imageUrl() + '/atb-financial.png'">
                    </a>
                </div>
                <div class="card-width slide">
                    <a class="why-choose-card" target="_black" href="https://www.hsbc.co.uk/investments/">
                        <img [src]="root.imageUrl() + '/hsbc-uk.png'">
                    </a>
                </div>
                <div class="card-width slide">
                    <a class="why-choose-card" target="_black" href="https://blissgroup.ca/">
                        <img [src]="root.imageUrl() + '/svg-icons/bliss.svg' ">
                    </a>
                </div>
                <div class="card-width slide">
                    <a class="why-choose-card" target="_black" href="https://eternalfinancial.ca/">
                        <img [src]="root.imageUrl() + '/svg-icons/eternal.svg' ">
                    </a>
                </div>
                <div class="card-width slide">
                    <a class="why-choose-card" target="_black" href="https://www.tazwiz.com/">
                        <img [src]="root.imageUrl() + '/svg-icons/tazwiz.svg' ">
                    </a>
                </div>
                <div class="card-width slide">
                    <a class="why-choose-card" target="_black" href="https://www.vegitone.com/">
                        <img [src]="root.imageUrl() + '/svg-icons/vegitone.svg' ">
                    </a>
                </div>
                <div class="d-none card-width slide">
                    <a class="why-choose-card" target="_black" href="https://oneviz.co/">
                        <img [src]="root.imageUrl() + '/svg-icons/oneviz.svg' ">
                    </a>
                </div>
                <div class="card-width slide">
                    <a class="why-choose-card" target="_black" href="https://gtrack.vegitone.com/">
                        <img [src]="root.imageUrl() + '/svg-icons/gtrack.svg' ">
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="tab-panel">
        <div class="d-flex align-items-center title-block">
            <div class="icon-block">
                <div class="icon cloud-service"></div>
            </div>
            <div class="text-block">
                <span class="text">CLOUD SERVICES</span>
            </div>
        </div>
        <div class="portfolio-section">
            <div class="customer-logos slider">
                <div class="card-width slide">
                    <a class="why-choose-card" target="_black" href="https://www.cimb.com/en/home.html">
                        <img [src]="root.imageUrl() + '/svg-icons/climb.svg' ">
                    </a>
                </div>
                <div class="card-width slide">
                    <a class="why-choose-card" target="_black" href="https://www.coastcapitalsavings.com/">
                        <img [src]="root.imageUrl() + '/svg-icons/coast.svg' ">
                    </a>
                </div>
                <div class="card-width slide">
                    <a class="why-choose-card">
                        <img [src]="root.imageUrl() + '/svg-icons/ccu.svg' ">
                    </a>
                </div>
                <div class="card-width slide">
                    <a class="why-choose-card" target="_black" href="https://www.hsbc.co.uk/investments/">
                        <img [src]="root.imageUrl() + '/hsbc-uk.png'">
                    </a>
                </div>
                <div class="card-width slide">
                    <a class="why-choose-card" target="_black" href="https://www.ipcc.ca/">
                        <img [src]="root.imageUrl() + '/svg-icons/ipc.svg' ">
                    </a>
                </div>
                <div class="card-width slide">
                    <a class="why-choose-card" target="_black" href="https://www.qtrade.ca/en/investor.html">
                        <img [src]="root.imageUrl() + '/svg-icons/qtrade.svg' ">
                    </a>
                </div>
                <div class="card-width slide">
                    <a class="why-choose-card" target="_black" href="https://www.raymondjames.com/">
                        <img [src]="root.imageUrl() + '/svg-icons/ray-james.svg' ">
                    </a>
                </div>
                <div class="card-width slide">
                    <a class="why-choose-card" target="_black" href="https://www.nbfwm.ca/">
                        <img [src]="root.imageUrl() + '/svg-icons/nbf.svg' ">
                    </a>
                </div>
                <div class="card-width slide">
                    <a class="why-choose-card" target="_black" href="https://www.holliswealth.com/global/rebrand.html">
                        <img [src]="root.imageUrl() + '/svg-icons/hollis.svg' ">
                    </a>
                </div>
                <div class="card-width slide">
                    <a class="why-choose-card" target="_black" href="http://www.dynamicinvestors.in/">
                        <img [src]="root.imageUrl() + '/svg-icons/dynamic.svg' ">
                    </a>
                </div>
                <div class="card-width slide">
                    <a class="why-choose-card" target="_black" href="https://www.atb.com/personal/">
                        <img [src]="root.imageUrl() + '/svg-icons/atb.svg' ">
                    </a>
                </div>
                <div class="card-width slide">
                    <a class="why-choose-card" target="_black" href="https://www.westminsterfinancial.com/">
                        <img [src]="root.imageUrl() + '/svg-icons/westmin.svg' ">
                    </a>
                </div>
                <div class="card-width slide">
                    <a class="why-choose-card" target="_black" href="https://mdm.ca/md-financial-management">
                        <img [src]="root.imageUrl() + '/svg-icons/md.svg' ">
                    </a>
                </div>   
                <div class="card-width slide">
                    <a class="why-choose-card" target = "_black" href="https://www.hss.edu/">
                        <img [src]="root.imageUrl() + '/svg-icons/hss.svg' ">
                    </a>
                </div> 
                <div class="card-width slide">
                    <a class="why-choose-card" target = "_black" href="https://www.mskcc.org">
                        <img [src]="root.imageUrl() + '/svg-icons/memorial-slon.svg' ">
                    </a>
                </div>              
            </div>
        </div>
    </div>
</div>