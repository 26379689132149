import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'industries',
  templateUrl: './industries.component.html',
  styleUrls: ['./industries.component.scss']
})
export class IndustriesComponent implements OnInit {

  constructor(public root: RootService) { }

  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;
  isActiveTelecom: boolean = true;
  isActiveBanking: boolean = false;
  isActiveEducation: boolean = false;
  isActiveEnergy: boolean = false;
  isActiveGovt: boolean = false;
  isActiveHealth: boolean = false;
  isActiveService: boolean = false;


  ngOnInit(): void {
  }

  scrollLeft() {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  }
  scrollRight() {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }

  onActiveTelecom() {
    this.isActiveTelecom = true;
    this.isActiveBanking = false;
    this.isActiveEducation = false;
    this.isActiveEnergy = false;
    this.isActiveGovt = false;
    this.isActiveHealth = false;
    this.isActiveService = false;
  }

  onActiveBanking() {
    this.isActiveTelecom = false;
    this.isActiveBanking = true;
    this.isActiveEducation = false;
    this.isActiveEnergy = false;
    this.isActiveGovt = false;
    this.isActiveHealth = false;
    this.isActiveService = false;
  }

  onActiveEducation() {
    this.isActiveTelecom = false;
    this.isActiveBanking = false;
    this.isActiveEducation = true;
    this.isActiveEnergy = false;
    this.isActiveGovt = false;
    this.isActiveHealth = false;
    this.isActiveService = false;
  }

  onActiveEnergy() {
    this.isActiveTelecom = false;
    this.isActiveBanking = false;
    this.isActiveEducation = false;
    this.isActiveEnergy = true;
    this.isActiveGovt = false;
    this.isActiveHealth = false;
    this.isActiveService = false;
  }

  onActiveGovt() {
    this.isActiveTelecom = false;
    this.isActiveBanking = false;
    this.isActiveEducation = false;
    this.isActiveEnergy = false;
    this.isActiveGovt = true;
    this.isActiveHealth = false;
    this.isActiveService = false;
  }

  onActiveHealth() {
    this.isActiveTelecom = false;
    this.isActiveBanking = false;
    this.isActiveEducation = false;
    this.isActiveEnergy = false;
    this.isActiveGovt = false;
    this.isActiveHealth = true;
    this.isActiveService = false;
  }


  onActiveService() {
    this.isActiveTelecom = false;
    this.isActiveBanking = false;
    this.isActiveEducation = false;
    this.isActiveEnergy = false;
    this.isActiveGovt = false;
    this.isActiveHealth = false;
    this.isActiveService = true;
  }
}
