import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'app-custom-software-consulting',
  templateUrl: './custom-software-consulting.component.html',
  styleUrls: ['./custom-software-consulting.component.scss']
})
export class CustomSoftwareConsultingComponent implements OnInit {

  public isDisplay: boolean = false;
  public isShow:boolean=false;

  newTitle: string = 'Custom Software Consulting: Should your startup go for it?';
  longDescription: string = ' Reasons why your business needs a custom made software and how it can benefit your business.';
  longKeyword: string = 'Custom Software Consulting, Custom software development, software development, mobile application development, software consulting, software developers, enterprise resource planning, ERP, Artificial Intelligence, Cloud operations, data security, business software, enterprise software solution, business app, business solution, holistic software, digital business, digitalization, modern business apps, modern business software';

  constructor(private title: Title,public root:RootService, private meta: Meta) { }

  ngOnInit(): void {

    this.title.setTitle(this.newTitle);
    this.meta.addTag({ name: "description", content: this.longDescription });
    this.meta.addTag({ name: "keywords", content: this.longKeyword });
  }

}
