<div class="ecs-portfolio-container">
    <div class="portfolio-heading">
        <span class="portfolio-heading-text">
            Our Portfolio
        </span>
    </div>
    <div class="portfolio-subheading">
        <span class="portfolio-subheading-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua.
        </span>
    </div>
    <div class="tab-panel">
        <ul ngbNav #nav="ngbNav" class="nav-tabs">
            <li ngbNavItem>
                <a ngbNavLink>
                    <div class="d-flex align-items-center">
                        <div class="icon-block">
                            <div class="icon web-app"></div>
                        </div>
                        <div class="text-block">
                            <span class="text">WEB APP</span>
                        </div>
                    </div>
                </a>
                <ng-template ngbNavContent>
                    <div class="portfolio-section">
                        <div class="customer-logos slider">
                            <div class="card-width slide">
                                <div class="why-choose-card">
                                    <img [src]="root.imageUrl() + '/finametrica.png'">
                                </div>
                            </div>
                            <div class="card-width slide">
                                <div class="why-choose-card">
                                    <img [src]="root.imageUrl() + '/morningstar.png'">
                                </div>
                            </div>
                            <div class="card-width slide">
                                <div class="why-choose-card">
                                    <img [src]="root.imageUrl() + '/md-financial-management.png'">
                                </div>
                            </div>
                            <div class="card-width slide">
                                <div class="why-choose-card">
                                    <img [src]="root.imageUrl() + '/atb-financial.png'">
                                </div>
                            </div>
                            <div class="card-width slide">
                                <div class="why-choose-card">
                                    <img [src]="root.imageUrl() + '/hsbc-uk.png'">
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>
                    <div class="d-flex align-items-center">
                        <div class="icon-block">
                            <div class="icon cloud-service"></div>
                        </div>
                        <div class="text-block">
                            <span class="text">CLOUD SERVICES</span>
                        </div>
                    </div>
                </a>
                <ng-template ngbNavContent>
                    <div class="portfolio-section">
                        <div class="customer-logos slider">
                            <div class="card-width slide">
                                <div class="why-choose-card">
                                    <img [src]="root.imageUrl() + '/finametrica.png'">
                                </div>
                            </div>
                            <div class="card-width slide">
                                <div class="why-choose-card">
                                    <img [src]="root.imageUrl() + '/morningstar.png'">
                                </div>
                            </div>
                            <div class="card-width slide">
                                <div class="why-choose-card">
                                    <img [src]="root.imageUrl() + '/md-financial-management.png'">
                                </div>
                            </div>
                            <div class="card-width slide">
                                <div class="why-choose-card">
                                    <img [src]="root.imageUrl() + '/atb-financial.png'">
                                </div>
                            </div>
                            <div class="card-width slide">
                                <div class="why-choose-card">
                                    <img [src]="root.imageUrl() + '/hsbc-uk.png'">
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </div>
</div>