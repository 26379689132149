import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'faq-banner',
  templateUrl: './faq-banner.component.html',
  styleUrls: ['./faq-banner.component.scss']
})
export class FaqBannerComponent implements OnInit {

  constructor(public root: RootService) { }
  ngOnInit(): void {
  }

}
