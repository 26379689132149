import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IncubationComponent } from './pages/incubation/incubation.component';

const routes: Routes = [  {
  path: '',
  redirectTo: 'incubation',
  pathMatch: 'full'
},
{
  path: '',
  component: IncubationComponent,
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IncubationRoutingModule { }
