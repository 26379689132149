<div class="incubation-support">
    <div class="title-panel">
        <div class="row align-items-end no-gutters">            
            <div class="col-lg-6 order-12 order-lg-2">
                <div class="left-block">
                    <div class="left-content text-left">
                        <div class="mt-20 info-content">
                            <div><span class="color-orange">Comprehensive support</span>
                            </div>
                            <div><span class="color-orange">we offer</span>
                            </div>
                        </div>
                        <div class="mt-20">
                            <span class="color-black content-text">ekZero offers access to all the resources needed to start your dream business. Right from Technology consulting to Design Inspirations to cloud operation & Branding, we have it all!</span>
                        </div>
                    </div>
                    <div class="support-we-offer-section">
                        <div class="offer-panel">
                            <div class="number-block">
                                <span class="number-text">
                                    1
                                </span>
                            </div>
                            <div class="support-content-panel">
                                <span class="support-content-text">
                                    MVP Designing
                                </span>
                            </div>
                        </div>
                        <div class="offer-panel">
                            <div class="number-block">
                                <span class="number-text">
                                    2
                                </span>
                            </div>
                            <div class="support-content-panel">
                                <span class="support-content-text">
                                    Technology Architecture
                                </span>
                            </div>
                        </div>
                        <div class="offer-panel">
                            <div class="number-block">
                                <span class="number-text">
                                    3
                                </span>
                            </div>
                            <div class="support-content-panel">
                                <span class="support-content-text">
                                    Software Development
                                </span>
                            </div>
                        </div>
                        <div class="offer-panel">
                            <div class="number-block">
                                <span class="number-text">
                                    4
                                </span>
                            </div>
                            <div class="support-content-panel">
                                <span class="support-content-text">
                                    Branding
                                </span>
                            </div>
                        </div>
                        <div class="offer-panel">
                            <div class="number-block">
                                <span class="number-text">
                                    5
                                </span>
                            </div>
                            <div class="support-content-panel">
                                <span class="support-content-text">
                                    Cloud Operations
                                </span>
                            </div>
                        </div>
                        <div class="offer-panel">
                            <div class="number-block">
                                <span class="number-text">
                                    6
                                </span>
                            </div>
                            <div class="support-content-panel">
                                <span class="support-content-text">
                                    Cognitive AI networking
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 order-1 order-lg-12" data-aos="fade-up" data-aos-duration="1500">
                <div class="right-block">
                    <div class="image-block">
                        <img [src]="root.imageUrl() + '/incubation-support-img.png'">
                    </div>
                    <div class="color-block"></div>
                </div>
            </div>
        </div>
    </div>
</div>