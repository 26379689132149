import { Component, OnInit } from '@angular/core';
import { RootService } from '../../../../shared/services/root.service';

@Component({
  selector: 'technology-platforms',
  templateUrl: './technology-platforms.component.html',
  styleUrls: ['./technology-platforms.component.scss']
})
export class TechnologyPlatformsComponent implements OnInit {

  constructor(public root: RootService) { }

  ngOnInit(): void {
  }

}
