<div class="body-panel position-relative">
    <div class="tech-icon center"><img [src]="root.imageUrl() + '/svg-icons/service-default-lg.svg'"></div>
    <div class="row no-gutters">
        <div class="col-lg-8">
            <div class="left-wrapper">
                <div class="left-content">
                    <div class="info-content mb-30"><span>SERVICE & ENTERTAINMENT</span></div>
                    <div>
                        <span class="content-text">No matter what service you provide. We can enhance your clients'
                            experience by helping you go digital.

                        </span>
                    </div>
                </div>
                <div class="d-none pagination-block">
                    <div class="slider active"></div>
                    <div class="slider"></div>
                    <div class="slider"></div>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="right-content position-relative" data-aos="fade-up" data-aos-duration="1500">
                <img [src]="root.imageUrl() + '/ez-service-info.png'">
            </div>
        </div>
    </div>
</div>