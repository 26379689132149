<div id="service-detail-intro" data-aos="fade-up" data-aos-duration="1500">
    <div class="detail-intro">
        <div class="row">
            <div class="col-lg-6">
                <div class="left-content">
                    <div class="info-content mb-30"><span class="color-orange font-">Escalating business operations with
                            enterprise software services</span></div>
                    <div class="detail-info">
                        <p class="color-black content-text">We are a bunch of geeky and creative people, who live,
                            breathe and believe in
                            the power of technology to create a world with innovations - a better world. We are in
                            search of
                            energetic innovators, thinkers and doers who dare to learn fast, adapt faster and execute in
                            real time. The developers who passionately talk about the perfection in design and the
                            designers
                            who talk about development trends with conviction or a marketer who wants to build up their
                            own
                            sphere of stories. Think you have got what it takes? With your heartfelt desire to be a
                            pioneer
                            in this digital realm, consider joining a forward-thinking team.</p>
                    </div>
                    <div class="btn-grp"><a class="btn-primary btn-xlg" [routerLink]="root.contact()" target="_top">Connect with ekZero</a></div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="right-content">
                    <img [src]="root.imageUrl() + '/faq-tech-img.png'">
                </div>
            </div>
        </div>
    </div>
</div>