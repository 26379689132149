import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  constructor(public root: RootService) { }

  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;


  ngOnInit(): void {
  }

  scrollLeft() {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  }
  scrollRight() {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }
}

