<div id="work-process">
    <div class="process-wrapper">
        <div class="row">
            <div class="col-lg-8">
            </div>
            <div class="col-lg-4">
                <div class="left-content text-right">
                    <div class="info-title"><span class="color-black">HOW WE WORK ?</span></div>
                    <div class="mt-20 info-content">
                        <div><span class="color-orange">Software development</span>
                        </div>
                        <div><span class="color-orange">life cycle</span>
                        </div>
                    </div>
                    <div class="mt-40">
                        <span class="color-black content-text">Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit,
                            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="right-content">
            <div class="row">
                <div class="col-lg-12">
                    <div class="mt-50 mb-50 timeline">
                        <div class="center">
                            <div class="active timeline-circle text-center">
                                <div class="mb-15"><span class="timeline-no">01</span></div>
                                <div class="outer-circle center">
                                    <div class="inner-circle"></div>
                                </div>
                            </div>
                            <div class="center">
                                <div class="path"></div>
                                <div class="timeline-circle text-center">
                                    <div class="mb-15"><span class="timeline-no">02</span></div>
                                    <div class="outer-circle center">
                                        <div class="inner-circle"></div>
                                    </div>
                                </div>
                                <div class="path"></div>
                                <div class="timeline-circle text-center">
                                    <div class="mb-15"><span class="timeline-no">03</span></div>
                                    <div class="outer-circle center">
                                        <div class="inner-circle"></div>
                                    </div>
                                </div>
                                <div class="path"></div>
                            </div>
                            <div class="timeline-circle text-center">
                                <div class="mb-15"><span class="timeline-no">04</span></div>
                                <div class="outer-circle center">
                                    <div class="inner-circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="overflow-wrapper -ms-scrollbar scrollable-body">
                <div class="card-wrapper">
                    <div class="row overflow-panel">
                        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-card">
                            <div class="content-card">
                                <div class="mb-30"><img [src]="root.imageUrl() + '/svg-icons/product-defination-icon.svg'"
                                        class="work-icon"></div>
                                <div class="mb-30"><span class="color-black process-title">Product definition & specs
                                        development</span></div>
                                <div><span class="color-black content-text">Lorem ipsum dolor sit amet, consectetur
                                        adipiscing
                                        elit, sed do eiusmod tempor
                                        incididunt ut labore et</span></div>
                                <div class="arrow-icon center">
                                    <a><img [src]="root.imageUrl() + '/svg-icons/white-arrow.svg'"></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-card">
                            <div class="content-card">
                                <div class="mb-30"><img [src]="root.imageUrl() + '/svg-icons/ui-ux-icon.svg'" class="work-icon">
                                </div>
                                <div class="mb-30"><span class="color-black process-title">UI/UX, graphic design</span>
                                </div>
                                <div><span class="color-black content-text">Lorem ipsum dolor sit amet, consectetur
                                        adipiscing
                                        elit, sed do eiusmod tempor
                                        incididunt ut labore et</span></div>
                                <div class="arrow-icon center">
                                    <a><img [src]="root.imageUrl() + '/svg-icons/white-arrow.svg'"></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-card">
                            <div class="content-card">
                                <div class="mb-30"><img [src]="root.imageUrl() + '/svg-icons/software-development-icon.svg'"
                                        class="work-icon"></div>
                                <div class="mb-30"><span class="color-black process-title">Software development</span>
                                </div>
                                <div><span class="color-black content-text">Lorem ipsum dolor sit amet, consectetur
                                        adipiscing
                                        elit, sed do eiusmod tempor
                                        incididunt ut labore et</span></div>
                                <div class="arrow-icon center">
                                    <a><img [src]="root.imageUrl() + '/svg-icons/white-arrow.svg'"></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-card">
                            <div class="content-card">
                                <div class="mb-30"><img [src]="root.imageUrl() + '/svg-icons/qa-bugfix-icon.svg'"
                                        class="work-icon">
                                </div>
                                <div class="mb-30"><span class="color-black process-title">QA & bug fixing</span></div>
                                <div><span class="color-black content-text">Lorem ipsum dolor sit amet, consectetur
                                        adipiscing
                                        elit, sed do eiusmod tempor
                                        incididunt ut labore et</span></div>
                                <div class="arrow-icon center">
                                    <a><img [src]="root.imageUrl() + '/svg-icons/white-arrow.svg'"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slider-block slider">
                <div class="slider-component active"></div>
                <div class="slider-component"></div>
                <div class="slider-component"></div>
            </div>
        </div>
    </div>
</div>