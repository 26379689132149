import { Component, OnInit } from '@angular/core';
import { RootService } from '../../../../shared/services/root.service';

@Component({
  selector: 'ekvegit',
  templateUrl: './ekvegit.component.html',
  styleUrls: ['./ekvegit.component.scss']
})
export class EkvegitComponent implements OnInit {

  constructor(public root: RootService) { }

  ngOnInit(): void {
  }

}
