<div class="body-panel position-relative">
    <div class="tech-icon center"><img [src]="root.imageUrl() + '/svg-icons/banking-default-lg.svg'"></div>
    <div class="row no-gutters">
        <div class="col-lg-8">
            <div class="left-wrapper">
                <div class="left-content">
                    <div class="info-content mb-30"><span>BANKING & FINANCIAL SERVICES</span></div>
                    <div>
                        <span class="content-text">We provide our services to all kinds of financial institutes ranging
                            from banks, insurance service providers to small investment management firms. You can trust
                            us to take care of your software division while you focus on your core business.

                        </span>
                    </div>
                </div>
                <div class="d-none pagination-block">
                    <div class="slider active"></div>
                    <div class="slider"></div>
                    <div class="slider"></div>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="right-content position-relative" data-aos="fade-up" data-aos-duration="1500">
                <img [src]="root.imageUrl() + '/ez-banking-info.png'">
            </div>
        </div>
    </div>
</div>