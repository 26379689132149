import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(private http: HttpClient) { }

  public support(obj) {
    const apiurl = environment.apiUrl + '/public/contactus';
    return (this.http.post(apiurl, obj));
  }
}
