import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { RootService } from '../../services/root.service';
import { RouterModule } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isShow: boolean = false;
  isOpen: boolean = false;
  isOpenecs: boolean = false;
  isOpenAboutus: boolean = false;
  isOpenService: boolean = false;
  isOpenProduct: boolean = false;
  isOpenContact: boolean = false;
  isOpenCareers: boolean = false;
  isOpenfaq: boolean = false;
  demoPath: string;
  isClose: boolean = false;

  constructor(private router: Router, public root: RootService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationError) {
        // this.demoPath = event['url'];
        console.log(this.demoPath)
        console.log(event)
      }
      if (event instanceof NavigationEnd) {
        this.demoPath = event['url'];
        console.log(this.demoPath)
        console.log(event)
      }


    })
  }


  ngOnInit(): void {
  }

}
