<div class="ez-home-banner">  
    <div class="position-relative">
        <div class="text-container">           
            <div class="subheading-block mb-15">
                <span class="subheading-block-text">
                    We Create Market Leading
                </span>
            </div>
            <div class="heading1-block mb-15">
                <span class="heading1-block-text" #tw1>
                </span>
            </div>
            <div class="subheading-block">
                <span class="subheading-block-text">
                    that Transform Businesses
                </span>
            </div>
        </div>
        <div class="overlay-img-block">
            <img [src]="root.imageUrl() + '/banner-overlay-img.png '">
        </div>
        <div class="overlay-img-block">
            <a class="btn-primary btn-lg" target="_top" [routerLink]="root.ourServices()" target="_top">Know More</a>
        </div>
        <div class="slider-block">
            <div class="slider-component active"></div>
            <div class="slider-component"></div>
            <div class="slider-component"></div>
        </div>
    </div>
</div>