<div class="body-panel position-relative">
    <div class="tech-icon center"><img [src]="root.imageUrl() + '/svg-icons/goverment-default-lg.svg'"></div>
    <div class="row no-gutters">
        <div class="col-lg-8">
            <div class="left-wrapper">
                <div class="left-content">
                    <div class="info-content mb-30"><span>GOVERMENT</span></div>
                    <div>
                        <span class="content-text">Save costs and make access to public services easy and secure. We can
                            provide the best in class, connected and safe solutions to make government (or semi-govt.)
                            agency-driven services available to the public.

                        </span>
                    </div>
                </div>
                <div class="d-none pagination-block">
                    <div class="slider active"></div>
                    <div class="slider"></div>
                    <div class="slider"></div>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="right-content position-relative" data-aos="fade-up" data-aos-duration="1500">
                <img [src]="root.imageUrl() + '/ez-goverment-info.png'">
            </div>
        </div>
    </div>
</div>