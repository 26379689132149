<div id="career-specification">
    <div class="process-wrapper">
        <div class="left-content">
            <div class="info-title"><span class="color-black">Excited to</span></div>
            <div class="mt-20 info-content">
                <div><span class="color-orange">What do we </span>
                </div>
                <div><span class="color-orange">look for?</span>
                </div>
            </div>
            <!-- <div class="mt-30 spec-text">
                <span class="color-black content-text">We are a bunch of geeky and creative people, who live, breathe and believe in the power of technology to create a world with innovations - a better world. We are in search of energetic innovators, thinkers and doers who dare to learn fast, adapt faster and execute in real-time. The developers who passionately talk about the perfection in design and the designers who talk about development trends with conviction or a marketer who wants to build up their own sphere of stories. Think you have got what it takes? With your heartfelt desire to be a pioneer in this digital realm, consider joining a forward-thinking team.
                </span>
            </div> -->
        </div>
        <div class="right-content">
            <div class="overflow-wrapper -ms-scrollbar scrollable-body">
                <div class="card-wrapper">
                    <div class="row overflow-panel">
                        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-card">
                            <div class="content-card" (click)="onBlock1" [ngClass]="{'block-active': block1}"
                                data-aos="fade-right" data-aos-duration="1500">
                                <div class="mb-30 image-block"><img
                                        [src]="root.imageUrl() + '/svg-icons/product-defination-icon.svg' "
                                        class="work-icon"></div>
                                <div class="mb-30"><span class="color-black process-title">Passion</span></div>
                                <div><span class="color-black content-text">If you too are as passionate about
                                        technological advancement as we are, we’ll be making a great team!</span></div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-card">
                            <div class="content-card animate__delay-1s" (click)="onBlock2"
                                [ngClass]="{'block-active': block2}" data-aos="fade-right" data-aos-duration="1500">
                                <div class="mb-30 image-block"><img
                                        [src]="root.imageUrl() + '/svg-icons/ui-ux-icon.svg' " class="work-icon">
                                </div>
                                <div class="mb-30"><span class="color-black process-title">Team Player</span>
                                </div>
                                <div><span class="color-black content-text">At ekZero, everyone is the team player! We
                                        highly encourage collaboration and working together as a team with a common
                                        vision.
                                    </span></div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-card">
                            <div class="content-card animate__delay-2s" (click)="onBlock3"
                                [ngClass]="{'block-active': block3}" data-aos="fade-right" data-aos-duration="1500">
                                <div class="mb-30 image-block"><img
                                        [src]="root.imageUrl() + '/svg-icons/software-development-icon.svg' "
                                        class="work-icon"></div>
                                <div class="mb-30"><span class="color-black process-title">Authenticity</span>
                                </div>
                                <div><span class="color-black content-text">Every member of our team is accountable for
                                        fostering a culture that promotes extraordinary and authentic results.
                                    </span></div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-card">
                            <div class="content-card animate__delay-3s" (click)="onBlock4"
                                [ngClass]="{'block-active': block4}" data-aos="fade-right" data-aos-duration="1500">
                                <div class="mb-30 image-block"><img
                                        [src]="root.imageUrl() + '/svg-icons/qa-bugfix-icon.svg' " class="work-icon">
                                </div>
                                <div class="mb-30"><span class="color-black process-title">Honest</span></div>
                                <div><span class="color-black content-text">Do the right thing the right way! A healthy
                                        work environment is based on being ethical to oneself and others.
                                    </span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slider-block slider">
                <div class="slider-component active" (click)="onBlock1()" [ngClass]="{'active': block1}"></div>
                <div class="slider-component" (click)="onBlock2()" [ngClass]="{'active': block2}"></div>
                <div class="slider-component" (click)="onBlock3()" [ngClass]="{'active': block3}"></div>
                <div class="slider-component" (click)="onBlock4()" [ngClass]="{'active': block4}"></div>
            </div>
            <!-- <div class="d-flex justify-content-center btn-grp">
                <a class="btn-lg btn-secondary" [routerLink]="root.lifeatekzero()" target="_top">LIFE @ekZero</a>
            </div> -->
        </div>
    </div>
</div>