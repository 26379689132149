<div id="service-benefits" data-aos="fade-up" data-aos-duration="1500">
    <div class="text-center ml-10 mr-10">
        <div class=""><span class="font-20 color-black">Benefits of</span></div>
        <div class="mt-20 info-content"><span class="color-orange">ekZero’s Cloud Services</span></div>
    </div>
    <div class="benefit-content">
        <div class="row no-gutters">
            <div class="col-xl-6 col-lg-7">
                <div class="left-content">
                    <div class="benefit-pill mb-30">
                        <div class="d-flex flex-column-wrapper">
                            <div><img [src]="root.imageUrl() + '/svg-icons/cloud-robust-network.svg'"
                                    class="benefit-icon"></div>
                            <div class="ml-20">
                                <div class="mb-10"><span class="color-black font-18">Robust Network Infrastructure
                                    </span></div>
                                <div><span class="color-black content-text mb-0">Get 24/7 support for your cloud servers so that you don’t have to worry about unexpected breakdowns and downtimes.

                                    </span></div>
                            </div>
                        </div>
                    </div>
                    <div class="benefit-pill mb-30">
                        <div class="d-flex flex-column-wrapper">
                            <div><img [src]="root.imageUrl() + '/svg-icons/cloud-easy-to-scale.svg'"
                                    class="benefit-icon"></div>
                            <div class="ml-20">
                                <div class="mb-10"><span class="color-black font-18">Easy to scale
                                    </span></div>
                                <div><span class="color-black content-text mb-0">Readily available resources that can be easily adjusted to fit any organization’s dynamic needs.
                                    </span></div>
                            </div>
                        </div>
                    </div>
                    <div class="benefit-pill mb-30">
                        <div class="d-flex flex-column-wrapper">
                            <div><img [src]="root.imageUrl() + '/svg-icons/cloud-enhance-security.svg'"
                                    class="benefit-icon"></div>
                            <div class="ml-20">
                                <div class="mb-10"><span class="color-black font-18">Enhanced Security and Backups
                                    </span></div>
                                <div><span class="color-black content-text mb-0">Securing data is always our top priority. Ensure your cloud ecosystem with top-notch protection and safeguard them against security threats.
                                    </span></div>
                            </div>
                        </div>
                    </div>
                    <div class="benefit-pill mb-30">
                        <div class="d-flex flex-column-wrapper">
                            <div><img [src]="root.imageUrl() + '/svg-icons/cloud-cost-control.svg'"
                                    class="benefit-icon"></div>
                            <div class="ml-20">
                                <div class="mb-10"><span class="color-black font-18">Cost Control</span>
                                </div>
                                <div><span class="color-black content-text mb-0">No need to invest in expensive servers, routers, networks, etc. By outsourcing your cloud requirements you  will save as much as 40% on costs</span></div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="benefit-pill mb-30">
                        <div class="d-flex flex-column-wrapper">
                            <div><img [src]="root.imageUrl() + '/svg-icons/service-benefit-icon.svg'"
                                    class="benefit-icon"></div>
                            <div class="ml-20">
                                <div class="mb-10"><span class="color-black font-18">Order Management</span></div>
                                <div><span class="color-black content-text mb-0">Experience the real-time tracker that
                                        fulfils every customer order flawlessly
                                        & effectively, managing all your orders from a single platform</span></div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-xl-6 col-lg-5">
                <div class="right-content">
                    <img [src]="root.imageUrl() + '/ez-service-benefit-right-bg.png'">
                </div>
            </div>
        </div>
    </div>
</div>