<div class="body-panel position-relative">
    <div class="tech-icon center"><img [src]="root.imageUrl() + '/svg-icons/it-telecome-default-lg.svg'"></div>
    <div class="row no-gutters">
        <div class="col-xl-8 col-lg-6 col-md-12">
            <div class="left-wrapper">
                <div class="left-content">
                    <div class="info-content mb-30"><span>IT AND TELECOM</span></div>
                    <div>
                        <span class="content-text">Whether you are a service provider or someone who provides end-to-end
                            IT business solutions - leave it to us to design, develop, test, deploy and support
                            solutions involving multiple system integrations (legacy and modern) and multiple vendors.

                        </span>
                    </div>
                </div>
                <div class="d-none pagination-block">
                    <div class="slider active"></div>
                    <div class="slider"></div>
                    <div class="slider"></div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12">
            <div class="right-content position-relative" data-aos="fade-up" data-aos-duration="1500">
                <img [src]="root.imageUrl() + '/it-telecome-info.png'">
                <!-- <div>
                    <div class="content-block">
                        <div class="d-flex">
                            <div>
                                <div class="block-top"></div>
                                <div class="middle-block">
                                    <span>CSS</span>
                                </div>
                            </div>
                            <div class="block-right"></div>
                        </div>
                    </div>
                    <div class="content-block block2">
                        <div class="d-flex">
                            <div>
                                <div class="block-top"></div>
                                <div class="middle-block">
                                    <span>HTML</span>
                                </div>
                            </div>
                            <div class="block-right"></div>
                        </div>
                    </div>
                    <div class="content-block block3">
                        <div class="d-flex">
                            <div>
                                <div class="block-top"></div>
                                <div class="middle-block">
                                    <span>C++</span>
                                </div>
                            </div>
                            <div class="block-right"></div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>