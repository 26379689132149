<div class="ez-our-story" id="research-development">
    <div class="row no-gutters">
        <div class="col-md-7 order-12 order-md--1">
            <div class="our-story-right-panel">
                <div class="uppar-content">
                    <div><span>Enterprise Growth backed by Research
                            & Deep Learning</span>
                    </div>
                </div>
                <div class="lower-content">
                    <div><span class="content-text">Our multi-skilled team of digital enthusiasts, who are incredibly
                            serious and intrigued about the prospects of technology innovation, assists businesses in
                            turning their ideas into reality. Our insatiable curiosity, perseverance, and desire to
                            learn about complicated ideas and challenges enable us to accomplish our vision of providing
                            transformational and inventive solutions. ekZero thrives on collaborating with R&D and
                            organizations all over the world to create and deliver innovative products and services.

                        </span></div>
                </div>
                <div class="btn-grp mt-30">
                    <a class="btn-primary btn-xlg" [routerLink]="root.contact()" target="_top">Connect with ekZero</a>
                </div>
            </div>
        </div>
        <div class="col-md-5 order-md-12 order-2">
            <div class="intro-left-panel" data-aos="fade-down" data-aos-duration="1700">
                <div class="intro-img">
                    <div class="our-story-right-content"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="product-service-panel">
    <div class="row">
        <div class="col-lg-3 col-sm-6" data-aos="fade-right" data-aos-duration="1500">
            <div class="product-block">
                <div class="icon-block regular-improvement">

                </div>
                <div class="title-panel">
                    <span class="title-text">Regular Improvement
                        of Our Products</span>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-sm-6" data-aos="fade-right" data-aos-duration="1500">
            <div class="product-block">
                <div class="icon-block product-development">

                </div>
                <div class="title-panel">
                    <span class="title-text">New product Development</span>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-sm-6" data-aos="fade-right" data-aos-duration="1500">
            <div class="product-block">
                <div class="icon-block product-durability">

                </div>
                <div class="title-panel">
                    <span class="title-text">Product durability & Security</span>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-sm-6" data-aos="fade-right" data-aos-duration="1500">
            <div class="product-block">
                <div class="icon-block innovation">

                </div>
                <div class="title-panel">
                    <span class="title-text">Innovation & Creativity</span>
                </div>
            </div>
        </div>
    </div>
</div>