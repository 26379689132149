<div class="life-ekzero">
  <div class="bg-bubble bubble-1-position">
    <div class="bubble-small"></div>
  </div>
  <div class="bg-bubble bubble-2-position">
    <div class="bubble-small"></div>
  </div>
  <!-- -------------gallery -----------start----->
  <div class="gallery">
    <div class="row">
      <div class="col-12">
        <div>
          <div class="gallery-wrapper center">
            <!-- <div>
                            <div class="img-frame-1 img-frame"><img src="" class="img-frame-images"></div>
                            <div class="img-frame-6 img-frame"><img src="" class="img-frame-images"></div>
                        </div> -->
            <div class="width-30">
              <div
                class="img-frame-2 img-frame grid"
                data-aos="fade-down-right"
                data-aos-duration="1500"
              >
                <div class="img-frame-2 img-frame">
                  <img
                    [src]="root.imageUrl() + '/ez-life-ekzero-img1.png '"
                    class="img-frame-images"
                  />
                </div>
                <div class="img-frame-2 img-frame">
                  <img
                    [src]="root.imageUrl() + '/ez-life-ekzero-img4.png '"
                    class="img-frame-images"
                  />
                </div>
                <div class="img-frame-2 img-frame">
                  <img
                    [src]="root.imageUrl() + '/ez-life-ekzero-img5.png '"
                    class="img-frame-images"
                  />
                </div>
              </div>
              <div
                class="img-frame-7 img-frame grid"
                data-aos="fade-up-right"
                data-aos-duration="1500"
              >
                <div class="img-frame-7 img-frame">
                  <img
                    [src]="root.imageUrl() + '/ez-life-ekzero-img2.png '"
                    class="img-frame-images"
                  />
                </div>
                <div class="img-frame-7 img-frame">
                  <img
                    [src]="root.imageUrl() + '/ez-life-ekzero-img3.png '"
                    class="img-frame-images"
                  />
                </div>
                <div class="img-frame-7 img-frame">
                  <img
                    [src]="root.imageUrl() + '/ez-life-ekzero-img6.png '"
                    class="img-frame-images"
                  />
                </div>
              </div>
            </div>
            <div class="width-30">
              <div
                class="img-frame-3 img-frame grid"
                data-aos="zoom-in-down"
                data-aos-duration="1500"
              >
                <div class="img-frame-3 img-frame">
                  <img
                    [src]="root.imageUrl() + '/ez-life-ekzero-img3.png '"
                    class="img-frame-images"
                  />
                </div>
                <div class="img-frame-3 img-frame">
                  <img
                    [src]="root.imageUrl() + '/ez-life-ekzero-img6.png '"
                    class="img-frame-images"
                  />
                </div>
                <div class="img-frame-3 img-frame">
                  <img
                    [src]="root.imageUrl() + '/ez-life-ekzero-img2.png '"
                    class="img-frame-images"
                  />
                </div>
              </div>
              <div
                class="img-frame-8 img-frame grid"
                data-aos="zoom-in-up"
                data-aos-duration="1500"
              >
                <div class="img-frame-8 img-frame">
                  <img
                    [src]="root.imageUrl() + '/ez-life-ekzero-img4.png '"
                    class="img-frame-images"
                  />
                </div>
                <div class="img-frame-8 img-frame">
                  <img
                    [src]="root.imageUrl() + '/ez-life-ekzero-img5.png '"
                    class="img-frame-images"
                  />
                </div>
                <div class="img-frame-8 img-frame">
                  <img
                    [src]="root.imageUrl() + '/ez-life-ekzero-img1.png '"
                    class="img-frame-images"
                  />
                </div>
              </div>
            </div>
            <div class="width-30">
              <div
                class="img-frame-4 img-frame grid"
                data-aos="fade-down-left"
                data-aos-duration="1500"
              >
                <div class="img-frame-4 img-frame">
                  <img
                    [src]="root.imageUrl() + '/ez-life-ekzero-img5.png '"
                    class="img-frame-images"
                  />
                </div>
                <div class="img-frame-4 img-frame">
                  <img
                    [src]="root.imageUrl() + '/ez-life-ekzero-img1.png '"
                    class="img-frame-images"
                  />
                </div>
                <div class="img-frame-4 img-frame">
                  <img
                    [src]="root.imageUrl() + '/ez-life-ekzero-img4.png '"
                    class="img-frame-images"
                  />
                </div>
              </div>
              <div
                class="img-frame-9 img-frame grid"
                data-aos="fade-up-left"
                data-aos-duration="1500"
              >
                <div class="img-frame-9 img-frame">
                  <img
                    [src]="root.imageUrl() + '/ez-life-ekzero-img6.png '"
                    class="img-frame-images"
                  />
                </div>
                <div class="img-frame-9 img-frame">
                  <img
                    [src]="root.imageUrl() + '/ez-life-ekzero-img2.png '"
                    class="img-frame-images"
                  />
                </div>
                <div class="img-frame-9 img-frame">
                  <img
                    [src]="root.imageUrl() + '/ez-life-ekzero-img3.png '"
                    class="img-frame-images"
                  />
                </div>
              </div>
            </div>
            <!-- <div>
                            <div class="img-frame-5 img-frame"><img src="" class="img-frame-images"></div>
                            <div class="img-frame-10 img-frame"><img src=""class="img-frame-images"></div>
                        </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ----------------gallery-------end---- -->

  <!-- --------------------------start---------- -->
  <div class="young-workers" data-aos="fade-left" data-aos-duration="1500">
    <div class="young-workers-wrapper">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div class="yw-content">
            <div>
              <p class="color-orange font-600">
                ekZero has a distinct culture that encourages employees to
                develop and enhance their roles in accordance with their
                strengths.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div>
            <div class="yw-picture" data-aos="fade-up" data-aos-duration="1500">
              <img
                [src]="
                  root.imageUrl() +
                  '/ez-lifeekzero-young-businesswoman-with-co-workers.png'
                "
                width="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- --------------------------------end--------- -->

  <!-- -------------Innovation---------start-- -->
  <div class="Innovation" id="Innovation">
    <div class="row">
      <div
        class="col-md-12 col-lg-6 order-1"
        data-aos="fade-right"
        data-aos-duration="1500"
      >
        <div class="">
          <div class="img-frame-blue">
            <img [src]="root.imageUrl() + '/ez-life-innovation.png'" />
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-6 order-2">
        <div class="height-100 innovation-text pl-70">
          <div class="">
            <div class="mb-20">
              <span class="font-orange font-600 font-32">Innovation</span>
            </div>
            <div>
              <p class="font-14">
                At ekZero, we provide an autonomous work environment that
                genuinely encourages innovation. We are spurred by each of our
                employees' individuality and originality. The ekZero team
                believes in open communication, collaborative work, and new
                solutions. Our quest for innovation is led by a group of
                creative and passionate individuals who are regarded for their
                innovative efforts and contributions to our ongoing global
                success.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- -------------Innovation---------end-- -->

  <!-- -------------Flexibility---------start-- -->
  <div class="Innovation" id="Flexibility">
    <div class="row">
      <div class="col-md-12 col-lg-6">
        <div class="height-100 innovation-text pr-70">
          <div class="">
            <div class="mb-20">
              <span class="font-blue font-600 font-32">Flexibility</span>
            </div>
            <div>
              <p class="font-14">
                Our core principles, particularly our dedication to compassion
                and generosity, have always been critical to our success. ekZero
                encourages and welcomes new ideas and insights from everyone!
                After all, it's new ideas that lead to significant advances. We
                believe in fostering a culture that changes through time to
                reflect the diversity of ideas, knowledge, and ethnicity
                represented by our employees.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-md-12 col-lg-6"
        data-aos="fade-left"
        data-aos-duration="1500"
      >
        <div class="">
          <div class="img-frame-orange">
            <img [src]="root.imageUrl() + '/ez-life-flexibility.png'" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- -------------Flexibility---------end-- -->

  <!-- -------------Activity & Fun---------start-- -->
  <div class="Innovation" id="Activity">
    <div class="row">
      <div
        class="col-md-12 col-lg-6 order-1"
        data-aos="fade-right"
        data-aos-duration="1500"
      >
        <div class="">
          <div class="img-frame-blue">
            <img [src]="root.imageUrl() + '/ez-life-activity-fun.png'" />
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-6 order-2">
        <div class="height-100 innovation-text pl-70">
          <div class="">
            <div class="mb-20">
              <span class="font-orange font-600 font-32">Activity & Fun</span>
            </div>
            <div>
              <p class="font-14">
                ekZero is more than just a company; we are a family of geeky
                enthusiasts. As much as we take our work seriously, we enjoy
                taking a break and having some fun. No accomplishment is too
                small to be celebrated, and we all actively engage in company
                activities such as success parties, outings, festival feasts,
                and other fun events that foster healthy relationships and happy
                memories.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- -------------Activity & Fun---------end-- -->

  <!-- -------------Enthusiastic Work Culture---------start-- -->
  <div class="Innovation" id="Enthusiastic">
    <div class="row">
      <div class="col-md-12 col-lg-6">
        <div class="height-100 innovation-text pr-70">
          <div class="">
            <div class="mb-20">
              <span class="font-blue font-600 font-32"
                >Enthusiastic Work Culture</span
              >
            </div>
            <div>
              <p class="font-14">
                To nurture a dynamic workplace, we cherish and embrace the
                diverse perspectives of our workforce. A significant component
                of our success is our team's capacity to adapt to changes and
                accept new challenges. We are committed to endeavouring
                knowledge and innovation, and we recognise individuals for their
                talents. With the trust and support of the team, we have the
                freedom and choice to bring significant differences in the
                organisation and inspire creativity and problem-solving.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-md-12 col-lg-6"
        data-aos="fade-left"
        data-aos-duration="1500"
      >
        <div class="">
          <div class="img-frame-orange">
            <img [src]="root.imageUrl() + '/ez-life-work-culture.png'" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- -------------Enthusiastic Work Culture---------end-- -->
</div>
