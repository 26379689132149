<div class="ez-career-banner">   
    <div class="text-container">
        <div class="subheading-block position-relative mb-15">
            <span class="subheading-block-text">
                Career
            </span>
        </div>
        <div class="subheading2-block mb-15">
            <span class="subheading2-block-text">
                Become a part of our tech family 
            </span>
        </div>        
    </div>
</div>
