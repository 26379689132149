import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ServiceHomeComponent } from './pages/service-home/service-home.component';
import { ServiceDetailPageComponent } from './pages/service-detail-page/service-detail-page.component';
import { EnterpriseSoftwareServiceComponent } from './pages/enterprise-software-service/enterprise-software-service.component';
import { MobileAppServiceComponent } from './pages/mobile-app-service/mobile-app-service.component';
import { CloudServiceComponent } from './pages/cloud-service/cloud-service.component';
import { DevopsServiceComponent } from './pages/devops-service/devops-service.component';
import { SoftwareConsultingServiceComponent } from './pages/software-consulting-service/software-consulting-service.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'ourservices',
    pathMatch: 'full'
  },
  {
    path: '',
    component: ServiceHomeComponent,
  },
  {
    path: 'detail',
    component: ServiceDetailPageComponent,
  },
  {
    path: 'enterprise-software-service',
    component: EnterpriseSoftwareServiceComponent,
  },
  {
    path: 'mobile-app-service',
    component: MobileAppServiceComponent,
  },
  {
    path: 'cloud-service',
    component: CloudServiceComponent,
  },
  {
    path: 'devops-service',
    component: DevopsServiceComponent,
  }, 
  {
    path: 'software-consulting-service',
    component: SoftwareConsultingServiceComponent,
  },    
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OurServicesRoutingModule { }

