import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'app-evolution-of-gps-technology-in-fleet-operations',
  templateUrl: './evolution-of-gps-technology-in-fleet-operations.component.html',
  styleUrls: ['./evolution-of-gps-technology-in-fleet-operations.component.scss']
})
export class EvolutionOfGPSTechnologyInFleetOperationsComponent implements OnInit {
  isDisplay: boolean = false;
  isShow: boolean = false;
  constructor(public root: RootService) { }

  ngOnInit(): void {
  }

}
