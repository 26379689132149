<div class="contact-list">
  <div class="upper-content">
    <div class="row no-gutters">
      <div class="col-lg-4" data-aos="fade-right" data-aos-duration="1500">
        <div class="left-wrapper">
          <div>
            <img
              [src]="root.imageUrl() + '/ez-support-left-bg.png'"
              class="support-img"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="right-panel">
          <div
            class="support-wrapper"
            data-aos="zoom-in"
            data-aos-duration="1500"
            id="support"
          >
            <div class="support-panel">
              <div class="support-block">
                <div class="d-flex flex-wrapper">
                  <div class="left-content flex-order-2">
                    <div class="mb-30">
                      <span class="color-orange content-title"
                        >24 x 7 Support</span
                      >
                    </div>
                    <div>
                      <p class="color-black">
                        ekZero is here to help our customers succeed and
                        end-users run their businesses more efficiently. Be it
                        on-site assistance, technical support, or remote
                        support, ekZero is always at your service with
                        unparalleled scalability and expertise.
                      </p>
                    </div>
                  </div>
                  <div class="flex-order-1">
                    <img
                      class="contact-image-block"
                      [src]="root.imageUrl() + '/ez-all-time-support-img.png'"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="btn-grp d-flex justify-content-center">
              <a
                class="btn-lg btn-primary"
                target="_top"
                (click)="openModal(Content)"
                >CONNECT US</a
              >
            </div>
          </div>
          <div
            class="support-wrapper"
            data-aos="zoom-in"
            data-aos-duration="1500"
            id="talk-business"
          >
            <div class="support-panel">
              <div class="support-block">
                <div class="d-flex flex-wrapper">
                  <div>
                    <img
                      class="contact-image-block"
                      [src]="root.imageUrl() + '/ez-bussiness-talk-img.png '"
                    />
                  </div>
                  <div class="left-content">
                    <div class="mb-30">
                      <span class="color-orange content-title"
                        >Let's Talk Business!</span
                      >
                    </div>
                    <div>
                      <p class="color-black">
                        Let us help you succeed by taking care of your digital
                        infrastructure, software solution development,
                        integration, and delivery while you focus on developing
                        your business.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn-grp d-flex justify-content-center">
              <a
                class="btn-lg btn-primary"
                target="_top"
                (click)="showModal(Content1)"
                >INQUIRE NOW</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="product-supoort-panel" id="product-support">
    <div class="text-center mb-50">
      <span class="color-orange content-title"
        >Connect to our Products support</span
      >
    </div>
    <div class="overflow-wrapper -ms-scrollbar scrollable-body">
      <div class="card-wrapper">
        <div class="row no-gutters">
          <div
            class="col-md-4 col-4 product-wrapper"
            (click)="(onBlock1)"
            [ngClass]="{ 'block-active': block1 }"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            <div class="product-card position-relative">
              <div class="mb-20">
                <span class="color-black font-20">VegitOne</span>
              </div>
              <div>
                <p class="color-black product-desc">
                  One that delivers! VegitOne is dedicated to using cutting-edge
                  technology to transform the professional life cycle of any
                  trucking, fleet, or transportation enterprise. Visit
                  <a href="https://www.vegitone.com" target="_blank"
                    >vegitone.com</a
                  >
                  to learn more.
                </p>
              </div>
              <a
                class="arrow-icon center"
                href="https://www.vegitone.com"
                target="_blank"
              >
                <img
                  [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'"
                />
              </a>
            </div>
            <div class="product-img center">
              <img
                [src]="
                  root.imageUrl() + '/svg-icons/vegit-one-product-logo.svg'
                "
              />
            </div>
          </div>
          <!-- <div
            class="col-md-4 col-4 product-wrapper"
            (click)="(onBlock2)"
            [ngClass]="{ 'block-active': block2 }"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            <div class="product-card position-relative">
              <div class="mb-20">
                <span class="color-black font-20">OneViz</span>
              </div>
              <div>
                <p class="color-black product-desc">
                  OneViz is the hub of a unique and robust suite of solutions
                  that helps to run your societies, clubs, events, conferences,
                  classes, and much more.OneViz is an all in one solution for
                  association management. Visit
                  <a href="https://oneviz.co" target="_blank"> oneviz.co</a> to
                  learn more.
                </p>
              </div>
              <a
                class="arrow-icon center"
                href="https://oneviz.co"
                target="_blank"
              >
                <img
                  [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'"
                />
              </a>
            </div>
            <div class="product-img center">
              <img
                [src]="root.imageUrl() + '/svg-icons/oneviz-product-logo.svg'"
              />
            </div>
          </div> -->
          <div
            class="col-md-4 col-4 product-wrapper"
            (click)="(onBlock3)"
            [ngClass]="{ 'block-active': block3 }"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            <div class="product-card position-relative">
              <div class="mb-20">
                <span class="color-black font-20">GTrack</span>
              </div>
              <div>
                <p class="color-black product-desc">
                  GTrack is a full-fledged GPS tracking solution. With the
                  leverage of IoT devices, it tracks the asset's location and
                  brings you various insights via an interactive dashboard.
                  Visit
                  <a href="https://gtrack.vegitone.com" target="_blank"
                    >gtrack.vegitone.com</a
                  >
                  to learn more.
                </p>
              </div>
              <a
                class="arrow-icon center"
                href="https://gtrack.vegitone.com"
                target="_blank"
              >
                <img
                  [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'"
                />
              </a>
            </div>
            <div class="product-img center">
              <img
                [src]="root.imageUrl() + '/svg-icons/gtrack-product-logo.svg'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="blue-slider" class="slider-block">
      <div
        class="slider-component active"
        (click)="onBlock1()"
        [ngClass]="{ active: block1 }"
      ></div>
      <div
        class="slider-component"
        (click)="onBlock2()"
        [ngClass]="{ active: block2 }"
      ></div>
      <div
        class="slider-component"
        (click)="onBlock3()"
        [ngClass]="{ active: block3 }"
      ></div>
    </div>
  </div>
  <div class="enterprise-section">
    <div class="enterprise-panel">
      <div
        class="d-flex align-items-center justify-content-between flex-wrapper"
      >
        <div>
          <span class="enterprise-text"
            >Unlock the power of the
            <span class="transform-capitalize font-600"
              >Enterprise Technology
            </span></span
          >
        </div>
        <div>
          <a
            class="btn-lg btn-primary"
            target="_top"
            [routerLink]="root.productsList()"
            >READ MORE</a
          >
        </div>
      </div>
    </div>
  </div>
  <div class="branch-panel" data-aos="fade-up" data-aos-duration="1500">
    <div class="branch-wrapper">
      <div class="row no-gutters">
        <div class="col-md-4">
          <div class="text-center address-info">
            <div class="mb-30">
              <img [src]="root.imageUrl() + '/canada-flag-img.png'" />
            </div>
            <div class="mb-20">
              <span class="color-black country-name">Canada (HQ)</span>
            </div>
            <div class="pb-10">
              <span class="color-black">Toronto | Vancouver</span>
            </div>
            <div>
              <span class="color-black">10 Four Seasons Place, Suite 1000</span>
            </div>
            <div><span class="color-black">Toronto, ON</span></div>
            <div><span class="color-black">M9B 6H7, Canada</span></div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="text-center address-info">
            <div class="mb-30">
              <img [src]="root.imageUrl() + '/usa-flag-img.png'" />
            </div>
            <div class="mb-20">
              <span class="color-black country-name">USA</span>
            </div>
            <div class="pb-10">
              <span class="color-black">New York | Austin</span>
            </div>
            <div>
              <span class="color-black">1979 Marcus Avenue Suite 210</span>
            </div>
            <div><span class="color-black">Lake Success, NY</span></div>
            <div><span class="color-black">11042, USA</span></div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="text-center address-info">
            <div class="mb-30">
              <img [src]="root.imageUrl() + '/india-flag-img.png'" />
            </div>
            <div class="mb-20">
              <span class="color-black country-name">India</span>
            </div>
            <div class="pb-10"><span class="color-black">Vadodara</span></div>
            <div>
              <span class="color-black">1008, K-10 Grand,Sarabhai Campus</span>
            </div>
            <div>
              <span class="color-black">Vadi Wadi Road, Vadodara, Gujarat</span>
            </div>
            <div><span class="color-black">390007,India</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #Content let-modal class="career-popup">
  <div class="modal-header">
    <div class="mr-10"><span class="font-600 font-16">Support Form</span></div>
    <button
      type="button"
      class="cancel-icon"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body contactUsBody">
    <form [formGroup]="support">
      <div class="row no-gutters">
        <div class="col-md-6">
          <div class="form-wrapper mr-10">
            <div class="form-group">
              <input
                class="form-control"
                placeholder="First Name"
                formControlName="firstName"
              />
              <div *ngIf="support.touched">
                <div
                  class="errorBlock contactErrorBlock"
                  *ngFor="
                    let validation of support_validation_messages.firstName
                  "
                >
                  <span
                    *ngIf="
                      support.get('firstName').hasError(validation.type) &&
                      (support.get('firstName').dirty ||
                        support.get('firstName').touched)
                    "
                    >{{ validation.message }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-wrapper">
            <div class="form-group">
              <input
                class="form-control"
                placeholder="Last Name"
                formControlName="lastName"
              />
              <div *ngIf="support.touched">
                <div
                  class="errorBlock contactErrorBlock"
                  *ngFor="
                    let validation of support_validation_messages.lastName
                  "
                >
                  <span
                    *ngIf="
                      support.get('lastName').hasError(validation.type) &&
                      (support.get('lastName').dirty ||
                        support.get('lastName').touched)
                    "
                    >{{ validation.message }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-6">
          <div class="form-wrapper mr-10">
            <div class="form-group">
              <input
                class="form-control"
                placeholder="Email"
                formControlName="email"
              />
              <div *ngIf="support.touched">
                <div
                  class="errorBlock contactErrorBlock"
                  *ngFor="let validation of support_validation_messages.email"
                >
                  <span
                    *ngIf="
                      support.get('email').hasError(validation.type) &&
                      (support.get('email').dirty ||
                        support.get('email').touched)
                    "
                    >{{ validation.message }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-wrapper">
            <div class="form-group">
              <input
                class="form-control"
                placeholder="Phone Number"
                mask="000-000-0000"
                formControlName="phone"
              />
              <div *ngIf="support.touched">
                <div
                  class="errorBlock contactErrorBlock"
                  *ngFor="let validation of support_validation_messages.phone"
                >
                  <span
                    *ngIf="
                      support.get('phone').hasError(validation.type) &&
                      (support.get('phone').dirty ||
                        support.get('phone').touched)
                    "
                    >{{ validation.message }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-6">
          <div class="form-wrapper mr-10">
            <div class="form-group">
              <input
                class="form-control"
                placeholder="Organization Name"
                formControlName="organizationName"
              />
              <div *ngIf="support.touched">
                <div
                  class="errorBlock contactErrorBlock"
                  *ngFor="
                    let validation of support_validation_messages.organizationName
                  "
                >
                  <span
                    *ngIf="
                      support
                        .get('organizationName')
                        .hasError(validation.type) &&
                      (support.get('organizationName').dirty ||
                        support.get('organizationName').touched)
                    "
                    >{{ validation.message }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-wrapper">
            <div class="form-group">
              <select class="form-control" formControlName="jobRole">
                <option>Job Role</option>
                <option *ngFor="let jobRole of jobRoleArray">
                  {{ jobRole }}
                </option>
              </select>
              <div *ngIf="support.touched">
                <div
                  class="errorBlock contactErrorBlock"
                  *ngFor="let validation of support_validation_messages.jobRole"
                >
                  <span
                    *ngIf="
                      support.get('jobRole').hasError(validation.type) &&
                      (support.get('jobRole').dirty ||
                        support.get('jobRole').touched)
                    "
                    >{{ validation.message }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-6">
          <div class="form-wrapper mr-10">
            <div class="form-group">
              <input
                class="form-control"
                placeholder="Job Title"
                formControlName="jobTitle"
              />
              <div *ngIf="support.touched">
                <div
                  class="errorBlock contactErrorBlock"
                  *ngFor="
                    let validation of support_validation_messages.jobTitle
                  "
                >
                  <span
                    *ngIf="
                      support.get('jobTitle').hasError(validation.type) &&
                      (support.get('jobTitle').dirty ||
                        support.get('jobTitle').touched)
                    "
                    >{{ validation.message }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-wrapper">
            <div class="form-group">
              <select class="form-control" formControlName="solutionArea">
                <option>Solution Area</option>
                <option *ngFor="let solution of solutionsAray">
                  {{ solution }}
                </option>
              </select>
              <div *ngIf="support.touched">
                <div
                  class="errorBlock contactErrorBlock"
                  *ngFor="
                    let validation of support_validation_messages.solutionArea
                  "
                >
                  <span
                    *ngIf="
                      support.get('solutionArea').hasError(validation.type) &&
                      (support.get('solutionArea').dirty ||
                        support.get('solutionArea').touched)
                    "
                    >{{ validation.message }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-6">
          <div class="form-wrapper mr-10">
            <div class="form-group">
              <select class="form-control" formControlName="industry">
                <option>Industry</option>
                <option *ngFor="let industry of industryArray">
                  {{ industry }}
                </option>
              </select>
              <div *ngIf="support.touched">
                <div
                  class="errorBlock contactErrorBlock"
                  *ngFor="
                    let validation of support_validation_messages.industry
                  "
                >
                  <span
                    *ngIf="
                      support.get('industry').hasError(validation.type) &&
                      (support.get('industry').dirty ||
                        support.get('industry').touched)
                    "
                    >{{ validation.message }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-wrapper">
            <div class="form-group">
              <input
                class="form-control"
                placeholder="Country"
                formControlName="country"
              />
              <div *ngIf="support.touched">
                <div
                  class="errorBlock contactErrorBlock"
                  *ngFor="let validation of support_validation_messages.country"
                >
                  <span
                    *ngIf="
                      support.get('country').hasError(validation.type) &&
                      (support.get('country').dirty ||
                        support.get('country').touched)
                    "
                    >{{ validation.message }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-12">
          <div class="form-group">
            <textarea
              class="form-control"
              cols=""
              rows="6"
              formControlName="message"
              placeholder="Message/ Details / Comments"
            ></textarea>
            <div *ngIf="support.touched">
              <div
                class="errorBlock contactErrorBlock"
                *ngFor="let validation of support_validation_messages.message"
              >
                <span
                  *ngIf="
                    support.get('message').hasError(validation.type) &&
                    (support.get('message').dirty ||
                      support.get('message').touched)
                  "
                  >{{ validation.message }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-20">
        <div>
          <a
            class="btn-md btn-primary"
            target="_top"
            (click)="contactUsSubmit()"
            >Submit</a
          >
        </div>
      </div>

      <div class="error-msg-block" *ngIf="showErrorMsg">
        <div class="error-icon">
          <img [src]="root.imageUrl() + '/svg-icons/error-red-icon.svg'" />
        </div>
        <div>
          <div class="error-title">
            <span class="error-title-text"> Error </span>
          </div>
          <div class="error-msg-panel">
            <span class="error-msg-panel-text"> Error sending Message </span>
          </div>
        </div>
      </div>
      <div class="error-msg-block success" *ngIf="showSuccessMsg">
        <div class="error-icon">
          <img [src]="root.imageUrl() + '/svg-icons/success-check-icon.svg'" />
        </div>
        <div>
          <div class="error-title">
            <span class="error-title-text"> Success </span>
          </div>
          <div class="error-msg-panel">
            <span class="error-msg-panel-text">
              Thank you. We will get back your request.
            </span>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #Content1 let-modal class="career-popup">
  <div class="modal-header">
    <div class="mr-10"><span class="font-600 font-16">Enquiry Form</span></div>
    <button
      type="button"
      class="cancel-icon"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body contactUsBody">
    <form [formGroup]="inquiryForm">
      <div class="row no-gutters">
        <div class="col-md-6">
          <div class="form-wrapper mr-10">
            <div class="form-group">
              <input
                class="form-control"
                placeholder="First Name"
                formControlName="firstName"
              />
              <div *ngIf="inquiryForm.touched">
                <div
                  class="errorBlock contactErrorBlock"
                  *ngFor="
                    let validation of support_validation_messages.firstName
                  "
                >
                  <span
                    *ngIf="
                      inquiryForm.get('firstName').hasError(validation.type) &&
                      (inquiryForm.get('firstName').dirty ||
                        inquiryForm.get('firstName').touched)
                    "
                    >{{ validation.message }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-wrapper">
            <div class="form-group">
              <input
                class="form-control"
                placeholder="Last Name"
                formControlName="lastName"
              />
              <div *ngIf="inquiryForm.touched">
                <div
                  class="errorBlock contactErrorBlock"
                  *ngFor="
                    let validation of support_validation_messages.lastName
                  "
                >
                  <span
                    *ngIf="
                      inquiryForm.get('lastName').hasError(validation.type) &&
                      (inquiryForm.get('lastName').dirty ||
                        inquiryForm.get('lastName').touched)
                    "
                    >{{ validation.message }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-6">
          <div class="form-wrapper mr-10">
            <div class="form-group">
              <input
                class="form-control"
                placeholder="Email"
                formControlName="email"
              />
              <div *ngIf="inquiryForm.touched">
                <div
                  class="errorBlock contactErrorBlock"
                  *ngFor="let validation of support_validation_messages.email"
                >
                  <span
                    *ngIf="
                      inquiryForm.get('email').hasError(validation.type) &&
                      (inquiryForm.get('email').dirty ||
                        inquiryForm.get('email').touched)
                    "
                    >{{ validation.message }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-wrapper">
            <div class="form-group">
              <input
                class="form-control"
                placeholder="Phone Number"
                formControlName="phone"
                mask="000-000-0000"
              />
              <div *ngIf="inquiryForm.touched">
                <div
                  class="errorBlock contactErrorBlock"
                  *ngFor="let validation of support_validation_messages.phone"
                >
                  <span
                    *ngIf="
                      inquiryForm.get('phone').hasError(validation.type) &&
                      (inquiryForm.get('phone').dirty ||
                        inquiryForm.get('phone').touched)
                    "
                    >{{ validation.message }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-6">
          <div class="form-wrapper mr-10">
            <div class="form-group">
              <input
                class="form-control"
                placeholder="Organization Name"
                formControlName="organizationName"
              />
              <div *ngIf="inquiryForm.touched">
                <div
                  class="errorBlock contactErrorBlock"
                  *ngFor="
                    let validation of support_validation_messages.organizationName
                  "
                >
                  <span
                    *ngIf="
                      inquiryForm
                        .get('organizationName')
                        .hasError(validation.type) &&
                      (inquiryForm.get('organizationName').dirty ||
                        inquiryForm.get('organizationName').touched)
                    "
                    >{{ validation.message }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-wrapper">
            <div class="form-group">
              <input
                class="form-control"
                placeholder="Country"
                formControlName="country"
              />
              <div *ngIf="inquiryForm.touched">
                <div
                  class="errorBlock contactErrorBlock"
                  *ngFor="let validation of support_validation_messages.country"
                >
                  <span
                    *ngIf="
                      inquiryForm.get('country').hasError(validation.type) &&
                      (inquiryForm.get('country').dirty ||
                        inquiryForm.get('country').touched)
                    "
                    >{{ validation.message }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-6">
          <div class="form-wrapper mr-10">
            <div class="form-group">
              <select class="form-control" formControlName="enquiringFor">
                <option>Enquiring for</option>
                <option *ngFor="let enquiryValue of enquiryArray">
                  {{ enquiryValue }}
                </option>
              </select>
              <div *ngIf="inquiryForm.touched">
                <div
                  class="errorBlock contactErrorBlock"
                  *ngFor="
                    let validation of support_validation_messages.enquiringFor
                  "
                >
                  <span
                    *ngIf="
                      inquiryForm
                        .get('enquiringFor')
                        .hasError(validation.type) &&
                      (inquiryForm.get('enquiringFor').dirty ||
                        inquiryForm.get('enquiringFor').touched)
                    "
                    >{{ validation.message }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-12">
          <div class="form-group">
            <textarea
              class="form-control"
              cols=""
              rows="6"
              formControlName="message"
              placeholder="Message/ Details / Comments"
            ></textarea>
            <div *ngIf="inquiryForm.touched">
              <div
                class="errorBlock contactErrorBlock"
                *ngFor="let validation of support_validation_messages.message"
              >
                <span
                  *ngIf="
                    inquiryForm.get('message').hasError(validation.type) &&
                    (inquiryForm.get('message').dirty ||
                      inquiryForm.get('message').touched)
                  "
                  >{{ validation.message }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-20">
        <div>
          <a class="btn-md btn-primary" target="_top" (click)="enquirySubmit()"
            >Submit</a
          >
        </div>
      </div>
      <div class="error-msg-block" *ngIf="showErrorMsg">
        <div class="error-icon">
          <img [src]="root.imageUrl() + '/svg-icons/error-red-icon.svg'" />
        </div>
        <div>
          <div class="error-title">
            <span class="error-title-text"> Error </span>
          </div>
          <div class="error-msg-panel">
            <span class="error-msg-panel-text"> Error sending Message </span>
          </div>
        </div>
      </div>
      <div class="error-msg-block success" *ngIf="showSuccessMsg">
        <div class="error-icon">
          <img [src]="root.imageUrl() + '/svg-icons/success-check-icon.svg'" />
        </div>
        <div>
          <div class="error-title">
            <span class="error-title-text"> Success </span>
          </div>
          <div class="error-msg-panel">
            <span class="error-msg-panel-text">
              Thank you. We will get back your request.
            </span>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
