<div
  class="journey-bg"
  data-aos="fade-up"
  data-aos-duration="1500"
  id="journey"
>
  <div class="title-wrapper">
    <div class="right-content">
      <div class="d-flex align-items-center justify-content-end">
        <div><span class="color-black year-no">8</span></div>
        <div class="ml-10">
          <div>
            <span class="color-black font-18">Years of</span>
          </div>
          <div class="info-content">
            <span class="color-orange">Journey</span>
          </div>
        </div>
      </div>
      <div>
        <span class="color-black content-text"
          >What began as a three-person team has grown into a global company
          with over 200 motivated employees, five offices across the world, and
          a long list of pleased and delighted customers.
        </span>
      </div>
    </div>
  </div>
  <div class="journey-body" data-aos="fade-down" data-aos-duration="1500">
    <img
      [src]="root.imageUrl() + '/journy-timeline-img.png'"
      class="journey-timeline-img default"
    />
    <!-- <img [src]="root.imageUrl() + '/journy-timeline-responsive-img.png' " class="journey-timeline-img responsive"> -->

    <div
      class="overflow-wrapper -ms-scrollbar scrollable-body journey-timeline-img responsive"
    >
      <div class="center blocks-wrapper">
        <div
          class="journey-blocks wow animated animate__animated animate__slow"
          (click)="(onBlock1)"
          [ngClass]="{ 'block-active': block1 }"
        >
          <div class="outer">
            <div class="top">
              <div class="line"></div>
            </div>
            <div class="bottom"></div>
            <div class="journey-content-wrapper position-absolute">
              <div class="journey-content-panel">
                <div class="row" style="padding-bottom: 25px">
                  <div class="col-6">
                    <div><span class="journey-no">2013-16</span></div>
                    <div><span class="journey-des">Year(s)</span></div>
                  </div>
                  <div class="col-6">
                    <div><span class="journey-no">20+</span></div>
                    <div><span class="journey-des">Employee(s)</span></div>
                  </div>
                </div>
                <div class="journey-content-height"></div>
                <div class="row">
                  <div class="col-sm-12">
                    <p class="content-wrap">The start of ECS</p>
                    <p class="content-wrap">First Client project - Team of 3</p>
                    <p class="content-wrap">75+ projects, 25+ clients</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="journey-blocks wow animated animate__animated animate__slow"
          (click)="(onBlock2)"
          [ngClass]="{ 'block-active': block2 }"
        >
          <div class="outer">
            <div class="top">
              <div class="line"></div>
            </div>
            <div class="bottom"></div>
            <div class="journey-content-wrapper position-absolute">
              <div class="journey-content-panel">
                <div class="row" style="padding-bottom: 25px">
                  <div class="col-6">
                    <div><span class="journey-no">2017-18</span></div>
                    <div><span class="journey-des">Year(s)</span></div>
                  </div>
                  <div class="col-6">
                    <div><span class="journey-no">50+</span></div>
                    <div><span class="journey-des">Employee(s)</span></div>
                  </div>
                </div>
                <div></div>
                <div class="journey-content-height"></div>
                <div class="row">
                  <div class="col-sm-12">
                    <p class="content-wrap">
                      ekZero Corporation founded in Canada
                    </p>
                    <p class="content-wrap">
                      ekZero expanded in USA, 1st development office in India
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="journey-blocks wow animated animate__animated animate__slow"
          (click)="onBlock3()"
          [ngClass]="{ 'block-active': block3 }"
        >
          <div class="outer">
            <div class="top">
              <div class="line"></div>
            </div>
            <div class="bottom"></div>
            <div class="journey-content-wrapper position-absolute">
              <div class="journey-content-panel">
                <div class="row" style="padding-bottom: 25px">
                  <div class="col-6">
                    <div><span class="journey-no">2019-21</span></div>
                    <div><span class="journey-des">Year(s)</span></div>
                  </div>
                  <div class="col-6">
                    <div><span class="journey-no">Full time</span></div>
                    <div><span class="journey-des">Employee(s)</span></div>
                  </div>
                </div>
                <div class="journey-content-height"></div>
                <div class="row">
                  <div class="col-sm-12">
                    <p class="content-wrap">
                      VegitOne Product family and 1st incubation project
                    </p>
                    <p class="content-wrap">GTrack & OneViz product Family</p>
                    <p class="content-wrap">
                      7 current & upcoming products, client and customers 4
                      continent and counting
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="slider-block" id="blue-slider">
            <div class="slider-component" (click)="onBlock1()" [ngClass]="{'active': block1}"></div>
            <div class="slider-component" (click)="onBlock2()" [ngClass]="{'active': block2}"></div>
            <div class="slider-component" (click)="onBlock3()" [ngClass]="{'active': block3}"></div>
        </div> -->
  </div>
</div>
