import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'ekvegit',
  templateUrl: './ekvegit.component.html',
  styleUrls: ['./ekvegit.component.scss']
})
export class EkvegitComponent implements OnInit {

  newTitle: string = 'Trucking Dispatch Management Solution | VegitOne by ekZero';
  longDescription: string = ' VegitOne by ekZero is a Fleet Management solution. Manage Orders | Dispatch | Orders | Routes | Accounts | FTL/LTL Loads and more with VegitOne';
  longKeyword: string = 'ekZero, VegitOne by ekZero, Dispatch Management, Trucking Management, Telematics, TMS, Fleet Management, Transportation Management, VegitOne, One that Delivers, Orders, Dispatch, Orders, Routes, Accounts, FTL/LTL Load';


  constructor(private title: Title,
    private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle(this.newTitle);
    this.meta.addTag({ name: "description", content: this.longDescription });
    this.meta.addTag({ name: "keywords", content: this.longKeyword });

  }
}
