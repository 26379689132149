<career-banner></career-banner>
<div class="ez-section-card">
    <div class="ez-technology-platform-section">
        <div class="technologies-section">
            <div>
                <career></career>
                <career-specifications></career-specifications>
                <app-life-at-ekzero></app-life-at-ekzero>
                <career-openings></career-openings>
            </div>
        </div>
    </div>
</div>