<ecs-banner></ecs-banner>
<div class="ez-section-card">
    <div class="ez-technology-platform-section">
        <div class="technologies-section">
            <div class="technologies-section-bg">
                <ecs-intro></ecs-intro>              
            </div>
            <div>
                <services></services>
                <ecs-work-process></ecs-work-process>
                <technologies></technologies>
                <!-- <ecs-case-study></ecs-case-study> -->
                <industries></industries>
                <!-- <testimonials></testimonials> -->
                <our-portfolio></our-portfolio>                
                <sales></sales>
                <!-- <timezone></timezone> -->
            </div>
        </div>
    </div>
</div>