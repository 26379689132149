import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'gtrack-product-technology',
  templateUrl: './gtrack-product-technology.component.html',
  styleUrls: ['./gtrack-product-technology.component.scss']
})
export class GtrackProductTechnologyComponent implements OnInit {

  constructor(public root: RootService) { }

  ngOnInit(): void {
  }

}
