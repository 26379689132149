import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';
import Typewriter from 't-writer.js';
@Component({
  selector: 'app-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrls: ['./home-banner.component.scss']
})
export class HomeBannerComponent implements OnInit {

  @ViewChild('tw1', { static: true }) typewriterElement1: ElementRef;

  constructor(public root: RootService) { }

  ngOnInit(): void {
    const target1 = this.typewriterElement1.nativeElement;

    const writer1 = new Typewriter(target1, 
      {
      loop: false,
      delay: 75,
      autoStart: false,
      typeColor: 'white'
    });    
      writer1
        .type('Digital Products, Platforms & Experiences')
        .rest(3000)
        .start()       
  }  

}
