import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AccordionModule} from "ngx-accordion";


import { CareersRoutingModule } from './careers-routing.module';
import { CareerHomeComponent } from './pages/career-home/career-home.component';
import { CareerBannerComponent } from './components/career-banner/career-banner.component';
import { CareerComponent } from './components/career/career.component';
import { CareerSpecificationsComponent } from './components/career-specifications/career-specifications.component';
import { CareerOpeningsComponent } from './components/career-openings/career-openings.component';
import { LifeAtEkzeroComponent } from './components/life-at-ekzero/life-at-ekzero.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [CareerHomeComponent, CareerBannerComponent, CareerComponent, CareerSpecificationsComponent, CareerOpeningsComponent, LifeAtEkzeroComponent],
  imports: [
    CommonModule,
    CareersRoutingModule,
    AccordionModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskModule,
    SharedModule
  ]
})
export class CareersModule { }
