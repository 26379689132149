<!-- <blog-banner></blog-banner> -->
<div id="blog-detail">
    <div class="blog-detail-wrapper">
        <div class="row no-gutters">
            <div class="col-xl-8 col-lg-7 col-12 col-md-order">
                <div class="left-content-panel">
                    <div>
                        <img [src]="root.imageUrl() + '/ez-blog1-img.png '" class="blog-detail-img">
                    </div>
                    <div class="text-wrapper">
                        <div class="mb-20">
                            <span class="blog-title color-black">Analyzing The Evolving Software Development Trends In
                                Detail</span>
                        </div>
                        <div class="d-flex align-items-center justify-content-start blog-publish">
                            <div><span class="color-orange">Software</span></div>
                            <div class="ml-15"><span class="pipe color-black">|</span></div>
                            <div class="ml-15"><span class="color-black">Nov 25, 2020</span></div>
                        </div>
                    </div>
                    <div class="key-points-section">
                        <div class="text-wrapper"><a class="btn-md btn-secondary font-16">Key Points</a></div>
                        <div class="key-points-block">
                            <ul class="pl-0">
                                <li>Tips for finding the finest Mobile App Development Company</li>
                                <li>Tips for finding the finest Mobile App Development Company</li>
                                <li>Tips for finding the finest Mobile App Development Company
                                    <ul class="sub-points">
                                        <li>Tips for finding the finest Mobile App Development Company</li>
                                        <li>Tips for finding the finest Mobile App Development Company</li>
                                    </ul>
                                </li>
                                <li>Tips for finding the finest Mobile App Development Company</li>
                                <li>Tips for finding the finest Mobile App Development Company</li>
                            </ul>
                        </div>
                    </div>
                    <div class="blog-desc">
                        <p class="content-desc">Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when
                            an unknown printer took a galley of type and scrambled it to make a type specimen book. It
                            has survived not only five centuries, but also the leap into electronic typesetting,
                            remaining essentially unchanged. It was popularised in the 1960s with the release of
                            Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                            software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <p class="sub-content-desc">Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book. It has survived not only five
                            centuries</p>
                        <p class="content-desc">Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when
                            an unknown printer took a galley of type and scrambled it to make a type specimen book. It
                            has survived not only five centuries, but also the leap into electronic typesetting,
                            remaining essentially unchanged. It was popularised in the 1960s with the release of
                            Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                            software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    </div>
                    <div class="blog-keywords">
                        <div class="keyowrds d-flex align-items-center flex-wrap">
                            <a class="color-orange">Software</a>
                            <a class="color-orange">Graphic</a>
                            <a class="color-orange">Android App Devlopment</a>
                        </div>
                    </div>
                    <div class="comment-section">
                        <div>
                            <span class="color-black form-title">COMMENTS <span class="comment-no">(3)</span></span>
                        </div>
                        <div>
                            <div class="comments-block">
                                <div class="d-flex flex-wrapper">
                                    <div><img [src]="root.imageUrl() + '/user-img.png' " class="user-img"></div>
                                    <div class="right-content">
                                        <div class="mb-5"><span class="color-black">Lucy Stewart</span></div>
                                        <div class="mb-10"><span class="color-black font-12">Dec 14, 2020</span></div>
                                        <div class="mb-50">
                                            <p class="color-black">Lorem Ipsum is simply dummy text of the printing and
                                                typesetting
                                                industry. Lorem Ipsum has been the industry's standard dummy text ever
                                                since the 1500s.</p>
                                        </div>
                                        <div class="d-flex align-items-center reply-wrapper">
                                            <a class="reply-arrow"></a>
                                            <div class="ml-20"><span class="color-black">Reply</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="comments-block">
                                <div class="d-flex flex-wrapper">
                                    <div><img [src]="root.imageUrl() + '/user-img.png' " class="user-img"></div>
                                    <div class="right-content">
                                        <div class="mb-5"><span class="color-black">Lucy Stewart</span></div>
                                        <div class="mb-10"><span class="color-black font-12">Dec 14, 2020</span></div>
                                        <div class="mb-50">
                                            <p class="color-black">Lorem Ipsum is simply dummy text of the printing and
                                                typesetting
                                                industry. Lorem Ipsum has been the industry's standard dummy text ever
                                                since the 1500s.</p>
                                        </div>
                                        <div class="d-flex align-items-center reply-wrapper">
                                            <a class="reply-arrow"></a>
                                            <div class="ml-20"><span class="color-black">Reply</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="comments-block">
                                <div class="d-flex flex-wrapper">
                                    <div><img [src]="root.imageUrl() + '/user-img.png' " class="user-img"></div>
                                    <div class="right-content">
                                        <div class="mb-5"><span class="color-black">Lucy Stewart</span></div>
                                        <div class="mb-10"><span class="color-black font-12">Dec 14, 2020</span></div>
                                        <div class="mb-50">
                                            <p class="color-black">Lorem Ipsum is simply dummy text of the printing and
                                                typesetting
                                                industry. Lorem Ipsum has been the industry's standard dummy text ever
                                                since the 1500s.</p>
                                        </div>
                                        <div class="d-flex align-items-center reply-wrapper">
                                            <a class="reply-arrow"></a>
                                            <div class="ml-20"><span class="color-black">Reply</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="comment-form">
                        <div>
                            <div class="mb-20"><span class="color-black form-title">LEAVE A REPLY</span></div>
                            <div><span class="color-black">Your email address will not be published. Required fields are
                                    marked</span></div>
                        </div>
                        <div class="post-form">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input class="form-control" placeholder="Name">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input class="form-control" placeholder="Email">
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="form-group">
                                    <textarea class="form-control" placeholder="Type Comment Here" rows="7"></textarea>
                                </div>
                            </div>
                            <div class="btn-grp mt-40">
                                <a class="btn-primary btn-xlg" target="_top">POST COMMENT</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-5 col-12">
                <div class="right-content-panel">
                    <div class="content-body">
                        <div class="search-block mb-20">
                            <div><span class="color-black font-18">Search our <span
                                        class="color-orange transform-capitalize font-18">Blogs</span></span></div>
                            <div class="mt-20">
                                <div class="search-bar">
                                    <input type="text" class="form-control search-input" placeholder="Search...">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-body">
                        <div class="mb-20">
                            <div><span class="color-black font-18">Recent <span
                                        class="color-orange transform-capitalize font-18">Posts</span></span></div>
                            <div class="mt-20">
                                <div class="collapsable-wrapper position-relative"
                                    [ngClass]="{'collapsable-active': isDisplay}">
                                    <div
                                        class="collapsable-header responsive-view align-items-center justify-content-between mt-30">
                                        <div><span class="color-black">Check Our Recent Posts</span></div>
                                        <div><a class="collapsable-icon" (click)="isDisplay = !isDisplay"></a></div>
                                    </div>
                                    <div class="blog-title-list blog-list collapsable-body -ms-scrollbar scrollable-body" [ngbCollapse]="!isDisplay">
                                        <div class="blog-title active"><a>Tips for finding the finest Mobile App
                                                Development
                                                Company</a></div>
                                        <div class="blog-title"><a>Tips for finding the finest Mobile App Development
                                                Company</a></div>
                                        <div class="blog-title"><a>ERP Software Solutions: Top Benefits of Developing
                                                ERP
                                                Software</a></div>
                                        <div class="blog-title"><a>React vs Svelte – A Comprehensive Comparison Between
                                                Javascript Libraries</a></div>
                                        <div class="blog-title"><a>How Does Amazon Mechanical Turk (MTurk) Help Reinvent
                                                Business Processes?</a></div>
                                        <div class="blog-title"><a>Top 15 Mobile App Ideas That Will Inspire You In
                                                2021</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-body">
                        <div class="mb-20">
                            <div><span class="color-black font-18">Categories </span></div>
                            <div class="mt-20">
                                <div class="collapsable-wrapper position-relative"
                                    [ngClass]="{'collapsable-active': isShow}">
                                    <div
                                    class="collapsable-header responsive-view align-items-center justify-content-between mt-30">
                                    <div><span class="color-black">Select Categories</span></div>
                                    <div><a class="collapsable-icon" (click)="isShow = !isShow"></a></div>
                                </div>
                                    <div class="blog-category-list blog-list collapsable-body -ms-scrollbar scrollable-body mt-20" [ngbCollapse]="!isShow">
                                        <div class="d-flex align-items-center flex-wrap">
                                            <div class="tag-item"><a>Software</a></div>
                                            <div class="tag-item"><a>Graphic</a></div>
                                            <div class="tag-item"><a>Android App Development</a></div>
                                            <div class="tag-item"><a>JavaScript</a></div>
                                            <div class="tag-item"><a>Startup</a></div>
                                            <div class="tag-item"><a>Machine Learning</a></div>
                                            <div class="tag-item"><a>JavaScript</a></div>
                                            <div class="tag-item"><a>Startup</a></div>
                                            <div class="tag-item"><a>React Native</a></div>
                                            <div class="tag-item"><a>Angular Js</a></div>
                                            <div class="tag-item"><a>Software</a></div>
                                            <div class="tag-item"><a>Graphic</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>