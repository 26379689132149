<div id="service-detail-intro">
    <div class="our-services">
        <!-- --------------Cloud Services---------------start -->
        <div class="our-services-wrapper">
            <div class="orange-bg position-relative">
                <div class="row">
                    <div class="col-12">
                        <div class="width-50 mb-50 text-align-center pr-10">
                            <div class="mb-30">
                                <h2 class="font-600">Cloud Services</h2>
                            </div>
                            <div>
                                <p class="font-14">Transform your IT environment with superior Cloud computing services.
                                    ekZero assists customers with on-demand delivery of cloud computing services
                                    enabling
                                    them to become more agile and responsive to the ever-evolving marketing landscape.
                                    Our
                                    certified professionals are experts in Amazon and Azure cloud services.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-lg-4 col-order-2">
                        <div class="justify-content-sb h-100">
                            <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                                <div class="logo-block">
                                    <img [src]="root.imageUrl() + '/svg-icons/cloud.svg'" class="wifi-logo">
                                    <!-- <img src="assets/images/svg-icon/cloud.svg" class="wifi-logo"> -->
                                </div>
                                <div>
                                    <div class="mb-10">
                                        <h5>Cloud Migration</h5>
                                    </div>
                                    <div><span class="font-14">Get your first step towards digital transformation
                                            through
                                            the right strategy, roadmap and ecosystem collaborations.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                                <div class="logo-block">
                                    <img [src]="root.imageUrl() + '/svg-icons/optimization.svg'" class="wifi-logo">
                                    <!-- <img src="assets/images/svg-icon/optimization.svg" class="wifi-logo"> -->
                                </div>
                                <div>
                                    <div class="mb-10">
                                        <h5>Cloud Optimization</h5>
                                    </div>
                                    <div><span class="font-14">Automate your operations, and control resource consumption
                                            and
                                            costs by streamlining your cloud environment.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                                <div class="logo-block">
                                    <img [src]="root.imageUrl() + '/svg-icons/maintenance.svg'" class="wifi-logo">
                                    <!-- <img src="assets/images/svg-icon/maintenance.svg" class="wifi-logo"> -->
                                </div>
                                <div>
                                    <div class="mb-10">
                                        <h5>Cloud Maintenance</h5>
                                    </div>
                                    <div><span class="font-14">Ensure 24/7 round the clock cloud and server performance
                                            monitoring with zero downtime.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-4 col-order-1">
                        <div class="center h-100">
                            <img [src]="root.imageUrl() + '/ez-services-cloud-services.png'" class="center-img"
                                data-aos="flip-left" data-aos-duration="1500">
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 col-order-2 ">
                        <div class="justify-content-sb h-100 text-align">
                            <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                                <div class="change-order">
                                    <div class="mb-10">
                                        <h5 class="font-black">Cloud Architecture</h5>
                                    </div>
                                    <div><span class="font-14 font-black">Setup a secure cloud architecture for your
                                            organization. From setting up a framework to maintenance ekZero can assist you
                                            with all.
                                        </span>
                                    </div>
                                </div>
                                <div class="logo-block right-block">
                                    <img [src]="root.imageUrl() + '/svg-icons/architecture.svg'" class="wifi-logo">
                                    <!-- <img src="assets/images/svg-icon/architecture.svg" class="wifi-logo"> -->

                                </div>
                            </div>
                            <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                                <div class="change-order">
                                    <div class="mb-10">
                                        <h5 class="font-black">Cloud Consulting</h5>
                                    </div>
                                    <div><span class="font-14 font-black">End-to-end cloud consulting offerings that are
                                            designed to support you in your tech decisions from exploratory evaluations
                                            to
                                            cloud strategy to solutions.
                                        </span></div>
                                </div>
                                <div class="logo-block right-block">
                                    <img [src]="root.imageUrl() + '/svg-icons/consulting.svg'" class="wifi-logo">
                                    <!-- <img src="assets/images/svg-icon/consulting.svg" class="wifi-logo"> -->
                                </div>
                            </div>
                            <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                                <div class="change-order">
                                    <div class="mb-10">
                                        <h5 class="font-black">Managed Cloud Services
                                        </h5>
                                    </div>
                                    <div><span class="font-14 font-black">Collaborative process that provides migration,
                                            maintenance and optimization on various cloud environments.
                                        </span></div>
                                </div>
                                <div class="logo-block right-block">
                                    <img [src]="root.imageUrl() + '/svg-icons/cs.svg'" class="wifi-logo">
                                    <!-- <img src="assets/images/svg-icon/cs.svg" class="wifi-logo"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="dots">
                    <div class="point bg-white"></div>
                    <div class="point bg-white"></div>
                    <div class="point bg-white"></div>
                </div> -->
            </div>
        </div>

        <!-- --------------Cloud Services---------------end -->
    </div>
    <div class="detail-intro" data-aos="fade-up" data-aos-duration="1500">
        <div class="row">
            <div class="col-lg-6">
                <div class="left-content">
                    <div class="info-content mb-30"><span class="color-orange font-">Simplified Cloud Methodologies That
                            Increases Profitability With Accelerated Time-To-Market. </span></div>
                    <div class="detail-info">
                        <p class="color-black content-text">ekZero helps organizations in the smooth transition to the
                            cloud through a secure cloud consulting framework. You can easily scale up and migrate your
                            chosen applications. No matter whether your applications are running in an on-premise captive data
                            center, a 3rd party hosted public or private cloud, or a combination of two, ekZero is a
                            one-stop cloud consulting provider that enables organizations to reduce IT resource
                            requirements and improves productivity. Our clients prefer us because we help them in
                            driving down the cost, bringing flexibility, 24/7 security, and compliance in
                            mission-critical applications. Our certified professionals are experts in Amazon and Azure
                            cloud services.
                        </p>
                    </div>
                    <div class="btn-grp"><a class="btn-primary btn-xlg" [routerLink]="root.contact()"
                            target="_top">Connect with ekZero</a></div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="right-content">
                    <img [src]="root.imageUrl() + '/service-cloud-img.png'">
                </div>
            </div>
        </div>
    </div>
</div>