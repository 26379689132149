<div id="faq-section">
    <div class="panel-wrapper panel-wrapper-bg">
        <div class="row no-gutters row-wrapper">
            <div class="col-12 col-lg-6">
                <div class="faq-left-content"  data-aos="fade-right" data-aos-duration="1500">
                    <img [src]="root.imageUrl() + '/faq-intro-img.png '">
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="faq-right-content" data-aos="fade-left" data-aos-duration="1500">
                    <div>
                        <div class="info-title">
                            <span class="color-black">FAQS</span>
                        </div>
                        <div class="mt-20 info-content">
                            <span class="color-orange">Do you have Any Questions?</span>
                        </div>
                        <div>
                            <p class="color-black content-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed
                                do eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum suspendisse
                                ultrices
                                gravida.</p>
                        </div>
                    </div>
                    <div class="faq-info-block">      
                        <div class="accordion panel-group" id="accordionExample">
                            <div class="accordion-item panel-default">
                              <div class="accordion-header" id="headingOne">
                                <div class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <div class="w-100">
                                        <div class="opening-info d-flex justify-content-between align-items-center">
                                            <div><span class="panel-title color-black">Where should I Incorporate my
                                                    business? </span></div>
                                            <div class="btn-toggle center flex-column">
                                                <div class="toggle-plus toggle-transform"></div>
                                                <div class="toggle-plus"></div>
                                            </div>
                                            <!-- <div class="toggle-close"><a
                                                        class="btn-toggle center"><span>-</span></a></div> -->
                                        </div>
                                    </div>
                                </div>
                              </div>
                              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="require-info">
                                        <p class="color-black"> Leverage agile frameworks to provide a robust synopsis for
                                            high level overviews. Iterative approaches to corporate strategy foster
                                            collaborative thinking to further the overall value proposition. Impress clients
                                            new and existing with elite construction brochures. Impress clients.</p>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item panel-default">
                              <div class="accordion-header" id="headingTwo">
                                <div class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <div class="w-100">
                                        <div class="opening-info d-flex justify-content-between align-items-center">
                                            <div><span class="panel-title color-black">Where should I Incorporate my
                                                    business? </span></div>
                                            <div class="btn-toggle center flex-column">
                                                <div class="toggle-plus toggle-transform"></div>
                                                <div class="toggle-plus"></div>
                                            </div>
                                            <!-- <div class="toggle-close"><a
                                                        class="btn-toggle center"><span>-</span></a></div> -->
                                        </div>
                                    </div>
                                </div>
                              </div>
                              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="require-info">
                                        <p class="color-black"> Leverage agile frameworks to provide a robust synopsis for
                                            high level overviews. Iterative approaches to corporate strategy foster
                                            collaborative thinking to further the overall value proposition. Impress clients
                                            new and existing with elite construction brochures. Impress clients.</p>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item panel-default">
                              <div class="accordion-header" id="headingThree">
                                <div class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <div class="w-100">
                                        <div class="opening-info d-flex justify-content-between align-items-center">
                                            <div><span class="panel-title color-black">What access do I have on the free plan?
                                            </span></div>
                                            <div class="btn-toggle center flex-column">
                                                <div class="toggle-plus toggle-transform"></div>
                                                <div class="toggle-plus"></div>
                                            </div>
                                            <!-- <div class="toggle-close"><a
                                                        class="btn-toggle center"><span>-</span></a></div> -->
                                        </div>
                                    </div>
                                </div>
                              </div>
                              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="require-info">
                                        <p class="color-black"> Leverage agile frameworks to provide a robust synopsis for
                                            high level overviews. Iterative approaches to corporate strategy foster
                                            collaborative thinking to further the overall value proposition. Impress clients
                                            new and existing with elite construction brochures. Impress clients.</p>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item panel-default">
                                <div class="accordion-header" id="headingFour">
                                  <div class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                      <div class="w-100">
                                          <div class="opening-info d-flex justify-content-between align-items-center">
                                              <div><span class="panel-title color-black">Where should I Incorporate my
                                                      business? </span></div>
                                              <div class="btn-toggle center flex-column">
                                                  <div class="toggle-plus toggle-transform"></div>
                                                  <div class="toggle-plus"></div>
                                              </div>
                                              <!-- <div class="toggle-close"><a
                                                          class="btn-toggle center"><span>-</span></a></div> -->
                                          </div>
                                      </div>
                                  </div>
                                </div>
                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                  <div class="accordion-body">
                                      <div class="require-info">
                                          <p class="color-black"> Leverage agile frameworks to provide a robust synopsis for
                                              high level overviews. Iterative approaches to corporate strategy foster
                                              collaborative thinking to further the overall value proposition. Impress clients
                                              new and existing with elite construction brochures. Impress clients.</p>
                                      </div>
                                  </div>
                                </div>
                              </div>
                          </div>                                                            
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- web design tab section | starts-->
    <div class="tab-section">
        <div class="content-overflow" #widgetsContent>
            <ul ngbNav #nav="ngbNav" class="nav-tabs center">
                <li ngbNavItem>
                    <div>
                        <a ngbNavLink class="center flex-column">
                            <div class="tech-icon center mb-20">
                                <img [src]="root.imageUrl() + '/svg-icons/web-design-icon.svg'" class="default-icon">
                                <img [src]="root.imageUrl() + '/svg-icons/web-design-icon-hover.svg'" class="hover-icon">
                            </div>
                            Web Design
                        </a>
                    </div>
                    <ng-template ngbNavContent>
                        <div class="tech-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col-lg-5">
                                    <div class="left-content">
                                        <div>
                                            <div class="info-title">
                                                <span class="color-black">Web Design</span>
                                            </div>
                                            <div class="mt-30">
                                                <p class="color-black content-text">Lorem ipsum dolor sit amet,
                                                    consectetur
                                                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                                    dolore
                                                    magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo
                                                    viverra maecenas accumsan facilisis.</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="tech-key-points" data-aos="fade-right" data-aos-duration="1500" data-aos="fade-right" data-aos-duration="1500">
                                                <div class="d-flex">
                                                    <div class="right-img">
                                                        <span></span>
                                                    </div>
                                                    <div class="pl-30">
                                                        <div class="key-point-title">
                                                            <span class="color-black">Super Responsive</span>
                                                        </div>
                                                        <div class="key-point-content">
                                                            <p class="color-black content-text">Lorem ipsum dolor sit
                                                                amet,
                                                                consectetur
                                                                adipiscing elit, sed do eiusmod tempor incididunt ut
                                                                labore
                                                                et
                                                                dolore magna aliqua.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tech-key-points" data-aos="fade-right" data-aos-duration="1500">
                                                <div class="d-flex">
                                                    <div class="right-img">
                                                        <span></span>
                                                    </div>
                                                    <div class="pl-30">
                                                        <div class="key-point-title">
                                                            <span class="color-black">Super Responsive</span>
                                                        </div>
                                                        <div class="key-point-content">
                                                            <p class="color-black content-text">Lorem ipsum dolor sit
                                                                amet,
                                                                consectetur
                                                                adipiscing elit, sed do eiusmod tempor incididunt ut
                                                                labore
                                                                et
                                                                dolore magna aliqua.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tech-key-points" data-aos="fade-right" data-aos-duration="1500">
                                                <div class="d-flex">
                                                    <div class="right-img">
                                                        <span></span>
                                                    </div>
                                                    <div class="pl-30">
                                                        <div class="key-point-title">
                                                            <span class="color-black">Super Responsive</span>
                                                        </div>
                                                        <div class="key-point-content">
                                                            <p class="color-black content-text">Lorem ipsum dolor sit
                                                                amet,
                                                                consectetur
                                                                adipiscing elit, sed do eiusmod tempor incididunt ut
                                                                labore
                                                                et
                                                                dolore magna aliqua.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="right-content" data-aos="flip-right" data-aos-duration="1500">
                                        <img [src]="root.imageUrl() + '/faq-tech-img.png'">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <div>
                        <a ngbNavLink class="center flex-column">
                            <div class="tech-icon center mb-20">
                                <img [src]="root.imageUrl() + '/svg-icons/web-design-icon.svg'" class="default-icon">
                                <img [src]="root.imageUrl() + '/svg-icons/web-design-icon-hover.svg'" class="hover-icon">
                            </div>
                            App Design
                        </a>
                    </div>
                    <ng-template ngbNavContent>
                        <div class="tech-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col-lg-5">
                                    <div class="left-content">
                                        <div>
                                            <div class="info-title">
                                                <span class="color-black">Web Design</span>
                                            </div>
                                            <div class="mt-30">
                                                <p class="color-black content-text">Lorem ipsum dolor sit amet,
                                                    consectetur
                                                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                                    dolore
                                                    magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo
                                                    viverra maecenas accumsan facilisis.</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="tech-key-points" data-aos="fade-right" data-aos-duration="1500">
                                                <div class="d-flex">
                                                    <div class="right-img">
                                                        <span></span>
                                                    </div>
                                                    <div class="pl-30">
                                                        <div class="key-point-title">
                                                            <span class="color-black">Super Responsive</span>
                                                        </div>
                                                        <div class="key-point-content">
                                                            <p class="color-black content-text">Lorem ipsum dolor sit
                                                                amet,
                                                                consectetur
                                                                adipiscing elit, sed do eiusmod tempor incididunt ut
                                                                labore
                                                                et
                                                                dolore magna aliqua.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tech-key-points" data-aos="fade-right" data-aos-duration="1500">
                                                <div class="d-flex">
                                                    <div class="right-img">
                                                        <span></span>
                                                    </div>
                                                    <div class="pl-30">
                                                        <div class="key-point-title">
                                                            <span class="color-black">Super Responsive</span>
                                                        </div>
                                                        <div class="key-point-content">
                                                            <p class="color-black content-text">Lorem ipsum dolor sit
                                                                amet,
                                                                consectetur
                                                                adipiscing elit, sed do eiusmod tempor incididunt ut
                                                                labore
                                                                et
                                                                dolore magna aliqua.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tech-key-points" data-aos="fade-right" data-aos-duration="1500">
                                                <div class="d-flex">
                                                    <div class="right-img">
                                                        <span></span>
                                                    </div>
                                                    <div class="pl-30">
                                                        <div class="key-point-title">
                                                            <span class="color-black">Super Responsive</span>
                                                        </div>
                                                        <div class="key-point-content">
                                                            <p class="color-black content-text">Lorem ipsum dolor sit
                                                                amet,
                                                                consectetur
                                                                adipiscing elit, sed do eiusmod tempor incididunt ut
                                                                labore
                                                                et
                                                                dolore magna aliqua.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="right-content" data-aos="flip-right" data-aos-duration="1500">
                                        <img [src]="root.imageUrl() + '/faq-tech-img.png'">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <div>
                        <a ngbNavLink class="center flex-column">
                            <div class="tech-icon center mb-20">
                                <img [src]="root.imageUrl() + '/svg-icons/web-design-icon.svg'" class="default-icon">
                                <img [src]="root.imageUrl() + '/svg-icons/web-design-icon-hover.svg'" class="hover-icon">
                            </div>
                            App Design
                        </a>
                    </div>
                    <ng-template ngbNavContent>
                        <div class="tech-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col-lg-5">
                                    <div class="left-content">
                                        <div>
                                            <div class="info-title">
                                                <span class="color-black">Web Design</span>
                                            </div>
                                            <div class="mt-30">
                                                <p class="color-black content-text">Lorem ipsum dolor sit amet,
                                                    consectetur
                                                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                                    dolore
                                                    magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo
                                                    viverra maecenas accumsan facilisis.</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="tech-key-points" data-aos="fade-right" data-aos-duration="1500">
                                                <div class="d-flex">
                                                    <div class="right-img">
                                                        <span></span>
                                                    </div>
                                                    <div class="pl-30">
                                                        <div class="key-point-title">
                                                            <span class="color-black">Super Responsive</span>
                                                        </div>
                                                        <div class="key-point-content">
                                                            <p class="color-black content-text">Lorem ipsum dolor sit
                                                                amet,
                                                                consectetur
                                                                adipiscing elit, sed do eiusmod tempor incididunt ut
                                                                labore
                                                                et
                                                                dolore magna aliqua.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tech-key-points" data-aos="fade-right" data-aos-duration="1500">
                                                <div class="d-flex">
                                                    <div class="right-img">
                                                        <span></span>
                                                    </div>
                                                    <div class="pl-30">
                                                        <div class="key-point-title">
                                                            <span class="color-black">Super Responsive</span>
                                                        </div>
                                                        <div class="key-point-content">
                                                            <p class="color-black content-text">Lorem ipsum dolor sit
                                                                amet,
                                                                consectetur
                                                                adipiscing elit, sed do eiusmod tempor incididunt ut
                                                                labore
                                                                et
                                                                dolore magna aliqua.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tech-key-points" data-aos="fade-right" data-aos-duration="1500">
                                                <div class="d-flex">
                                                    <div class="right-img">
                                                        <span></span>
                                                    </div>
                                                    <div class="pl-30">
                                                        <div class="key-point-title">
                                                            <span class="color-black">Super Responsive</span>
                                                        </div>
                                                        <div class="key-point-content">
                                                            <p class="color-black content-text">Lorem ipsum dolor sit
                                                                amet,
                                                                consectetur
                                                                adipiscing elit, sed do eiusmod tempor incididunt ut
                                                                labore
                                                                et
                                                                dolore magna aliqua.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="right-content" data-aos="flip-right" data-aos-duration="1500">
                                        <img [src]="root.imageUrl() + '/faq-tech-img.png'">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <div>
                        <a ngbNavLink class="center flex-column">
                            <div class="tech-icon center mb-20">
                                <img [src]="root.imageUrl() + '/svg-icons/web-design-icon.svg'" class="default-icon">
                                <img [src]="root.imageUrl() + '/svg-icons/web-design-icon-hover.svg'" class="hover-icon">
                            </div>
                            App Design
                        </a>
                    </div>
                    <ng-template ngbNavContent>
                        <div class="tech-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col-lg-5">
                                    <div class="left-content">
                                        <div>
                                            <div class="info-title">
                                                <span class="color-black">Web Design</span>
                                            </div>
                                            <div class="mt-30">
                                                <p class="color-black content-text">Lorem ipsum dolor sit amet,
                                                    consectetur
                                                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                                    dolore
                                                    magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo
                                                    viverra maecenas accumsan facilisis.</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="tech-key-points" data-aos="fade-right" data-aos-duration="1500">
                                                <div class="d-flex">
                                                    <div class="right-img">
                                                        <span></span>
                                                    </div>
                                                    <div class="pl-30">
                                                        <div class="key-point-title">
                                                            <span class="color-black">Super Responsive</span>
                                                        </div>
                                                        <div class="key-point-content">
                                                            <p class="color-black content-text">Lorem ipsum dolor sit
                                                                amet,
                                                                consectetur
                                                                adipiscing elit, sed do eiusmod tempor incididunt ut
                                                                labore
                                                                et
                                                                dolore magna aliqua.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tech-key-points" data-aos="fade-right" data-aos-duration="1500">
                                                <div class="d-flex">
                                                    <div class="right-img">
                                                        <span></span>
                                                    </div>
                                                    <div class="pl-30">
                                                        <div class="key-point-title">
                                                            <span class="color-black">Super Responsive</span>
                                                        </div>
                                                        <div class="key-point-content">
                                                            <p class="color-black content-text">Lorem ipsum dolor sit
                                                                amet,
                                                                consectetur
                                                                adipiscing elit, sed do eiusmod tempor incididunt ut
                                                                labore
                                                                et
                                                                dolore magna aliqua.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tech-key-points" data-aos="fade-right" data-aos-duration="1500">
                                                <div class="d-flex">
                                                    <div class="right-img">
                                                        <span></span>
                                                    </div>
                                                    <div class="pl-30">
                                                        <div class="key-point-title">
                                                            <span class="color-black">Super Responsive</span>
                                                        </div>
                                                        <div class="key-point-content">
                                                            <p class="color-black content-text">Lorem ipsum dolor sit
                                                                amet,
                                                                consectetur
                                                                adipiscing elit, sed do eiusmod tempor incididunt ut
                                                                labore
                                                                et
                                                                dolore magna aliqua.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="right-content" data-aos="flip-right" data-aos-duration="1500">
                                        <img [src]="root.imageUrl() + '/faq-tech-img.png'">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <div>
                        <a ngbNavLink class="center flex-column">
                            <div class="tech-icon center mb-20">
                                <img [src]="root.imageUrl() + '/svg-icons/web-design-icon.svg'" class="default-icon">
                                <img [src]="root.imageUrl() + '/svg-icons/web-design-icon-hover.svg'" class="hover-icon">
                            </div>
                            App Design
                        </a>
                    </div>
                    <ng-template ngbNavContent>
                        <div class="tech-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col-lg-5">
                                    <div class="left-content">
                                        <div>
                                            <div class="info-title">
                                                <span class="color-black">Web Design</span>
                                            </div>
                                            <div class="mt-30">
                                                <p class="color-black content-text">Lorem ipsum dolor sit amet,
                                                    consectetur
                                                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                                    dolore
                                                    magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo
                                                    viverra maecenas accumsan facilisis.</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="tech-key-points" data-aos="fade-right" data-aos-duration="1500">
                                                <div class="d-flex">
                                                    <div class="right-img">
                                                        <span></span>
                                                    </div>
                                                    <div class="pl-30">
                                                        <div class="key-point-title">
                                                            <span class="color-black">Super Responsive</span>
                                                        </div>
                                                        <div class="key-point-content">
                                                            <p class="color-black content-text">Lorem ipsum dolor sit
                                                                amet,
                                                                consectetur
                                                                adipiscing elit, sed do eiusmod tempor incididunt ut
                                                                labore
                                                                et
                                                                dolore magna aliqua.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tech-key-points" data-aos="fade-right" data-aos-duration="1500">
                                                <div class="d-flex">
                                                    <div class="right-img">
                                                        <span></span>
                                                    </div>
                                                    <div class="pl-30">
                                                        <div class="key-point-title">
                                                            <span class="color-black">Super Responsive</span>
                                                        </div>
                                                        <div class="key-point-content">
                                                            <p class="color-black content-text">Lorem ipsum dolor sit
                                                                amet,
                                                                consectetur
                                                                adipiscing elit, sed do eiusmod tempor incididunt ut
                                                                labore
                                                                et
                                                                dolore magna aliqua.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tech-key-points" data-aos="fade-right" data-aos-duration="1500">
                                                <div class="d-flex">
                                                    <div class="right-img">
                                                        <span></span>
                                                    </div>
                                                    <div class="pl-30">
                                                        <div class="key-point-title">
                                                            <span class="color-black">Super Responsive</span>
                                                        </div>
                                                        <div class="key-point-content">
                                                            <p class="color-black content-text">Lorem ipsum dolor sit
                                                                amet,
                                                                consectetur
                                                                adipiscing elit, sed do eiusmod tempor incididunt ut
                                                                labore
                                                                et
                                                                dolore magna aliqua.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="right-content" data-aos="flip-right" data-aos-duration="1500">
                                        <img [src]="root.imageUrl() + '/faq-tech-img.png'">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
        </div>
        <div [ngbNavOutlet]="nav"></div>
    </div>
    <!-- web design tab section | ends-->
</div>