<service-banner></service-banner>
<div class="ez-section-card">
    <div class="ez-technology-platform-section">
        <div class="technologies-section">
            <app-ess-detail-intro></app-ess-detail-intro>
            <app-ess-detail-offer></app-ess-detail-offer>
            <app-ess-detail-benefits></app-ess-detail-benefits>            
            <app-ess-services></app-ess-services>
            <sales></sales>
        </div>
    </div>
</div>
