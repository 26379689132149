<product-banner></product-banner>
<div class="ez-section-card" id="our-product">
    <div class="ez-technology-platform-section">
        <div class="technologies-section">
            <!-- <app-onveviz-intro></app-onveviz-intro> -->
            <app-onveviz-intro></app-onveviz-intro>
            <!-- <product-list></product-list> -->
            <app-onveviz-feature></app-onveviz-feature>
            <app-product-oneviz></app-product-oneviz>
            <oneviz-product-technology></oneviz-product-technology>
            <product-footer></product-footer>
        </div>
    </div>
</div>