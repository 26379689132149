<div class="ecs-contact-us career-life-at-ekzero" id="contact-us">
    <div class="contact-panel" data-aos="fade-up" data-aos-duration="1500">
        <div class="row no-gutters align-items-center">
            <div class="col-xl-8 col-lg-7">
                <div class="left-panel">
                    <div class="upper-content">
                        <span class="text-line1">Accelerate your career and become a part of the innovative and tech enthusiast family of ekZero </span>
                        <span class="text-line1">enterprise technology stack.</span>
                    </div>                   
                </div>
            </div>
            <div class="col-xl-4 col-lg-5">
                   <div class="d-flex justify-content-center btn-grp">
                <a class="btn-lg btn-secondary" [routerLink]="root.lifeatekzero()" target="_top">LIFE @ekZero</a>
            </div>
            </div>
        </div>
    </div>
</div>