import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
 
import { OurServicesRoutingModule } from './our-services-routing.module';
import { ServiceBannerComponent } from './components/service-banner/service-banner.component';
import { ServiceHomeComponent } from './pages/service-home/service-home.component';
import { ServicesContentComponent } from './components/services-content/services-content.component';
import { ServiceDetailPageComponent } from './pages/service-detail-page/service-detail-page.component';
import { ExploreServicesComponent } from './components/explore-services/explore-services.component';
import { ServiceBenefitsComponent } from './components/service-benefits/service-benefits.component';
import { ServiceDetailIntroComponent } from './components/service-detail-intro/service-detail-intro.component';
import { ServiceOfferComponent } from './components/service-offer/service-offer.component';
import { EnterpriseSoftwareServiceComponent } from './pages/enterprise-software-service/enterprise-software-service.component';
import { MobileAppServiceComponent } from './pages/mobile-app-service/mobile-app-service.component';
import { CloudServiceComponent } from './pages/cloud-service/cloud-service.component';
import { DevopsServiceComponent } from './pages/devops-service/devops-service.component';
import { SoftwareConsultingServiceComponent } from './pages/software-consulting-service/software-consulting-service.component';
import { EssDetailIntroComponent } from './components/ess/ess-detail-intro/ess-detail-intro.component';
import { EssDetailOfferComponent } from './components/ess/ess-detail-offer/ess-detail-offer.component';
import { EssDetailBenefitsComponent } from './components/ess/ess-detail-benefits/ess-detail-benefits.component';
import { EssServicesComponent } from './components/ess/ess-services/ess-services.component';
import { MobileAppIntroComponent } from './components/mobile-app/mobile-app-intro/mobile-app-intro.component';
import { MobileAppOfferComponent } from './components/mobile-app/mobile-app-offer/mobile-app-offer.component';
import { MobileAppBenefitsComponent } from './components/mobile-app/mobile-app-benefits/mobile-app-benefits.component';
import { MobileAppServicesComponent } from './components/mobile-app/mobile-app-services/mobile-app-services.component';
import { CloudDetailBenefitComponent } from './components/cloud-services/cloud-detail-benefit/cloud-detail-benefit.component';
import { CloudDetailIntroComponent } from './components/cloud-services/cloud-detail-intro/cloud-detail-intro.component';
import { CloudDetailOfferComponent } from './components/cloud-services/cloud-detail-offer/cloud-detail-offer.component';
import { CloudDetailServicesComponent } from './components/cloud-services/cloud-detail-services/cloud-detail-services.component';
import { DevopsDetailBenefitsComponent } from './components/devops-services/devops-detail-benefits/devops-detail-benefits.component';
import { DevopsDetailIntroComponent } from './components/devops-services/devops-detail-intro/devops-detail-intro.component';
import { DevopsDetailOfferComponent } from './components/devops-services/devops-detail-offer/devops-detail-offer.component';
import { DevopsDetailServicesComponent } from './components/devops-services/devops-detail-services/devops-detail-services.component';
import { SoftwareDetailBenefitsComponent } from './components/software-consulting/software-detail-benefits/software-detail-benefits.component';
import { SoftwareDetailIntroComponent } from './components/software-consulting/software-detail-intro/software-detail-intro.component';
import { SoftwareDetailOfferComponent } from './components/software-consulting/software-detail-offer/software-detail-offer.component';
import { SoftwareDetailServicesComponent } from './components/software-consulting/software-detail-services/software-detail-services.component';


@NgModule({
  declarations: [ServiceBannerComponent, ServiceHomeComponent, ServicesContentComponent, ServiceDetailPageComponent, ExploreServicesComponent, ServiceBenefitsComponent, ServiceDetailIntroComponent, ServiceOfferComponent, EnterpriseSoftwareServiceComponent, MobileAppServiceComponent, CloudServiceComponent, DevopsServiceComponent, SoftwareConsultingServiceComponent, EssDetailIntroComponent, EssDetailOfferComponent, EssDetailBenefitsComponent, EssServicesComponent, MobileAppIntroComponent, MobileAppOfferComponent, MobileAppBenefitsComponent, MobileAppServicesComponent, CloudDetailBenefitComponent, CloudDetailIntroComponent, CloudDetailOfferComponent, CloudDetailServicesComponent, DevopsDetailBenefitsComponent, DevopsDetailIntroComponent, DevopsDetailOfferComponent, DevopsDetailServicesComponent, SoftwareDetailBenefitsComponent, SoftwareDetailIntroComponent, SoftwareDetailOfferComponent, SoftwareDetailServicesComponent],
  imports: [
    CommonModule,
    OurServicesRoutingModule,
    SharedModule
  ]
})
export class OurServicesModule { }
