<service-banner></service-banner>
<div class="ez-section-card">
    <div class="ez-technology-platform-section">
        <div class="technologies-section">
            <service-detail-intro></service-detail-intro>
            <service-offer></service-offer>
            <service-benefits></service-benefits>
            <!-- <case-study></case-study> -->
            <explore-services></explore-services>
            <sales></sales>
        </div>
    </div>
</div>