<div id="product-tech">
    <div class="tech-wrapper">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="left-panel">
                    <img [src]="root.imageUrl() + '/vegitone-fleet-mgmt.png'">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="right-panel">
                    <div class="info-content mb-30"><span class="color-orange">Fleet Management backed by accurate data</span></div>
                    <div>
                        <p class="color-black content-text">VegitOne provides analytical tools that assist you in evaluating your best freight routes, aggregation opportunities, efficient shipment routing, and carrier alignment with your network. VegitOne collects, analyzes, and stores data for better, faster, and smarter fleet management and reporting that not only ensures operational accuracy but also meets regulatory standards.
                        </p>
                    </div>
                    <div>
                        <p class="color-black content-text">With VegitOne, you can discover all of the issues and constraints that are causing interruptions in your transportation network from a unified, extensive real-time tracking dashboard. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 tech-section col-md-order2">
                <div class="right-panel">
                    <div class="info-content mb-30"><span class="color-orange">Tailored to meet Dispatcher requirements
                        </span></div>
                    <div>
                        <p class="color-black content-text">VegitOne's dispatch management system integrates all operations, including scheduling, compliance, communication, task management, and more, into one solution. VegitOne's Dispatch Management takes care of all aspects of your booming transportation and trucking business.
                        </p>
                    </div>
                    <div>
                        <p class="color-black content-text">VegtitOne's dynamic features, such as route optimization, driver and equipment management, and handling multiple shipments at once, ensure that all dispatcher requirements are met efficiently. </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 tech-section col-md-order1">
                <div class="left-panel">
                    <img [src]="root.imageUrl() + '/vegitone-dispatcher.png'">
                </div>
            </div>
        </div>
    </div>
</div>