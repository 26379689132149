import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'app-software-detail-benefits',
  templateUrl: './software-detail-benefits.component.html',
  styleUrls: ['./software-detail-benefits.component.scss']
})
export class SoftwareDetailBenefitsComponent implements OnInit {

  constructor(public root: RootService) { }

  ngOnInit(): void {
  }

}
