<div id="explore-services">
    <div class="explore-block">
        <div class="row">
            <div class="col-xl-8 col-lg-order-2">
                <div class="left-content">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="mb-30">
                                <div><img class="service-img"
                                        [src]="root.imageUrl() + '/svg-icons/automation.svg'">
                                </div>
                                <div class="mt-15"><span class="color-black service-title font-600">DevOps Automation
                                    </span></div>
                                <div class="mt-15"><span class="color-black content-text">Achieve higher accuracy and efficiency by automating your manual processes and improve your workflow. DevOps automation will substantially reduce the cost of resources being used in manual processes. After assessing your IT process setup, ekZero’s DevOps experts will build a powerful infrastructure code to leverage infra-automation for deploying scripts, engaging teams, monitoring tools, tracking performance, and adherence to the planned targets. It will provide a better degree of trustworthiness and also boosts cross-team collaboration.

                                    </span></div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-30">
                                <div><img class="service-img"
                                        [src]="root.imageUrl() + '/svg-icons/dev-cloud.svg'">
                                </div>
                                <div class="mt-15"><span class="color-black service-title font-600">DevOps on Cloud</span></div>
                                <div class="mt-15"><span class="color-black content-text">Speed up your development productivity and efficiency by keeping the balanced focus on cloud and DevOps. Cloud computing permits the developers more control over their own components, resulting in smaller wait times. The professionals at ekZero will use cloud tools and services to automate the process of building, managing, and furnishings through the code, speed up the application launch time, eliminate possible human error and establish stability. Our developers are professionally certified with Amazon and Azure cloud.
                                    </span></div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="mb-30">
                                <div><img class="service-img"
                                        [src]="root.imageUrl() + '/svg-icons/assessment.svg'">
                                </div>
                                <div class="mt-15"><span class="color-black service-title font-600">DevOps Assessments
                                    </span></div>
                                <div class="mt-15"><span class="color-black content-text">Through DevOps assessment services, ekZero can change the way an organization performs. By integrating lean and agile principles across the software lifecycle, ekZero will improve the processes, foster innovations, and enable a responsive feedback mechanism. Our experts will thoroughly assess the current state of your DevOps culture, process, and toolchain and draft a necessary actionable plan to get you started on the right path to continuous integration and delivery. We will open up the entire world of DevOps to you. Your organization will experience the phases from development to production moving ahead quickly and smoothly.
                                    </span></div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-30">
                                <div><img class="service-img"
                                        [src]="root.imageUrl() + '/svg-icons/dev-maintenance.svg'">
                                </div>
                                <div class="mt-15"><span class="color-black service-title font-600">DevOps Maintenance
                                    </span></div>
                                <div class="mt-15"><span class="color-black content-text">ekZero closely works with the organizations to define metrics and endpoints of monitoring and maintenance. We effectively work on the 80-20 rule, in which we filter out the 80% of low priority issues to focus on what matters to your business the most. ekZero’s DevOps teams will help you find and fix even the slightest susceptibility in your application or new feature. ekZero utilizes cutting-edge technologies and best DevOps practices to constantly ensure that your business stays online. Our experts take on the stress of managing and taking care of your infrastructure while you concentrate on business.
                                    </span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-order-1">
                <div class="right-content">
                    <div class="right-bg">
                        <div class="mt-20 info-content"><span>Our DevOps Offerings
                        </span></div>
                        <div class="mt-20"><span class="content-text">ekZero provides customers with services encompassing several capabilities like on-demand workload management, security, and automated testing techniques.</span></div>
                        <div><a class="btn-news btn-md" [routerLink]="root.ourServices()"  target="_top">VIEW ALL</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>