<!--Blogs-->
<div class="ez-case-study ez-resource" id="insights">
  <div class="row no-gutters">
    <div class="col-lg-5">
      <div class="cs-left-content left-content">
        <div class="ekzero-title">
          <span class="color-black">INSIGHTS</span>
        </div>
        <div class="row no-gutters">
          <div class="col-xl-4 col-lg-6">
            <div class="mt-20 info-content">
              <div><span class="color-orange">Interesting</span></div>
              <div><span class="color-orange">thoughts &</span></div>
              <div><span class="color-orange">updates</span></div>
            </div>
          </div>
          <div class="col-xl-8 col-lg-6">
            <div class="insight-intro">
              <span class="color-black content-text"
                >Explore and learn from our several blogs, which cover a variety
                of industrial topics, news & updates, data-driven analysis,
                remarkable insights, and a variety of other topics.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-7"></div>
  </div>
  <div class="row no-gutters">
    <div class="col-xl-6 insight-panel">
      <div class="left-panel">
        <!-- <div class="ekzero-title mb-30">
          <span class="color-black font-20">BLOG</span>
        </div> -->
        <!-- <div class="mt-30">
          <a
            [routerLink]="root.blogs()"
            target="_top"
            class="btn-primary btn-md"
            >VIEW ALL</a
          >
        </div> -->
      </div>
      <!--blogs-->
      <div class="mt-60">
        <div class="row no-gutters" data-aos="fade-up" data-aos-duration="1500">
          <div class="col-md-6">
            <div class="case-study-container">
              <div class="position-relative">
                <div class="h-100">
                  <img
                    [src]="
                      root.imageUrl() + '/what-is-cloud-computing-min-image.png'
                    "
                    alt="blog"
                    class="w-100 h-100"
                  />
                </div>
                <div class="case-study-content position-relative">
                  <div class="blog-category">
                    <span>Cloud Computing</span>
                  </div>
                  <div class="blog-card-content">
                    <div class="mb-20">
                      <div class="date-panel"><span>Oct 31, 2022</span></div>
                      <div class="blog-title">
                        <span
                          >What is Cloud Computing? How it helps your business
                          gain a competitive advantage.</span
                        >
                      </div>
                    </div>
                    <div class="blog-footer">
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <div class="blog-avtar center">
                          <div class="avtar-image">
                            <img
                              [src]="root.imageUrl() + '/avtar-img.png'"
                              alt=""
                            />
                          </div>
                          <div>
                            <span class="font-16">Admin</span>
                          </div>
                        </div>
                        <a
                          class="text-right"
                          [routerLink]="root.whatiscloudcomputing()"
                          target="_top"
                        >
                          <img
                            [src]="
                              root.imageUrl() +
                              '/svg-icons/right-orange-arrow.svg'
                            "
                            alt="arrow"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="case-study-container">
              <div class="position-relative">
                <div class="h-100">
                  <img
                    [src]="
                      root.imageUrl() +
                      '/essential-mobile-app-design-min-image.png'
                    "
                    alt="blog"
                    class="w-100 h-100"
                  />
                </div>
                <div class="case-study-content position-relative">
                  <div class="blog-category">
                    <span>Technology</span>
                  </div>
                  <div class="blog-card-content">
                    <div class="mb-20">
                      <div class="date-panel"><span>Sept 16, 2022</span></div>
                      <div class="blog-title">
                        <span
                          >6 Essential Mobile App Design Steps to Take in
                          2022</span
                        >
                      </div>
                    </div>
                    <div class="blog-footer">
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <div class="blog-avtar center">
                          <div class="avtar-image">
                            <img
                              [src]="root.imageUrl() + '/avtar-img.png'"
                              alt=""
                            />
                          </div>
                          <div>
                            <span class="font-16">Admin</span>
                          </div>
                        </div>
                        <a
                          class="text-right"
                          [routerLink]="root.mobileEssential()"
                          target="_top"
                        >
                          <img
                            [src]="
                              root.imageUrl() +
                              '/svg-icons/right-orange-arrow.svg'
                            "
                            alt="arrow"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-6">
                        <div class="case-study-container">
                            <div class="position-relative">
                                <div class="h-100">
                                    <img [src]="root.imageUrl() + '/next-phase-internet.jpg' " alt="blog" class="w-100">
                                </div>
                                <div class="case-study-content position-relative">
                                    <div class="blog-category">
                                        <span>Technology</span>
                                    </div>
                                    <div class="blog-card-content">
                                        <div class="mb-20">
                                            <div class="date-panel"><span>July 11, 2022</span></div>
                                            <div class="blog-title">
                                                <span> Evolution of Web 3.0. The next phase of the Internet! </span>
                                            </div>
                                        </div>
                                        <div class="blog-footer">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="blog-avtar center">
                                                    <div class="avtar-image">
                                                        <img [src]="root.imageUrl() + '/avtar-img.png' " alt="">
                                                    </div>
                                                    <div>
                                                        <span class="font-16"> Admin </span>
                                                    </div>
                                                </div>
                                                <a class="text-right" [routerLink]="root.nextPhaseOfInternet()"
                                                    target="_top">
                                                    <img [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'"
                                                        alt="arrow">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
          <!-- <div class="col-md-6">
                        <div class="case-study-container">
                            <div class="position-relative">
                                <img [src]="root.imageUrl() + '/software-consulting-min.jpg' " alt="blog" class="w-100">
                                <div class="case-study-content position-relative">
                                    <div class="blog-category">
                                        <span>Enterprise Software Solutions</span>
                                    </div>
                                    <div class="blog-card-content">
                                        <div class="mb-20">
                                            <div class="date-panel"><span>March 23, 2022</span></div>
                                            <div class="blog-title">
                                                <span>Custom Software Consulting: Should your startup go for it? </span>
                                            </div>
                                            <div class="blog-content">
                                                <span> Whether you are a service provider or someone who
                                                    provides end to...</span>
                                            </div>
                                        </div>
                                        <div class="blog-footer">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="blog-avtar center">
                                                    <div class="avtar-image">
                                                        <img [src]="root.imageUrl() + '/avtar-img.png' " alt="">
                                                    </div>
                                                    <div>
                                                        <span class="font-16"> Admin </span>
                                                    </div>
                                                </div>
                                                <a class="text-right" [routerLink]="root.customSoftware()"
                                                    target="_top">
                                                    <img [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'"
                                                        alt="arrow">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
          <!-- <div class="col-md-6"> 
                        <div class="case-study-container">
                            <div class="position-relative">
                                <img [src]="root.imageUrl() + '/cloud-migration.jpg' "
                                    alt="blog" class="w-100">

                                <div class="case-study-content position-relative">
                                    <div class="blog-category">
                                        <span>Cloud Computing</span>
                                    </div>
                                    <div class="blog-card-content">
                                        <div class="mb-20">
                                            <div class="date-panel"><span>Feb 22, 2022</span></div>
                                            <div class="blog-title">
                                                <span>How do you overcome obstacles in Cloud Migration? Simple solutions.</span>
                                            </div>
                                            <div class="blog-content">
                                                <span> Whether you are a service provider or someone who
                                                    provides end to...</span>
                                            </div>
                                        </div>
                                        <div class="blog-footer">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="blog-avtar center">
                                                    <div class="avtar-image">
                                                        <img [src]="root.imageUrl() + '/avtar-img.png' " alt="">
                                                    </div>
                                                    <div>
                                                        <span class="font-16"> Admin </span>
                                                    </div>
                                                </div>
                                                <a class="text-right" [routerLink]="root.cloudMigration()"
                                                    target="_top">
                                                    <img [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'"
                                                        alt="arrow">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  -->
          <!-- <div class="col-md-6">
                        <div class="case-study-container">
                            <div class="position-relative">
                                <img [src]="root.imageUrl() + '/ensuring-safety-in-trucking-industry-img.png' "
                                    alt="blog" class="w-100">

                                <div class="case-study-content position-relative">
                                    <div class="blog-category">
                                        <span>Trucking Industry</span>
                                    </div>
                                    <div class="blog-card-content">
                                        <div class="mb-20">
                                            <div class="date-panel"><span>Mar 23, 2020</span></div>
                                            <div class="blog-title">
                                                <span>Ensuring Safety in the Trucking Industry. Regulations, Technology,
                                                    and Advantages to business owners.</span>
                                            </div>
                                             <div class="blog-content">
                                                <span> Whether you are a service provider or someone who
                                                    provides end to...</span>
                                            </div> 
                                        </div>
                                        <div class="blog-footer">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="blog-avtar center">
                                                    <div class="avtar-image">
                                                        <img [src]="root.imageUrl() + '/avtar-img.png' " alt="">
                                                    </div>
                                                    <div>
                                                        <span class="font-16"> Admin </span>
                                                    </div>
                                                </div>
                                                <a class="text-right" [routerLink]="root.truckingindustry()"
                                                    target="_top">
                                                    <img [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'"
                                                        alt="arrow">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

          <!-- <div class="col-md-6">
                        <div class="case-study-container">
                            <div class="position-relative">
                                <img [src]="root.imageUrl() + '/loss-of-truck-driver-in-covid-pandemic.jpg' " alt="blog"
                                    class="w-100">

                                <div class="case-study-content position-relative">
                                    <div class="blog-category">
                                        <span>Truck Drivers</span>
                                    </div>
                                    <div class="blog-card-content">
                                        <div class="mb-20">
                                            <div class="date-panel"><span>June 05, 2020</span></div>
                                            <div class="blog-title">
                                                <span>Loss to Truck Drivers in Covid-19 Pandemic.</span>
                                            </div>
                                             <div class="blog-content">
                                                <span> Whether you are a service provider or someone who
                                                    provides end to...</span>
                                            </div> 
                                        </div>
                                        <div class="blog-footer">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="blog-avtar center">
                                                    <div class="avtar-image">
                                                        <img [src]="root.imageUrl() + '/avtar-img.png' " alt="">
                                                    </div>
                                                    <div>
                                                        <span class="font-16"> Admin </span>
                                                    </div>
                                                </div>
                                                <a class="text-right" [routerLink]="root.losttruckdriver()"
                                                    target="_top">
                                                    <img [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'"
                                                        alt="arrow">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  -->
        </div>
      </div>
    </div>
    <div class="col-xl-6">
      <div class="news-wrapper" data-aos="fade-down" data-aos-duration="1500">
        <!-- <div class="d-flex align-items-end justify-content-end flex-column">
                    <div class="ekzero-title mb-20"><span class="color-white font-16">Blogs</span></div>
                    <div><a class="btn-news btn-md">VIEW ALL</a></div>
                </div> -->

        <div class="news-panel">
          <div class="row mo-gutters">
            <div class="col-lg-8">
              <div class="news-card-wrapper">
                <div class="news-card mb-15">
                  <div class="news-card-content">
                    <div class="news-card-title">
                      <span
                        >Evolution of GPS Technology in fleet operations.</span
                      >
                    </div>
                    <div class="news-content d-flex justify-content-between">
                      <!-- <div class="mr-40">
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing
                                                    elit, sed aliqua.</span>
                                            </div> -->
                      <a [routerLink]="root.fleetoperations()" target="_top">
                        <img
                          [src]="
                            root.imageUrl() + '/svg-icons/right-grey-icon.svg'
                          "
                          alt="right icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="news-card news-card-right">
                  <div class="news-card-content">
                    <div class="news-card-title">
                      <span
                        >Will The Self-Driving Trucks Transform The Trucking And
                        Logistic Industry?</span
                      >
                    </div>
                    <div class="news-content d-flex justify-content-between">
                      <!-- <div class="mr-40">
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing
                                                    elit, sed aliqua.</span>
                                            </div> -->
                      <a [routerLink]="root.logisticindustry()" target="_top">
                        <img
                          [src]="
                            root.imageUrl() + '/svg-icons/right-grey-icon.svg'
                          "
                          alt="right icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-lg-pl">
              <div class="news-card news-single ml-0">
                <div class="news-card-content">
                  <div class="news-card-title skyrocket">
                    <span
                      >Skyrocket Your Association Revenue By Employing Efficient
                      Ways.</span
                    >
                  </div>
                  <div class="news-content">
                    <!-- <div class="mb-20">
                                            <span>Lorem ipsum dolor sit amet, consectetur adipiscing
                                                elit, sed aliqua.</span>
                                        </div> -->
                    <a [routerLink]="root.efficientways()" target="_top">
                      <img
                        [src]="
                          root.imageUrl() + '/svg-icons/right-grey-icon.svg'
                        "
                        alt="right icon"
                        class="rotate-icon"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
