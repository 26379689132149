<div class="body-panel position-relative">
    <div class="tech-icon center"><img [src]="root.imageUrl() + '/svg-icons/energy-default-lg.svg'"></div>
    <div class="row no-gutters">
        <div class="col-lg-8">
            <div class="left-wrapper">
                <div class="left-content">
                    <div class="info-content mb-30"><span>ENERGY & UTILITIES</span></div>
                    <div>
                        <span class="content-text">Your client base is well served when they have online secure access
                            to their services, bills, communications, and maintenance requests. We can develop the
                            solution connected to your internal existing IT system, a brand new system or enhancements
                            to any of those.

                        </span>
                    </div>
                </div>
                <div class="d-none pagination-block">
                    <div class="slider active"></div>
                    <div class="slider"></div>
                    <div class="slider"></div>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="right-content position-relative" data-aos="fade-up" data-aos-duration="1500">
                <img [src]="root.imageUrl() + '/ez-energy-info.png'">
            </div>
        </div>
    </div>
</div>