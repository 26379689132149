import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RootService {

  constructor() { }


  // root(): string {
  //   return `localhost/ekzerowebsite`;
  // }

  home() {
    const page = '/home';
    return `${page}`
  }
  ecs() {
    const page = '/ecs';
    return `${page}`
  }
  aboutus() {
    const page = '/about-us';
    return `${page}`
  }

  privacyPolicy() {
    const page = '/privacy-policy';
    return `${page}`
  }

  ourServices() {
    const page = '/ourservices';
    return `${page}`
  }

  careers() {
    const page = '/careers';
    return `${page}`
  }

  // blogs() {
  //   const page = '/blogs/list';
  //   return `${page}`
  // }


  productsList() {
    const page = '/our-products/list';
    return `${page}`
  }

  incubation() {
    const page = '/incubation';
    return `${page}`
  }

  researchdevelopment() {
    const page = '/research-development';
    return `${page}`
  }

  productDetail() {
    const page = '/products/detail';
    return `${page}`
  }

  contact() {
    const page = '/contact';
    return `${page}`
  }

  lifeatekzero() {
    const page = '/life/ekzero';
    return `${page}`
  }

  faq() {
    const page = '/faq';
    return `${page}`
  }
  imageUrl() {
    // const url = 'https://assets.ekzero.com';
    //const url = '../../../../../assets';

    //set url Dt:8/4/2024
    const url = 'https://ekzero-assets.s3.ca-central-1.amazonaws.com/assets'
    return url;
  }
  truckingindustry() {
    const page = '/blogs/ensuring-safety-in-the-trucking-industry';
    return `${page}`
  }
  cloudMigration() {
    const page = '/blogs/cloud-migration';
    return `${page}`
  }
  losttruckdriver() {
    const page = '/blogs/loss-truck-drivers-in-covid-pandemic';
    return `${page}`
  }
  fleetoperations() {
    const page = '/blogs/evolution-of-gps-technology-in-fleet-operations';
    return `${page}`
  }
  logisticindustry() {
    const page = '/blogs/will-the-self-driving-trucks-transform-the-trucking-and-logistic-industry';
    return `${page}`
  }
  efficientways() {
    const page = '/blogs/skyrocket-your-association-revenue-by-employing-efficient-ways';
    return `${page}`
  }
  membercentric() {
    const page = '/blogs/create-member-centric';
    return `${page}`
  }
  ekvegit() {
    const page = '/products/ekvegit';
    return `${page}`
  }
  gtrack() {
    const page = '/products/gtrack';
    return `${page}`
  }
  oneviz() {
    const page = '/products/oneviz';
    return `${page}`
  }
  ess() {
    const page = '/ourservices/enterprise-software-service';
    return `${page}`
  }
  mobileApp() {
    const page = '/ourservices/mobile-app-service';
    return `${page}`
  }
  cloud() {
    const page = '/ourservices/cloud-service';
    return `${page}`
  }
  devops() {
    const page = '/ourservices/devops-service';
    return `${page}`
  }
  scs() {
    const page = '/ourservices/software-consulting-service';
    return `${page}`
  }
  customSoftware() {
    const page = '/blogs/custom-software-consulting';
    return `${page}`
  }
  nextPhaseOfInternet() {
    const page = '/blogs/evolution-the-next-phase-of-internet';
    return `${page}`
  }
  mobileEssential() {
    const page = '/blogs/essential-mobile-app-design';
    return `${page}`
  }
  whatiscloudcomputing() {
    const page = '/blogs/what-is-cloud-computing';
    return `${page}`
  }
}