<div class="d-flex justify-content-center spinner">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="d-flex align-items-center">
        <span class="font-14 color-tertiary loading-text"> Loading</span>
        <div>
            <span class="bounce-dot dot1">.</span>
            <span class="bounce-dot dot2">.</span>
            <span class="bounce-dot dot3">.</span>
        </div>
    </div>

</div>

