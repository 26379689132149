<product-banner></product-banner>
<div class="ez-section-card" id="our-product">
    <div class="ez-technology-platform-section">
        <div class="technologies-section">
            <product-intro></product-intro>
            <!-- <product-list></product-list> -->
            <product-feature></product-feature>
            <product-vegitone></product-vegitone>
            <product-technology></product-technology>
            <product-footer></product-footer>
        </div>
    </div>
</div>
