<div class="row no-gutters">
    <div class="col-12">
        <div class="technolofy-platform-banner">
            <div class="header-block">
                <span class="header-block-text">Digital Solutions & Innovative Platforms</span>
            </div>
            <div class="desc-block">
                <span class="desc-block-text">
                    One Stop Service provider for all your Technology needs.
                </span>
            </div>
        </div>
    </div>
    <div class="col-12 technology-use-panel">
        <div class="technology-use-block flex-wrap d-flex align-items-center justify-content-center">
            <div class="technologies-container">
                <img [src]="root.imageUrl() + '/svg-icons/apple-icon.svg'" class="default-icon">
                <img [src]="root.imageUrl() + '/svg-icons/apple-hover-icon.svg'" class="hover-icon">
            </div>
            <div class="technologies-container">
                <img [src]="root.imageUrl() + '/svg-icons/android-icon.svg'" class="default-icon">
                <img [src]="root.imageUrl() + '/svg-icons/android-hover-icon.svg'" class="hover-icon">
            </div>
            <div class="technologies-container">
                <img [src]="root.imageUrl() + '/svg-icons/angular-icon.svg'" class="default-icon">
                <img [src]="root.imageUrl() + '/svg-icons/angular-hover-icon.svg'" class="hover-icon">
            </div>
            <div class="technologies-container active">
                <img [src]="root.imageUrl() + '/svg-icons/react-icon.svg'" class="default-icon">
                <img [src]="root.imageUrl() + '/svg-icons/react-hover-icon.svg'" class="hover-icon">
            </div>
            <div class="technologies-container">
                <img [src]="root.imageUrl() + '/svg-icons/node-icon.svg'" class="default-icon">
                <img [src]="root.imageUrl() + '/svg-icons//node-hover-icon.svg'" class="hover-icon">
            </div>
            <div class="technologies-container">
                <img [src]="root.imageUrl() + '/svg-icons/js-icon.svg'" class="default-icon">
                <img [src]="root.imageUrl() + '/svg-icons/js-hover-icon.svg'" class="hover-icon">
            </div>
            <div class="technologies-container">
                <img [src]="root.imageUrl() + '/svg-icons/java-icon.svg' " class="default-icon">
                <img [src]="root.imageUrl() + '/svg-icons/java-hover-icon.svg' " class="hover-icon">
            </div>
            <div class="technologies-container">
                <img [src]="root.imageUrl() + '/svg-icons/aws-icon.svg' " class="default-icon">
                <img [src]="root.imageUrl() + '/svg-icons/aws-hover-icon.svg' " class="hover-icon">
            </div>
        </div>
    </div>
</div>