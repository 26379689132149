<div class="research-development-process">
    <div class="overflow-wrapper -ms-scrollbar scrollable-body">
        <div class="center blocks-wrapper">
            <div class="journey-blocks" data-aos="fade-right" data-aos-duration="1500">
                <div class="outer">
                    <div class="top">
                        <div class="line"></div>
                    </div>
                    <div class="bottom">
                    </div>
                    <div class="journey-content-wrapper position-absolute">
                        <div class="journey-content-panel">                         
                            <div class="icon-block">
                                <img [src]="root.imageUrl() + '/svg-icons/idea-generation-icon.svg'"/>
                            </div>                         
                            <div class="title-panel">
                                <span class="title-text">
                                    Idea Generation
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="journey-blocks" data-aos="fade-right" data-aos-duration="1500">
                <div class="outer">
                    <div class="top">
                        <div class="line"></div>
                    </div>
                    <div class="bottom">
                    </div>
                    <div class="journey-content-wrapper position-absolute">
                        <div class="journey-content-panel">                        
                            <div class="icon-block">
                                <img [src]="root.imageUrl() + '/svg-icons/screening-icon.svg'"/>
                            </div>                         
                            <div class="title-panel">
                                <span class="title-text">
                                    Screening
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="journey-blocks" data-aos="fade-right" data-aos-duration="1500">
                <div class="outer">
                    <div class="top">
                        <div class="line"></div>
                    </div>
                    <div class="bottom">
                    </div>
                    <div class="journey-content-wrapper position-absolute">
                        <div class="journey-content-panel">
                            <div class="icon-block">
                                <img [src]="root.imageUrl() + '/svg-icons/research-icon.svg'"/>
                            </div>                         
                            <div class="title-panel">
                                <span class="title-text">
                                    Research
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="journey-blocks" data-aos="fade-right" data-aos-duration="1500">
                <div class="outer">
                    <div class="top">
                        <div class="line"></div>
                    </div>
                    <div class="bottom">
                    </div>
                    <div class="journey-content-wrapper position-absolute">
                        <div class="journey-content-panel">                        
                            <div class="icon-block">
                                <img [src]="root.imageUrl() + '/svg-icons/design-icon.svg'"/>
                            </div>                         
                            <div class="title-panel">
                                <span class="title-text">
                                    Design
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="journey-blocks" data-aos="fade-right" data-aos-duration="1500">
                <div class="outer">
                    <div class="top">
                        <div class="line"></div>
                    </div>
                    <div class="bottom">
                    </div>
                    <div class="journey-content-wrapper position-absolute">
                        <div class="journey-content-panel">
                            <div class="icon-block">
                                <img [src]="root.imageUrl() + '/svg-icons/testing-icon.svg'"/>
                            </div>                         
                            <div class="title-panel">
                                <span class="title-text">
                                    Testing
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="journey-blocks" data-aos="fade-right" data-aos-duration="1500">
                <div class="outer">
                    <div class="top">
                        <div class="line"></div>
                    </div>
                    <div class="bottom">
                    </div>
                    <div class="journey-content-wrapper position-absolute">
                        <div class="journey-content-panel">                        
                            <div class="icon-block">
                                <img [src]="root.imageUrl() + '/svg-icons/launch-icon.svg'"/>
                            </div>                         
                            <div class="title-panel">
                                <span class="title-text">
                                    Launch
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>