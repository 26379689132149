import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'our-portfolio',
  templateUrl: './our-portfolio.component.html',
  styleUrls: ['./our-portfolio.component.scss']
})
export class OurPortfolioComponent implements OnInit {

  constructor(public root: RootService) { }

  ngOnInit(): void {
  }

}
