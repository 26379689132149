<div class="ecs-contact-us" id="contact-us">
    <div class="contact-panel" data-aos="fade-up" data-aos-duration="1500">
        <div class="row no-gutters">
            <div class="col-xl-8 col-lg-7 col-md-order">
                <div class="left-panel">
                    <div class="upper-content">
                        <span class="text-line1">Unlock the power of the </span>
                        <span class="text-line1">enterprise technology stack</span>
                    </div>
                    <div class="middle-content">
                        <span class="text-line1">& begin your </span>
                        <span class="text-line1">organization's transformation</span>
                    </div>
                    <div class="mb-10">
                        <span class="text-line3">Get in touch with a sales professional.</span>
                    </div>
                    <div class="btn-grp">
                        <a class="btn-secondary btn-lg cursor-pointer" [routerLink]="root.contact()" target="_top">Let's
                                Connect</a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-5">
                <div class="right-panel">
                    <div class="contact-image" data-aos="fade-up"
                    data-aos-duration="1500"></div>
                </div>
            </div>
        </div>
    </div>
</div>