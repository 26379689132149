import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'ecs-home',
  templateUrl: './ecs-home.component.html',
  styleUrls: ['./ecs-home.component.scss']
})
export class EcsHomeComponent implements OnInit {

  newTitle: string = 'ekZero Consulting Services | ekZero Corporation';
  longDescription: string = 'Let us help you succeed by taking care of your end-to-end product and solution development and delivery while you focus on developing your business.';
  longKeyword: string = 'ekZero, Enterprise Software Services, Mobile application, Software Consulting, DevOps, Cloud Services, Digital Solutions, ERP, ekZero Consulting Services, ECS, Best IT company in Toronto, POC, MVP, Product Development';

  constructor(private title: Title,
    private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle(this.newTitle);
    this.meta.addTag({ name: "description", content: this.longDescription });
    this.meta.addTag({ name: "keywords", content: this.longKeyword });
  }

}
