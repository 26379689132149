<div class="ez-section-card">
    <div class="ez-technology-platform-section">
        <div class="technologies-section">
            <div class="technologies-section-bg" id="home-banner">
                <div class="d-none row no-gutters">
                    <div class="col-lg-4">
                        <div class="technolofy-platform-banner">
                            <div class="header-block">
                                <span class="header-block-text">Technologies + Platform</span>
                            </div>
                            <div class="desc-block">
                                <span class="desc-block-text">
                                    One Stop Service provider for all your Technology needs.
                                </span>
                            </div>
                        </div>

                    </div>
                    <div class="col-lg-8 technology-use-panel">
                        <div class="technology-use-block flex-wrap d-flex align-items-center justify-content-center">
                            <div class="technologies-container">
                                <img [src]="root.imageUrl() + '/svg-icons/apple-icon.svg'" class="default-icon">
                                <img [src]="root.imageUrl() + '/svg-icons/apple-hover-icon.svg'"
                                    class="hover-icon">
                            </div>
                            <div class="technologies-container">
                                <img [src]="root.imageUrl() + '/svg-icons/android-icon.svg'" class="default-icon">
                                <img [src]="root.imageUrl() + '/svg-icons/android-hover-icon.svg'"
                                    class="hover-icon">
                            </div>
                            <div class="technologies-container">
                                <img [src]="root.imageUrl() + '/svg-icons/angular-icon.svg'" class="default-icon">
                                <img [src]="root.imageUrl() + '/svg-icons/angular-hover-icon.svg'"
                                    class="hover-icon">
                            </div>
                            <div class="technologies-container active">
                                <img [src]="root.imageUrl() + '/svg-icons/react-icon.svg'" class="default-icon">
                                <img [src]="root.imageUrl() + '/svg-icons/react-hover-icon.svg'"
                                    class="hover-icon">
                            </div>
                            <div class="technologies-container">
                                <img [src]="root.imageUrl() + '/svg-icons/node-icon.svg'" class="default-icon">
                                <img [src]="root.imageUrl() + '/svg-icons/node-hover-icon.svg'"
                                    class="hover-icon">
                            </div>
                            <div class="technologies-container">
                                <img [src]="root.imageUrl() + '/svg-icons/js-icon.svg'" class="default-icon">
                                <img [src]="root.imageUrl() + '/svg-icons/js-hover-icon.svg'" class="hover-icon">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--services-->
            <div class="d-none ez-service-panel">
                <div class="row no-gutters">
                    <div class="col-lg-3">
                        <div class="service-left-content">
                            <div class="info-title"><span class="color-black">SERVICES</span></div>
                            <div class="mt-20 info-content">
                                <div><span class="color-orange">We Provide</span></div>
                                <div><span class="color-orange">Awesome</span></div>
                                <div><span class="color-orange">Services</span></div>
                            </div>
                            <div class="mt-40">
                                <span class="color-black content-text">Let us help you succeed by taking care of your digital infrastructure, software solution development, integration, and delivery while you focus on developing your business.</span>
                            </div>
                            <div class="nav-arrows d-flex">
                                <div class="left-arrow mr-10"><a><img
                                            [src]="root.imageUrl() + '/svg-icons/left-arrow-icon.svg'"></a></div>
                                <div class="left-arrow"><a><img
                                    [src]="root.imageUrl() + '/svg-icons/right-arrow-icon.svg'"></a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9">
                        <div class="service-right-content">
                            <div class="position-relative">
                                <div class="card-bg">
                                    <div class="card-panel">
                                        <div class="overflow-auto-panel -ms-scrollbar scrollable-card">
                                            <div class="content-card position-relative text-center">
                                                <!-- d-flex align-items-center flex-column -->
                                                <div class="service-icon center">
                                                    <img
                                                        [src]="root.imageUrl() + '/svg-icons/enterprise-service-icon.svg'">
                                                </div>
                                                <div class="mb-15"><span class="color-black service-title">Enterprise
                                                        Software
                                                        Services</span></div>
                                                <div class="card-content"><span class="color-black content-text">Let us help you succeed by taking care of your digital infrastructure, software solution development, integration, and delivery while you focus on developing your business.</span></div>
                                                <div>
                                                    <a><img [src]="root.imageUrl() + '/svg-icons/right-arrow-icon.svg'"
                                                            class="arrow-icon"></a>
                                                </div>
                                            </div>
                                            <div class="content-card position-relative text-center">
                                                <div class="service-icon center">
                                                    <img
                                                        [src]="root.imageUrl() + '/svg-icons/mobile-service-icon.svg'">
                                                </div>
                                                <div class="mb-15"><span class="color-black service-title">Mobile
                                                        Application </span></div>
                                                <div class="card-content"><span class="color-black content-text">Let us help you succeed by taking care of your digital infrastructure, software solution development, integration, and delivery while you focus on developing your business.</span></div>
                                                <div>
                                                    <a><img [src]="root.imageUrl() + '/svg-icons/right-arrow-icon.svg'"
                                                            class="arrow-icon"></a>
                                                </div>
                                            </div>
                                            <div class="content-card position-relative text-center">
                                                <div class="service-icon center">
                                                    <img
                                                        [src]="root.imageUrl() + '/svg-icons/cloud-service-icon.svg'">
                                                </div>
                                                <div class="mb-15"><span class="color-black service-title">Cloud
                                                        Services</span></div>
                                                <div class="card-content"><span class="color-black content-text">Let us help you succeed by taking care of your digital infrastructure, software solution development, integration, and delivery while you focus on developing your business.</span></div>
                                                <div>
                                                    <a><img [src]="root.imageUrl() + '/svg-icons/right-arrow-icon.svg'"
                                                            class="arrow-icon"></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--Testimonial-->
            <div class="d-none ez-testimonial-panel">
                <div class="ez-testimonial-banner">
                    <div class="row">
                        <div class="col-lg-8 order-1-md">
                            <div>
                                <img [src]="root.imageUrl() + '/testimonial-left-banner-bg.png '"
                                    class="left-banner-img">
                            </div>
                            <div class="customer-info-block">
                                <div class="position-relative">
                                    <div class="nav-arrows d-flex align-items-center">
                                        <div class="mr-20"><a><img
                                                    [src]="root.imageUrl() + '/svg-icons/left-round-white-arrow.svg'"></a>
                                        </div>
                                        <div><a><img
                                                    [src]="root.imageUrl() + '/svg-icons/right-round-orange-arrow.svg'"></a>
                                        </div>

                                    </div>
                                    <div class="d-flex align-items-center customer-info-direction">
                                        <div class="profile-info">
                                            <div class="profile-photo">
                                                <img [src]="root.imageUrl() + '/svg-icons/profile-photo.svg'">
                                            </div>
                                        </div>
                                        <div class="profile-text">
                                            <div>
                                                <p class="color-white">Let us help you succeed by taking care of your digital infrastructure, software solution development, integration, and delivery while you focus on developing your business.</p>
                                            </div>
                                            <div class="mb-10">
                                                <span class="color-white">Lucy Stewart</span>
                                            </div>
                                            <div>
                                                <span class="color-white">Owner of Logistic</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 order-12-md">
                            <div class="content-info">
                                <div class="info-title"><span class="color-black">TESTIMONIAL</span></div>
                                <div class="mt-20 info-content">
                                    <div><span class="color-orange">What our</span></div>
                                    <div><span class="color-orange">customers</span></div>
                                    <div><span class="color-orange">are saying</span></div>
                                </div>
                                <div class="mt-40">
                                    <span class="color-black content-text">Let us help you succeed by taking care of your digital infrastructure, software solution development, integration, and delivery while you focus on developing your business.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--Product-->
            <div class="ez-product-panel d-none">
                <div class="ez-banner">
                    <div class="product-header">
                        <div class="info-title"><span class="color-white">PRODUCTS</span></div>
                        <div class="mt-20 info-content">
                            <div><span class="color-white">Our Products</span></div>
                        </div>
                        <div class="mt-30">
                            <span class="color-white">Let us help you succeed by taking care of your digital infrastructure, software solution development, integration, and delivery while you focus on developing your business.</span>
                        </div>
                        <div class="mt-10">
                            <span class="color-white">eiusmod tempor incididunt ut labore et dolore magna aliqua </span>
                        </div>
                    </div>
                    <div class="middle-block">
                        <div class="row">
                            <div class="col-lg-7">
                                <div class="pt-30 product-info">
                                    <div class="mb-20"><img
                                            [src]="root.imageUrl() + '/svg-icons/vegitone-icon.svg'"
                                            class="vegitone-icon"></div>
                                    <div>
                                        <p class="color-white content-text">Let us help you succeed by taking care of your digital infrastructure, software solution development, integration, and delivery while you focus on developing your business.</p>
                                    </div>
                                    <div class="product-slider">
                                        <div class="slider-component"></div>
                                        <div class="slider-component active"></div>
                                        <div class="slider-component"></div>
                                        <div class="slider-component"></div>
                                    </div>
                                    <div class="nav-arrows d-flex justify-content-end mt-20">
                                        <div class="left-arrow mr-20">
                                            <a><img
                                                    [src]="root.imageUrl() + '/svg-icons/left-arrow-white-icon.svg'"></a>
                                        </div>
                                        <div class="right-arrow">
                                            <a><img
                                                    [src]="root.imageUrl() + '/svg-icons/right-arrow-white-icon.svg'"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5">
                                <div class="right-panel-bg">
                                    <div class="ez-app-book">
                                        <img [src]="root.imageUrl() + '/ez-app-book.png'" class="app-book-img">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--Industries-->
            <div class="ez-industry-panel d-none">
                <!-- old structure -->
                <div class="d-none ez-industry-banner">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="left-content-panel">

                                <div class="info-title"><span class="color-black">INDUSTRIES</span></div>
                                <div class="mt-20 info-content">
                                    <div><span class="color-orange">ekZero</span></div>
                                    <div><span class="color-orange">for your </span></div>
                                    <div><span class="color-orange">Industries </span></div>
                                </div>
                                <div class="mt-40">
                                    <span class="color-black content-text">Let us help you succeed by taking care of your digital infrastructure, software solution development, integration, and delivery while you focus on developing your business.</span>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="right-content-panel">
                                <div class="right-content d-flex">
                                    <div class="content-card">
                                        <div class="d-flex">
                                            <div class="">
                                                <div class="content-img">
                                                    <img [src]="root.imageUrl() + '/it-telecom-img.png '"
                                                        class="h-100">
                                                </div>
                                            </div>
                                            <div class="content-info">
                                                <div class="mb-20"><span
                                                        class="color-orange transform-capitalize font-18">IT and
                                                        Telecom</span></div>
                                                <div>
                                                    <p class="color-black content-text">
                                                        Whether you are a service provider or someone who provides
                                                        end
                                                        to end IT business solutions. Leave on us to design,
                                                        develop,
                                                        test, deploy and support …
                                                    </p>
                                                </div>
                                                <div class="text-right">
                                                    <a href="javacript:void(0);"
                                                        class="read-more-link transform-capitalize color-orange">Read
                                                        More
                                                        <img [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'"
                                                            class="pl-10">
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content-card">
                                        <div class="d-flex">
                                            <div class="">
                                                <div class="content-img">
                                                    <img [src]="root.imageUrl() + '/banking-financial-img.png '"
                                                        class="h-100">
                                                </div>
                                            </div>
                                            <div class="content-info">
                                                <div class="mb-20"><span
                                                        class="color-orange transform-capitalize font-18">Banking &
                                                        Financial Serivices</span></div>
                                                <div>
                                                    <p class="color-black content-text">
                                                        Whether you are a service provider or someone who provides
                                                        end
                                                        to end IT business solutions. Leave on us to design,
                                                        develop,
                                                        test, deploy and support …
                                                    </p>
                                                </div>
                                                <div class="text-right">
                                                    <a class="read-more-link transform-capitalize color-orange">Read
                                                        More
                                                        <img [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'"
                                                            class="pl-10">
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="nav-arrows d-flex justify-content-center mt-30">
                                    <div class="left-arrow mr-10"><a><img
                                        [src]="root.imageUrl() + '/svg-icons/left-arrow-icon.svg'"></a>
                                    </div>
                                    <div class="left-arrow"><a><img
                                        [src]="root.imageUrl() + '/svg-icons/right-arrow-icon.svg'"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- new structure -->
                <div class="ez-industry-banner">
                    <div class="row no-gutters">
                        <div class="col-xl-4 col-lg-4 col-sm-12">
                            <div class="left-content-panel">
                                <div class="info-title">
                                    <span class="color-black">INDUSTRIES</span>
                                </div>
                                <div class="mt-20 info-content">
                                    <div>
                                        <span class="color-orange">ekZero</span>
                                    </div>
                                    <div>
                                        <span class="color-orange">for your</span>
                                    </div>
                                    <div>
                                        <span class="color-orange">Industries </span>
                                    </div>
                                </div>
                                <div class="mt-40">
                                    <span class="color-black content-text">Let us help you succeed by taking care of your digital infrastructure, software solution development, integration, and delivery while you focus on developing your business.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-8 col-lg-8 col-sm-12">
                            <div class="right-content-panel">
                                <div class="right-content">
                                    <div class="content-card">
                                        <div class="row no-gutters">
                                            <div class="col-lg-4 col-xl-4">
                                                <div class="content-img">
                                                    <!-- <img [src]="root.imageUrl() + '/it-telecom-service-icon.jpg"> -->
                                                </div>
                                            </div>
                                            <div class="col-lg-8 col-xl-8">
                                                <div class="content-info">
                                                    <div class="mb-20">
                                                        <span
                                                            class="color-orange transform-capitalize font-18 title-text">IT
                                                            and
                                                            Telecom</span>
                                                    </div>
                                                    <div>
                                                        <p class="color-black content-text"> Whether you are a service
                                                            provider or someone who provides end to end IT business
                                                            solutions. Leave
                                                            on us to design, develop, test, deploy and support … </p>
                                                    </div>
                                                    <div class="link-panel"><a href="javacript:void(0);"
                                                            class="read-more-link transform-capitalize color-orange">Read
                                                            More <img
                                                                [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'"
                                                                class="pl-10"></a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content-card">
                                        <div class="row no-gutters">
                                            <div class="col-lg-4 col-xl-4">
                                                <div class="content-img">
                                                    <!-- <img [src]="root.imageUrl() + '/banking-financial-img.png '"> -->
                                                </div>
                                            </div>
                                            <div class="col-lg-8 col-xl-8">
                                                <div class="content-info">
                                                    <div class="mb-20">
                                                        <span
                                                            class="color-orange transform-capitalize font-18 title-text">Banking
                                                            & Financial Serivices</span>
                                                    </div>
                                                    <div>
                                                        <p class="color-black content-text"> Whether you are a service
                                                            provider or someone who provides end to end IT business
                                                            solutions. Leave
                                                            on us to design, develop, test, deploy and support … </p>
                                                    </div>
                                                    <div class="link-panel"><a href="javacript:void(0);"
                                                            class="read-more-link transform-capitalize color-orange">Read
                                                            More <img
                                                                [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'"
                                                                class="pl-10"></a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="nav-arrows d-flex justify-content-center mt-30">
                                    <div _ngcontent-ato-c21="" class="left-arrow mr-10"><a><img
                                                [src]="root.imageUrl() + '/svg-icons/left-arrow-icon.svg'"></a>
                                    </div>
                                    <div class="left-arrow"><a><img
                                         [src]="root.imageUrl() + '/svg-icons/right-arrow-icon.svg'"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>