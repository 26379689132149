<div class="ez-work-process-wrapper">
    <div class="ez-work-process-panel">
        <div class="row align-items-end justify-content-end no-gutters">
            <div class="col-xl-3 col-lg-6 col-md-8">
                <div class="info-title"><span>How</span></div>
                <div class="mt-20 info-content">
                    <div><span class="color-orange">We Work?</span></div>
                    <div><span class="content-text ">We innovate to discover a better way for our clients, customers,
                            and communities who count on us.
                        </span></div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" data-aos="fade-up" data-aos-duration="1700">
        <div class="col-12">
            <div>
                <img [src]="root.imageUrl() + '/ez-ecs-how-we-work.png'" width="100%" />
            </div>
        </div>
    </div>
    <!-- <div class="ez-work-content-wrapper">
        <div class="overflow-wrapper">
    <div class="ez-work-content-wrapper">
        <div class="overflow-wrapper -ms-scrollbar scrollable-body">
            <div class="card-panel-wrapper">
                <div class="row card-wrapper">
                    <div class="col-xl-3 col-lg-6">
                        <div class="center flex-column">
                            <div class="content-icon content-icon-blueBg center">
                                <img [src]="root.imageUrl() + '/svg-icons/plan-icon.svg'" alt="">
                            </div>
                            <div class="vl-card-panel">
                                <div class="vl-card-header vl-header-blue center">
                                    <div>
                                        <span>PLAN</span>
                                    </div>

                                </div>
                                <div class="vl-card-content">
                                    <div class="back-flip back-flip-blue"></div>
                                    <div class="vl-card-content-panel">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since the
                                            1500s,
                                            when an unknown printer took a galley of type and scrambled it to make a
                                            type
                                            specimen book.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-6">
                        <div class="center flex-column">
                            <div class="content-icon content-icon-orangeBg center">
                                <img [src]="root.imageUrl() + '/svg-icons/plan-icon.svg'" alt="">
                            </div>
                            <div class="vl-card-panel">
                                <div class="vl-card-header vl-header-orange center">
                                    <div>
                                        <span>PLAN</span>
                                    </div>

                                </div>
                                <div class="vl-card-content">
                                    <div class="back-flip back-flip-orange"></div>
                                    <div class="vl-card-content-panel">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since the
                                            1500s,
                                            when an unknown printer took a galley of type and scrambled it to make a
                                            type
                                            specimen book.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-6">
                        <div class="center flex-column">
                            <div class="content-icon content-icon-blueBg center">
                                <img [src]="root.imageUrl() + '/svg-icons/plan-icon.svg'" alt="">
                            </div>
                            <div class="vl-card-panel">
                                <div class="vl-card-header vl-header-blue center">
                                    <div>
                                        <span>PLAN</span>
                                    </div>

                                </div>
                                <div class="vl-card-content">
                                    <div class="back-flip back-flip-blue"></div>
                                    <div class="vl-card-content-panel">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since the
                                            1500s,
                                            when an unknown printer took a galley of type and scrambled it to make a
                                            type
                                            specimen book.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-6">
                        <div class="center flex-column">
                            <div class="content-icon content-icon-orangeBg center">
                                <img [src]="root.imageUrl() + '/svg-icons/plan-icon.svg'" alt="">
                            </div>
                            <div class="vl-card-panel">
                                <div class="vl-card-header vl-header-orange center">
                                    <div>
                                        <span>PLAN</span>
                                    </div>

                                </div>
                                <div class="vl-card-content">
                                    <div class="back-flip back-flip-orange"></div>
                                    <div class="vl-card-content-panel">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since the
                                            1500s,
                                            when an unknown printer took a galley of type and scrambled it to make a
                                            type
                                            specimen book.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    <!--Timeline-->
    <!-- <div class="row">
            <div class="col-sm-12"> -->
    <!-- <div class="center">
                    <div class="outer-circle-orange center">
                        <div class="inner-circle"></div>
                    </div>
                    <div class="blue-path"></div>
                    <div class="outer-circle-blue center">
                        <div class="inner-circle"></div>
                    </div>
                    <div class="orange-path"></div>
                    <div class="outer-circle-orange center">
                        <div class="inner-circle"></div>
                    </div>
                </div> -->
    <!-- <div class="mt-60">
                    <div class="timeline">
                        <div class="center">
                            <div class="first center">
                                <div class="inner-circle"></div>
                            </div>
                            <div class="center">
                                <div class="odd-path"></div>
                                <div class="odd-circle center">
                                    <div class="inner-circle"></div>
                                </div>
                                <div class="even-path"></div>
                                <div class="even-circle center">
                                    <div class="inner-circle"></div>
                                </div>
                                <div class="odd-path"></div>
                                <div class="odd-circle center">
                                    <div class="inner-circle"></div>
                                </div>
                            </div>
                            <div class="last"></div>
                        </div>
                    </div> -->
    <!-- responsive slider -->
    <!-- <div class="slider-block">
                        <div class="slider-component active"></div>
                        <div class="slider-component"></div>
                        <div class="slider-component"></div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>