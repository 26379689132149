import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionModule } from "ngx-accordion";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FaqRoutingModule } from './faq-routing.module';
import { FaqComponent } from './pages/faq/faq.component';
import { FaqBannerComponent } from './components/faq-banner/faq-banner.component';
import { FaqSectionComponent } from './components/faq-section/faq-section.component';


@NgModule({
  declarations: [FaqComponent, FaqBannerComponent, FaqSectionComponent],
  imports: [
    CommonModule,
    FaqRoutingModule,
    AccordionModule,
    NgbModule
  ]
})
export class FaqModule { }
