<div id="product-footer" class="join-our-team" data-aos="fade-up" data-aos-duration="1500">
    <div class="footer-wrapper">
        <div class="row no-gutters">
            <div class="col-lg-5">
                <div class="left-content">
                    <img [src]="root.imageUrl() + '/ez-life-Join-our-team.png'" width="100%">
                </div>
            </div>
            <div class="col-lg-7">
                <div class="footer-content">
                    <div class="right-content">
                        <div class="footer-title">
                            <span>Join our team of great<span class="title-info"> techies </span> </span>
                        </div>
                        <div class="btn-grp">
                            <a class="btn-primary btn-xlg" [routerLink]="root.careers()" target="_top">View current
                                openings</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>