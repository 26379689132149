import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'app-create-member-centeric',
  templateUrl: './create-member-centeric.component.html',
  styleUrls: ['./create-member-centeric.component.scss']
})
export class CreateMemberCentericComponent implements OnInit {
  isDisplay: boolean = false;
  isShow: boolean = false;
  constructor(public root: RootService) { }

  ngOnInit(): void {
  }

}
