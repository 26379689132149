import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ekzero-intro',
  templateUrl: './ekzero-intro.component.html',
  styleUrls: ['./ekzero-intro.component.scss']
})
export class EkzeroIntroComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
