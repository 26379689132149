import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'app-self-driving-truck-transform',
  templateUrl: './self-driving-truck-transform.component.html',
  styleUrls: ['./self-driving-truck-transform.component.scss']
})
export class SelfDrivingTruckTransformComponent implements OnInit {
  isDisplay: boolean = false;
  isShow: boolean = false;
  constructor(public root: RootService) { }

  ngOnInit(): void {
  }

}
