<div id="our-experts" data-aos="fade-up" data-aos-duration="1500">
    <div class="expert-wrapper">
        <div class="row no-gutters">
            <div class="col-lg-4">
                <div class="left-panel">
                    <div class="content-info">
                        <div class="info-title"><span class="color-black">OUR EXPERTS</span></div>
                        <div class="mt-20 info-content">
                            <div><span class="color-orange">Meet our</span></div>
                            <div><span class="color-orange">key</span></div>
                            <div><span class="color-orange">executive</span>
                            </div>
                        </div>
                        <!-- <div class="mt-40">
                            <span class="color-black content-text">Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </span>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="overflow-wrapper -ms-scrollbar scrollable-body">
                    <div class="right-panel card-wrapper">
                        <div class="row no-gutters">
                            <!-- <div class="col-sm-4 col-4">
                                <div class="right-panel-content wow animated animate__fadeIn animate__animated animate__slower">
                                    <div class="center "><img [src]="root.imageUrl() + '/pradeep-moradiya.png' ">
                                    </div>
                                    <div class="text-center mt-30 name-tag">
                                        <div class="mb-10"><span class="color-black emp-name">Pradeep Moradiya</span>
                                        </div>
                                        <div><span class="color-orange emp-designation">COO</span></div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-sm-5 col-6">
                                <div class="right-panel-content  wow animated animate__fadeIn animate__animated animate__slower">
                                    <div class="center"><img [src]="root.imageUrl() + '/jay-beldar.png' "></div>
                                    <div class="text-center mt-30 name-tag">
                                        <div class="mb-10"><span class="color-black emp-name">Jay Beldar</span></div>
                                        <div><span class="color-orange emp-designation">CEO & CO-FOUNDER</span></div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-sm-5 col-6">
                                <div class="right-panel-content  wow animated animate__fadeIn animate__animated animate__slower">
                                    <div class="center "><img [src]="root.imageUrl() + '/dhruv-dave.png' "></div>
                                    <div class="text-center mt-30 name-tag">
                                        <div class="mb-10"><span class="color-black emp-name">Dhruv Dave</span></div>
                                        <div><span class="color-orange emp-designation">VP Operations</span></div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>