<div id="explore-services">
    <div class="explore-block">
        <div class="row">
            <div class="col-xl-8 col-lg-order-2">
                <div class="left-content">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="mb-30">
                                <div><img class="service-img" [src]="root.imageUrl() + '/svg-icons/cloud.svg'">
                                </div>
                                <div class="mt-15"><span class="color-black service-title font-600">Cloud Migration
                                    </span></div>
                                <div class="mt-15"><span class="color-black content-text">Cloud Migration is a process
                                        where the organization's resources, services, and apps are deployed on the
                                        cloud, and the migrated assets are not accessed beyond the cloud’s firewall.
                                        Through ekZero Cloud Migration, we determine which approach works best for your
                                        organization i.e, public, private, or hybrid. Our procedure for effective cloud
                                        migration includes - Planned Strategy, Infrastructure Audits, Architect
                                        Designing, Automation, Deployment, and Rapid Testing. We restructure the process
                                        by minimizing errors and glitches by addressing migration requirements and infra
                                        needs. Our comprehensive processes also include disaster recovery to keep your
                                        data safe and well backed up all the time.
                                    </span></div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-30">
                                <div><img class="service-img" [src]="root.imageUrl() + '/svg-icons/optimization.svg'">
                                </div>
                                <div class="mt-15"><span class="color-black service-title font-600">Cloud
                                        Optimization</span></div>
                                <div class="mt-15"><span class="color-black content-text">The heart of cloud computing
                                        is Optimization. That’s why ekZero’s framework builds a cloud ecosystem that is
                                        optimized across networks, storage, compute, and operations to bring higher
                                        returns on investments. These 4 are the foundation pillars of all cloud
                                        optimization activities. ekZero tends to provide best in class security and
                                        governance standards through the years of cloud domain expertise. We assess the
                                        gaps in your current infrastructure, remove obsolete components, identify the
                                        standard operating environment for your target infrastructure and then optimize
                                        your networks, storages, and incurring costs. This complete flow helps you in
                                        gaining budget transparency along with the correct usage statistics and security
                                        reports.
                                    </span></div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="mb-30">
                                <div><img class="service-img" [src]="root.imageUrl() + '/svg-icons/maintenance.svg'">
                                </div>
                                <div class="mt-15"><span class="color-black service-title font-600">Cloud Maintenance
                                    </span></div>
                                <div class="mt-15"><span class="color-black content-text">ekZero’s engineers will give
                                        you an unforgettable experience through our cloud maintenance services and cloud
                                        infrastructure management services. From ensuring a safe environment for your
                                        data assets to making servers accessible around the clock you can rely on us to
                                        solve your cloud complexities. We achieve client success through continuously
                                        monitoring server performances, ensuring all applications are in operation,
                                        keeping an eye on bandwidth usage, server faults, and memory leaks. Security is
                                        our topmost priority while servicing and our experts are constantly supervising
                                        for threats so that they are fixed before damages become apparent.
                                    </span></div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-30">
                                <div><img class="service-img" [src]="root.imageUrl() + '/svg-icons/architecture.svg'">
                                </div>
                                <div class="mt-15"><span class="color-black service-title font-600">Cloud Architecture
                                    </span></div>
                                <div class="mt-15"><span class="color-black content-text">The purpose of great cloud
                                        architecture is to allow users to experience uninterrupted access to their data
                                        and application, enabled with high bandwidth. Ensuring the success of your cloud
                                        service completely depends on how sturdy and robust is your cloud architecture.
                                        At ekZero, our cloud designers and implementers go beyond providing your
                                        business the cloud support within the data center to make sure that your cloud
                                        architecture is capable of meeting the mission-critical objectives or not. With
                                        us, you can ensure high application availability and fast performance for
                                        service users anywhere in the world, connecting with any type of device.
                                    </span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-order-1">
                <div class="right-content">
                    <div class="right-bg">
                        <div class="mt-20 info-content"><span>Our Cloud Offerings
                            </span></div>
                        <div class="mt-20"><span class="content-text">ekZero provides the entire spectrum of cloud
                                services covering the public, private and hybrid cloud types.</span></div>
                        <div><a class="btn-news btn-md" [routerLink]="root.ourServices()" target="_top">VIEW ALL</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>