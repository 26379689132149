import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'product-banner',
  templateUrl: './product-banner.component.html',
  styleUrls: ['./product-banner.component.scss']
})
export class ProductBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
