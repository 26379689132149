<!-- --------------------ekvegit------------------start ----->
<div class="products" id="ekvegit">
  <div class="products-wrapper">
    <div class="row">
      <div class="col-12">
        <div class="width-50">
          <div class="text-align-center">
            <span class="font--30 color-orange font-600">
              ekVegit Product Family
            </span>
          </div>
          <div class="mt-30 text-align-center">
            <!-- <p class="font-14">
                            VegitOne - One that delivers !!
                        </p> -->
            <p class="font-14">
              VegitOne is ekZero's revolutionary product for logistics and fleet
              management that aims in serving small and medium transport
              enterprises around the world.
            </p>
          </div>
          <div
            class="mt-50 d-flex justify-center"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <div>
              <div class="mr-50 ml-5">
              <img [src]="root.imageUrl() + '/svg-icons/vegitone-product-logo.svg'"  class="secondary-icon">
              </div>
              <div class=""><span class="font-14">VegitOne</span></div>
            </div>
            <div>
              <div> 
              <img [src]="root.imageUrl() + '/svg-icons/ez-Gtrack-logo.svg'"  class="secondary-icon">                
              </div>
              <div class="ml-5">
                <span class="font-14">GTrack</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="products-container mt-50"
      data-aos="fade-right"
      data-aos-duration="1500"
    >
      <div class="background-left"></div>
      <div class="row">
        <div class="col-12">
          <div class="text-align-center"> 
            <img [src]="root.imageUrl() + '/svg-icons/ez-VegitOne-name-logo.svg'" class="primary-icon">                
          </div>
          <div class="mt-30 mb-80 text-align-center">
            <!-- <div class="font-14">
                            VegitOne is ekZero's revolutionary product for logistics and fleet management that aims in
                            serving small and medium transport enterprises around the world. Visit <a
                                href="https://www.vegitone.com" target="_blank">vegitone.com</a> to learn
                            more.
                        </div> -->
            <div class="font-14 mt-10 mb-10">
              VegitOne is a one-stop solution (TMS App, driver app, client
              portal, and ELD portal)that allows transport businesses to manage
              orders, loads, dispatches, clients, drivers, schedules, invoicing,
              accounting, driver settlements, Live tracking, Electronic logs,
              HOS, reporting, IFTA, and other operations from one cloud
              connected place.
            </div>
            <div>
              Visit
              <a href="https://www.vegitone.com" target="_blank"
                >vegitone.com</a
              >
              to learn more.
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xl-4 col-lg-order2">
          <div class="app-book-img-left-wrapper">
            <img
              [src]="root.imageUrl() + '/ez-app-book.png'"
              class="app-book-img"
            />
          </div>
        </div>
        <div class="col-12 col-xl-8 col-lg-order1">
          <div class="">
            <div class="row">
              <div class="col-12 col-lg-6 col-xl-6">
                <div class="d-flex mb-40">
                  <div class="mr-15">
                    <img
                      [src]="root.imageUrl() + '/svg-icons/ai.svg'"
                      class="wifi-icon"
                    />
                    <!-- <img src="assets/images/svg-icon/ai.svg" class=" wifi-icon"> -->
                  </div>
                  <div>
                    <div class="mb-10">
                      <h5>Powerful AI</h5>
                    </div>
                    <div>
                      <span class="font-14">
                        Robust algorithms that assist the dispatcher in
                        performing complicated tasks like space calculation,
                        load scheduling, route optimization, etc. automatically.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 col-xl-6">
                <div class="d-flex mb-40">
                  <div class="mr-15">
                    <img
                      [src]="root.imageUrl() + '/svg-icons/ui.svg'"
                      class="wifi-icon"
                    />
                    <!-- <img src="assets/images/svg-icon/ui.svg" class=" wifi-icon"> -->
                  </div>
                  <div>
                    <div class="mb-10">
                      <h5>Easy User Interface</h5>
                    </div>
                    <div>
                      <span class="font-14">
                        User-centred application design to ensure effortless
                        in-app navigation and interaction. Provides quick
                        access, ease in usage, and comprehension to users.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <div class="row">
              <div class="col-12 col-lg-6 col-xl-6">
                <div class="d-flex mb-40">
                  <div class="mr-15">
                    <img
                      [src]="root.imageUrl() + '/svg-icons/gps.svg'"
                      class="wifi-icon"
                    />
                    <!-- <img src="assets/images/svg-icon/gps.svg" class=" wifi-icon"> -->
                  </div>
                  <div>
                    <div class="mb-10">
                      <h5>Live GPS Tracking</h5>
                    </div>
                    <div>
                      <span class="font-14">
                        Track your in-transit dispatches live. Get automated
                        updates on location, stoppage, speed, arrival time, etc
                        immediately.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 col-xl-6">
                <div class="d-flex mb-40">
                  <div class="mr-15">
                    <img
                      [src]="
                        root.imageUrl() + '/svg-icons/ez-wifi-orange-logo.svg'
                      "
                      class="wifi-icon"
                    />
                  </div>
                  <div>
                    <div class="mb-10">
                      <h5>Real-Time Communication</h5>
                    </div>
                    <div>
                      <span class="font-14">
                        Connect with your driver through VegitOne CoPilot and
                        maintain a smooth flow of information.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <a
            [routerLink]="root.ekvegit()"
            target="_top"
            class="btn-primary btn-lg button-p-right"
            >Read More</a
          >
        </div>
      </div>
    </div>
    <!-- GTrack -->
    <div
      class="products-container mt-100"
      data-aos="fade-right"
      data-aos-duration="1500"
    >
      <div class="background-right"></div>
      <div class="row">
        <div class="col-12">
          <div class="text-right resp-center">
            <img
              [src]="root.imageUrl() + '/gtrack-logo.png'"
              class="primary-icon-2"
            />
          </div>
          <div class="mt-30 mb-80 text-align-center">
            <div class="font-14">
              GTrack is a full-fledged GPS tracking system. It tracks the
              asset's position using IoT devices and provides you with numerous
              insights via an interactive dashboard. Depending on various
              customer needs, GTrack is flexible to be used as a personal and a
              corporate solution.
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xl-8">
          <div class="">
            <div class="row">
              <div class="col-12 col-lg-6 col-xl-6">
                <div class="d-flex mb-40">
                  <div class="mr-15">
                    <img
                      [src]="root.imageUrl() + '/svg-icons/glt.svg'"
                      class="wifi-icon"
                    />
                    <!-- <img src="assets/images/svg-icon/glt.svg" alt="" class=" wifi-icon"> -->
                  </div>
                  <div>
                    <div class="mb-10">
                      <h5>Live Location Tracking</h5>
                    </div>
                    <div>
                      <span class="font-14">
                        GTrack regularly lets you know where your assets or
                        vehicles are at your convenient time frequency, starting
                        from every 10 sec to once a week.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 col-xl-6">
                <div class="d-flex mb-40">
                  <div class="mr-15">
                    <img
                      [src]="root.imageUrl() + '/svg-icons/ds.svg'"
                      class="wifi-icon"
                    />
                    <!-- <img src="assets/images/svg-icon/ds.svg" alt="" class=" wifi-icon"> -->
                  </div>
                  <div>
                    <div class="mb-10">
                      <h5>Data Security</h5>
                    </div>
                    <div>
                      <span class="font-14">
                        GTrack always takes data security as a top priority. All
                        the data which GTrack collects is securely stored near
                        you.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <div class="row">
              <div class="col-12 col-lg-6 col-xl-6">
                <div class="d-flex mb-40">
                  <div class="mr-15">
                    <img
                      [src]="root.imageUrl() + '/svg-icons/geofence.svg'"
                      class="wifi-icon"
                    />
                    <!-- <img src="assets/images/svg-icon/geofence.svg" alt="" class=" wifi-icon"> -->
                  </div>
                  <div>
                    <div class="mb-10">
                      <h5>Geofencing</h5>
                    </div>
                    <div>
                      <span class="font-14">
                        GTrack helps in setting up virtual boundaries to keep a
                        detailed track of your assets. Automatically sends you
                        updates if any devices cross the defined boundary.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 col-xl-6">
                <div class="d-flex mb-40">
                  <div class="mr-15">
                    <img
                      [src]="root.imageUrl() + '/svg-icons/offine-gps.svg'"
                      class="wifi-icon"
                    />
                    <!-- <img src="assets/images/svg-icon/offine-gps.svg" alt="" class=" wifi-icon"> -->
                  </div>
                  <div>
                    <div class="mb-10">
                      <h5>Offline GPS Data Recovery</h5>
                    </div>
                    <div>
                      <span class="font-14">
                        GTrack goes beyond the boundaries of just tracking a
                        vehicle in real-time. Even in remote and low internet
                        connectivity areas, GTrack provides the full logging
                        details to users once the connectivity is restored.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-4">
          <div class="app-book-img-right-wrapper">
            <img
              [src]="root.imageUrl() + '/ez-gtrack-app-book.png'"
              class="app-book-img"
            />
          </div>
        </div>
        <div>
          <a
            [routerLink]="root.gtrack()"
            target="_top"
            class="btn-primary btn-lg button-p-left"
            >Read More</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
<!-- --------------------ekvegit------------------end ----->

<!-- --------------------OneViz------------------start ----->
<div class="d-none products" id="oneviz">
  <div class="products-wrapper">
    <div class="row">
      <div class="col-12">
        <div class="width-50 ml-50per">
          <div class="text-right text-align-center">
            <span class="font--30 font-600 skyblue"> OneViz </span>
          </div>
          <div class="mt-30 text-right text-align-center">
            <p class="font-14">
              OneViz provides a comprehensive collection of solutions to assist
              you with association management, member onboarding, event
              planning, conference administration, running seminars, and much
              more.
            </p>
          </div>
          <div
            class="mt-50 d-flex justify-content-end justify-center"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <div>
              <div class="mr-50">
                <img
                  [src]="root.imageUrl() + '/svg-icons/ez-Amity-logo.svg'"
                  class="secondary-icon"
                />
              </div>
              <div class="ml-10"><span class="font-14">Amity</span></div>
            </div>
            <div>
              <div class="mr-5">
                <img
                  [src]="root.imageUrl() + '/svg-icons/ez-ekVent-logo.svg'"
                  class="secondary-icon"
                />
              </div>
              <div class="ml-5">
                <span class="font-14">ekVent</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- amity final -->
    <div
      class="products-container mt-50"
      data-aos="fade-right"
      data-aos-duration="1500"
    >
      <div class="background-left"></div>
      <div class="row">
        <div class="col-12">
          <div class="text-align-center">
            <img
              [src]="root.imageUrl() + '/svg-icons/oneviz-amity-logo.svg'"
              width="130"
              class="primary-icon-1"
            />
          </div>
          <div class="mt-30 mb-80 text-align-center">
            <div class="font-14">
              A comprehensive solution to manage every type of Societies,
              Associations, and Clubs. Build your website, grow members, manage
              their subscriptions, run elections/polls, share news, and more
              information all in one single platform.
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xl-4 col-lg-order2">
          <div class="app-book-img-left-wrapper">
            <img
              [src]="root.imageUrl() + '/ez-oneviz-app-book.png'"
              class="app-book-img"
            />
          </div>
        </div>
        <div class="col-12 col-xl-8 col-lg-order1">
          <div class="">
            <div class="row">
              <div class="col-12 col-lg-6 col-xl-6">
                <div class="d-flex mb-20 align-items-center">
                  <div class="mr-15">
                    <img
                      [src]="root.imageUrl() + '/svg-icons/member.svg'"
                      class="wifi-icon"
                    />
                    <!-- <img src="assets/images/svg-icon/member.svg" alt="" class=" wifi-icon"> -->
                  </div>
                  <div>
                    <div class="mb-10">
                      <h5>Member Management</h5>
                    </div>
                    <div>
                      <span class="font-14">
                        Utilize a sophisticated database and communication tools
                        to manage member and prospect data and interaction.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 col-xl-6">
                <div class="d-flex mb-20 align-items-center">
                  <div class="mr-15">
                    <img
                      [src]="root.imageUrl() + '/svg-icons/website.svg'"
                      class="wifi-icon"
                    />
                    <!-- <img src="assets/images/svg-icon/website.svg" alt="" class=" wifi-icon"> -->
                  </div>
                  <div>
                    <div class="mb-10">
                      <h5>Public Website Builder</h5>
                    </div>
                    <div>
                      <span class="font-14">
                        Customize and create community website by choosing from
                        professionally crafted templates and designs without any
                        technological requirements.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <div class="row">
              <div class="col-12 col-lg-6 col-xl-6">
                <div class="d-flex mb-20 align-items-center">
                  <div class="mr-15">
                    <img
                      [src]="root.imageUrl() + '/svg-icons/elelction.svg'"
                      class="wifi-icon"
                    />
                    <!-- <img src="assets/images/svg-icon/elelction.svg" alt="" class=" wifi-icon"> -->
                  </div>
                  <div>
                    <div class="mb-10">
                      <h5>Election Management</h5>
                    </div>
                    <div>
                      <span class="font-14">
                        OneViz Amity is meant to meet all of the fundamental
                        needs, such as security, integrity, auditability, and
                        efficiency, throughout every election phase.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 col-xl-6">
                <div class="d-flex mb-20 align-items-center">
                  <div class="mr-15">
                    <img
                      [src]="root.imageUrl() + '/svg-icons/payment.svg'"
                      class="wifi-icon"
                    />
                    <!-- <img src="assets/images/svg-icon/payment.svg" alt="" class=" wifi-icon"> -->
                  </div>
                  <div>
                    <div class="mb-10">
                      <h5>Payments and Settlements</h5>
                    </div>
                    <div>
                      <span class="font-14">
                        Manage multiple transactions and financial settlements
                        by scheduling payments, and sending reminders.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <a
            [routerLink]="root.oneviz()"
            target="_top"
            class="btn-secondary btn-lg button-p-right"
            >Read More</a
          >
        </div>
      </div>
    </div>
    <!-- oneviz final -->
    <div
      class="products-container mt-100"
      data-aos="fade-right"
      data-aos-duration="1500"
    >
      <div class="background-right"></div>
      <div class="row">
        <div class="col-12">
          <div class="text-right resp-center">
            <img
              [src]="root.imageUrl() + '/svg-icons/ez-oneviz-name-logo.svg'"
              class="primary-icon-1"
            />
          </div>
          <div class="mt-30 mb-80 text-right">
            <div class="font-14">
              A comprehensive platform to handle all the operational activities
              of any event and conference. It also manages attendees'
              registration, payments, attendance, website, room allocation,
              event itinerary, etc.
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xl-8">
          <div class="">
            <div class="row">
              <div class="col-12 col-lg-6 col-xl-6">
                <div class="d-flex mb-20 align-items-center">
                  <div class="mr-15">
                    <img
                      [src]="root.imageUrl() + '/svg-icons/planning.svg'"
                      class="wifi-icon"
                    />
                    <!-- <img src="assets/images/svg-icon/planning.svg" alt="" class=" wifi-icon"> -->
                  </div>
                  <div>
                    <div class="mb-10">
                      <h5>Event Planning</h5>
                    </div>
                    <div>
                      <span class="font-14">
                        Organizing your next event will be easier than ever with
                        our all-in-one event management platform, OneViz ekVent.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 col-xl-6">
                <div class="d-flex mb-20 align-items-center">
                  <div class="mr-15">
                    <img
                      [src]="root.imageUrl() + '/svg-icons/attendee.svg'"
                      class="wifi-icon"
                    />
                    <!-- <img src="assets/images/svg-icon/attendee.svg" alt="" class=" wifi-icon"> -->
                  </div>
                  <div>
                    <div class="mb-10">
                      <h5>Attendee Management</h5>
                    </div>
                    <div>
                      <span class="font-14">
                        Create seamless experience for attendees, starting from
                        registration to ongoing event guidance, and successfully
                        handle several important occasions.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <div class="row">
              <div class="col-12 col-lg-6 col-xl-6">
                <div class="d-flex mb-20 align-items-center">
                  <div class="mr-15">
                    <img
                      [src]="root.imageUrl() + '/svg-icons/gallery.svg'"
                      class="wifi-icon"
                    />
                    <!-- <img src="assets/images/svg-icon/gallery.svg" alt="" class=" wifi-icon"> -->
                  </div>
                  <div>
                    <div class="mb-10">
                      <h5>News and Gallery</h5>
                    </div>
                    <div>
                      <span class="font-14">
                        Keep your members well engaged and up-to-date with
                        community news and gallery feature.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 col-xl-6">
                <div class="d-flex mb-20 align-items-center">
                  <div class="mr-15">
                    <img
                      [src]="root.imageUrl() + '/svg-icons/directory.svg'"
                      class="wifi-icon"
                    />
                    <!-- <img src="assets/images/svg-icon/directory.svg" alt="" class=" wifi-icon"> -->
                  </div>
                  <div>
                    <div class="mb-10">
                      <h5>Member Directory</h5>
                    </div>
                    <div>
                      <span class="font-14">
                        Create member profiles and maintain member directory to
                        easily access and manage personal details and contact
                        information on community members.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-4">
          <div class="app-book-img-right-wrapper">
            <!-- <img [src]="root.imageUrl() + '/ez-oneviz-app-book.png'" class="app-book-img"> -->
            <img
              [src]="root.imageUrl() + '/ez-app-book.png'"
              class="app-book-img"
            />
          </div>
        </div>
        <div>
          <a
            [routerLink]="root.oneviz()"
            target="_top"
            class="btn-secondary btn-lg button-p-left"
            >Read More</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
<!-- --------------------OneViz------------------end ----->

<!-- --------------------Education Classes------------------start ----->
<div class="d-none products">
  <div class="products-wrapper">
    <div class="row">
      <div class="col-12">
        <div class="width-50 ml-50per">
          <div class="text-right text-align-center">
            <span class="font--30 font-600 skyblue"> Education Classes </span>
          </div>
          <div class="mt-30 text-right text-align-center">
            <p class="font-14">
              The community that You live in is your goldmine surrounded by
              individuals who have vast skills and wisdom. Together with
              utilizing TazWiz networks and life experiences, there is no
              challenge that cannot be solved, this is the magic of unity.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="products-container mt-50"
      data-aos="fade-right"
      data-aos-duration="1500"
    >
      <div class="background-right"></div>
      <div class="row">
        <div class="col-12">
          <div class="text-right text-align-center">
            <img
              [src]="root.imageUrl() + '/svg-icons/ez-VegitOne-name-logo.svg'"
              class="primary-icon"
            />
          </div>
          <div class="mt-30 mb-80 text-right text-align-center">
            <div class="font-14">
              The community that You live in is your goldmine surrounded by
              individuals who have vast skills and wisdom.
            </div>
            <div class="font-14">
              Together with utilizing TazWiz networks and life experiences,
              there is no challenge that cannot be solved, this is the magic of
              unity.
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xl-4">
          <div class="app-book-img-right-wrapper">
            <img
            [src]="root.imageUrl() + '/ez-app-book.png'"
              class="app-book-img"
            />
          </div>
        </div>
        <div class="col-12 col-xl-8">
          <div class="">
            <div class="row">
              <div class="col-12 col-lg-6 col-xl-6">
                <div class="d-flex mb-40">
                  <div class="mr-15">
                    <img
                      [src]="
                        root.imageUrl() + '/svg-icons/ez-wifi-skyblue-logo.svg'
                      "
                      class="wifi-icon"
                    />
                  </div>
                  <div>
                    <div class="mb-10">
                      <h5>Real Time Communication</h5>
                    </div>
                    <div>
                      <span class="font-14">
                        Connect with your driver through VegitOne CoPilot and
                        maintain a smooth flow of information.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 col-xl-6">
                <div class="d-flex mb-40">
                  <div class="mr-15">
                    <img
                      [src]="
                        root.imageUrl() + '/svg-icons/ez-wifi-skyblue-logo.svg'
                      "
                      class="wifi-icon"
                    />
                  </div>
                  <div>
                    <div class="mb-10">
                      <h5>Real Time Communication</h5>
                    </div>
                    <div>
                      <span class="font-14">
                        Connect with your driver through VegitOne CoPilot and
                        maintain a smooth flow of information.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <div class="row">
              <div class="col-12 col-lg-6 col-xl-6">
                <div class="d-flex mb-40">
                  <div class="mr-15">
                    <img
                      [src]="
                        root.imageUrl() + '/svg-icons/ez-wifi-skyblue-logo.svg'
                      "
                      class="wifi-icon"
                    />
                  </div>
                  <div>
                    <div class="mb-10">
                      <h5>Real Time Communication</h5>
                    </div>
                    <div>
                      <span class="font-14">
                        Connect with your driver through VegitOne CoPilot and
                        maintain a smooth flow of information.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 col-xl-6">
                <div class="d-flex mb-40">
                  <div class="mr-15">
                    <img
                      [src]="
                        root.imageUrl() + '/svg-icons/ez-wifi-skyblue-logo.svg'
                      "
                      class="wifi-icon"
                    />
                  </div>
                  <div>
                    <div class="mb-10">
                      <h5>Real Time Communication</h5>
                    </div>
                    <div>
                      <span class="font-14">
                        Connect with your driver through VegitOne CoPilot and
                        maintain a smooth flow of information.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <a
            routerLink="/products/education-classes"
            target="_top"
            class="btn-secondary btn-lg button-p-left"
            >Read More</a
          >
        </div>
      </div>
    </div>
  </div>
</div>

<!-- --------------------Education Classes------------------end ----->
