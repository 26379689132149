import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../shared/shared.module';


import { EcsRoutingModule } from './ecs-routing.module';
import { EcsBannerComponent } from './components/ecs-banner/ecs-banner.component';
import { EcsHomeComponent } from './pages/ecs-home/ecs-home.component';
import { TechnologiesComponent } from './components/technologies/technologies.component';
import { EcsIntroComponent } from './components/ecs-intro/ecs-intro.component';
import { ServicesComponent } from './components/services/services.component';
import { IndustriesComponent } from './components/industries/industries.component';
import { ItAndTelecomeComponent } from './components/industries-tabs/it-and-telecome/it-and-telecome.component';
import { BankingAndFinancialServiceComponent } from './components/industries-tabs/banking-and-financial-service/banking-and-financial-service.component';
import { EducationComponent } from './components/industries-tabs/education/education.component';
import { EneryAndUtilitiesComponent } from './components/industries-tabs/enery-and-utilities/enery-and-utilities.component';
import { GovermentComponent } from './components/industries-tabs/goverment/goverment.component';
import { HealthComponent } from './components/industries-tabs/health/health.component';
import { ServiceAndEntertainmentComponent } from './components/industries-tabs/service-and-entertainment/service-and-entertainment.component';
import { EcsWorkProcessComponent } from './components/ecs-work-process/ecs-work-process.component';
import { EcsCaseStudyComponent } from './components/ecs-case-study/ecs-case-study.component';
import { TimezoneComponent } from './components/timezone/timezone.component';
import { PortfolioComponent } from './components/portfolio/portfolio.component';
import { OurPortfolioComponent } from './components/our-portfolio/our-portfolio.component';


@NgModule({
  declarations: [EcsBannerComponent, EcsHomeComponent, TechnologiesComponent, EcsIntroComponent, ServicesComponent, IndustriesComponent, ItAndTelecomeComponent, BankingAndFinancialServiceComponent, EducationComponent, EneryAndUtilitiesComponent, GovermentComponent, HealthComponent, ServiceAndEntertainmentComponent, EcsWorkProcessComponent, EcsCaseStudyComponent, TimezoneComponent, PortfolioComponent, OurPortfolioComponent],
  imports: [
    CommonModule,
    EcsRoutingModule,
    NgbModule,
    SharedModule
  ]
})
export class EcsModule { }
