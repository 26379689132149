<div id="product-intro">
    <div class="intro-wrapper">
        <div class="row no-gutters align-items-center">
            <div class="col-md-6">
                <div class="left-panel">
                    <div class="product-img">
                        <div class="play-icon center">
                            <div class="play-bg center">
                                <div class="play"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="right-panel">
                    <div class="info-section">
                        <div class="info-content mb-30"><span class="color-orange"></span>GTrack Overview</div>
                        <div>
                            <p class="content-text">GTrack is a full-fledged GPS tracking solution. With the leverage of
                                IoT devices, it tracks the asset's location and brings you various insights via an
                                interactive dashboard.
                            </p>
                        </div>
                        <div>
                            <p class="content-text">Depending on various customer needs, GTrack is flexible to be used
                                as a personal and a corporate solution.</p>
                        </div>
                        <div>
                            <p class="content-text">With the seamless integration of various industry-leading software,
                                GTrack takes down the stress of regular tracking and brings peace of mind.
                            </p>
                        </div>
                        <div>
                            <p class="content-text">Whether it’s your business or your little one going to school, you
                                can reply on GTrack for their security and safety anytime.
                            </p>
                        </div>
                        <div class="visit-button-panel">
                            <a class="btn-lg btn-primary visit-button" target="_blank"
                                href="https://gtrack.vegitone.com/">Visit to
                                GTrack</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>