<div id="product-intro">
    <div class="intro-wrapper">
        <div class="row no-gutters align-items-center">
            <div class="col-md-6">
                <div class="left-panel">
                    <div class="product-img">
                        <!-- <div class="play-icon center">
                            <div class="play-bg center">
                                <div class="play"></div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="right-panel">
                    <div class="info-section">
                        <div class="info-content mb-30"><span class="color-orange">OneViz Overview</span></div>
                        <div>
                            <p class="content-text">OneViz’s mission is to be a one-stop platform that caters to all
                                professional and operational needs required to run any social group or gathering in an
                                organized manner.


                            </p>
                        </div>
                        <div>
                            <p class="content-text">Taking advantage of a powerful algorithm system, IT, analytics, and
                                integrations, OneViz beautifully encourages interaction, knowledge sharing, and
                                collaboration to happen organically at the micro-level. </p>
                        </div>
                        <div>
                            <p class="content-text">OneViz is a comprehensive solution to manage every type of
                                Society, Associations, and Clubs.


                            </p>
                        </div>
                        <div>
                            <p class="content-text">OneViz intends to assist all types of social organisations in
                                streamlining their administration, operations, and information flow.
                            </p>
                        </div>
                        <div class="visit-button-panel">
                            <a class="btn-lg btn-primary visit-button" target="_blank"
                                href="http://oneviz.co/">Visit to OneViz</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>