<div id="service-benefits" data-aos="fade-up" data-aos-duration="1500">
    <div class="text-center ml-10 mr-10">
        <div class=""><span class="font-20 color-black">Benefits of</span></div>
        <div class="mt-20 info-content"><span class="color-orange">ekZero's Mobile Application</span></div>
    </div>
    <div class="benefit-content">
        <div class="row no-gutters">
            <div class="col-xl-6 col-lg-7">
                <div class="left-content">
                    <div class="benefit-pill mb-30">
                        <div class="d-flex flex-column-wrapper">
                            <div><img [src]="root.imageUrl() + '/svg-icons/mobile-driving-high-engagement.svg'"
                                    class="benefit-icon"></div>
                            <div class="ml-20">
                                <div class="mb-10"><span class="color-black font-18">Driving High Engagement

                                    </span></div>
                                <div><span class="color-black content-text mb-0">Businesses can use a mobile app to
                                        reach out to prospective new customers. Mobile applications have made it much
                                        easier to connect with potential customers.
                                    </span></div>
                            </div>
                        </div>
                    </div>
                    <div class="benefit-pill mb-30">
                        <div class="d-flex flex-column-wrapper">
                            <div><img [src]="root.imageUrl() + '/svg-icons/mobile-direct-comminication.svg'"
                                    class="benefit-icon"></div>
                            <div class="ml-20">
                                <div class="mb-10"><span class="color-black font-18">Direct Communication

                                    </span></div>
                                <div><span class="color-black content-text mb-0">Business mobile application development
                                        has enabled clear and direct communication between customers and enterprises by
                                        providing instant access to a plethora of information.

                                    </span></div>
                            </div>
                        </div>
                    </div>
                    <div class="benefit-pill mb-30">
                        <div class="d-flex flex-column-wrapper">
                            <div><img [src]="root.imageUrl() + '/svg-icons/mobile-brand-awareness.svg'"
                                    class="benefit-icon"></div>
                            <div class="ml-20">
                                <div class="mb-10"><span class="color-black font-18">Brand Awareness

                                    </span></div>
                                <div><span class="color-black content-text mb-0">The more value you provide, the more
                                        interested your customers get in your brand. Without explicit branding efforts,
                                        mobile applications make it easy to achieve a meaningful brand experience.

                                    </span></div>
                            </div>
                        </div>
                    </div>
                    <div class="benefit-pill mb-30">
                        <div class="d-flex flex-column-wrapper">
                            <div><img [src]="root.imageUrl() + '/svg-icons/mobile-competitive-advantage.svg'"
                                    class="benefit-icon"></div>
                            <div class="ml-20">
                                <div class="mb-10"><span class="color-black font-18">Competitive Advantage</span>
                                </div>
                                <div><span class="color-black content-text mb-0">One of the most essential advantages of
                                        mobile apps is that they will help to stay ahead of the competitive curve. Apps
                                        are incredibly important in today's technology, and employing them for business
                                        is getting increasingly popular.</span></div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="benefit-pill mb-30">
                        <div class="d-flex flex-column-wrapper">
                            <div><img [src]="root.imageUrl() + '/svg-icons/service-benefit-icon.svg'"
                                    class="benefit-icon"></div>
                            <div class="ml-20">
                                <div class="mb-10"><span class="color-black font-18">Order Management</span></div>
                                <div><span class="color-black content-text mb-0">Experience the real-time tracker that
                                        fulfils every customer order flawlessly
                                        & effectively, managing all your orders from a single platform</span></div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-xl-6 col-lg-5">
                <div class="right-content">
                    <img [src]="root.imageUrl() + '/ez-service-benefit-right-bg.png'">
                </div>
            </div>
        </div>
    </div>
</div>