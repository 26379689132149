<div>
    <div class="ez-footer">
        <div class="ez-footer-container">
            <div class="ez-section-panel">
                <div class="mb-30">
                    <div class="row no-gutters">
                        <div class="col-xl-3 col-12">
                            <div class="ez-footer-logo-block">
                                <a [routerLink]="root.home()" target="_top">
                                    <img [src]="root.imageUrl() + '/ekzero-white-logo.png ' ">
                                </a>
                            </div>
                            <!-- <div class="address-block">
                                <div class="address-info canada-info">
                                    <div class="mb-10"><span>1 Yonge St | Suite 1801</span></div>
                                    <div class="mb-10"><span> Toronto | ON M5E 1W7</span></div>
                                    <div class="mb-10"><span> Canada</span></div>
                                </div>
                            </div> -->
                            <div class="content-text"><span>At ekZero, your goals become ours. We envision your success
                                    by becoming part of your organization's family.
                                </span></div>
                        </div>
                        <div class="col-xl-9 col-12">
                            <div class="row no-gutters">
                                <div class="col-lg-3 col-md-4 col-sm-6">
                                    <div class="content-block">
                                        <ul>
                                            <li class="link-header pb-25"><span>Useful Links</span></li>
                                            <li class="link-content pb-25"><a [routerLink]="root.contact()"
                                                    target="_top">Contact Us</a></li>
                                            <!-- <li class="link-content pb-25"><a>FAQs</a></li> -->
                                            <li class="link-content pb-25"><a [routerLink]="root.privacyPolicy()"
                                                    target="_top">Privacy Policy</a></li>
                                            <!-- <li class="link-content pb-25"><a>Terms & Conditions</a></li> -->
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-4 col-sm-6">
                                    <div class="content-block">
                                        <ul>
                                            <li class="link-header pb-25"><span>Quick Links</span></li>
                                            <li class="link-content pb-25"><a [routerLink]="root.productsList()"
                                                    target="_top">Products</a></li>
                                            <!-- <li class="link-content pb-25"><a>Industries</a></li> -->
                                            <!-- <li class="link-content pb-25"><a [routerLink]="root.ourServices()"
                                                    target="_top">Services</a></li> -->
                                            <li class="link-content pb-25"><a [routerLink]="root.careers()"
                                                    target="_top" fragment="application">Careers</a></li>
                                            <li class="link-content pb-25"><a [routerLink]="root.lifeatekzero()"
                                                    target="_top">Life@ekZero</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-4 col-sm-6">
                                    <!-- <div class="content-block">
                                        <ul>
                                            <li class="link-header pb-25"><span>Resources</span></li>
                                            <li class="link-content pb-25"><a>Client Testimonials</a></li>
                                            <li class="link-content pb-25"><a [routerLink]="root.blogs()"
                                                    target="_top">Blogs</a></li>
                                        </ul>
                                    </div> -->
                                </div>
                                <div class="col-lg-3 col-md-4 col-sm-6">
                                    <div class="content-block">
                                        <ul>
                                            <li class="link-header pb-25"><span>Get in Touch</span></li>
                                            <li class="link-content pb-25">
                                                <a href="mailto:contact@ekzero.com"><span
                                                        class="contact-info email-content"></span>contact@ekzero.com</a>
                                            </li>
                                            <li class="link-content pb-25">
                                                <a href="tel:+1 (833) 359-3761"><span
                                                        class="contact-info phone-content">+1 (833) 359-3761</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row no-gutters align-items-center">
                    <div class="col-xl-3 col-lg-4 col-12">
                        <!-- <div class="address-block mt-0">
                            <div class="address-info india-info">
                                <div class="mb-10"><span>1008, K-10 Grand, Sarabhai Campus,</span></div>
                                <div class="mb-10"><span> Vadi Wadi Road,</span></div>
                                <div class="mb-10"><span> Vadodara, Gujarat 390007</span></div>
                            </div>
                        </div> -->
                        <div class="content-block">
                            <ul>
                                <li class="link-header pb-25"><span>Visit Our Products</span></li>
                            </ul>
                        </div>
                        <div class="icon-block d-flex">
                            <div class="icon-container">
                                <a class="icon-hover" href="https://www.vegitone.com" target="_blank">
                                    <div class="icon">

                                        <!-- <a><img [src] ="root.imageUrl()/svg-icons/vegitone-logo-icon.svg'"></a> -->
                                        <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75"
                                            viewBox="0 0 75 75">
                                            <g id="Group_16659" data-name="Group 16659"
                                                transform="translate(-128 -7491)">
                                                <rect id="Rectangle_2905" data-name="Rectangle 2905" width="auto"
                                                    height="75" rx="11" transform="translate(128 7491)" fill="#fff"
                                                    opacity="0.34" />
                                                <g id="Group_13352" data-name="Group 13352"
                                                    transform="translate(-13 -133)">
                                                    <path id="Polygon_1" data-name="Polygon 1" d="M13.5,0,27,24H0Z"
                                                        transform="translate(181 7673) rotate(180)" fill="#fff" />
                                                    <ellipse id="Ellipse_410" data-name="Ellipse 410" cx="11.5" cy="12"
                                                        rx="11.5" ry="12" transform="translate(181 7649)" fill="#fff" />
                                                </g>
                                            </g>
                                        </svg>
                                        <span>VegitOne</span>

                                        <div class="social-media-icon-container">
                                            <div class="icon-block d-flex">
                                                <div class="icon-container">
                                                    <a href="https://twitter.com/vegitone" target="_blank"><img
                                                            [src]="root.imageUrl() + '/svg-icons/single-twitter-icon.svg' "></a>
                                                </div>
                                                <div class="icon-container">
                                                    <a href="https://www.facebook.com/vegitone" target="_blank"><img
                                                            [src]="root.imageUrl() + '/svg-icons/single-facebook-icon.svg' "></a>
                                                </div>
                                                <div class="icon-container">
                                                    <a href="https://www.instagram.com/vegit_one/" target="_blank">
                                                        <img
                                                            [src]="root.imageUrl() + '/svg-icons/single-instagram-icon.svg' "></a>
                                                </div>
                                                <div class="icon-container">
                                                    <a href="https://www.linkedin.com/showcase/vegitone"
                                                        target="_blank"><img
                                                            [src]="root.imageUrl() + '/svg-icons/single-linkdin-icon.svg' "></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="icon-container">
                                <a class="icon-hover" href="https://gtrack.vegitone.com" target="_blank">
                                    <div class="icon">
                                        <!-- <a><img [src] ="root.imageUrl()/svg-icons/gtrack-logo-icon.svg'"></a> -->
                                        <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75"
                                            viewBox="0 0 75 75">
                                            <g id="Group_16660" data-name="Group 16660"
                                                transform="translate(-223 -7490.999)">
                                                <rect id="Rectangle_2906" data-name="Rectangle 2906" width="auto"
                                                    height="75" rx="11" transform="translate(223 7490.999)" fill="#fff"
                                                    opacity="0.34" />
                                                <path id="Path_2489" data-name="Path 2489"
                                                    d="M187.807,103.027c.776,0,1.551.01,2.327,0,.256-.005.363.056.327.33a14.717,14.717,0,0,1-.4,2.152,30.715,30.715,0,0,1-5.447,9.4,57.362,57.362,0,0,1-4.089,4.7c-.193.2-.3.253-.514.028q-2.124-2.234-4.036-4.65a47.494,47.494,0,0,1-3.411-4.872,20.169,20.169,0,0,1-2.129-4.619,9.654,9.654,0,0,1-.247-4.462,10.124,10.124,0,0,1,6.564-7.789,10.211,10.211,0,0,1,10.36,2.005,10.334,10.334,0,0,1,2.826,4.217,5.984,5.984,0,0,1,.362,1.376c.029.247-.024.354-.314.348-.868-.021-1.738-.011-2.607,0-.355,0-.522-.075-.652-.487a6.807,6.807,0,0,0-7.886-4.515,6.632,6.632,0,0,0-4.931,4.192,6.2,6.2,0,0,0-.448,3.164,6.731,6.731,0,0,0,3.012,4.967,6.555,6.555,0,0,0,4.709,1.082,6.65,6.65,0,0,0,5.117-3.571c.18-.33.055-.351-.212-.35-.227,0-.658.011-.885.018-.184.005-.261-.389-.258-.587.01-.6.011-1.2,0-1.8-.006-.233.1-.255.292-.254.789.006,1.782-.013,2.57-.013"
                                                    transform="translate(77.959 7422.464)" fill="#fff" />
                                            </g>
                                        </svg>
                                        <span>GTrack</span>
                                        <div class="social-media-icon-container">
                                            <div class="icon-block d-flex">
                                                <div class="icon-container">
                                                    <a href="https://twitter.com/gtrack_india" target="_blank"><img
                                                            [src]="root.imageUrl() + '/svg-icons/single-twitter-icon.svg' "></a>
                                                </div>
                                                <div class="icon-container">
                                                    <a href="https://www.facebook.com/vegitone" target="_blank"><img
                                                            [src]="root.imageUrl() + '/svg-icons/single-facebook-icon.svg' "></a>
                                                </div>
                                                <div class="icon-container">
                                                    <a href="https://www.instagram.com/vegit_one/" target="_blank">
                                                        <img
                                                            [src]="root.imageUrl() + '/svg-icons/single-instagram-icon.svg' "></a>
                                                </div>
                                                <div class="icon-container">
                                                    <a href="https://www.linkedin.com/showcase/vegitone"
                                                        target="_blank"><img
                                                            [src]="root.imageUrl() + '/svg-icons/single-linkdin-icon.svg' "></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <!-- <div class="icon-container">
                                <a class="icon-hover" href="https://oneviz.co" target="_blank">
                                    <div class="icon">
                                        <a><img [src] ="root.imageUrl()/svg-icons/oneviz-logo-icon.svg'"></a>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75"
                                            viewBox="0 0 75 75">
                                            <g id="Group_16661" data-name="Group 16661"
                                                transform="translate(-318 -7491)">
                                                <rect id="Rectangle_2907" data-name="Rectangle 2907" width="auto"
                                                    height="75" rx="11" transform="translate(318 7491)" fill="#fff"
                                                    opacity="0.34" />
                                                <g id="Group_1168" data-name="Group 1168"
                                                    transform="translate(342 7514)">
                                                    <path id="Path_638" data-name="Path 638"
                                                        d="M203.989,686.772l-8.726-8.75A13.9,13.9,0,0,1,203,675.059a14.069,14.069,0,0,1,9.739,2.94Z"
                                                        transform="translate(-189.822 -675.018)" fill="#fff"
                                                        fill-rule="evenodd" />
                                                    <path id="Path_639" data-name="Path 639"
                                                        d="M203.483,762.005a13.9,13.9,0,0,0,8.965-3.268l-1.651-1.745a11.335,11.335,0,0,1-14.467.135l-1.753,1.66A13.907,13.907,0,0,0,203.483,762.005Z"
                                                        transform="translate(-189.334 -733.373)" fill="#fff"
                                                        fill-rule="evenodd" />
                                                    <path id="Path_640" data-name="Path 640"
                                                        d="M181.059,709.651a12.2,12.2,0,0,1,.018-13.993l-1.825-1.569-.041,0a14.412,14.412,0,0,0,.126,17.328Z"
                                                        transform="translate(-176.378 -688.592)" fill="#fff"
                                                        fill-rule="evenodd" />
                                                    <path id="Path_641" data-name="Path 641"
                                                        d="M256.833,709.651a12.2,12.2,0,0,0-.018-13.993l1.825-1.569.041,0a14.413,14.413,0,0,1-.126,17.328Z"
                                                        transform="translate(-233.639 -688.592)" fill="#fff"
                                                        fill-rule="evenodd" />
                                                </g>
                                            </g>
                                        </svg>
                                        <span>OneViz</span>
                                        <div class="social-media-icon-container" id="oneviz">
                                            <div class="icon-block d-flex">
                                                <div class="icon-container">
                                                    <a href="https://twitter.com/ekZeroCanada" target="_blank"><img 
                                                            src="../../../../assets/images/svg-icons/single-twitter-icon.svg"></a>
                                                </div>
                                                <div class="icon-container">
                                                    <a href="https://www.facebook.com/theoneviz" target="_blank"><img
                                                            [src]="root.imageUrl() + '/svg-icons/single-facebook-icon.svg' "></a>
                                                </div>
                                                <div class="icon-container">
                                                    <a href="https://www.instagram.com/one.viz/" target="_blank">
                                                        <img
                                                            [src]="root.imageUrl() + '/svg-icons/single-instagram-icon.svg' "></a>
                                                </div>
                                                <div class="icon-container">
                                                    <a href="https://www.linkedin.com/showcase/oneviz/"
                                                        target="_blank"><img
                                                            [src]="root.imageUrl() + '/svg-icons/single-linkdin-icon.svg' "></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-12">
                        <div class="content-block">
                            <ul>
                                <li class="link-header pb-25"><span>We work across the world</span></li>
                                <div class="work-area">
                                    <img [src]="root.imageUrl() + '/svg-icons/map-image.svg'" alt="">
                                    <!-- <img src="assets/image/work-area-map.svg" alt=""> -->

                                    <!-- <div class="location loc-first blink"></div>
                                    <div class="location loc-second blink"></div>
                                    <div class="location loc-third blink"></div> -->
                                </div>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-3 col-12">
                        <div class="content-block">
                            <ul>
                                <li class="link-header pb-25"><span>Follow us on</span></li>
                            </ul>
                        </div>
                        <div class="icon-block d-flex">
                            <div class="icon-container">
                                <a href="https://twitter.com/ekZeroMedia1" target="_blank">
                                    <img width="55px"
                                       [src]="root.imageUrl() + '/svg-icons/twitter-icon.svg'">
                                    </a>
                            </div>
                            <div class="icon-container">
                                <a href="https://www.facebook.com/ekZeroCorp" target="_blank"><img width="55px"
                                       [src]="root.imageUrl() + '/svg-icons/facebook-icon.svg'"></a>
                            </div>
                            <div class="icon-container">
                                <a href="https://www.instagram.com/ekzerocorporation/" target="_blank"><img width="55px"
                                       [src]="root.imageUrl() + '/svg-icons/instagram-icon.svg'"></a>
                            </div>
                            <div class="icon-container">
                                <a href="https://www.linkedin.com/company/ekzero/" target="_blank"><img width="55px"
                                        [src]="root.imageUrl() + '/svg-icons/linkedin-icon.svg'"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="ez-copyright-panel">
        <span class="copyright-panel-text">Copyright &copy; {{today}} ekZero. All Rights Reserved</span>
    </div>
    <div class="top-to-bottom-scroll" (click)="gotoTop()" *ngIf="isShow">
        <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg' ">
    </div>
</div>