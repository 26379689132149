import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RootService } from 'src/app/shared/services/root.service';
import { SupportService } from '../../../../shared/services/support.service';
import { InquiryService } from '../../../../shared/services/inquiry.service';
@Component({
  selector: 'contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss']
})
export class ContactListComponent implements OnInit {

  block1: boolean = true;
  block2: boolean = false;
  block3: boolean = false;
  isCareer: boolean = false;
  isInquire: boolean = false;

  support: FormGroup;
  inquiryForm: FormGroup;


  showErrorMsg: boolean = false;
  showSuccessMsg: boolean = false;

  jobRoleEnum = {
    'C-Level': 'C_LEVEL',
    'President': 'PRESIDENT',
    'Director': 'DIRECTOR',
    'Manager': 'MANAGER',
    'Technician': 'TECHNICIAN',
    'Developer': 'DEVELOPER',
    'Consultant': 'CONSULTANT',
    'Other': 'OTHER',
  }

  jobRoleArray = [
    'C-Level',
    'President',
    'Director',
    'Manager',
    'Technician',
    'Developer',
    'Consultant',
    'Other'
  ]

  solutionsEnum = {
    'Enterprise Software Services': 'ENTERPRISE_SOFTWARE_SERVICES',
    'Mobile Application Services': 'MOBILE_APPLICATION_SERVICES',
    'Cloud Services': 'CLOUD_SERVICES',
    'DevOps Services': 'DEVOPS_SERVICES',
    'Software Consulting': 'SOFTWARE_CONSULTING',
    'Extended Team': 'EXTENDED_TEAM',
    'R&D Services': 'R_AND_D_SERVICES',
    'Product Development Services': 'PRODUCT_DEVELOPMENT_SERVICES',
    'Incubation': 'INCUBATION',
    'Other': 'OTHER'
  }


  solutionsAray = [
    'Enterprise Software Services',
    'Mobile Application Services',
    'Cloud Services',
    'DevOps Services',
    'Software Consulting',
    'Extended Team',
    'R&D Services',
    'Product Development Services',
    'Incubation',
    'Other'
  ]


  industryEnum = {
    'IT & Telecom': 'IT_AND_TELECOM',
    'Banking and Financial': 'BANKING_AND_FINANCIAL',
    'Education': 'EDUCATION',
    'Energy and Utilities': 'ENERGY_AND_UTILITIES',
    'Government': 'GOVERNMENT',
    'Healthcare': 'HEALTHCARE',
    'Service and Entertainment': 'SERVICE_AND_ENTERTAINMENT'
  }


  industryArray = [
    'IT & Telecom',
    'Banking and Financial',
    'Education',
    'Energy and Utilities',
    'Government',
    'Healthcare',
    'Service and Entertainment'
  ]


  enquiryEnum = {
    'Enterprise Software Services': 'ENTERPRISE_SOFTWARE_SERVICES',
    'Mobile Application Services': 'MOBILE_APPLICATION_SERVICES',
    'Cloud Services': 'CLOUD_SERVICES',
    'DevOps Services': 'DEVOPS_SERVICES',
    'Software Consulting': 'SOFTWARE_CONSULTING',
    'Extended Team': 'EXTENDED_TEAM',
    'R&D Services': 'R_AND_D_SERVICES',
    'Product Development Services': 'PRODUCT_DEVELOPMENT_SERVICES',
    'Incubation': 'INCUBATION',
    'Other': 'OTHER'
  }


  enquiryArray = [
    'Enterprise Software Services',
    'Mobile Application Services',
    'Cloud Services',
    'DevOps Services',
    'Software Consulting',
    'Extended Team',
    'R&D Services',
    'Product Development Services',
    'Incubation',
    'Other',
  ]

  constructor(public root: RootService, public supportService: SupportService, public inquiryService: InquiryService, private modalService: NgbModal) {
    this.support = new FormGroup({
      firstName: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(25),
        Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z ]+$'),
      ])),
      lastName: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(25),
        Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z ]+$'),
      ])),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      phone: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]{3}[0-9]{4}[0-9]{3}$')
      ])),
      organizationName: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      jobRole: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      jobTitle: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      solutionArea: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      industry: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      country: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      message: new FormControl('', Validators.compose([
        Validators.required,
      ])),

    })

    this.inquiryForm = new FormGroup({
      firstName: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(25),
        Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z ]+$'),
      ])),
      lastName: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(25),
        Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z ]+$'),
      ])),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      phone: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]{3}[0-9]{4}[0-9]{3}$')
      ])),
      organizationName: new FormControl('', Validators.compose([
        Validators.required,
      ])),

      country: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      enquiringFor: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      message: new FormControl('', Validators.compose([
        Validators.required,
      ])),

    })
  }

  support_validation_messages = {
    'firstName': [
      { type: 'required', message: 'First name is required' },
      { type: 'maxlength', message: 'First name cannot be more than 25 characters long' },
      { type: 'pattern', message: 'First name must only contain alphabets' }
    ],
    'lastName': [
      { type: 'required', message: 'Last name is required' },
      { type: 'maxlength', message: 'Last name cannot be more than 25 characters long' },
      { type: 'pattern', message: 'Last name must only contain alphabets' }
    ],
    'email': [
      { type: 'required', message: 'Email is required' },
      { type: 'pattern', message: 'Enter a valid email' }
    ],
    'phone': [
      { type: 'required', message: 'Contact Number is required' },
      { type: 'pattern', message: 'Enter a valid Contact Number' },
    ],
    'organizationName': [
      { type: 'required', message: 'Organization Name is required' },
    ],
    'jobRole': [
      { type: 'required', message: 'Job Role is required' },
    ],
    'jobTitle': [
      { type: 'required', message: 'Job Title is required' },
    ],
    'solutionArea': [
      { type: 'required', message: 'Solution Area is required' },
    ],
    'industry': [
      { type: 'required', message: 'Industry is required' },
    ],
    'enquiringFor': [
      { type: 'required', message: 'Inquiry is required' },
    ],
    'country': [
      { type: 'required', message: 'Country is required' },
    ],
    'message': [
      { type: 'required', message: 'Message is required' }
    ]
  }


  ngOnInit(): void {
  }


  openModal(Content) {
    this.modalService.open(Content, { size: 'lg', centered: true, windowClass: 'popup' });
  }

  showModal(Content1) {
    this.modalService.open(Content1, { size: 'lg', centered: true, windowClass: 'popup' });
  }

  onBlock1() {
    this.block1 = true;
    this.block2 = false;
    this.block3 = false;
  }
  onBlock2() {
    this.block1 = false;
    this.block2 = true;
    this.block3 = false;
  }
  onBlock3() {
    this.block1 = false;
    this.block2 = false;
    this.block3 = true;
  }

  contactUsSubmit() {
    const jobValue = this.support.get('jobRole').value;
    const solutionValue = this.support.get('solutionArea').value;
    const industryValue = this.support.get('industry').value;

    const object =
    {
      "id": null,
      "firstName": this.support.get('firstName').value,
      "lastName": this.support.get('lastName').value,
      "email": this.support.get('email').value,
      "phone": this.support.get('phone').value,
      "organizationName": this.support.get('organizationName').value,
      "jobRole": this.jobRoleEnum[jobValue],
      "jobTitle": this.support.get('jobTitle').value,
      "solutionArea": this.solutionsEnum[solutionValue],
      "industry": this.industryEnum[industryValue],
      "country": this.support.get('country').value,
      "enquiringFor": null,
      "message": this.support.get('message').value,
      "formType": "CONTACT_US"
    }
    console.log(object)
    this.supportService.support(object)
      .subscribe((res) => {
        if (res['code'] === 200) {

          this.showSuccessMsg = true;
          this.support.reset();
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 5000);

        }
        console.log(res)

      }, (error) => {
        this.showErrorMsg = true;
        setTimeout(() => {
          this.showErrorMsg = false;
        }, 5000);
      }
      );
  }

  enquirySubmit() {

    const enquiryValue = this.inquiryForm.get('enquiringFor').value;
    console.log(enquiryValue);
    const object =
    {
      "id": null,
      "firstName": this.inquiryForm.get('firstName').value,
      "lastName": this.inquiryForm.get('lastName').value,
      "email": this.inquiryForm.get('email').value,
      "phone": this.inquiryForm.get('phone').value,
      "organizationName": this.inquiryForm.get('organizationName').value,
      "jobRole": null,
      "jobTitle": null,
      "solutionArea": null,
      "industry": null,
      "country": this.inquiryForm.get('country').value,
      "enquiringFor": this.enquiryEnum[enquiryValue],
      "message": this.inquiryForm.get('message').value,
      "formType": "SALES_INQUIRY_FORM",
      "isExistingCustomer": false
    }
    console.log(object)
    this.inquiryService.inquiry(object)
      .subscribe((res) => {
        if (res['code'] === 200) {
          this.showSuccessMsg = true;
          this.inquiryForm.reset();
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 5000);

        }
      

      }, (error) => {
        this.showErrorMsg = true;
        setTimeout(() => {
          this.showErrorMsg = false;
        }, 5000);
      }
      );
  }

}
