<div class="ez-research-development-banner">
    <div class="text-container">
        <div class="subheading-block position-relative mb-15">
            <span class="subheading-block-text">
                Research           
            </span>
        </div>
        <div class="subheading-block position-relative mb-15">
            <span class="subheading-block-text">
                & Development
            </span>
        </div>
        <div class="subheading2-block">
            <span class="subheading2-block-text">
                Exploring new ways to learn & innovate
            </span>
        </div>
    </div>
</div>