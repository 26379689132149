import { Component, OnInit } from '@angular/core';
import { RootService } from '../../services/root.service';

@Component({
  selector: 'case-study',
  templateUrl: './case-study.component.html',
  styleUrls: ['./case-study.component.scss']
})
export class CaseStudyComponent implements OnInit {

  constructor(public root: RootService) { }

  ngOnInit(): void {
  }

}
