<div id="privacy-list">
    <div class="privacy-wrapper">
        <div class="row no-gutters">
            <div class="col-xl-8 col-lg-7 col-12 col-md-order">
                <div class="list-wrapper">
                    <div class="left-wrapper">
                        <div data-aos="fade-up" data-aos-duration="1500">
                            <!-- <div class="contactform-header mb-20 center">
                                <div class="contactus-panel">
                                    <div class="d-flex">
                                        <img [src]="root.imageUrl() + '/svg-icons/computer-active-icon.svg' "
                                            class="default-icon">
                                        <img [src]="root.imageUrl() + '/svg-icons/computer-default-icon.svg' "
                                            class="active-icon">
                                        <span class="ml-20">Web</span>
                                    </div>
                                </div>
                            </div> -->
                            <div class="info-content"><span class="color-orange">Introduction</span></div>
                            <div class="privacy-info">
                                <p class="color-black title-info mb-0">This privacy notice discloses the privacy
                                    practices
                                    for ekVegit Inc and our website; <a href="https://www.ekzero.com"
                                        target="_blank">https://www.ekzero.com</a>. This privacy
                                    notice applies solely to information collected by this website, except where stated
                                    otherwise. It will notify you of the following:</p>
                            </div>
                        </div>

                        <div class="privacy-info">
                            <div class="key-points-block content-list mt-0 mb-0" data-aos="fade-right"
                                data-aos-duration="1500">
                                <ul class="pl-0">
                                    <li> What information we collect ? </li>
                                    <li>With whom it is shared ?</li>
                                    <li>How it can be corrected ?</li>
                                    <li>How it is secured ?</li>
                                    <li>How policy changes will be communicated ?</li>
                                    <li>How to address concerns over misuse of personal data ?</li>
                                </ul>
                            </div>
                            <div class="info-content"><span class="color-orange">Overview</span></div>
                        </div>


                        <div class="privacy-info" data-aos="fade-up" data-aos-duration="1500" id="collection-info">
                            <div><span class="color-black title-content">Information Collection, Use, and Sharing</span>
                            </div>
                            <div>
                                <p class="color-black title-info">We are the sole owners of the information collected on
                                    this site. We only have access to/collect information that you voluntarily give us
                                    via contact form, email or other direct contact from you. We will not sell or rent
                                    this information to anyone.
                                    We will use your information to respond to you, regarding the reason you contacted
                                    us. We will not share your information with any third party outside of our
                                    organization, other than as necessary to fulfill your request or to address your
                                    concern. Unless you ask us not to, we may contact you via email in the future to
                                    tell you about specials, new products or services, or changes to this privacy
                                    policy.</p>
                            </div>
                        </div>
                        <div class="privacy-info" data-aos="fade-up" data-aos-duration="1500" id="access-info">
                            <div><span class="color-black title-content">Your Access to and Control Over
                                    Information</span>
                            </div>
                            <div>
                                <p class="color-black title-info">You may opt-out of any future contacts from us at any
                                    time. You can do the following at any time by contacting us via our website:</p>
                            </div>
                            <div class="key-points-block" data-aos="fade-right" data-aos-duration="1500">
                                <ul class="pl-0">
                                    <li>See what data we have about you, if any.</li>
                                    <li>Change/correct any data we have about you.</li>
                                    <li>Have us delete any data we have about you.</li>
                                    <li>Express any concern you have about our use of your data.</li>
                                </ul>
                            </div>
                        </div>
                        <div class="privacy-info" data-aos="fade-up" data-aos-duration="1500" id="registration-info">
                            <div><span class="color-black title-content">Registration</span></div>
                            <div>
                                <p class="color-black title-info">In order to use this website, a user must first
                                    complete the registration form. During registration a user is required to give
                                    certain information (such as name and email address). This information is used to
                                    contact you about the products/services on our site in which you have expressed
                                    interest. At your option, you may also provide demographic information (such as
                                    gender or age) about yourself, but it is not required.</p>
                            </div>
                        </div>
                        <div class="privacy-info" data-aos="fade-up" data-aos-duration="1500" id="order-info">
                            <div><span class="color-black title-content">Orders</span>
                            </div>
                            <div>
                                <p class="color-black title-info">We request information from you on our order form. To
                                    buy from us, you must provide contact information (like name and shipping address)
                                    and financial information (like credit card number, expiration date). This
                                    information is used for billing purposes and to fill your orders. If we have trouble
                                    processing an order, we’ll use this information to contact you.</p>
                            </div>
                        </div>
                        <div class="privacy-info" data-aos="fade-up" data-aos-duration="1500" id="cookies-info">
                            <div><span class="color-black title-content">Cookies</span>
                            </div>
                            <div>
                                <p class="color-black title-info">We use “cookies” on this site. A cookie is a piece of
                                    data stored on a site visitor’s hard drive to help us improve your access to our
                                    site and identify repeat visitors to our site. For instance, when we use a cookie to
                                    identify you, you would not have to log in a password more than once, thereby saving
                                    time while on our site. Cookies can also enable us to track and target the interests
                                    of our users to enhance their experience on our site. Usage of a cookie is in no way
                                    linked to any personally identifiable information on our site.</p>
                            </div>
                        </div>
                        <div class="privacy-info" data-aos="fade-up" data-aos-duration="1500" id="link-info">
                            <div><span class="color-black title-content">Links</span>
                            </div>
                            <div>
                                <p class="color-black title-info">This website may contain links to other sites. Please
                                    be aware that we are not responsible for the content or privacy practices of such
                                    other sites. We encourage our users to be aware when they leave our site and to read
                                    the privacy statements of any other site that collects personally identifiable
                                    information.</p>
                            </div>
                        </div>
                        <div class="privacy-info" data-aos="fade-up" data-aos-duration="1500" id="survey-info">
                            <div><span class="color-black title-content">Surveys & Contests</span></div>
                            <div>
                                <p class="color-black title-info">From time-to-time our site requests information via
                                    surveys or contests. Participation in these surveys or contests is completely
                                    voluntary and you may choose whether or not to participate and therefore disclose
                                    this information. Information requested may include contact information (such as
                                    name and address), and demographic information (such as postal/zip code, age level).
                                    Contact information will be used to notify the winners and award prizes. Survey
                                    information will be used for purposes of monitoring or improving the use and
                                    satisfaction of this site.</p>
                            </div>
                        </div>
                        <div class="privacy-info" data-aos="fade-up" data-aos-duration="1500" id="notification-info">
                            <div><span class="color-black title-content">Notification of Changes</span></div>
                            <div>
                                <p class="color-black title-info">The changes made to the privacy policy will be
                                    informed via subscribed emails in the contact records only.
                                </p>
                            </div>
                        </div>
                        <div class="privacy-info" data-aos="fade-up" data-aos-duration="1500" id="other-info">
                            <div><span class="color-black title-content">Other Provisions as Required by Law</span>
                            </div>
                            <div>
                                <p class="color-black title-info">Numerous other provisions and/or practices may be
                                    required as a result of laws, international treaties, or industry practices. It is
                                    up to you to determine what additional practices must be followed and/or what
                                    additional disclosures are required. Please take special notice of the California
                                    Online Privacy Protection Act (CalOPPA), which is frequently amended and now
                                    includes a disclosure requirement for “Do Not Track” signals.
                                </p>
                            </div>
                        </div>
                        <div class="privacy-info" data-aos="fade-up" data-aos-duration="1500">
                            <div>
                                <p class="color-black mb-5">If you feel that we are not abiding by this privacy
                                    policy, you should contact us immediately via email at
                                </p>
                            </div>
                            <div>
                                <a class="color-orange font-500" href="mailto:contact@ekzero.com">contact@ekzero.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-5 col-12">
                <div class="right-wrapper">
                    <div class="right-content-wrapper">
                        <div class="content-body">
                            <div class="collapsable-wrapper position-relative"
                                [ngClass]="{'collapsable-active': isShow}">
                                <div
                                    class="collapsable-header responsive-view align-items-center justify-content-between">
                                    <div><span class="color-black">Select Categories</span></div>
                                    <div><a class="collapsable-icon" (click)="isShow = !isShow"></a></div>
                                </div>
                                <div class="privacy-list collapsable-body -ms-scrollbar scrollable-body" [ngbCollapse]="!isShow">
                                    <div class="privacy-title active">
                                        <a class="color-black" [routerLink]="'.'" fragment="collection-info">Information Collection, Use, and Sharing</a>
                                    </div>
                                    <div class="privacy-title">
                                        <a class="color-black" [routerLink]="'.'" fragment="access-info">Your
                                            Access to and Control Over Information</a>
                                    </div>
                                    <div class="privacy-title">
                                        <a class="color-black" [routerLink]="'.'" fragment="registration-info">Registration</a>
                                    </div>
                                    <div class="privacy-title">
                                        <a class="color-black" [routerLink]="'.'" fragment="order-info">Orders</a>
                                    </div>
                                    <div class="privacy-title">
                                        <a class="color-black" [routerLink]="'.'" fragment="cookies-info">Cookies</a>
                                    </div>
                                    <div class="privacy-title">
                                        <a class="color-black" [routerLink]="'.'" fragment="link-info">Links</a>
                                    </div>
                                    <div class="privacy-title">
                                        <a class="color-black" [routerLink]="'.'" fragment="survey-info">Surveys & Contests</a>
                                    </div>
                                    <div class="privacy-title">
                                        <a class="color-black" [routerLink]="'.'" fragment="notification-info">Notification of Changes</a>
                                    </div>
                                    <div class="privacy-title">
                                        <a class="color-black" [routerLink]="'.'" fragment="other-info">Other
                                            Provisions as Required by Law</a>
                                    </div>
                                    <div class="privacy-title">
                                        <a>Get a Quote</a>
                                        <div class="contact-info mt-15">
                                            <div class="mb-10"><a href="tel: +1 (833) 359-3761"><span
                                                        class="color-orange">+1 (833) 359-3761</span></a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>