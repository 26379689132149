import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { BlogsComponent } from './pages/blogs/blogs.component';
// import { BlogDetailPageComponent } from './pages/blog-detail-page/blog-detail-page.component';
import { EnsuringSafetyInTheTruckingIndustryComponent } from './components/ensuring-safety-in-the-trucking-industry/ensuring-safety-in-the-trucking-industry.component';
import { LossTruckDriversInCovidPandemicComponent} from './components/loss-truck-drivers-in-covid-pandemic/loss-truck-drivers-in-covid-pandemic.component';
import { EvolutionOfGPSTechnologyInFleetOperationsComponent } from './components/evolution-of-gps-technology-in-fleet-operations/evolution-of-gps-technology-in-fleet-operations.component';
import { SkyrocketYourAssociationRevenueByEmployingEfficientWaysComponent } from './components/skyrocket-your-association-revenue-by-employing-efficient-ways/skyrocket-your-association-revenue-by-employing-efficient-ways.component';
import { CreateMemberCentericComponent} from './components/create-member-centeric/create-member-centeric.component';
import { SelfDrivingTruckTransformComponent } from './components/self-driving-truck-transform/self-driving-truck-transform.component';
import { ObstaclesOfCloudMigrationComponent } from './components/obstacles-of-cloud-migration/obstacles-of-cloud-migration.component';
import { CustomSoftwareConsultingComponent } from './components/custom-software-consulting/custom-software-consulting.component';
import { EvolutionTheNextPhaseOfInternetComponent } from './components/evolution-the-next-phase-of-internet/evolution-the-next-phase-of-internet.component';
import { EssentialMobileAppDesignComponent } from './components/essential-mobile-app-design/essential-mobile-app-design.component';
import { WhatIsCloudComputingComponent } from './components/what-is-cloud-computing/what-is-cloud-computing.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full'
  },
  // {
  //   path: 'list',
  //   component: BlogsComponent,
  // },
  // {
  //   path: 'detail',
  //   component: BlogDetailPageComponent,
  // },
  {
    path: 'ensuring-safety-in-the-trucking-industry',
    component: EnsuringSafetyInTheTruckingIndustryComponent,
  },
  {
    path: 'loss-truck-drivers-in-covid-pandemic',
    component: LossTruckDriversInCovidPandemicComponent,
  },
  {
    path: 'evolution-of-gps-technology-in-fleet-operations',
    component: EvolutionOfGPSTechnologyInFleetOperationsComponent,
  },
  {
    path: 'will-the-self-driving-trucks-transform-the-trucking-and-logistic-industry',
    component: SelfDrivingTruckTransformComponent,
  },
  {
    path: 'skyrocket-your-association-revenue-by-employing-efficient-ways',
    component: SkyrocketYourAssociationRevenueByEmployingEfficientWaysComponent,
  },
  {
    path: 'create-member-centric',
    component: CreateMemberCentericComponent,
  },
  {
    path: 'cloud-migration',
    component: ObstaclesOfCloudMigrationComponent,
  },
  {
    path: 'custom-software-consulting',
    component:  CustomSoftwareConsultingComponent,
  },
  {
    path: 'evolution-the-next-phase-of-internet',
    component:  EvolutionTheNextPhaseOfInternetComponent
  },
  {
    path: 'essential-mobile-app-design',
    component:  EssentialMobileAppDesignComponent
  },
  {
    path: 'what-is-cloud-computing',
    component: WhatIsCloudComputingComponent
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BlogsRoutingModule { }
