import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PageNotFoundService {

  constructor() { }
  home() {
    const page = '/home';
    return `${page}`
  }
  ecs(){
    const page = '/services/consulting-services';
    return `${page}`
  }
  aboutus() {
    const page = '/about-us';
    return `${page}`
  }

  privacyPolicy() {
    const page = '/privacy-policy';
    return `${page}`
  }

  ourServices() {
    const page = '/ourservices';
    return `${page}`
  }

  careers() {
    const page = '/careers';
    return `${page}`
  }

  blogs() {
    const page = '/blogs/list';
    return `${page}`
  }

  productsList() {
    const page = '/our-products/list';
    return `${page}`
  }

  productDetail() {
    const page = '/products/detail';
    return `${page}`
  }

  contact() {
    const page = '/contact';
    return `${page}`
  }
  lifeatekzero() {
    const page = '/life/ekzero';
    return `${page}`
  }

  faq() {
    const page = '/faq';
    return `${page}`
  }

}
