import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlogsRoutingModule } from './blogs-routing.module';
import { BlogBannerComponent } from './components/blog-banner/blog-banner.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { BlogListComponent } from './components/blog-list/blog-list.component';
import { BlogDetailComponent } from './components/blog-detail/blog-detail.component';
import { NgbDropdownModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { BlogDetailPageComponent } from './pages/blog-detail-page/blog-detail-page.component';
import { SharedModule } from '../../shared/shared.module';
import { EnsuringSafetyInTheTruckingIndustryComponent } from './components/ensuring-safety-in-the-trucking-industry/ensuring-safety-in-the-trucking-industry.component';
import { EvolutionOfGPSTechnologyInFleetOperationsComponent } from './components/evolution-of-gps-technology-in-fleet-operations/evolution-of-gps-technology-in-fleet-operations.component';
import { SkyrocketYourAssociationRevenueByEmployingEfficientWaysComponent } from './components/skyrocket-your-association-revenue-by-employing-efficient-ways/skyrocket-your-association-revenue-by-employing-efficient-ways.component';
import { LossTruckDriversInCovidPandemicComponent } from './components/loss-truck-drivers-in-covid-pandemic/loss-truck-drivers-in-covid-pandemic.component';
import { CreateMemberCentericComponent } from './components/create-member-centeric/create-member-centeric.component';
import { SelfDrivingTruckTransformComponent } from './components/self-driving-truck-transform/self-driving-truck-transform.component';
import { ObstaclesOfCloudMigrationComponent } from './components/obstacles-of-cloud-migration/obstacles-of-cloud-migration.component';
import { CustomSoftwareConsultingComponent } from './components/custom-software-consulting/custom-software-consulting.component';
import { EvolutionTheNextPhaseOfInternetComponent } from './components/evolution-the-next-phase-of-internet/evolution-the-next-phase-of-internet.component';
import { EssentialMobileAppDesignComponent } from './components/essential-mobile-app-design/essential-mobile-app-design.component';
import { WhatIsCloudComputingComponent } from './components/what-is-cloud-computing/what-is-cloud-computing.component';
@NgModule({
  declarations: [BlogBannerComponent, BlogsComponent, BlogListComponent, BlogDetailComponent, BlogDetailPageComponent, EnsuringSafetyInTheTruckingIndustryComponent, EvolutionOfGPSTechnologyInFleetOperationsComponent, SkyrocketYourAssociationRevenueByEmployingEfficientWaysComponent,  LossTruckDriversInCovidPandemicComponent, CreateMemberCentericComponent, SelfDrivingTruckTransformComponent, ObstaclesOfCloudMigrationComponent, CustomSoftwareConsultingComponent, EvolutionTheNextPhaseOfInternetComponent, EssentialMobileAppDesignComponent, WhatIsCloudComputingComponent],
  imports: [
    CommonModule,
    BlogsRoutingModule,
    NgbDropdownModule,
    NgbCollapseModule,
    SharedModule
  ]
})
export class BlogsModule { }
