import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'enterprise-software-service',
  templateUrl: './enterprise-software-service.component.html',
  styleUrls: ['./enterprise-software-service.component.scss']
})
export class EnterpriseSoftwareServiceComponent implements OnInit {

  newTitle: string = 'Enterprise Services - Process Digitization | ekZero';
  longDescription: string = ' Leverage expert enterprise services in developing enterprise software, UI/UX design, API integrations, Process testing, Maintenance, and Data Management.';
  longKeyword: string = 'ekZero, Enterprise Resource Planning, ERP, Enterprise Web Solutions, Process Automation, Solution Consultation, Software Testing, Product Enhancement, ekZero';

  constructor(private title: Title,
    private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle(this.newTitle);
    this.meta.addTag({ name: "description", content: this.longDescription });
    this.meta.addTag({ name: "keywords", content: this.longKeyword });
  }

}
