import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'app-onveviz-feature',
  templateUrl: './onveviz-feature.component.html',
  styleUrls: ['./onveviz-feature.component.scss']
})
export class OnvevizFeatureComponent implements OnInit {

  constructor(public root: RootService) { }

  ngOnInit(): void {
  }

}
