import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { RootComponent } from './component/root/root.component';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 200],
};

const routes: Routes = [{
  path: '',
  component: RootComponent,
  children: [
    {
      path: '',
      loadChildren: './modules/home/home.module#HomeModule',
    },
    {
      path: 'home',
      loadChildren: './modules/home/home.module#HomeModule',
    },
    {
      path: 'ecs',
      loadChildren: './modules/ecs/ecs.module#EcsModule',
    },
    
    {
      path: 'about-us',
      loadChildren: './modules/about-us/about-us.module#AboutUsModule',
    },

    {
      path: 'ourservices',
      loadChildren: './modules/our-services/our-services.module#OurServicesModule',

    },
    {
      path: 'our-products',
      loadChildren: './modules/products/products.module#ProductsModule',

    },
    {
      path: 'contact',
      loadChildren: './modules/contact-us/contact-us.module#ContactUsModule',
    },
    // {
    //   path: 'blogs',
    //   loadChildren: './modules/blogs/blogs.module#BlogsModule',
    // },

    {
      path: 'privacy-policy',
      loadChildren: './modules/privacy-policy/privacy-policy.module#PrivacyPolicyModule',
    },
    {
      path: 'products',
      loadChildren: './modules/our-products/our-products.module#OurProductsModule',
    },
    {
      path: 'careers',
      loadChildren: './modules/careers/careers.module#CareersModule',
    },
    // {
    //   path: 'faq',
    //   loadChildren: './modules/faq/faq.module#FaqModule',
    // },
    {
      path: 'life',
      loadChildren: './modules/life/life.module#LifeModule',
    },
    {
      path: 'incubation',
      loadChildren: './modules/incubation/incubation.module#IncubationModule',
    },
    {
      path: 'research-development',
      loadChildren: './modules/research-development/research-development.module#ResearchDevelopmentModule',
    },
    {
      path: '**',
      redirectTo: '/page-not-found',
      pathMatch: 'full'
    },
  ]

}];

@NgModule({
  imports: [RouterModule.forRoot(routes,routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
