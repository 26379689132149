<div id="product-tech">
    <div class="tech-wrapper">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="left-panel">
                    <img [src]="root.imageUrl() + '/oneviz-admin.png'">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="right-panel">
                    <div class="info-content mb-30"><span class="color-orange">Simplifying Admin Processes</span></div>
                    <div>
                        <p class="color-black content-text">With a comprehensive set of features, OneViz can efficiently manage a wide range of responsibilities for association administrators. OneViz can be leveraged to administer everything from scheduling meetings, planning events, and maintaining documents to maintaining member profiles, analyzing dashboards, and much more.
                        </p>
                    </div>
                    <div>
                        <p class="color-black content-text">OneViz enables administrators to perform  organizational duties seamlessly, however, the most notable advantage is that all administrative tasks can be simply managed from one platform. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 tech-section col-md-order2">
                <div class="right-panel">
                    <div class="info-content mb-30"><span class="color-orange">Encouraging Member Engagement
                        </span></div>
                    <div>
                        <p class="color-black content-text">Administrators of professional associations can use OneViz to communicate with members and show them all of the different ways they can interact on the platform. 
                            Oneviz offers a number of facilities including chats, emails, news & gallery, service request builder, quiz, polls, surveys, and many more such tools that highly influences members to stay actively engaged in recreational and organizational activities.                             
                        </p>
                    </div>
                    <div>
                        <p class="color-black content-text">OneViz is a user-friendly, social & collaborative platform that encourages members to stay active by offering recreational tools, easy access to required information, and smooth communication. </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 tech-section col-md-order1">
                <div class="left-panel">
                    <img [src]="root.imageUrl() + '/oneviz-member.png'">
                </div>
            </div>
        </div>
    </div>
</div>