import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'cloud-service',
  templateUrl: './cloud-service.component.html',
  styleUrls: ['./cloud-service.component.scss']
})
export class CloudServiceComponent implements OnInit {

  newTitle: string = 'Cloud Services & Operations | ekZero Corporation';
  longDescription: string = 'What can’t go to the cloud? Cost and Performance optimization with Cloud services from ekZero.';
  longKeyword: string = 'ekZero, Cloud Services, ekZero Cloud Services, Cloud Consulting, Cloud Optimization, Cloud Maintenance, Cloud Migration, Cloud Architecture';

  constructor(private title: Title,
    private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle(this.newTitle);
    this.meta.addTag({ name: "description", content: this.longDescription });
    this.meta.addTag({ name: "keywords", content: this.longKeyword });
  }

}
