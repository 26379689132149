<div class="ez-our-story" id="ecs-intro">
    <div class="row no-gutters">
        <div class="col-md-5">
            <div class="intro-left-panel" data-aos="fade-right" data-aos-duration="1700">
                <div class="intro-img">
                    <div class="intro-content" data-aos="flip-left" data-aos-duration="1700">
                        <div class="info-title"><span class="color-white">WHAT IS</span></div>
                        <div class="mt-20 left-content">
                            <div><span class="color-white text-line1">ECS?</span></div>
                            <div class="mt-20"><span class="color-white text-line2">The who, what, and
                                    why our company exists.</span></div>
                        </div>
                    </div>
                    <div class="our-story-right-content"></div>
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="our-story-right-panel">
                <div class="uppar-content">
                    <div><span>Make better technological decisions, maximise ROI, and constantly innovate with
                            ECS</span>
                    </div>
                </div>
                <div class="lower-content">
                    <div><span class="content-text">ekZero's mission is to deliver strategic IT consulting services that
                            help businesses succeed and experience competitive advantage.
                        </span></div>
                    <div><span class="content-text">ekZero assists in the consultation, development, and implementation
                            of a clear and comprehensive IT plan with priorities that are directly tied to business
                            objectives.
                        </span></div>
                    <div><span class="content-text">Whether you need guidance with an upcoming project, operational
                            support, direction for IT investment, or guidance for enterprise systems - our team can
                            consult on IT planning and provide the services required to drive technological initiatives
                            in the right direction.
                        </span></div>
                </div>
                <div class="btn-grp mt-30">
                    <a class="btn-primary btn-lg" target="_top" [routerLink]="root.aboutus()">Read More</a>
                </div>
            </div>
        </div>
    </div>
</div>