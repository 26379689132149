<product-banner></product-banner>
<div class="ez-section-card" id="our-product">
    <div class="ez-technology-platform-section">
        <div class="technologies-section">
            <app-gtrack-intro></app-gtrack-intro>
            <!-- <product-list></product-list> -->
            <app-gtrack-feature></app-gtrack-feature>
            <app-product-gtrack></app-product-gtrack>    
            <gtrack-product-technology></gtrack-product-technology>       
            <product-footer></product-footer>
        </div>
    </div>
</div>
