<div id="service-benefits" data-aos="fade-up" data-aos-duration="1500">
    <div class="text-center ml-10 mr-10">
        <div class=""><span class="font-20 color-black">Benefits of</span></div>
        <div class="mt-20 info-content"><span class="color-orange">ekZero’s DevOps Services</span></div>
    </div>
    <div class="benefit-content">
        <div class="row no-gutters">
            <div class="col-xl-6 col-lg-7">
                <div class="left-content">
                    <div class="benefit-pill mb-30">
                        <div class="d-flex flex-column-wrapper">
                            <div><img [src]="root.imageUrl() + '/svg-icons/devops-collaboration.svg'"
                                    class="benefit-icon"></div>
                            <div class="ml-20">
                                <div class="mb-10"><span class="color-black font-18">Collaboration
                                    </span></div>
                                <div><span class="color-black content-text mb-0">DevOps enables cooperation by breaking down silos between Dev/Ops/QA teams and encouraging them to work collaboratively toward a common goal.
                                    </span></div>
                            </div>
                        </div>
                    </div>
                    <div class="benefit-pill mb-30">
                        <div class="d-flex flex-column-wrapper">
                            <div><img [src]="root.imageUrl() + '/svg-icons/devops-speed.svg'"
                                    class="benefit-icon"></div>
                            <div class="ml-20">
                                <div class="mb-10"><span class="color-black font-18">Speed
                                    </span></div>
                                <div><span class="color-black content-text mb-0">DevOps accelerates the deployment of software updates and modifications through test automation and integration.
                                    </span></div>
                            </div>
                        </div>
                    </div>
                    <div class="benefit-pill mb-30">
                        <div class="d-flex flex-column-wrapper">
                            <div><img [src]="root.imageUrl() + '/svg-icons/devops-innovation.svg'"
                                    class="benefit-icon"></div>
                            <div class="ml-20">
                                <div class="mb-10"><span class="color-black font-18">Innovation
                                    </span></div>
                                <div><span class="color-black content-text mb-0">DevOps fosters innovation by allowing developers to discover more and effectively understand the needs of their customers.

                                    </span></div>
                            </div>
                        </div>
                    </div>
                    <div class="benefit-pill mb-30">
                        <div class="d-flex flex-column-wrapper">
                            <div><img [src]="root.imageUrl() + '/svg-icons/devops-customer-satisfaction.svg'"
                                    class="benefit-icon"></div>
                            <div class="ml-20">
                                <div class="mb-10"><span class="color-black font-18">Customer Satisfaction</span>
                                </div>
                                <div><span class="color-black content-text mb-0">DevOps speeds up software delivery and optimizes several parts of your business. This has a direct and meaningful impact on consumer satisfaction.
                                </span></div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="benefit-pill mb-30">
                        <div class="d-flex flex-column-wrapper">
                            <div><img [src]="root.imageUrl() + '/devops-customer-satisfaction.svg"
                                    class="benefit-icon"></div>
                            <div class="ml-20">
                                <div class="mb-10"><span class="color-black font-18">Order Management</span></div>
                                <div><span class="color-black content-text mb-0">Experience the real-time tracker that
                                        fulfils every customer order flawlessly
                                        & effectively, managing all your orders from a single platform</span></div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-xl-6 col-lg-5">
                <div class="right-content">
                    <img [src]="root.imageUrl() + '/ez-service-benefit-right-bg.png'">
                </div>
            </div>
        </div>
    </div>
</div>