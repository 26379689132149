<div id="product-feature">
    <div class="feature-section">
        <div class="feature-wrapper">
            <div class="left-content">
                <div class="mt-20 info-content"><span class="color-orange">Key Features of GTrack</span></div>
                <div><span class="color-black content-text mt-15">Practical features that makes GTrack a one-stop tracking
                        solution.
                    </span>
                </div>
            </div>
            <div class="key-feature-wrapper">
                <div class="row no-gutters">
                    <div class="col-xl-6">
                        <div class="left-panel">
                            <div class="img-wrapper">
                                <img [src]="root.imageUrl() + '/feat-gtrack.png'">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="overflow-wrapper -ms-scrollbar scrollable-body">
                            <div class="card-panel-wrapper">
                                <div class="right-panel row no-gutters">
                                    <div class="feature-list col-xl-12 col-lg-4 col-4">
                                        <div class="d-flex flex-wrapper">
                                            <div class="mgmt-icon center"><img
                                                    [src]="root.imageUrl() + '/svg-icons/gtrack-live-location.svg'">
                                            </div>
                                            <div class="feature-content">
                                                <div class="mb-15"><span class="color-black feature-title">Live Location
                                                        Tracking</span>
                                                </div>
                                                <div>
                                                    <p class="color-black feature-info">GTrack regularly lets you know
                                                        where your assets or vehicles are at your convenient time
                                                        frequency, starting from every 10 sec to once a week. GTrack
                                                        brings peace of mind by keeping things transparent and sorted.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="feature-list col-xl-12 col-lg-4 col-4">
                                        <div class="d-flex flex-wrapper">
                                            <div class="mgmt-icon center"><img
                                                [src]="root.imageUrl() + '/svg-icons/gtrack-smart-alert.svg'">
                                            </div>
                                            <div class="feature-content">
                                                <div class="mb-15"><span class="color-black feature-title">Smart Alerts
                                                        & Notifications

                                                    </span></div>
                                                <div>
                                                    <p class="color-black feature-info">GTrack keeps you updated by
                                                        sending you push notifications and pop-ups in real-time. It
                                                        allows you to set up various alarms and get notified for speed,
                                                        temperature, theft, etc.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="feature-list col-xl-12 col-lg-4 col-4">
                                        <div class="d-flex flex-wrapper">
                                            <div class="mgmt-icon center"><img
                                                [src]="root.imageUrl() + '/svg-icons/gtrack-geofencing.svg'">
                                            </div>
                                            <div class="feature-content">
                                                <div class="mb-15"><span
                                                        class="color-black feature-title">Geofencing</span>
                                                </div>
                                                <div>
                                                    <p class="color-black feature-info">GTrack helps in setting up
                                                        virtual boundaries to keep a detailed track of your assets.
                                                        Automatically sends you updates if any devices cross the defined
                                                        boundary.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="slider-block" id="blue-slider">
                            <div class="slider-component active"></div>
                            <div class="slider-component"></div>
                            <div class="slider-component"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>