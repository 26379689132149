<div class="our-services">
    <!-- -----------Enterprise Software Services---------------start -->
    <div class="our-services-wrapper">
        <div class="blue-bg position-relative">
            <div class="row">
                <div class="col-12">
                    <div class="text-align-right">
                        <div class="pl-10 width-50 mb-50">
                            <div class="mb-30">
                                <h2 class="font-600">Enterprise Software Services</h2>
                            </div>
                            <div>
                                <p class="font-14">Leverage our 8-year expertise in enterprise software development,
                                    UI/UX designing, API integrations, Process testing, Maintenance and Data Management.
                                    ekZero helps large organizations to achieve success in the global arena by providing
                                    end-to-end IT development and support services.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4 col-order-2">
                    <div class="justify-content-sb h-100">
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="logo-block">
                                <img [src]="root.imageUrl() + '/svg-icons/ews.svg'" class="wifi-logo">
                            </div>
                            <div>
                                <div class="mb-10">
                                    <h5 class="font-black">Enterprise Web Solutions</h5>
                                </div>
                                <div><span class="font-14 font-black">Get complete web application or web services
                                        development regardless of how simple or complex the solution is.
                                    </span></div>
                            </div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="logo-block">
                                <img [src]="root.imageUrl() + '/svg-icons/pa.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/pa.svg" class="wifi-logo"> -->

                            </div>
                            <div>
                                <div class="mb-10">
                                    <h5 class="font-black">Process Automation
                                    </h5>
                                </div>
                                <div><span class="font-14 font-black">Make continual advancement and value addition to
                                        your existing product at regular intervals to retain competitive edge in market.
                                    </span></div>
                            </div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="logo-block">
                                <img [src]="root.imageUrl() + '/svg-icons/st.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/st.svg" class="wifi-logo"> -->
                            </div>
                            <div>
                                <div class="mb-10">
                                    <h5 class="font-black">Software Testing
                                    </h5>
                                </div>
                                <div><span class="font-14 font-black">Implement the best in class testing methodologies
                                        to improve your application time to market and ROI.
                                    </span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12 col-lg-4 col-order-1">
                    <div class="center h-100">
                        <img [src]="root.imageUrl() + '/ez-services-enterprise-software-services.png'"
                            class="center-img" data-aos="flip-left" data-aos-duration="1500">
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 col-order-2 ">
                    <div class="justify-content-sb h-100 text-align">
                        <div class="d-flex mb-30 " data-aos="fade-up" data-aos-duration="1500">
                            <div class="change-order">
                                <div class="mb-10">
                                    <h5>Enterprise Resource Planning (ERP)</h5>
                                </div>
                                <div><span class="font-14">Transform your complicated business management systems into
                                        user-friendly ERP solutions through cross-functional methodology.
                                    </span></div>
                            </div>
                            <div class="logo-block right-block">
                                <img [src]="root.imageUrl() + '/svg-icons/erp.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/erp.svg" class="wifi-logo"> -->

                            </div>
                        </div>
                        <div class="d-flex mb-30 " data-aos="fade-up" data-aos-duration="1500">
                            <div class="change-order">
                                <div class="mb-10">
                                    <h5>Solution Consultation</h5>
                                </div>
                                <div><span class="font-14">Bridge the gap within your product and client’s existing
                                        infrastructure with tailor-made solutions that seamlessly fit in between.
                                    </span>
                                </div>
                            </div>
                            <div class="logo-block right-block">
                                <img [src]="root.imageUrl() + '/svg-icons/sc.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/sc.svg" class="wifi-logo"> -->
                            </div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="change-order">
                                <div class="mb-10">
                                    <h5>Product Enhancement
                                    </h5>
                                </div>
                                <div><span class="font-14">Make continual advancement and value addition to your
                                        existing product at regular intervals to retain competitive edge in market.
                                    </span></div>
                            </div>
                            <div class="logo-block right-block">
                                <img [src]="root.imageUrl() + '/svg-icons/pe.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/pe.svg" class="wifi-logo"> -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="dots">
                <div class="point bg-white"></div>
                <div class="point bg-white"></div>
                <div class="point bg-white"></div>
            </div> -->
            <div><a class="btn-primary btn-lg btn btn-bottom-1"
                    [routerLink]="['/ourservices/enterprise-software-service']" target="_top">Read more</a></div>
        </div>
    </div>
    <!-- -----------Enterprise Software Services---------------end -->

    <!-- -----------Mobile Applications---------------start -->
    <div class="our-services-wrapper">
        <div class="white-bg position-relative">
            <div class="row">
                <div class="col-12">
                    <div class="text-center  mb-50">
                        <div class="mb-30">
                            <h2 class="font-600 font-orange">Mobile Applications</h2>
                        </div>
                        <div>
                            <p class="font-14">We conceptualize, design and develop user-friendly web and mobile
                                applications from the ground up to digitally transform your business. ekZero has a
                                decade long expertise in building apps and solutions that directly fits with the market
                                needs and empowers companies’ brand identity.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-lg-4 col-order-2">
                    <div class=" justify-content-sb h-100">
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="logo-block">
                                <img [src]="root.imageUrl() + '/svg-icons/native-mobile.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/native-mobile.svg" class="wifi-logo"> -->

                            </div>
                            <div>
                                <div class="mb-10">
                                    <h5>Native Mobile App Development
                                    </h5>
                                </div>
                                <div><span class="font-14">Build high-quality native apps which are dedicatedly made for
                                        specific mobile OS and are installed directly onto the device itself.
                                    </span></div>
                            </div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="logo-block">
                                <img [src]="root.imageUrl() + '/svg-icons/hybrid.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/hybrid.svg" class="wifi-logo"> -->
                            </div>
                            <div>
                                <div class="mb-10">
                                    <h5>Hybrid Mobile App Development</h5>
                                </div>
                                <div><span class="font-14">Give your business a twist of expansion with hybrid mobile
                                        apps that are cross-platform and easily work in different environments.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="logo-block">
                                <img [src]="root.imageUrl() + '/svg-icons/web.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/web.svg" class="wifi-logo"> -->
                            </div>
                            <div>
                                <div class="mb-10">
                                    <h5>Progressive Web App Development
                                    </h5>
                                </div>
                                <div><span class="font-14">Reach to anyone and anywhere on any device with high-end
                                        single code-based progressive web apps that deliver native-like
                                        capabilities.</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-4 col-order-1">
                    <div class="center h-100">
                        <img [src]="root.imageUrl() + '/ez-services-mobile-applications.png'" class="center-img"
                            data-aos="flip-left" data-aos-duration="1500">
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 col-order-2 ">
                    <div class="justify-content-sb h-100 text-align">
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="change-order">
                                <div class="mb-10">
                                    <h5>Wearable and Embedded App Development
                                    </h5>
                                </div>
                                <div><span class="font-14">Scale up your existing business technology with companion apps
                                        our developers create for a number of wearables devices.
                                    </span></div>
                            </div>
                            <div class="logo-block right-block">
                                <img [src]="root.imageUrl() + '/svg-icons/wearable.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/wearable.svg" class="wifi-logo"> -->

                            </div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="change-order">
                                <div class="mb-10">
                                    <h5>IoT App Development</h5>
                                </div>
                                <div><span class="font-14">Gain full potential of Internet of Things with a wide range
                                        of our IoT software development offerings.
                                    </span>
                                </div>
                            </div>
                            <div class="logo-block right-block">
                                <img [src]="root.imageUrl() + '/svg-icons/iot.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/iot.svg" class="wifi-logo"> -->

                            </div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="change-order">
                                <div class="mb-10">
                                    <h5>Business Applications</h5>
                                </div>
                                <div><span class="font-14">ekZero has expertise in turning your android tablet or iPad
                                        into a business pad which you can use to track the differences in and
                                        out-house activities of your business.</span></div>
                            </div>
                            <div class="logo-block right-block">
                                <img [src]="root.imageUrl() + '/svg-icons/business.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/business.svg" class="wifi-logo"> -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="dots">
                <div class="point bg-blue"></div>
                <div class="point bg-blue"></div>
                <div class="point bg-blue"></div>
            </div> -->
            <div><a class="btn-primary btn-lg btn btn-bottom-2" [routerLink]="['/ourservices/mobile-app-service']"
                    routerLinkActive="router-link-active" target="_top">Read more</a></div>

        </div>
    </div>

    <!-- -----------Mobile Applications---------------end -->

    <!-- --------------Cloud Services---------------start -->
    <div class="our-services-wrapper">
        <div class="orange-bg position-relative">
            <div class="row">
                <div class="col-12">
                    <div class="width-50 mb-50 text-align-center pr-10">
                        <div class="mb-30">
                            <h2 class="font-600">Cloud Services</h2>
                        </div>
                        <div>
                            <p class="font-14">Transform your IT environment with superior Cloud computing services.
                                ekZero assists customers with on-demand delivery of cloud computing services enabling
                                them to become more agile and responsive to the ever-evolving marketing landscape. Our
                                certified professionals are experts in Amazon and Azure cloud services.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-lg-4 col-order-2">
                    <div class="justify-content-sb h-100">
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="logo-block">
                                <img [src]="root.imageUrl() + '/svg-icons/cloud.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/cloud.svg" class="wifi-logo"> -->
                            </div>
                            <div>
                                <div class="mb-10">
                                    <h5>Cloud Migration</h5>
                                </div>
                                <div><span class="font-14">Get your first step towards digital transformation through
                                        the right strategy, roadmap and ecosystem collaborations.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="logo-block">
                                <img [src]="root.imageUrl() + '/svg-icons/optimization.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/optimization.svg" class="wifi-logo"> -->
                            </div>
                            <div>
                                <div class="mb-10">
                                    <h5>Cloud Optimization</h5>
                                </div>
                                <div><span class="font-14">Automate your operations, control resource consumption and
                                        costs by streamlining your cloud environment.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="logo-block">
                                <img [src]="root.imageUrl() + '/svg-icons/maintenance.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/maintenance.svg" class="wifi-logo"> -->
                            </div>
                            <div>
                                <div class="mb-10">
                                    <h5>Cloud Maintenance</h5>
                                </div>
                                <div><span class="font-14">Ensure 24/7 round the clock cloud and server performance
                                        monitoring with zero downtime.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-4 col-order-1">
                    <div class="center h-100">
                        <img [src]="root.imageUrl() + '/ez-services-cloud-services.png'" class="center-img"
                            data-aos="flip-left" data-aos-duration="1500">
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 col-order-2 ">
                    <div class="justify-content-sb h-100 text-align">
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="change-order">
                                <div class="mb-10">
                                    <h5 class="font-black">Cloud Architecture</h5>
                                </div>
                                <div><span class="font-14 font-black">Setup a secure cloud architecture for your
                                        organization. From setting up a framework to maintenance ekZero can assist you
                                        with all.
                                    </span>
                                </div>
                            </div>
                            <div class="logo-block right-block">
                                <img [src]="root.imageUrl() + '/svg-icons/architecture.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/architecture.svg" class="wifi-logo"> -->

                            </div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="change-order">
                                <div class="mb-10">
                                    <h5 class="font-black">Cloud Consulting</h5>
                                </div>
                                <div><span class="font-14 font-black">End-to-end cloud consulting offerings that are
                                        designed to support you in your tech decisions form exploratory evaluations to
                                        cloud strategy to solution.
                                    </span></div>
                            </div>
                            <div class="logo-block right-block">
                                <img [src]="root.imageUrl() + '/svg-icons/consulting.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/consulting.svg" class="wifi-logo"> -->
                            </div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="change-order">
                                <div class="mb-10">
                                    <h5 class="font-black">Managed Cloud Services
                                    </h5>
                                </div>
                                <div><span class="font-14 font-black">A collaborative process that provides migration,
                                        maintenance and optimization on various cloud environments.
                                    </span></div>
                            </div>
                            <div class="logo-block right-block">
                                <img [src]="root.imageUrl() + '/svg-icons/cs.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/cs.svg" class="wifi-logo"> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="dots">
                <div class="point bg-white"></div>
                <div class="point bg-white"></div>
                <div class="point bg-white"></div>
            </div> -->
            <div><a class="btn-secondary btn-lg btn btn-bottom-1" [routerLink]="root.cloud()" target="_top">Read
                    more</a></div>
        </div>
    </div>

    <!-- --------------Cloud Services---------------end -->

    <!-- -------------DevOps Services-----------start--- -->
    <div class="our-services-wrapper">
        <div class="white-bg position-relative">
            <div class="row">
                <div class="col-12">
                    <div class="text-center mb-50">
                        <div class="mb-30">
                            <h2 class="font-600 font-orange">DevOps Services</h2>
                        </div>
                        <div>
                            <p class="font-14">ekZero’s decade-long expertise in DevOps helps the businesses with
                                services encompassing several capabilities such as iterative and incremental
                                development, workload management on-demand, lightweight architecture , security and
                                automated testing techniques.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-lg-4 col-order-2">
                    <div class="justify-content-sb h-100">
                        <div class="d-flex mb-30 " data-aos="fade-up" data-aos-duration="1500">
                            <div class="logo-block">
                                <img [src]="root.imageUrl() + '/svg-icons/automation.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/automation.svg" class="wifi-logo"> -->
                            </div>
                            <div>
                                <div class="mb-10">
                                    <h5>DevOps Automation</h5>
                                </div>
                                <div><span class="font-14">Automate your entire process pipeline from code generation to
                                        production deployment, test cases, quality checks and more.

                                    </span></div>
                            </div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="logo-block">
                                <img [src]="root.imageUrl() + '/svg-icons/dev-cloud.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/dev-cloud.svg" class="wifi-logo"> -->
                            </div>
                            <div>
                                <div class="mb-10">
                                    <h5>DevOps on Cloud
                                    </h5>
                                </div>
                                <div><span class="font-14">Completely streamlined developer processes on cloud that
                                        enables faster access to development environments.
                                    </span></div>
                            </div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="logo-block">
                                <img [src]="root.imageUrl() + '/svg-icons/assessment.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/assessment.svg" class="wifi-logo"> -->
                            </div>
                            <div>
                                <div class="mb-10">
                                    <h5>DevOps Assessment</h5>
                                </div>
                                <div><span class="font-14">A robust assessment and consulting service which scales up
                                        your development infrastructure so that you can focus on faster delivery.
                                    </span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" col-md-12 col-lg-4 col-order-1">
                    <div class="center h-100">
                        <img [src]="root.imageUrl() + '/devops-service-img.png'" class="center-img"
                            data-aos="flip-left" data-aos-duration="1500">
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 col-order-2 ">
                    <div class="justify-content-sb h-100 text-align">
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="change-order">
                                <div class="mb-10">
                                    <h5>DevOps Maintenance</h5>
                                </div>
                                <div><span class="font-14">Experience continuous delivery and deployment. Discover
                                        correct vulnerabilities in your application, infrastructure and integration.

                                    </span></div>
                            </div>
                            <div class="logo-block right-block">
                                <img [src]="root.imageUrl() + '/svg-icons/dev-maintenance.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/dev-maintenance.svg" class="wifi-logo"> -->
                            </div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="change-order">
                                <div class="mb-10">
                                    <h5>DevOps Planning</h5>
                                </div>
                                <div><span class="font-14">Develop a necessary actionable plan that addresses the
                                        current state of your DevOps culture, processes and tool chain.
                                    </span></div>
                            </div>
                            <div class="logo-block right-block">
                                <img [src]="root.imageUrl() + '/svg-icons/dev-planning.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/dev-planning.svg" class="wifi-logo"> -->
                            </div>
                        </div>
                        <div class="d-flex" data-aos="fade-up" data-aos-duration="1500">
                            <div class="change-order">
                                <div class="mb-10">
                                    <h5>DevOps Management</h5>
                                </div>
                                <div><span class="font-14">A complete managed service that configures web hosting, meets
                                        delivery requirements and performs automatic deployment.
                                    </span></div>
                            </div>
                            <div class="logo-block right-block">
                                <img [src]="root.imageUrl() + '/svg-icons/dev-manage.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/dev-manage.svg" class="wifi-logo"> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="dots">
                <div class="point bg-blue"></div>
                <div class="point bg-blue"></div>
                <div class="point bg-blue"></div>
            </div> -->
            <div><a class="btn-primary btn-lg btn btn-bottom-2" [routerLink]="root.devops()" target="_top">Read more</a>
            </div>
        </div>
    </div>

    <!-- -------------DevOps Services-----------end--- -->

    <!-- ------------Software Consulting------------start -->
    <div class="our-services-wrapper">
        <div class="blue-bg position-relative">
            <div class="row">
                <div class="col-12">
                    <div class="text-align-right">
                        <div class="width-50 mb-50 pl-10">
                            <div class="mb-30">
                                <h2 class="font-600">Software Consulting</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-lg-4 col-order-2">
                    <div class="justify-content-sb h-100">
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="logo-block">
                                <img [src]="root.imageUrl() + '/svg-icons/conceptualization.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/conceptualization.svg" class="wifi-logo"> -->
                            </div>
                            <div>
                                <div class="mb-10">
                                    <h5 class="font-black">Conceptualization</h5>
                                </div>
                                <div><span class="font-14 font-black">Converting your thoughts and ideas into a well
                                        thought, well designed and nicely written set of codes.
                                    </span></div>
                            </div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="logo-block">
                                <img [src]="root.imageUrl() + '/svg-icons/design.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/design.svg" class="wifi-logo"> -->
                            </div>
                            <div>
                                <div class="mb-10">
                                    <h5 class="font-black">Design and Architecture
                                    </h5>
                                </div>
                                <div><span class="font-14 font-black">Get a unique and attractive UX design for your IT
                                        product. Develop a comprehensive software architecture from scratch.
                                    </span></div>
                            </div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="logo-block">
                                <img [src]="root.imageUrl() + '/svg-icons/prototyping.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/prototyping.svg" class="wifi-logo"> -->
                            </div>
                            <div>
                                <div class="mb-10">
                                    <h5 class="font-black">Prototyping</h5>
                                </div>
                                <div><span class="font-14 font-black">One-stop-shop in production of high quality,
                                        low-cost prototypes. Let your ideas communicate with the world.
                                    </span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" col-md-12 col-lg-4 col-order-1">
                    <div class="center h-100">
                        <img [src]="root.imageUrl() + '/software-condulting-service-img.png'" class="center-img"
                            data-aos="flip-left" data-aos-duration="1500">
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 col-order-2 ">
                    <div class="justify-content-sb h-100 text-align">
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="change-order">
                                <div class="mb-10">
                                    <h5>Development and Testing
                                    </h5>
                                </div>
                                <div><span class="font-14"> The entire development of software on a range of IT technologies and
                                        rapid testing infrastructure for a faster launch.
                                    </span>
                                </div>
                            </div>
                            <div class="logo-block right-block">
                                <img [src]="root.imageUrl() + '/svg-icons/dt.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/dt.svg" class="wifi-logo"> -->
                            </div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="change-order">
                                <div class="mb-10">
                                    <h5>Maintenance and Support</h5>
                                </div>
                                <div><span class="font-14">End to end server maintenance and tech support for your
                                        software. From DevOps to going live on production
                                    </span></div>
                            </div>
                            <div class="logo-block right-block">
                                <img [src]="root.imageUrl() + '/svg-icons/ms.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/ms.svg" class="wifi-logo"> -->
                            </div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="change-order">
                                <div class="mb-10">
                                    <h5>Deployment</h5>
                                </div>
                                <div><span class="font-14">Get you software live hassle free with thorough development
                                        and expert quality support services.
                                    </span></div>
                            </div>
                            <div class="logo-block right-block">
                                <img [src]="root.imageUrl() + '/svg-icons/deployment.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/deployment.svg" class="wifi-logo"> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="dots">
                <div class="point bg-white"></div>
                <div class="point bg-white"></div>
                <div class="point bg-white"></div>
            </div> -->
            <div><a class="btn-primary btn-lg btn btn-bottom-1" [routerLink]="root.scs()" target="_top">Read more</a>
            </div>
        </div>
    </div>

    <!-- ------------Software Consulting------------end -->

    <!-- ----------Extended Team-------------start-- -->
    <div class="d-none our-services-wrapper">
        <div class="white-bg position-relative">
            <div class="row">
                <div class="col-12">
                    <div class="text-center  mb-50">
                        <div class="mb-30">
                            <h2 class="font-600 font-orange">Extended Team</h2>
                        </div>
                        <div>
                            <p class="font-14">We build, train, and manage a team that works for your success.</p>
                            <p class="font-14">ekZero extended Team allows you to easily scale your team strength with
                                highly skilled and experienced professionals. This service provides our clients with a
                                cost-effective approach that relieves them from a tedious hiring process.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-lg-4 col-order-2">
                    <div class=" justify-content-sb h-100">
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="logo-block"><img [src]="root.imageUrl() + '/svg-icons/wifi-icon-white.svg'"
                                    class="wifi-logo"></div>
                            <div>
                                <div class="mb-10">
                                    <h5>Virtual Staff Control</h5>
                                </div>
                                <div><span class="font-14">Your virtual staff world exclusively for you, delivering the
                                        technical expertise you need and when you need it.</span></div>
                            </div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="logo-block"><img [src]="root.imageUrl() + '/svg-icons/wifi-icon-white.svg'"
                                    class="wifi-logo"></div>
                            <div>
                                <div class="mb-10">
                                    <h5>Tech and Talent</h5>
                                </div>
                                <div><span class="font-14">Having access to the best of overseas and local talents and
                                        technologies to meet your specific needs.
                                        We have the resource to work on pretty much all technology at the required
                                        expertise and experience level.</span></div>
                            </div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="logo-block"><img [src]="root.imageUrl() + '/svg-icons/wifi-icon-white.svg'"
                                    class="wifi-logo"></div>
                            <div>
                                <div class="mb-10">
                                    <h5>Full Privacy</h5>
                                </div>
                                <div><span class="font-14">ekZero respects privacy. We comply with protecting all
                                        clients’ data and intellectual property
                                        Our setup and processes allow access to client’s data to only fully screened
                                        local professionals and data never leave the country of origin.</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" col-md-12 col-lg-4 col-order-1">
                    <div class="center h-100">
                        <img [src]="root.imageUrl() + '/ez-services-extended-team.png'" class="center-img"
                            data-aos="flip-left" data-aos-duration="1500">
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 col-order-2 ">
                    <div class="justify-content-sb h-100 text-align">
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="change-order">
                                <div class="mb-10">
                                    <h5>Convenient Cost Control</h5>
                                </div>
                                <div><span class="font-14">Save and control cost. No hassle.
                                        Manage your cost without compromising the quality of work. Our rates are very
                                        competitive to local markets without compromise in quality and process of how we
                                        work.</span></div>
                            </div>
                            <div class="logo-block right-block"><img
                                    [src]="root.imageUrl() + '/svg-icons/wifi-icon-white.svg'" class="wifi-logo"></div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="change-order">
                                <div class="mb-10">
                                    <h5>Trial Period </h5>
                                </div>
                                <div><span class="font-14">Don’t take chances. Try us out for free.
                                        All our services packages include a free contract-free trial period.</span>
                                </div>
                            </div>
                            <div class="logo-block right-block"><img
                                    [src]="root.imageUrl() + '/svg-icons/wifi-icon-white.svg'" class="wifi-logo"></div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="change-order">
                                <div class="mb-10">
                                    <h5>Performance Guarantee</h5>
                                </div>
                                <div><span class="font-14">We guarantee to deliver on agreed velocity and quality and
                                        all our efforts are in maintaining that superstar performance.</span></div>
                            </div>
                            <div class="logo-block right-block"><img
                                    [src]="root.imageUrl() + '/svg-icons/wifi-icon-white.svg'" class="wifi-logo"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="dots">
                <div class="point bg-blue"></div>
                <div class="point bg-blue"></div>
                <div class="point bg-blue"></div>
            </div> -->
            <div><a class="btn-secondary btn-lg btn btn-bottom-2" [routerLink]="['/ourservices/detail']"
                    target="_top">Read more</a></div>
        </div>
    </div>
    <!-- ----------Extended Team-------------end-- -->
</div>