import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'app-life-footer',
  templateUrl: './life-footer.component.html',
  styleUrls: ['./life-footer.component.scss']
})
export class LifeFooterComponent implements OnInit {

  constructor(public root: RootService) { }

  ngOnInit(): void {
  }

}
