// export const jobsList = [
//     {
//         title: 'Mobile Developer - React Native | Android |iOS',
//         position: '',
//         location: '',
//         image: '',
//         longDescription: [],
//         role: '',
//         experience: [],
//         primarySkills: [],
//         keyResponsibility: []
//     }
// ]

export const departmentsEnum = {
    "QA": "QA",
    "FRONTEND": 'Frontend',
    "BACKEND": 'Backend',
    "DEVOPS": "DevOps",
    "OTHERS": "Others",
    "SALES": "Sales",
    "MOBILE": "Mobile",
    "MARKETING": "Marketing",
    "UI": "UI"
}


export const rolesEnum = {
    "JUNIOR": 'Junior',
    "INTERMEDIATE": 'Intermediate',
    "SENIOR": 'Senior',
    "LEAD": 'Lead',
    "MANAGER": 'Manager'
}