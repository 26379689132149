<div class="research-development-technology">
    <div class="text-center">
        <div class="mt-20 info-content">
            <div><span class="color-orange">Technologies we Research and Develop</span></div>
        </div>
        <div class="mt-20">
            <span class="color-black content-text">Embracing tech-driven business operations backed by intelligent solutions</span>
        </div>
    </div>
    <div class="technology-main-section">
        <div class="row align-items-center">
            <div class="col-lg-6 order-12 order-lg-1" data-aos="fade-right" data-aos-duration="1500">
                <div class="left-panel" [ngClass]="{'active': block1}">
                    <div class="title-panel">
                        <span class="title-text">
                            Artificial Intelligence
                        </span>
                    </div>
                    <div class="desc-panel">
                        <span class="desc-text">
                            Artificial Intelligence (AI) has revolutionized the way machines and devices work by allowing them to assess the surroundings, understand speech and gestures, interact naturally, and make complicated data-driven judgments. Artificial Intelligence (AI), a critical component of the Internet of Things, is reshaping the methods of creating, manufacturing, and delivering services.
                        </span>
                    </div>
                    <div class="desc-panel mt-20">
                        <span class="desc-text">
                            From a basic web search to complex online advertising, AI is revolutionizing the digital world. Today's digitally dominated market makes Artificial Intelligence Solutions so critical in achieving competitive advantage and corporate success. AI’s cutting-edge nature mandates robust knowledge. Empower our AI expertise to leverage the power of deep machine learning and advanced analytics into your organization.
                        </span>
                    </div>
                    <div class="nav-arrow">
                        <div class="left-arrow center arrow-icon">
                            <a (click)="onBlock6()" [ngClass]="{'active': block6}">
                                <img [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'" class="default"/>
                                <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" class="hover">
                            </a>
                        </div>
                        <div class="right-arrow center arrow-icon">
                            <a (click)="onBlock2()" [ngClass]="{'active': block2}">
                                <img [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'" class="default"/>
                                <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" class="hover">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="left-panel" [ngClass]="{'active': block2}">
                    <div class="title-panel">
                        <span class="title-text">
                            Internet of Things
                        </span>
                    </div>
                    <div class="desc-panel">
                        <span class="desc-text">
                            The Internet of Things (IoT) has been a disruptive technology in recent years, with smart gadgets revolutionizing lifestyles. IoT solutions and services are rapidly being used by businesses of all sizes to improve operational efficiency and redefine their business models.  It's become imperative for businesses to find a tech partner that can provide robust, end-to-end IoT services in order to progress forward in the technological revolution.
                        </span>
                    </div>
                    <div class="desc-panel mt-20">
                        <span class="desc-text">
                            Our IoT services include strategic consultation, development, data analytics, and application management, all targeted at assisting businesses in overcoming business difficulties and generating new business opportunities through the use of IoT technology. Whether you're launching a new IoT endeavour or scaling up an existing Iot system, ekZero can help!
                        </span>
                    </div>
                    <div class="nav-arrow">
                        <div class="left-arrow center arrow-icon">
                            <a (click)="onBlock1()" [ngClass]="{'active': block1}">
                                <img [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'" class="default"/>
                                <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" class="hover">
                            </a>
                        </div>
                        <div class="right-arrow center arrow-icon">
                            <a (click)="onBlock3()" [ngClass]="{'active': block3}">
                                <img [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'" class="default"/>
                                <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" class="hover">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="left-panel" [ngClass]="{'active': block3}">
                    <div class="title-panel">
                        <span class="title-text">
                            Machine Learning
                        </span>
                    </div>
                    <div class="desc-panel">
                        <span class="desc-text">
                            While artificial intelligence (AI) is a vast discipline that mimics human abilities, machine learning is a subset of AI that teaches a system how to learn. Machine learning (ML) allows your software network to learn from data without having to use conventional programming techniques. Profound machine learning and other sophisticated approaches allow us to develop the most effective ML-based systems for enterprises.
                        </span>
                    </div>
                    <div class="desc-panel mt-20">
                        <span class="desc-text">
                            We assist you in exploring the possibilities of machine learning in solving important business difficulties, allowing data-driven initiatives, and developing creative business models with cutting-edge apps and solutions. Our team of professionals can assist you in developing and deploying cutting-edge machine learning solutions that will help you accomplish your business goals swiftly.
                        </span>
                    </div>
                    <div class="nav-arrow">
                        <div class="left-arrow center arrow-icon">
                            <a (click)="onBlock2()" [ngClass]="{'active': block2}">
                                <img [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'" class="default"/>
                                <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" class="hover">
                            </a>
                        </div>
                        <div class="right-arrow center arrow-icon">
                            <a (click)="onBlock4()" [ngClass]="{'active': block4}">
                                <img [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'" class="default"/>
                                <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" class="hover">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="left-panel" [ngClass]="{'active': block4}">
                    <div class="title-panel">
                        <span class="title-text">
                            Augmented Reality
                        </span>
                    </div>
                    <div class="desc-panel">
                        <span class="desc-text">
                            Augmented reality (AR) is a digitally modified replica of the real physical environment created by the use of computerized visual elements audio, or other sensory stimulation delivered through technologies. Augmented Reality enables the creation of an intuitive, rapid, and expanding experience for clients and end users.
                        </span>
                    </div>
                    <div class="desc-panel mt-20">
                        <span class="desc-text">
                            By thoroughly assessing your business requirements, our team assists you in adopting AR breakthroughs through tailored solutions. Our team's AR professionals improve product communication and assist businesses in gaining market share by enhancing market presence. With ekZero, you can invest in an AR solution that unlocks a slew of new business possibilities.
                        </span>
                    </div>
                    <div class="nav-arrow">
                        <div class="left-arrow center arrow-icon">
                            <a (click)="onBlock3()" [ngClass]="{'active': block3}">
                                <img [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'" class="default"/>
                                <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" class="hover">
                            </a>
                        </div>
                        <div class="right-arrow center arrow-icon">
                            <a (click)="onBlock5()" [ngClass]="{'active': block5}">
                                <img [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'" class="default"/>
                                <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" class="hover">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="left-panel" [ngClass]="{'active': block5}">
                    <div class="title-panel">
                        <span class="title-text">
                            Virtual Reality
                        </span>
                    </div>
                    <div class="desc-panel">
                        <span class="desc-text">
                            Virtual reality (VR) is a computer-generated simulation in which a consumer can connect with electronic equipment within an artificially three-dimensional environment. As the corporate world becomes more competitive by the day, being unique is the key to sticking out. Virtual Reality (VR) opens new opportunities for organizations to showcase their products and services by replicating physical presence in both the actual and imagined worlds.
                        </span>
                    </div>
                    <div class="desc-panel mt-20">
                        <span class="desc-text">
                            We aim to research and develop virtual reality solutions that meet the modern business needs. Expert mobile app developers, 3D artists, UI/UX design experts, and digital producers make up our team, all of whom have vast talent and experience in building stunning solutions. Choose us as your technology partner to fully leverage the power of VR.
                        </span>
                    </div>
                    <div class="nav-arrow">
                        <div class="left-arrow center arrow-icon">
                            <a (click)="onBlock4()" [ngClass]="{'active': block4}">
                                <img [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'" class="default"/>
                                <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" class="hover">
                            </a>
                        </div>
                        <div class="right-arrow center arrow-icon">
                            <a (click)="onBlock6()" [ngClass]="{'active': block6}">
                                <img [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'" class="default"/>
                                <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" class="hover">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="left-panel" [ngClass]="{'active': block6}">
                    <div class="title-panel">
                        <span class="title-text">
                            Mixed Reality
                        </span>
                    </div>
                    <div class="desc-panel">
                        <span class="desc-text">
                            The merging of the real and virtual worlds to create new habitats and visualizations in which actual and digital items co-exist and interact in real time is what is known as mixed reality. Top corporate players are employing efficient digital strategies to win and maintain satisfied customers, despite the ever-changing nature of consumer behaviour and interactions.
                        </span>
                    </div>
                    <div class="desc-panel mt-20">
                        <span class="desc-text">
                            We assist you in exploring the exciting domains of extended realities for improved revenue potential and enhanced corporate value as technological innovations grow. With our cutting-edge mixed reality technologies, you can generate colorful and realistic mirrors of real-world settings for comprehensive, distinct, and intuitive user experiences.
                        </span>
                    </div>
                    <div class="nav-arrow">
                        <div class="left-arrow center arrow-icon">
                            <a (click)="onBlock5()" [ngClass]="{'active': block5}">
                                <img [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'" class="default"/>
                                <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" class="hover">
                            </a>
                        </div>
                        <div class="right-arrow center arrow-icon">
                            <a (click)="onBlock1()" [ngClass]="{'active': block1}">
                                <img [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'" class="default"/>
                                <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" class="hover">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 order-1 order-lg-12" data-aos="fade-left" data-aos-duration="1500">
                <div class="right-panel">
                    <div class="round-center-section" [ngClass]="{'active': block1}">
                        <div class="icon-panel">
                            <img [src]="root.imageUrl() + '/svg-icons/ai-highlight-icon.svg'"/>
                        </div>
                        <div class="text-panel">
                            <span class="text">
                                Artificial Intelligence
                            </span>
                        </div>
                    </div>
                    <div class="round-center-section" [ngClass]="{'active': block2}">
                        <div class="icon-panel">
                            <img [src]="root.imageUrl() + '/svg-icons/iot-highlight-icon.svg'"/>
                        </div>
                        <div class="text-panel">
                            <span class="text">
                                Internet of Things
                            </span>
                        </div>
                    </div>
                    <div class="round-center-section" [ngClass]="{'active': block3}">
                        <div class="icon-panel">
                            <img [src]="root.imageUrl() + '/svg-icons/ml-highlight-icon.svg'"/>
                        </div>
                        <div class="text-panel">
                            <span class="text">
                                Machine Learning
                            </span>
                        </div>
                    </div>
                    <div class="round-center-section" [ngClass]="{'active': block4}">
                        <div class="icon-panel">
                            <img [src]="root.imageUrl() + '/svg-icons/ar-highlight-icon.svg'"/>
                        </div>
                        <div class="text-panel">
                            <span class="text">
                                Augmented Reality
                            </span>
                        </div>
                    </div>
                    <div class="round-center-section" [ngClass]="{'active': block5}">
                        <div class="icon-panel">
                            <img [src]="root.imageUrl() + '/svg-icons/vr-highlight-icon.svg'"/>
                        </div>
                        <div class="text-panel">
                            <span class="text">
                                Virtual Reality
                            </span>
                        </div>
                    </div>
                    <div class="round-center-section" [ngClass]="{'active': block6}">
                        <div class="icon-panel">
                            <img [src]="root.imageUrl() + '/svg-icons/mr-highlight-icon.svg'"/>
                        </div>
                        <div class="text-panel">
                            <span class="text">
                                Mixed Reality
                            </span>
                        </div>
                    </div>
                    <div class="icon-block ai-icon" (click)="onBlock1()" [ngClass]="{'active': block1}">
                    </div>
                    <div class="icon-block iot-icon" (click)="onBlock2()" [ngClass]="{'active': block2}">
                    </div>
                    <div class="icon-block ml-icon" (click)="onBlock3()" [ngClass]="{'active': block3}">
                    </div>
                    <div class="icon-block ar-icon" (click)="onBlock4()" [ngClass]="{'active': block4}">
                    </div>
                    <div class="icon-block mr-icon" (click)="onBlock5()" [ngClass]="{'active': block5}">
                    </div>
                    <div class="icon-block vr-icon" (click)="onBlock6()" [ngClass]="{'active': block6}">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
