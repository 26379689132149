import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'career-specifications',
  templateUrl: './career-specifications.component.html',
  styleUrls: ['./career-specifications.component.scss']
})
export class CareerSpecificationsComponent implements OnInit {

  block1: boolean = true;
  block2: boolean = false;
  block3: boolean = false;
  block4: boolean = false;

  constructor(public root: RootService) { }

  ngOnInit(): void {
  }
  onBlock1() {
    this.block1 = true;
    this.block2 = false;
    this.block3 = false;
    this.block4 = false;
  }
  onBlock2() {
    this.block1 = false;
    this.block2 = true;
    this.block3 = false;
    this.block4 = false;
  }
  onBlock3() {
    this.block1 = false;
    this.block2 = false;
    this.block3 = true;
    this.block4 = false;
  }
  onBlock4() {
    this.block1 = false;
    this.block2 = false;
    this.block3 = false;
    this.block4 = true;
  }
}
