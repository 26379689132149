<div class="ez-aboutus-banner">
    <div class="text-container">
        <div class="subheading-block position-relative mb-15">
            <span class="subheading-block-text">
                Contact Us
            </span>
        </div>
        <div class="subheading2-block mb-15">
            <span class="subheading2-block-text">
                Discover new possibilities with ekZero
            </span>
        </div>       
    </div>
</div>