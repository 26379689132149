<service-banner></service-banner>
<div class="ez-section-card">
    <div class="ez-technology-platform-section">
        <div class="technologies-section">
            <app-mobile-app-intro></app-mobile-app-intro>
            <app-mobile-app-offer></app-mobile-app-offer>
            <app-mobile-app-benefits></app-mobile-app-benefits>            
            <app-mobile-app-services></app-mobile-app-services>
            <sales></sales>
        </div>
    </div>
</div>
