<div id="explore-services">
    <div class="explore-block">
        <div class="row">
            <div class="col-xl-8 col-lg-order-2">
                <div class="left-content">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="mb-30">
                                <div><img class="service-img"
                                        [src]="root.imageUrl() + '/svg-icons/enterprise-service-icon.svg'">
                                </div>
                                <div class="mt-15"><span class="color-black service-title font-600">Enterprise Software
                                        Services</span></div>
                                <div class="mt-15"><span class="color-black content-text">Connect with your driver
                                        through VegitOne CoPilot and maintain a smooth flow
                                        of information.</span></div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-30">
                                <div><img class="service-img"
                                        [src]="root.imageUrl() + '/svg-icons/enterprise-service-icon.svg'">
                                </div>
                                <div class="mt-15"><span class="color-black service-title font-600">Enterprise Software
                                        Services</span></div>
                                <div class="mt-15"><span class="color-black content-text">Connect with your driver
                                        through VegitOne CoPilot and maintain a smooth flow
                                        of information.</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="mb-30">
                                <div><img class="service-img"
                                        [src]="root.imageUrl() + '/svg-icons/enterprise-service-icon.svg'">
                                </div>
                                <div class="mt-15"><span class="color-black service-title font-600">Enterprise Software
                                        Services</span></div>
                                <div class="mt-15"><span class="color-black content-text">Connect with your driver
                                        through VegitOne CoPilot and maintain a smooth flow
                                        of information.</span></div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-30">
                                <div><img class="service-img"
                                        [src]="root.imageUrl() + '/svg-icons/enterprise-service-icon.svg'">
                                </div>
                                <div class="mt-15"><span class="color-black service-title font-600">Enterprise Software
                                        Services</span></div>
                                <div class="mt-15"><span class="color-black content-text">Connect with your driver
                                        through VegitOne CoPilot and maintain a smooth flow
                                        of information.</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-order-1">
                <div class="right-content">
                    <div class="right-bg">
                        <div class=""><span class="font-20">Explore Other</span></div>
                        <div class="mt-20 info-content"><span>ekZero Services</span></div>
                        <div class="mt-20"><span class="content-text">Lorem ipsum dolor sit amet, consectetur adipiscing
                                elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></div>
                        <div><a class="btn-news btn-md" href="/ekzerowebsite/ourservices" target="_top">VIEW ALL</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>