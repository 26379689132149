<div id="career-opening">
  <div class="career-wrapper">
    <div class="left-content">
      <div class="info-title">
        <span class="color-black">Take a look at</span>
      </div>
      <div class="mt-20 info-content">
        <div><span class="color-orange">The current opening</span></div>
      </div>
      <div class="mt-20 spec-text">
        <span class="color-black content-text"
          >Ready to make a difference? ekZero can be a great place for you!
        </span>
      </div>
    </div>

    <div class="opening-info-block" id="application">
      <!-- <div class="info-header"></div> -->
      <div class="accordion accordian-panel" id="accordionExample">
        <div class="accordion-item panel-group position-relative">
          <div class="accordion-header" id="headingFour">
            <div
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              <div class="accordion-header d-flex justify-content-between">
                <div class="d-flex">
                  <div class="tech-icon center">
                    <img
                      [src]="root.imageUrl() + '/svg-icons/devOps-aws-icon.svg'"
                    />
                  </div>
                  <div
                    class="opening-info d-flex flex-column justify-content-center"
                  >
                    <div>
                      <span class="panel-title">DevOps/CloudOps AWS </span>
                    </div>
                    <div class="d-flex mt-10 flex-wrapper">
                      <div class="info-pills mr-10">
                        <span
                          >Positions : <span>Intermediate & Senior</span></span
                        >
                      </div>
                      <div class="info-pills">
                        <span>Location : <span>Vadodara</span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div (click)="openModal(Content)" class="btn-panel">
                  <a class="btn-md btn-primary" target="_top">Apply now</a>
                </div>
              </div>
            </div>
          </div>
          <div
            id="collapseFour"
            class="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div class="panel-body">
              <div class="require-info pb-10">
                <div class="require-pl pt-20 pb-20">
                  <span class="info-content font-600 font-16"
                    >Key Responsibilities</span
                  >
                </div>
                <ul>
                  <li class="color-black">Demonstrated experience with AWS</li>
                  <li class="color-black">
                    Knowledge of servers, networks, storage, client-server
                    systems, and firewalls
                  </li>
                  <li class="color-black">
                    Strong expertise in Windows and/or Linux operating systems,
                    including system architecture and design, as well as
                    experience supporting and troubleshooting stability and
                    performance issues
                  </li>
                  <li class="color-black">
                    Thorough understanding of and experience with virtualization
                    technologies (e.g., VMWare/Hyper-V)
                  </li>
                  <li class="color-black">
                    Knowledge of core network services such as DHCP, DNS, IP
                    routing, VLANs, layer 2/3 routing, and load balancing is
                    required
                  </li>
                  <li class="color-black">
                    Experience in reading, writing or modifying PowerShell, Bash
                    scripts & Python code.Experience using git
                  </li>
                  <li class="color-black">
                    Working know-how of software-defined lifecycles, product
                    packaging, and deployments
                  </li>
                  <li class="color-black">
                    POSTGRESSQL or Oracle database administration (Backup,
                    Restore, Tuning, Monitoring, Management)
                  </li>
                  <li class="color-black">
                    At least 2 from AWS Associate Solutions Architect, DevOps,
                    or SysOps
                  </li>
                  <li class="color-black">
                    At least 1 from AWS Professional Solutions Architect, DevOps
                  </li>
                </ul>
                <div class="require-pl pt-20 pb-20">
                  <span class="info-content font-600 font-16"
                    >Primary Skills:</span
                  >
                </div>
                <ul>
                  <li class="color-black">
                    AWS: S3, Redshift, DynamoDB, EC2, VPC, Lambda, CloudWatch
                    etc.
                  </li>
                  <li class="color-black">
                    Bigdata: Databricks, Cloudera, Glue and Athena
                  </li>
                  <li class="color-black">DevOps: Jenkins, Bitbucket</li>
                  <li class="color-black">
                    Automation: Terraform, Cloud Formation, Python, Shell
                    scripting Experience in automating AWS infrastructure with
                    Terraform.
                  </li>
                  <li class="color-black">
                    Experience in database technologies is a plus.
                  </li>
                  <li class="color-black">
                    Knowledge in all aspects of DevOps (source control,
                    continuous integration, deployments, etc.)
                  </li>
                  <li class="color-black">
                    Proficiency in security implementation best practices on IAM
                    policies, KMS encryption, Secrets Management, Network
                    Security Groups etc.
                  </li>
                  <li class="color-black">
                    Experience working in the SCRUM Environment
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item panel-group position-relative">
          <div class="accordion-header" id="headingTen">
            <div
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTen"
              aria-expanded="false"
              aria-controls="collapseTen"
            >
              <div class="accordion-header d-flex justify-content-between">
                <div class="d-flex">
                  <div class="tech-icon center">
                    <img
                      [src]="
                        root.imageUrl() +
                        '/svg-icons/business-development-executive-icon.svg'
                      "
                    />
                  </div>
                  <div
                    class="opening-info d-flex flex-column justify-content-center"
                  >
                    <div>
                      <span class="panel-title"
                        >Business Development Executive</span
                      >
                    </div>
                    <div class="d-flex mt-10 flex-wrapper">
                      <div class="info-pills mr-10">
                        <span>Positions : <span>Intermediate</span></span>
                      </div>
                      <div class="info-pills">
                        <span>Location : <span>Vadodara</span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div (click)="openModal(Content)" class="btn-panel">
                  <a class="btn-md btn-primary" target="_top">Apply now</a>
                </div>
              </div>
            </div>
          </div>
          <div
            id="collapseTen"
            class="accordion-collapse collapse"
            aria-labelledby="headingTen"
            data-bs-parent="#accordionExample"
          >
            <div class="panel-body">
              <div class="require-info pb-10">
                <div class="require-pl pt-20 pb-20">
                  <span class="info-content font-600 font-16"
                    >Key Responsibilities</span
                  >
                </div>
                <ul>
                  <li class="color-black">
                    Developing and executing sales plans to meet and exceed
                    monthly and quarterly sales goals.
                  </li>
                  <li class="color-black">
                    Growing business through the development of new leads and
                    new contacts.
                  </li>
                  <li class="color-black">
                    Generating the leads through the various online bidding
                    platforms: Freelancer.com, Upwork, Guru, LinkedIn Sales
                    Navigator, and other SM Platforms.
                  </li>
                  <li class="color-black">
                    Experience with keeping track of leads through different
                    stages of engagements.
                  </li>
                  <li class="color-black">
                    Identifying new revenue opportunities.
                  </li>
                  <li class="color-black">
                    Building business relationships with current and potential
                    clients.
                  </li>
                  <li class="color-black">
                    Attending networking events to attract and retain clients.
                  </li>
                  <li class="color-black">
                    Experience with Developing and executing sales and marketing
                    strategies to grow business.
                  </li>
                  <li class="color-black">
                    Maintaining and updating sales, marketing, and business
                    development documentation. Assisting with marketing and
                    promotional projects.
                  </li>
                  <li class="color-black">
                    Collaborating with management on sales goals.
                  </li>
                  <li class="color-black">
                    Support the team with other responsibilities as required.
                  </li>
                </ul>
                <div class="require-pl pt-20 pb-20">
                  <span class="info-content font-600 font-16"
                    >Primary Skills:</span
                  >
                </div>
                <ul>
                  <li class="color-black">
                    Lead generation and requirement analysis
                  </li>
                  <li class="color-black">Proposal writing and Management</li>
                  <li class="color-black">
                    Bid writing, response, and strategy
                  </li>
                  <li class="color-black">Project bidding for IT sales</li>
                  <li class="color-black">Direct Client Interaction</li>
                  <li class="color-black">
                    Agile Project Management & Research
                  </li>
                  <li class="color-black">
                    Strong organizational and time management skills
                  </li>
                  <li class="color-black">
                    Excellent verbal and written communication skills
                  </li>
                  <li class="color-black">
                    Enthusiastic about building good relationships with people
                  </li>
                  <li class="color-black">
                    Ability to work well in a team environment
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item panel-group position-relative">
          <div class="accordion-header" id="headingFive">
            <div
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              <div class="accordion-header d-flex justify-content-between">
                <div class="d-flex">
                  <div class="tech-icon center">
                    <img
                      [src]="
                        root.imageUrl() + '/svg-icons/java-development-icon.svg'
                      "
                    />
                  </div>
                  <div
                    class="opening-info d-flex flex-column justify-content-center"
                  >
                    <div><span class="panel-title">Java Developer</span></div>
                    <div class="d-flex mt-10 flex-wrapper">
                      <div class="info-pills mr-10">
                        <span
                          >Positions : <span>Intermediate & Senior</span></span
                        >
                      </div>
                      <div class="info-pills">
                        <span>Location : <span>Vadodara</span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div (click)="openModal(Content)" class="btn-panel">
                  <a class="btn-md btn-primary" target="_top">Apply now</a>
                </div>
              </div>
            </div>
          </div>
          <div
            id="collapseFive"
            class="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div class="panel-body">
              <div class="require-info pb-10">
                <div class="require-pl pt-20 pb-20">
                  <span class="info-content font-600 font-16"
                    >Key Responsibilities</span
                  >
                </div>
                <ul>
                  <li class="color-black">
                    Java 8, Spring Boot, REST Web Services, Microservices,
                    Spring Data JPA, Hibernate, MySQL, PostgreSQL
                  </li>
                  <li class="color-black">
                    Jenkins, JIRA, BitBucket, SouceTree, and Confluence
                  </li>
                  <li class="color-black">
                    Team Player and efficient communicator who can work with
                    international teams
                  </li>
                  <li class="color-black">
                    Software Development Process, Object-Oriented Design (OOD),
                    Software Debugging
                  </li>
                  <li class="color-black">
                    Design and develop applications following the scrum
                    methodology
                  </li>
                  <li class="color-black">
                    Review requirements and provide estimates and schedules
                  </li>
                  <li class="color-black">
                    Work in coordination with other teams (Development, QA,
                    DevOps, and Support)
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item panel-group position-relative">
          <div class="accordion-header" id="headingNine">
            <div
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseNine"
              aria-expanded="false"
              aria-controls="collapseNine"
            >
              <div class="accordion-header d-flex justify-content-between">
                <div class="d-flex">
                  <div class="tech-icon center">
                    <img
                      [src]="
                        root.imageUrl() + '/svg-icons/business-analyst-icon.svg'
                      "
                    />
                  </div>
                  <div
                    class="opening-info d-flex flex-column justify-content-center"
                  >
                    <div><span class="panel-title">Business Analyst</span></div>
                    <div class="d-flex mt-10 flex-wrapper">
                      <div class="info-pills mr-10">
                        <span>Positions : <span>Senior</span></span>
                      </div>
                      <div class="info-pills">
                        <span>Location : <span>Vadodara</span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div (click)="openModal(Content)" class="btn-panel">
                  <a class="btn-md btn-primary" target="_top">Apply now</a>
                </div>
              </div>
            </div>
          </div>
          <div
            id="collapseNine"
            class="accordion-collapse collapse"
            aria-labelledby="headingNine"
            data-bs-parent="#accordionExample"
          >
            <div class="panel-body">
              <div class="require-info pb-10">
                <div class="require-pl pt-20 pb-20">
                  <span class="info-content font-600 font-16"
                    >Key Responsibilities</span
                  >
                </div>
                <ul>
                  <li class="color-black">
                    Must have exepertise in Business Analaysis process and
                    documentation.
                  </li>
                  <li class="color-black">
                    Passion for identifying business problems and eager to give
                    solutions.
                  </li>
                  <li class="color-black">
                    Expertise of making wire frame & prototyping.
                  </li>
                  <li class="color-black">
                    You should never bored with doing research and customer
                    interaction.
                  </li>
                  <li class="color-black">
                    Added advantage if you worked on software products.
                  </li>
                  <li class="color-black">
                    Must have hands on user flow diagrams, process flows, task
                    flow, use cases etc.
                  </li>
                  <li class="color-black">
                    Must ask lots of questions to stakeholders and can dig their
                    mind at fullest.
                  </li>
                  <li class="color-black">
                    Must having 100% Expertise on effective communication.
                  </li>
                  <li class="color-black">
                    Must develop high fidelity wireframes and prototypes.
                  </li>
                  <li class="color-black">
                    Must have expertise on Sketchapp, Axure RP, Invision, &
                    other necessary tools.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item panel-group position-relative">
          <div class="accordion-header" id="headingThree">
            <div
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              <div class="accordion-header d-flex justify-content-between">
                <div class="d-flex">
                  <div class="tech-icon center">
                    <img
                      [src]="
                        root.imageUrl() +
                        '/svg-icons/angular-development-icon.svg'
                      "
                      width="80%"
                    />
                  </div>
                  <div
                    class="opening-info d-flex flex-column justify-content-center"
                  >
                    <div>
                      <span class="panel-title">Angular Developer</span>
                    </div>
                    <div class="d-flex mt-10 flex-wrapper">
                      <div class="info-pills mr-10">
                        <span
                          >Positions : <span>Intermediate & Senior</span></span
                        >
                      </div>
                      <div class="info-pills">
                        <span>Location : <span>Vadodara</span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div (click)="openModal(Content)" class="btn-panel">
                  <a class="btn-md btn-primary" target="_top">Apply now</a>
                </div>
              </div>
            </div>
          </div>
          <div
            id="collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div class="panel-body">
              <div class="require-info pb-10">
                <div class="require-pl pt-20 pb-20">
                  <span class="info-content font-600 font-16"
                    >Key Responsibilities</span
                  >
                </div>
                <ul>
                  <li class="color-black">
                    Angular 6+, Typescript2+, JavaScript, HTML5, CSS3, JSON, ES6
                    standards, Bootstrap, Redux and Flux, NgRX
                  </li>
                  <li class="color-black">
                    Integration with REST Web-services
                  </li>
                  <li class="color-black">Experience with RESTful services</li>
                  <li class="color-black">
                    JIRA, BitBucket, SouceTree, and Confluence
                  </li>
                  <li class="color-black">
                    Team Player and efficient communicator who can work with
                    international teams
                  </li>
                  <li class="color-black">
                    Software Development Process, Object-Oriented Design (OOD),
                    Software Debugging
                  </li>
                  <li class="color-black">
                    Requirements Understanding and Estimates
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item panel-group position-relative">
          <div class="accordion-header" id="headingSix">
            <div
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              <div class="accordion-header d-flex justify-content-between">
                <div class="d-flex">
                  <div class="tech-icon center">
                    <img
                      [src]="
                        root.imageUrl() +
                        '/svg-icons/react-development-icon.svg'
                      "
                    />
                  </div>
                  <div
                    class="opening-info d-flex flex-column justify-content-center"
                  >
                    <div><span class="panel-title">React Developer </span></div>
                    <div class="d-flex mt-10 flex-wrapper">
                      <div class="info-pills mr-10">
                        <span
                          >Positions :
                          <span> Beginner and Intermediate</span></span
                        >
                      </div>
                      <div class="info-pills">
                        <span>Location : <span>Vadodara</span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div (click)="openModal(Content)" class="btn-panel">
                  <a class="btn-md btn-primary" target="_top">Apply now</a>
                </div>
              </div>
            </div>
          </div>
          <div
            id="collapseSix"
            class="accordion-collapse collapse"
            aria-labelledby="headingSix"
            data-bs-parent="#accordionExample"
          >
            <div class="panel-body">
              <div class="require-info pb-10">
                <div class="require-pl pt-20 pb-20">
                  <span class="info-content font-600 font-16"
                    >Key Responsibilities</span
                  >
                </div>
                <ul>
                  <li class="color-black">
                    Hands-on experience on React Native APIs, ReactJS,
                    Javascript, ECMAScript (OOJS) and JSX
                  </li>
                  <li class="color-black">
                    Hands-on experience on iOS or Android is an added advantage
                  </li>
                  <li class="color-black">
                    Demonstrated experience with React Native and Javascript
                    best coding practices and conventions.
                  </li>
                  <li class="color-black">
                    React.js, Javascript, iOS App Development, Android
                    Development, React Native development
                  </li>
                  <li class="color-black">
                    Ability to think from the end user's perspective and focused
                    on improving the overall product experiencey
                  </li>
                  <li class="color-black">
                    Good familiarity with most of the React Native built-in and
                    popular third-party frameworks, libraries, and accelerators,
                    with in-depth expertise on most of the popular ones
                  </li>
                  <li class="color-black">
                    Experience using 3rd party APIs (Facebook, Twitter, Google
                    Maps, other REST API, etc.)
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item panel-group position-relative">
          <div class="accordion-header" id="headingTwelve">
            <div
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwelve"
              aria-expanded="false"
              aria-controls="collapseTwelve"
            >
              <div class="accordion-header d-flex justify-content-between">
                <div class="d-flex">
                  <div class="tech-icon center">
                    <img
                      [src]="
                        root.imageUrl() + '/svg-icons/content-writing-icon.svg'
                      "
                    />
                  </div>
                  <div
                    class="opening-info d-flex flex-column justify-content-center"
                  >
                    <div><span class="panel-title">Content Writer</span></div>
                    <div class="d-flex mt-10 flex-wrapper">
                      <div class="info-pills mr-10">
                        <span
                          >Positions :
                          <span> Beginner and Intermediate</span></span
                        >
                      </div>
                      <div class="info-pills">
                        <span>Location : <span>Vadodara</span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div (click)="openModal(Content)" class="btn-panel">
                  <a class="btn-md btn-primary" target="_top">Apply now</a>
                </div>
              </div>
            </div>
          </div>
          <div
            id="collapseTwelve"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwelve"
            data-bs-parent="#accordionExample"
          >
            <div class="panel-body">
              <div class="require-info pb-10">
                <div class="require-pl pt-20 pb-20">
                  <span class="info-content font-600 font-16"
                    >Key Responsibilities</span
                  >
                </div>
                <ul>
                  <li class="color-black">
                    Able to write creative and quality content.
                  </li>
                  <li class="color-black">
                    Required advanced level of English writing.
                  </li>
                  <li class="color-black">
                    He/She must understand and deliver content as per the SEO
                    requirement.
                  </li>
                  <li class="color-black">
                    Can write content for websites, articles, press releases,
                    blogs, etc.
                  </li>
                  <li class="color-black">
                    The candidate should also know how to avoid problems like
                    keyword stuffing.
                  </li>
                  <li class="color-black">
                    Should have a good creative expression with words, good
                    research skills, and fast typing speed, create or edit
                    original articles in a way that appeals to the readers,
                    having research and analytical skills.
                  </li>
                  <li class="color-black">Good Communication Skills.</li>
                  <li class="color-black">
                    Basic understanding of SEO is required, also candidates
                    having experience in writing “ad copy” or drafting the
                    campaigns will be an added advantage.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item panel-group position-relative">
          <!-- <div (click)="openModal(Content)" style="right: 160px;top: 30px; position: absolute" class="rw-btn">
                                        <a class="btn-md btn-primary position-absolute cursor-pointer" target="_top">Apply now</a>
                                    </div> -->
          <div class="accordion-header" id="headingOne">
            <div
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <div class="accordion-header d-flex justify-content-between">
                <div class="d-flex">
                  <div class="tech-icon center">
                    <img
                      [src]="
                        root.imageUrl() +
                        '/svg-icons/mobile-development-icon.svg'
                      "
                    />
                  </div>
                  <div
                    class="opening-info d-flex flex-column justify-content-center"
                  >
                    <div>
                      <span class="panel-title"
                        >Mobile Developer - React Native | Android | iOS</span
                      >
                    </div>
                    <div class="d-flex mt-10 flex-wrapper">
                      <!-- <div class="info-pills"><span>Experience : <span>3-4 years</span></span>
                                                            </div> -->
                      <div class="info-pills mr-10">
                        <span
                          >Positions :
                          <span> Beginner and Intermediate</span></span
                        >
                      </div>
                      <div class="info-pills">
                        <span>Location : <span>Vadodara</span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div (click)="openModal(Content)" class="btn-panel">
                  <a class="btn-md btn-primary" target="_top">Apply now</a>
                </div>
              </div>
            </div>
          </div>
          <div
            id="collapseOne"
            class="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="panel-body">
              <div class="require-info pb-10">
                <!-- <ul>
                                                    <li class="color-black">Hands-on experience on React Native APIs, ReactJS,
                                                        Javascript, ECMAScript (OOJS) and JSX (minimum 1.5 -2 years).</li>
                                                    <li class="color-black">Hands-on experience on iOS or Android is an added advantage
                                                        (minimum 1.5 -2 years).</li>
                                                    <li class="color-black">Complete hands-on on React Native and Javascript coding
                                                        practices and conventions.</li>
                                                    <li class="color-black">React.js, Javascript, iOS App Development, Android
                                                        Development, React Native Type.
                                                        
                                                    </li>
                                                    <li class="color-black">Use React to develop next generation web user interfaces.
                                                    </li>
                                                    <li class="color-black">Develop native mobile apps with React-Native.</li>
                                                    <li class="color-black">Ability to think from end user's perspective and focused on
                                                        improving the overall product experience.</li>
                                                    <li class="color-black">Developer at heart with strong focus to learn and implement
                                                        latest enhancements in React Native.
                                                    </li>
                                                    <li>Thorough understanding of React Native development tools like IDEs (Atom,
                                                        Sublime Text, or Visual Studio Code), Chrome debugging tools, ESLint, React
                                                        Native CLI, Watchman package manager etc.</li>
                                                    <li>Good familiarity with most of the React Native built-in and popular third party
                                                        frameworks, libraries, and accelerators. With in-depth expertise on most of the
                                                        popular ones.
                                                    </li>
                                                    <li>Excellent expose and expertise in design patterns and software design patterns.
                                                    </li>
                                                    <li>Hands on experience on Android, iOS platforms in creating Hybrid / Native
                                                        applications.</li>
                                                    <li>Experience using 3rd party APIs (Facebook, Twitter, Google Maps, other REST API
                                                        etc.).</li>
                                                    <li> Apps in the Google Play Store or Apple App Store.</li>
                                                    <li>Ability to implement designs and functionality provided by UI/UX designers.</li>
                                                    <li> Use of Management tools including Bitbucket (GIT), Jira, Bamboo.
                                                    </li>
                                                    <li>Understanding of iOS and Android Code signing process and deployment model.</li>
                                                </ul> -->
                <div class="require-pl pt-20 pb-20">
                  <span class="info-content font-600 font-16"
                    >Key Responsibilities</span
                  >
                </div>
                <ul>
                  <li class="color-black">
                    Demonstrated working experience in software development
                  </li>
                  <li class="color-black">
                    Experience on technologies - React Native / React / Redux /
                    ES6+
                  </li>
                  <li class="color-black">
                    Experience in automated testing with Jest
                  </li>
                  <li class="color-black">
                    A thorough understanding of the entire mobile development
                    process
                  </li>
                  <li class="color-black">
                    Taking responsibility for the development of new and current
                    features for React Native mobile app
                  </li>
                  <li class="color-black">
                    Building new UI components, product features, and reusable
                    systems/abstractions across the stack
                  </li>
                  <li class="color-black">
                    Collaborating with design and product teammates to help make
                    UI/UX decisions
                  </li>
                  <li class="color-black">
                    High quality and performance efficient work delivery
                  </li>
                  <li>
                    The ability to constantly research, discover, evaluate, and
                    implement new technologies in order to maximise development
                    efficiency.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item panel-group position-relative">
          <div class="accordion-header" id="headingEleven">
            <div
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEleven"
              aria-expanded="false"
              aria-controls="collapseEleven"
            >
              <div class="accordion-header d-flex justify-content-between">
                <div class="d-flex">
                  <div class="tech-icon center">
                    <img
                      [src]="
                        root.imageUrl() + '/svg-icons/ui-developer-icon.svg'
                      "
                    />
                  </div>
                  <div
                    class="opening-info d-flex flex-column justify-content-center"
                  >
                    <div><span class="panel-title">UI Developer</span></div>
                    <div class="d-flex mt-10 flex-wrapper">
                      <div class="info-pills mr-10">
                        <span
                          >Positions :
                          <span>Beginner and Intermediate</span></span
                        >
                      </div>
                      <div class="info-pills">
                        <span>Location : <span>Vadodara</span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div (click)="openModal(Content)" class="btn-panel">
                  <a class="btn-md btn-primary" target="_top">Apply now</a>
                </div>
              </div>
            </div>
          </div>
          <div
            id="collapseEleven"
            class="accordion-collapse collapse"
            aria-labelledby="headingEleven"
            data-bs-parent="#accordionExample"
          >
            <div class="panel-body">
              <div class="require-info pb-10">
                <div class="require-pl pt-20 pb-20">
                  <span class="info-content font-600 font-16"
                    >Key Responsibilities</span
                  >
                </div>
                <ul>
                  <li class="color-black">
                    A general understanding of the products and its working
                    requirements.
                  </li>
                  <li class="color-black">
                    Collaborate with the UX team to design visually appealing
                    and enhanced UI.
                  </li>
                  <li class="color-black">
                    Proficiency in HTML5, CSS, SCSS, and Bootstrap.
                  </li>
                  <li class="color-black">
                    The ability to create user-friendly, complicated UI
                    programmes and webpages.
                  </li>
                  <li class="color-black">
                    A solid understanding of JIRA, Confluence, and Gitlab.
                  </li>
                  <li class="color-black">
                    A fundamental understanding of the Angular and React
                    structural frameworks.
                  </li>
                  <li class="color-black">
                    Capability to collaborate with team members and clarify
                    their fundamental expectations.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item panel-group position-relative">
          <!-- <div (click)="openModal(Content)" style="right: 160px;top: 30px; position: absolute" class="rw-btn">
                        <a class="btn-md btn-primary position-absolute cursor-pointer" target="_top">Apply now</a>
                    </div> -->
          <div class="accordion-header" id="headingTwo">
            <div
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              <div class="accordion-header d-flex justify-content-between">
                <div class="d-flex">
                  <div class="tech-icon center">
                    <img
                      [src]="
                        root.imageUrl() + '/svg-icons/sales-marketing-icon.svg'
                      "
                    />
                  </div>
                  <div
                    class="opening-info d-flex flex-column justify-content-center"
                  >
                    <div>
                      <span class="panel-title"
                        >Manager - Sales and Marketing​
                      </span>
                    </div>
                    <div class="d-flex mt-10 flex-wrapper">
                      <!-- <div class="info-pills"><span>Experience : <span>3-4 years</span></span>
                                            </div> -->
                      <div class="info-pills mr-10">
                        <span
                          >Positions : <span> Lead and Intermediate</span></span
                        >
                      </div>
                      <div class="info-pills">
                        <span>Location : <span>Vadodara</span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div (click)="openModal(Content)" class="btn-panel">
                  <a class="btn-md btn-primary" target="_top">Apply now</a>
                </div>
              </div>
            </div>
          </div>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div class="panel-body">
              <div class="require-info pb-10">
                <div class="require-pl pt-20 pb-20">
                  <span class="info-content font-600 font-16"
                    >Key Responsibilities</span
                  >
                </div>
                <ul>
                  <li class="color-black">
                    Identify and aggressively pursue new business opportunities
                    in the software service industry.
                  </li>
                  <li class="color-black">
                    Execute a Marketing Action Plan consisting of online and
                    offline marketing tactics to help drive sales.
                  </li>
                  <li class="color-black">
                    Ability to set clear goals and create roadmaps to achieve
                    them.
                  </li>
                  <li class="color-black">
                    Build positive, on-going relationships with local and
                    international businesses. (Canada, US, India).
                  </li>
                  <li class="color-black">
                    Research, coordinate and attend Gallery Events. Interact
                    with customers to obtain feedback.
                  </li>
                  <li class="color-black">
                    Write press releases and get published.
                  </li>
                  <li class="color-black">
                    Conduct audience research and competition research to
                    improve strategy campaigns/ads.
                  </li>
                  <li class="color-black">
                    Develop engaging email campaigns, content marketing, and
                    social media strategies.
                  </li>
                  <li class="color-black">
                    Manage all companies social media channels and collaborate
                    with Graphic Designer.
                  </li>
                  <li class="color-black">
                    Excellent writing, editing, and proofreading skills.
                  </li>
                  <li class="color-black">
                    Demonstrated experience in decision-making, strategic
                    thinking, and problem-solving.
                  </li>
                  <li class="color-black">
                    Recognized for execution as well as strategic thinking.
                  </li>
                  <li class="color-black">
                    Strong knowledge and experience in digital marketing,
                    specifically, social media marketing, landing page
                    optimization and online competitor research.
                  </li>
                  <li class="color-black">
                    In-depth knowledge of Facebook and Instagram ad platform.
                  </li>
                  <li class="color-black">
                    Working Knowledge in Google Analytics.
                  </li>
                </ul>
                <div class="require-pl pt-20 pb-20">
                  <span class="info-content font-600 font-16">Experience</span>
                </div>
                <ul>
                  <li class="color-black">
                    Highly energetic, self-motivated, self-aware and innovative.
                  </li>
                  <li class="color-black">
                    Able to be self-motivated and work well independently, as
                    well as in a team.
                  </li>
                  <li class="color-black">
                    Ability to set clear goals and create roadmaps to achieve
                    them.
                  </li>
                  <li class="color-black">
                    Bachelor’s degree in marketing or similar field.
                  </li>
                  <li class="color-black">
                    3+ years of marketing experience in IT/Software Services.
                  </li>
                  <li class="color-black">
                    2+ years of social media experience.
                  </li>
                  <li class="color-black">
                    Ability to disseminate market information and create the
                    best strategic business plan.
                  </li>
                  <li class="color-black">
                    Excellent writing and communications skills.
                  </li>
                  <li class="color-black">
                    Excellent organizational and project management skills.
                  </li>
                  <li class="color-black">
                    Excellent writing, editing, and proofreading skills.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="accordion-item panel-group position-relative">
          <div class="accordion-header" id="headingEight">
            <div
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEight"
              aria-expanded="false"
              aria-controls="collapseEight"
            >
              <div class="accordion-header d-flex justify-content-between">
                <div class="d-flex">
                  <div class="tech-icon center">
                    <img [src]="root.imageUrl() + '/svg-icons/qa-icon.svg'" />
                  </div>
                  <div
                    class="opening-info d-flex flex-column justify-content-center"
                  >
                    <div><span class="panel-title">QA</span></div>
                    <div class="d-flex mt-10 flex-wrapper">
                      <div class="info-pills mr-10">
                        <span
                          >Positions :
                          <span> Beginner and Intermediate</span></span
                        >
                      </div>
                      <div class="info-pills">
                        <span>Location : <span>Vadodara</span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div (click)="openModal(Content)" class="btn-panel">
                  <a class="btn-md btn-primary" target="_top">Apply now</a>
                </div>
              </div>
            </div>
          </div>
          <div
            id="collapseEight"
            class="accordion-collapse collapse"
            aria-labelledby="headingEight"
            data-bs-parent="#accordionExample"
          >
            <div class="panel-body">
              <div class="require-info pb-10">
                <div class="require-pl pt-20 pb-20">
                  <span class="info-content font-600 font-16"
                    >Key Responsibilities</span
                  >
                </div>
                <ul>
                  <li class="color-black">
                    Demonstrated work experience in Manual and Automation
                    testing
                  </li>
                  <li class="color-black">
                    Ability to develop Test Automation Scripts and Test
                    Executions
                  </li>
                  <li class="color-black">
                    Hands-on experience in Selenium web driver and Core Java,
                    TestNG/Cucumber
                  </li>
                  <li class="color-black">
                    Expertise in Testing web services/APIs, Jenkins
                  </li>
                  <li class="color-black">
                    Familiarity with tools/utilities such as Bitbucket, Jira,
                    Confluence
                  </li>
                  <li class="color-black">
                    It is advantageous to have prior experience with tools such
                    as JMeter in performance testing such as load testing, spike
                    testing, and so on.
                  </li>
                  <li class="color-black">
                    Strong problem-solving abilities, technical troubleshooting
                    skills, and attention to detail.
                  </li>
                  <li class="color-black">
                    Willingness and flexibility to switch between multiple high
                    priority assignments
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> -->
        <div class="accordion-item panel-group position-relative">
          <div class="accordion-header" id="headingSeven">
            <div
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
            >
              <div class="accordion-header d-flex justify-content-between">
                <div class="d-flex">
                  <div class="tech-icon center">
                    <img
                      [src]="root.imageUrl() + '/svg-icons/nodejs-icon.svg'"
                    />
                  </div>
                  <div
                    class="opening-info d-flex flex-column justify-content-center"
                  >
                    <div>
                      <span class="panel-title">Node JS Developer</span>
                    </div>
                    <div class="d-flex mt-10 flex-wrapper">
                      <div class="info-pills mr-10">
                        <span
                          >Positions :
                          <span> Beginner and Intermediate</span></span
                        >
                      </div>
                      <div class="info-pills">
                        <span>Location : <span>Vadodara</span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div (click)="openModal(Content)" class="btn-panel">
                  <a class="btn-md btn-primary" target="_top">Apply now</a>
                </div>
              </div>
            </div>
          </div>
          <div
            id="collapseSeven"
            class="accordion-collapse collapse"
            aria-labelledby="headingSeven"
            data-bs-parent="#accordionExample"
          >
            <div class="panel-body">
              <div class="require-info pb-10">
                <div class="require-pl pt-20 pb-20">
                  <span class="info-content font-600 font-16"
                    >Key Responsibilities</span
                  >
                </div>
                <ul>
                  <li class="color-black">
                    Node.JS, Express framework, MySQL, Mongo DB, GraphQL, Nginx,
                    RabbitMQ, JSON, CSS
                  </li>
                  <li class="color-black">
                    AWS, Jenkins, JIRA, BitBucket, SouceTree, and Confluence
                  </li>
                  <li class="color-black">
                    Team Player and efficient communicator who can work with an
                    international team
                  </li>
                  <li class="color-black">
                    Design and develop applications following the scrum
                    methodology
                  </li>
                  <li class="color-black">
                    Review requirements and provide estimates and schedules
                  </li>
                  <li class="color-black">
                    Work in coordination with other teams (Development, QA,
                    DevOps, and Support)
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- career popup -->
<ng-template #Content let-modal class="career-popup">
  <form [formGroup]="applyNow">
    <div class="modal-header">
      <div class="mr-10">
        <span class="font-600 font-16"
          >Apply Now <span class="font-400"> </span
        ></span>
      </div>
      <button
        type="button"
        class="cancel-icon"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
    <div class="modal-body contactUsBody">
      <div class="row no-gutters">
        <div class="col-md-6">
          <div class="form-wrapper mr-10">
            <div class="form-group">
              <input
                class="form-control"
                placeholder="Name"
                formControlName="name"
              />
              <div *ngIf="applyNow.touched">
                <div
                  class="errorBlock contactErrorBlock"
                  *ngFor="let validation of getdemo_validation_messages.name"
                >
                  <span
                    *ngIf="
                      applyNow.get('name').hasError(validation.type) &&
                      (applyNow.get('name').dirty ||
                        applyNow.get('name').touched)
                    "
                    >{{ validation.message }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-wrapper">
            <div class="form-group">
              <input
                class="form-control"
                placeholder="Email"
                formControlName="email"
              />
              <div *ngIf="applyNow.touched">
                <div
                  class="errorBlock contactErrorBlock"
                  *ngFor="let validation of getdemo_validation_messages.email"
                >
                  <span
                    *ngIf="
                      applyNow.get('email').hasError(validation.type) &&
                      (applyNow.get('email').dirty ||
                        applyNow.get('email').touched)
                    "
                    >{{ validation.message }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-4">
          <div class="form-wrapper mr-10">
            <div class="form-group">
              <input
                class="form-control"
                placeholder="Phone Number"
                mask="000-000-0000"
                formControlName="phone"
              />
              <div *ngIf="applyNow.touched">
                <div
                  class="errorBlock contactErrorBlock"
                  *ngFor="let validation of getdemo_validation_messages.phone"
                >
                  <span
                    *ngIf="
                      applyNow.get('phone').hasError(validation.type) &&
                      (applyNow.get('phone').dirty ||
                        applyNow.get('phone').touched)
                    "
                    >{{ validation.message }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-wrapper mr-10">
            <div class="form-group">
              <input
                class="form-control"
                placeholder="Current Company"
                formControlName="currentCompany"
              />
              <div *ngIf="applyNow.touched">
                <div
                  class="errorBlock contactErrorBlock"
                  *ngFor="
                    let validation of getdemo_validation_messages.currentCompany
                  "
                >
                  <span
                    *ngIf="
                      applyNow
                        .get('currentCompany')
                        .hasError(validation.type) &&
                      (applyNow.get('currentCompany').dirty ||
                        applyNow.get('currentCompany').touched)
                    "
                    >{{ validation.message }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-wrapper">
            <div class="form-group">
              <input
                class="form-control"
                placeholder="Years Of Experience"
                (change)="experience()"
                formControlName="yearsOfExp"
                maxlength="2"
              />
              <div *ngIf="applyNow.touched">
                <div class="errorBlock contactErrorBlock">
                  <span
                    *ngIf="
                      applyNow.get('yearsOfExp').errors &&
                      applyNow.get('yearsOfExp').errors['pattern']
                    "
                    >Enter only numeric values</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-wrapper">
            <div class="form-group">
              <select
                (change)="departmentChange()"
                class="form-control"
                [formControl]="deptControl"
              >
                <option disabled value="null">Select Departments</option>
                <option
                  [value]="dept['department']"
                  *ngFor="let dept of departMents"
                >
                  {{ departEnum[dept["department"]] }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div
          class="col-md-4"
          *ngIf="subDepartments && subDepartments.length > 0"
        >
          <div class="form-wrapper">
            <div class="form-group">
              <select
                (change)="positionChange()"
                class="form-control"
                [formControl]="subDeptControl"
              >
                <option disabled value="null">Select Subdepartment</option>
                <option
                  [value]="sub['subDepartment']"
                  *ngFor="let sub of subDepartments"
                >
                  {{ sub["subDepartment"] }}
                </option>
              </select>
              <!-- <div *ngIf="support.touched">
                                <div class="errorBlock contactErrorBlock"
                                    *ngFor="let validation of support_validation_messages.jobRole">
                                    <span
                                        *ngIf="support.get('jobRole').hasError(validation.type) && (support.get('jobRole').dirty || support.get('jobRole').touched )">{{validation.message}}</span>
                                </div>
                            </div> -->
            </div>
          </div>
        </div>
        <div class="col-md-4" *ngIf="jobLevels && jobLevels.length > 0">
          <div class="form-wrapper">
            <div class="form-group">
              <select
                class="form-control"
                [formControl]="roleControl"
                (change)="roleChange()"
              >
                <option disabled value="null">Select Role</option>
                <option
                  [value]="level['jobRoleId']"
                  *ngFor="let level of jobLevels"
                >
                  {{ roleEnum[level["jobLevel"]] }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-12">
          <div class="form-group">
            <textarea
              class="form-control"
              cols=""
              rows="6"
              placeholder="Message"
              formControlName="description"
            ></textarea>
            <div *ngIf="applyNow.touched">
              <div
                class="errorBlock contactErrorBlock"
                *ngFor="
                  let validation of getdemo_validation_messages.description
                "
              >
                <span
                  *ngIf="
                    applyNow.get('description').hasError(validation.type) &&
                    (applyNow.get('description').dirty ||
                      applyNow.get('description').touched)
                  "
                  >{{ validation.message }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="select-file mt-20">
        <div>
          <span class="color-black">Select a Text file to upload a Resume</span>
        </div>
        <div class="mt-20">
          <div class="form-group position-relative">
            <input
              type="file"
              class="custom-file-input"
              [formControl]="fileControl"
              (change)="preview($event, imgIndex)"
            />
            <label class="custom-file-label">Upload File</label>
            <span class="color-black pl-20 pt-10 font-12 custom-file-span">
              {{
                applyNow.get("uploadDTO").get("name").value
                  ? applyNow.get("uploadDTO").get("name").value
                  : "No file choosen"
              }}</span
            >
          </div>
        </div>
      </div>
      <div class="mt-20">
        <div>
          <button
            class="btn-md btn-primary"
            target="_top"
            [disabled]="applyNow.invalid"
            (click)="careerSubmit()"
          >
            Apply NOW
          </button>
        </div>
      </div>

      <div class="error-msg-block" *ngIf="showErrorMsg">
        <div class="error-icon">
          <img [src]="root.imageUrl() + '/svg-icons/error-red-icon.svg'" />
        </div>
        <div>
          <div class="error-title">
            <span class="error-title-text"> Error </span>
          </div>
          <div class="error-msg-panel">
            <span class="error-msg-panel-text"> Error sending Message </span>
          </div>
        </div>
      </div>
      <div class="error-msg-block" *ngIf="showFileMsg">
        <div class="error-icon">
          <img [src]="root.imageUrl() + '/svg-icons/error-red-icon.svg'" />
        </div>
        <div>
          <div class="error-title">
            <span class="error-title-text"> Error </span>
          </div>
          <div class="error-msg-panel">
            <span class="error-msg-panel-text">
              File size must be smaller than 5mb.
            </span>
          </div>
        </div>
      </div>
      <div class="error-msg-block success" *ngIf="showSuccessMsg">
        <div class="error-icon">
          <img [src]="root.imageUrl() + '/svg-icons/success-check-icon.svg'" />
        </div>
        <div>
          <div class="error-title">
            <span class="error-title-text"> Success </span>
          </div>
          <div class="error-msg-panel">
            <span class="error-msg-panel-text">
              Thank you. We will get back your request.
            </span>
          </div>
        </div>
      </div>
    </div>
  </form>
  <app-loader *ngIf="isLoading"></app-loader>
</ng-template>
