import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OurProductsRoutingModule } from './our-products-routing.module';
import { OurProductComponent } from './pages/our-product/our-product.component';
import { ProductBannerComponent } from './components/product-banner/product-banner.component';
import { ProductIntroComponent } from './components/product-intro/product-intro.component';
import { ProductListComponent } from './components/product-list/product-list.component';
import { ProductFooterComponent } from './components/product-footer/product-footer.component';
import { ProductTechnologyComponent } from './components/product-technology/product-technology.component';
import { ProductFeatureComponent } from './components/product-feature/product-feature.component';
import { ProductVegitoneComponent } from './components/product-vegitone/product-vegitone.component';
import { EkvegitComponent } from './pages/ekvegit/ekvegit.component';
import { OnevizComponent } from './pages/oneviz/oneviz.component';
import { GtrackComponent } from './pages/gtrack/gtrack.component';
import { EducationClassesComponent } from './pages/education-classes/education-classes.component';
import { AmityComponent } from './pages/amity/amity.component';
// import { ProductTechComponent } from './components/oneviz-detail/product-tech/product-tech.component';
import { ProductOnevizComponent } from './components/product-oneviz/product-oneviz.component';
import { OnvevizIntroComponent } from './components/onveviz-intro/onveviz-intro.component';
import { OnvevizFeatureComponent } from './components/onveviz-feature/onveviz-feature.component';
import { GtrackIntroComponent } from './components/gtrack-intro/gtrack-intro.component';
import { GtrackFeatureComponent } from './components/gtrack-feature/gtrack-feature.component';
import { ProductGtrackComponent } from './components/product-gtrack/product-gtrack.component';
import { GtrackProductTechnologyComponent } from './components/gtrack-product-technology/gtrack-product-technology.component';
import { OnevizProductTechnologyComponent } from './components/oneviz-product-technology/oneviz-product-technology.component';



@NgModule({
  declarations: [OurProductComponent, ProductBannerComponent, ProductIntroComponent, ProductListComponent, ProductFooterComponent, ProductTechnologyComponent, ProductFeatureComponent, ProductVegitoneComponent, EkvegitComponent, OnevizComponent, GtrackComponent, EducationClassesComponent, AmityComponent, ProductOnevizComponent, OnvevizIntroComponent, OnvevizFeatureComponent, GtrackIntroComponent, GtrackFeatureComponent, ProductGtrackComponent, GtrackProductTechnologyComponent, OnevizProductTechnologyComponent],
  imports: [
    CommonModule,
    OurProductsRoutingModule
  ]
})
export class OurProductsModule { }
