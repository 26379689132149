import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'app-ess-services',
  templateUrl: './ess-services.component.html',
  styleUrls: ['./ess-services.component.scss']
})
export class EssServicesComponent implements OnInit {

  constructor(public root: RootService) { }


  ngOnInit(): void {
  }

}
