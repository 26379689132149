import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'software-consulting-service',
  templateUrl: './software-consulting-service.component.html',
  styleUrls: ['./software-consulting-service.component.scss']
})
export class SoftwareConsultingServiceComponent implements OnInit {

  newTitle: string = 'Software Consulting Services | ekZero';
  longDescription: string = 'Software consulting services from a leading and experienced software development company | ekZero Corporation';
  longKeyword: string = 'ekZero, Software Consulting, Development, and Testing, Maintenance and Support, Deployment, Conceptualization, Design, and Architecture, Prototyping, Best IT consulting in Canada';

  constructor(private title: Title,
    private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle(this.newTitle);
    this.meta.addTag({ name: "description", content: this.longDescription });
    this.meta.addTag({ name: "keywords", content: this.longKeyword });
  }

}
