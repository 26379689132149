import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/layout/header/header.component';
import { FooterComponent } from './shared/layout/footer/footer.component';
import { RootComponent } from './component/root/root.component';
import { HomeModule } from './modules/home/home.module';
import { EcsModule } from './modules/ecs/ecs.module';
import { ProductsModule } from './modules/products/products.module';
import { ContactUsModule } from './modules/contact-us/contact-us.module';
import { AboutUsModule } from './modules/about-us/about-us.module';
import { OurServicesModule } from './modules/our-services/our-services.module';
import { PageNotFoundModule } from './modules/page-not-found/page-not-found.module';
import { BlogsModule } from './modules/blogs/blogs.module';
import { PrivacyPolicyModule } from './modules/privacy-policy/privacy-policy.module';
import { OurProductsModule } from './modules/our-products/our-products.module';
import { FaqModule } from './modules/faq/faq.module';
import { APP_BASE_HREF } from '@angular/common';
import { environment } from '../environments/environment';
import { SharedModule } from './shared/shared.module';
import { CareersModule } from './modules/careers/careers.module';
import { AccordionModule } from 'ngx-accordion';
import { LifeModule } from './modules/life/life.module';
import { IncubationModule } from './modules/incubation/incubation.module';
import { ResearchDevelopmentModule } from './modules/research-development/research-development.module'
import { NgwWowModule } from 'ngx-wow';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    // HeaderComponent,
    // FooterComponent,
    RootComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HomeModule,
    EcsModule,
    ProductsModule,
    ContactUsModule,
    AboutUsModule,
    OurServicesModule,
    PageNotFoundModule,
    ContactUsModule,
    BlogsModule,
    PrivacyPolicyModule,
    OurProductsModule,
    FaqModule,
    SharedModule,
    NgbModule,
    CareersModule,
    AccordionModule,
    LifeModule,
    IncubationModule,
    ResearchDevelopmentModule,
    NgwWowModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
