<div id="service-detail-intro">
    <div class="our-services">
        <!-- -----------Enterprise Software Services---------------start -->
        <div class="our-services-wrapper">
            <div class="blue-bg position-relative">
                <div class="row">
                    <div class="col-12">
                        <div class="text-align-right">
                            <div class="pl-10 width-50 mb-50">
                                <div class="mb-30">
                                    <h2 class="font-600">Enterprise Software Services</h2>
                                </div>
                                <div>
                                    <p class="font-14">Leverage our 8-year expertise in enterprise software development,
                                        UI/UX designing, API integrations, Process testing, Maintenance and Data Management.
                                        ekZero helps large organizations to achieve success in the global arena by providing
                                        end-to-end IT development and support services.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 col-order-2">
                        <div class="justify-content-sb h-100">
                            <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                                <div class="logo-block">
                                    <img [src]="root.imageUrl() + '/svg-icons/ews.svg'" class="wifi-logo">
                                    <!-- <img src="assets/images/svg-icon/ews.svg" class="wifi-logo"> -->
                                </div>
                                <div>
                                    <div class="mb-10">
                                        <h5 class="font-black">Enterprise Web Solutions</h5>
                                    </div>
                                    <div><span class="font-14 font-black">Get complete web application or web services
                                            development regardless of how simple or complex the solution is.
                                        </span></div>
                                </div>
                            </div>
                            <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                                <div class="logo-block">
                                    <img [src]="root.imageUrl() + '/svg-icons/pa.svg'" class="wifi-logo">
                                    <!-- <img src="assets/images/svg-icon/pa.svg" class="wifi-logo"> -->
    
                                </div>
                                <div>
                                    <div class="mb-10">
                                        <h5 class="font-black">Process Automation
                                        </h5>
                                    </div>
                                    <div><span class="font-14 font-black">Make continual advancement and value addition to
                                            your existing product at regular intervals to retain competitive edge in market.
                                        </span></div>
                                </div>
                            </div>
                            <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                                <div class="logo-block">
                                    <img [src]="root.imageUrl() + '/svg-icons/st.svg'" class="wifi-logo">
                                    <!-- <img src="assets/images/svg-icon/st.svg" class="wifi-logo"> -->
                                </div>
                                <div>
                                    <div class="mb-10">
                                        <h5 class="font-black">Software Testing
                                        </h5>
                                    </div>
                                    <div><span class="font-14 font-black">Implement the best in class testing methodologies
                                            to improve your application time to market and ROI.
                                        </span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-12 col-lg-4 col-order-1">
                        <div class="center h-100">
                            <img [src]="root.imageUrl() + '/ez-services-enterprise-software-services.png'"
                                class="center-img" data-aos="flip-left" data-aos-duration="1500">
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 col-order-2 ">
                        <div class="justify-content-sb h-100 text-align">
                            <div class="d-flex mb-30 " data-aos="fade-up" data-aos-duration="1500">
                                <div class="change-order">
                                    <div class="mb-10">
                                        <h5>Enterprise Resource Planning (ERP)</h5>
                                    </div>
                                    <div><span class="font-14">Transform your complicated business management systems into
                                            user-friendly ERP solutions through cross-functional methodology.
                                        </span></div>
                                </div>
                                <div class="logo-block right-block">
                                    <img [src]="root.imageUrl() + '/svg-icons/erp.svg'" class="wifi-logo">
                                    <!-- <img src="assets/images/svg-icon/erp.svg" class="wifi-logo"> -->
    
                                </div>
                            </div>
                            <div class="d-flex mb-30 " data-aos="fade-up" data-aos-duration="1500">
                                <div class="change-order">
                                    <div class="mb-10">
                                        <h5>Solution Consultation</h5>
                                    </div>
                                    <div><span class="font-14">Bridge the gap within your product and client’s existing
                                            infrastructure with tailor-made solutions that seamlessly fit in between.
                                        </span>
                                    </div>
                                </div>
                                <div class="logo-block right-block">
                                    <img [src]="root.imageUrl() + '/svg-icons/sc.svg'" class="wifi-logo">
                                    <!-- <img src="assets/images/svg-icon/sc.svg" class="wifi-logo"> -->
                                </div>
                            </div>
                            <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                                <div class="change-order">
                                    <div class="mb-10">
                                        <h5>Product Enhancement
                                        </h5>
                                    </div>
                                    <div><span class="font-14">Make continual advancement and value addition to your
                                            existing product at regular intervals to retain competitive edge in market.
                                        </span></div>
                                </div>
                                <div class="logo-block right-block">
                                    <img [src]="root.imageUrl() + '/svg-icons/pe.svg'" class="wifi-logo">
                                    <!-- <img src="assets/images/svg-icon/pe.svg" class="wifi-logo"> -->
    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="dots">
                    <div class="point bg-white"></div>
                    <div class="point bg-white"></div>
                    <div class="point bg-white"></div>
                </div> -->
            </div>
        </div>
        <!-- -----------Enterprise Software Services---------------end -->
    </div>
    <div class="detail-intro" data-aos="fade-up" data-aos-duration="1500">
        <div class="row">
            <div class="col-lg-6">
                <div class="left-content">
                    <div class="info-content mb-30"><span class="color-orange font-">Making Enterprise Softwares That
                            Enable Business Growth, Improve Employee Productivity & Business Operations. </span></div>
                    <div class="detail-info">
                        <p class="color-black content-text">Launching top-tier software requires strong technical
                            expertise and well-organized processes. This is what ekZero corporation provides at the core
                            to its customers. From hiring the tech talent to completing the project within budgets, we
                            are here to empower your enterprise. ekZero builds and re-engineers solutions to cover the
                            needs of large organizations. Leverage our 8 year expertise in enterprise software
                            development, UI/UX design, API integrations, Process testing, Maintenance, and Data
                            Management. Scale up, go digital and get the most out of your business with enterprise
                            software services.
                        </p>
                    </div>
                    <div class="btn-grp"><a class="btn-primary btn-xlg" [routerLink]="root.contact()"
                            target="_top">Connect with ekZero</a></div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="right-content">
                    <img [src]="root.imageUrl() + '/service-ecs-img.png'">
                </div>
            </div>
        </div>
    </div>
</div>