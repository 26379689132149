<div id="explore-services">
    <div class="explore-block">
        <div class="row">
            <div class="col-xl-8 col-lg-order-2">
                <div class="left-content">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="mb-30">
                                <div><img class="service-img" [src]="root.imageUrl() + '/svg-icons/ews.svg'">
                                </div>
                                <div class="mt-15"><span class="color-black service-title font-600">Enterprise Web
                                        Solutions
                                    </span></div>
                                <div class="mt-15"><span class="color-black content-text">Enterprise web development is
                                        a collaborative ecosystem where IT works to build applications that align the
                                        internal business teams as well as customers. The experts at ekZero gear you
                                        towards a digital transformation and helps in meeting the modern needs of
                                        business in this digital world. We provide end-to-end solutions which are
                                        scalable and process-oriented that include assisting the organization through
                                        the entire application lifecycle, viz. market research, UI/UX design,
                                        development, testing, deployment, support, maintenance, and enhancements.
                                    </span></div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-30">
                                <div><img class="service-img" [src]="root.imageUrl() + '/svg-icons/pa.svg'">
                                </div>
                                <div class="mt-15"><span class="color-black service-title font-600">Process
                                        Automation</span></div>
                                <div class="mt-15"><span class="color-black content-text">In this fast-paced
                                        environment, it is important to automate repetitive, time-consuming, and manual
                                        tasks, in order to boost productivity, efficiency, resource optimization, and IT
                                        cost reduction. With decade-long experience in the IT domain, ekZero has helped
                                        many organizations with batch process automation, big data automation, digital
                                        process automation, workload automation, infrastructure, and operations
                                        automation. We always focus towards to get the most out of your IT
                                        infrastructure by ensuring that machines are never left to idle and save IT
                                        personnel from having to manually perform routine tasks.
                                    </span></div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="mb-30">
                                <div><img class="service-img" [src]="root.imageUrl() + '/svg-icons/pe.svg'">
                                </div>
                                <div class="mt-15"><span class="color-black service-title font-600">Product Enhancements
                                    </span></div>
                                <div class="mt-15"><span class="color-black content-text">The transformation is an
                                        ongoing process. The rapidly fast-changing industry comes with the need for
                                        organizations to continue integrating services that improve the current
                                        technology environment, software products, and operations. ekZero excels in
                                        providing post-production enhancements services followed by world-class software
                                        testing and support. Our expert will ramp up your existing software products
                                        into something which is more feature-packed and follows modern trends. By
                                        performing extensive market research and analysis, ekZero will let your products
                                        function to their full potential.
                                    </span></div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-30">
                                <div><img class="service-img" [src]="root.imageUrl() + '/svg-icons/st.svg'">
                                </div>
                                <div class="mt-15"><span class="color-black service-title font-600">Software Testing
                                    </span></div>
                                <div class="mt-15"><span class="color-black content-text">A testing infrastructure is
                                        equipped with activities like QA strategy design and QA process improvements as
                                        well as integration, usability testing, security, and compliance testing. ekZero
                                        audits your software from different angles that include code quality,
                                        performance, and functionality. Depending on the nature of any organization’s
                                        requirement ekZero performs independent third-party testing and executes any
                                        required testing type of their combination. Our experts will help you in
                                        identifying the root cause of your quality problems and address them through an
                                        optimal QA strategy for you.
                                    </span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-order-1">
                <div class="right-content">
                    <div class="right-bg">
                        <div class="mt-20 info-content"><span>Our Enterprise Software Offerings
                            </span></div>
                        <div class="mt-20"><span class="content-text">ekZero provides customers with innovation-led
                                solutions and modern enhancement to your business.</span></div>
                        <div><a class="btn-news btn-md" [routerLink]="root.ourServices()" target="_top">VIEW ALL</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>