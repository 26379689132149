import { NgModule } from '@angular/core';
import { NgbDropdownModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { RouterModule } from '@angular/router';
import { SalesComponent } from './modules/sales/sales.component';
import { CaseStudyComponent } from './modules/case-study/case-study.component';
import { TestimonialsComponent } from './modules/testimonials/testimonials.component';
import { LoaderComponent } from './layout/loader/loader.component';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



@NgModule({
  declarations: [FooterComponent, HeaderComponent, SalesComponent, CaseStudyComponent, TestimonialsComponent, LoaderComponent],
  imports: [
    CommonModule,
    NgbDropdownModule,
    NgbCollapseModule,
    RouterModule,
    // BrowserAnimationsModule
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    SalesComponent,
    CaseStudyComponent,
    TestimonialsComponent,
    LoaderComponent
  ]
})
export class SharedModule { }

