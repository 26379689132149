import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'career-home',
  templateUrl: './career-home.component.html',
  styleUrls: ['./career-home.component.scss']
})
export class CareerHomeComponent implements OnInit {

  newTitle: string = 'Let’s grow together | Careers | ekZero Corporation';
  longDescription: string = 'Grow your IT career by becoming a part of our tech family. Apply and join ekZero Corporation.';
  longKeyword: string = 'ekZero, Careers ekZero, Job openings, IT jobs, Current openings, Join ekZero, Hiring, Current job openings, IT company, Information Technology';


  constructor(private title: Title,
    private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle(this.newTitle);
    this.meta.addTag({ name: "description", content: this.longDescription });
    this.meta.addTag({ name: "keywords", content: this.longKeyword });

  }

}
