<div id="ekzero-intro">
    <div class="intro-wrapper">
        <div class="row no-gutters align-items-center">
            <div class="col-lg-5 col-md-order2">
                <div class="left-panel">
                    <div class="our-story-img" data-aos="zoom-in" data-aos-duration="1500"></div>
                </div>
            </div>
            <div class="col-lg-7 col-md-order1" data-aos="fade-right" data-aos-duration="1500">
                <div class="right-panel">
                    <div class="intro-section">
                        <div class="mb-20"><span class="why-text">Why ekZero </span></div>
                        <div><span class="content-text">At ekZero, your goals become ours. We envision your success by
                                becoming part of your organization’s family.
                            </span></div>
                        <div><span class="content-text">Our mission goes beyond providing technology solutions. We help our clients achieve the highest level of operational excellence so you can focus on
                                what matters - your core business.

                            </span></div>
                        <div><span class="content-text">Through our comprehensive range of products and services, we
                                ensure our customers and clients stay ahead in the ever-changing technology landscape
                                and maximize their overall success.

                            </span></div>

                        <!-- <div><span class="content-text">While ekZero takes pride in serving their clients with our
                                comprehensive range of services, ekZero Corporation has its root deep into technology
                                space with multiple initiatives. ekZero works with scholar researchers and young
                                entrepreneurs to introduce great inventions to public and enterprise use.
                            </span></div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>