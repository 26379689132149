import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'mobile-app-service',
  templateUrl: './mobile-app-service.component.html',
  styleUrls: ['./mobile-app-service.component.scss']
})
export class MobileAppServiceComponent implements OnInit {

  newTitle: string = 'Mobile applications for modern businesses | ekZero';
  longDescription: string = 'Business Apps and solutions that directly fit with the market needs and empowers companies | ekZero.';
  longKeyword: string = 'ekZero, Mobile application development, mobile apps, application development, business apps, app services, mobile app services, app services ekZero, Wearable, and Embedded App Development, Native Mobile App Development, Hybrid Mobile App Development, IoT App Development, Progressive Web App Development';

  constructor(private title: Title,
    private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle(this.newTitle);
    this.meta.addTag({ name: "description", content: this.longDescription });
    this.meta.addTag({ name: "keywords", content: this.longKeyword });

  }

}
