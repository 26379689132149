import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'research-development-technology',
  templateUrl: './research-development-technology.component.html',
  styleUrls: ['./research-development-technology.component.scss']
})
export class ResearchDevelopmentTechnologyComponent implements OnInit {
  block1: boolean = true;
  block2: boolean = false;
  block3: boolean = false;
  block4: boolean = false;
  block5: boolean = false;
  block6: boolean = false;

  constructor(public root: RootService) { }

  ngOnInit(): void {
  }
  
  onBlock1() {
    this.block1 = true;
    this.block2 = false;
    this.block3 = false;
    this.block4 = false;
    this.block5 = false;
    this.block6 = false;
  }
  onBlock2() {
    this.block1 = false;
    this.block2 = true;
    this.block3 = false;
    this.block4 = false;
    this.block5 = false;
    this.block6 = false;
  }
  onBlock3() {
    this.block1 = false;
    this.block2 = false;
    this.block3 = true;
    this.block4 = false;
    this.block5 = false;
    this.block6 = false;
  }
  onBlock4() {
    this.block1 = false;
    this.block2 = false;
    this.block3 = false;
    this.block4 = true;
    this.block5 = false;
    this.block6 = false;
  }
  onBlock5() {
    this.block1 = false;
    this.block2 = false;
    this.block3 = false;
    this.block4 = false;
    this.block5 = true;
    this.block6 = false;
  }
  onBlock6() {
    this.block1 = false;
    this.block2 = false;
    this.block3 = false;
    this.block4 = false;
    this.block5 = false;
    this.block6 = true;
  }
  OnArrow1(){
    this.block1 = false;
    this.block2 = true;
    this.block3 = false;
    this.block4 = false;
    this.block5 = false;
    this.block6 = false;

  }
}
