import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResearchDevelopmentComponent } from './pages/research-development/research-development.component';

const routes: Routes = [  {
  path: '',
  redirectTo: 'research-development',
  pathMatch: 'full'
},
{
  path: '',
  component: ResearchDevelopmentComponent,
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ResearchDevelopmentRoutingModule { }
