<div id="blog-list">
    <div class="blog-wrapper">
        <div class="row no-gutters">
            <div class="col-xl-8 col-lg-7 col-12 col-md-order">
                <div class="blog-list-wrapper">
                    <div class="row no-gutters blog-row">
                        <div class="col-xl-6 blog-col">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <img [src]="root.imageUrl() + '/what-is-cloud-computing-image.jpg' "
                                        alt="cloud computing">
                                </div>
                                <div class="blog-body">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <div><span class="color-orange">Cloud Computing</span></div>
                                        <div class="ml-15"><span class="pipe color-black">|</span></div>
                                        <div class="ml-15"><span class="color-black"> Oct 31, 2022</span></div>
                                    </div>
                                    <div class="mt-20">
                                        <div class="blog-heading"><span class="color-black font-600 blog-title">
                                                What is Cloud Computing? How it helps your business gain a competitive
                                                advantage.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="blog-footer">
                                    <a class="btn-md btn-primary cursor-pointer"
                                        [routerLink]="root.whatiscloudcomputing()" target="_top">Read more</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 blog-col">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <img [src]="root.imageUrl() + '/essential-mobile-app-design-image.jpg' "
                                        alt=" Evolution of web">
                                </div>
                                <div class="blog-body">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <div><span class="color-orange"> Technology</span></div>
                                        <div class="ml-15"><span class="pipe color-black">|</span></div>
                                        <div class="ml-15"><span class="color-black"> Sept 16, 2022</span></div>
                                    </div>
                                    <div class="mt-20">
                                        <div class="blog-heading"><span class="color-black font-600 blog-title">
                                                6 Essential Mobile App Design Steps to Take in 2022 </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="blog-footer">
                                    <a class="btn-md btn-primary cursor-pointer" [routerLink]="root.mobileEssential()"
                                        target="_top">Read more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row no-gutters blog-row">
                        <div class="col-xl-6 blog-col">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <img [src]="root.imageUrl() + '/next-phase-internet.jpg' " alt=" Evolution of web">
                                </div>
                                <div class="blog-body">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <div><span class="color-orange"> Technology</span></div>
                                        <div class="ml-15"><span class="pipe color-black">|</span></div>
                                        <div class="ml-15"><span class="color-black"> July 11, 2022</span></div>
                                    </div>
                                    <div class="mt-20">
                                        <div class="blog-heading"><span class="color-black font-600 blog-title">
                                                Evolution of Web 3.0. The next phase of the Internet!</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="blog-footer">
                                    <a class="btn-md btn-primary cursor-pointer"
                                        [routerLink]="root.nextPhaseOfInternet()" target="_top">Read more</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 blog-col">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <img [src]="root.imageUrl() + '/software-consulting.jpg' ">
                                </div>
                                <div class="blog-body">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <div><span class="color-orange">Enterprise Software Solutions</span></div>
                                        <div class="ml-15"><span class="pipe color-black">|</span></div>
                                        <div class="ml-15"><span class="color-black">March 23, 2022</span></div>
                                    </div>
                                    <div class="mt-20">
                                        <div class="blog-heading"><span class="color-black font-600 blog-title"> Custom
                                                Software Consulting: Should your startup go for
                                                it? </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="blog-footer">
                                    <a class="btn-md btn-primary cursor-pointer" [routerLink]="root.customSoftware()"
                                        target="_top">Read more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row no-gutters blog-row">
                        <div class="col-xl-6 blog-col">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <img [src]="root.imageUrl() + '/cloud-migration.jpg' ">
                                </div>
                                <div class="blog-body">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <div><span class="color-orange">Cloud Computing</span></div>
                                        <div class="ml-15"><span class="pipe color-black">|</span></div>
                                        <div class="ml-15"><span class="color-black">Feb 22, 2022</span></div>
                                    </div>
                                    <div class="mt-20">
                                        <div class="blog-heading"><span class="color-black font-600 blog-title">How do
                                                you overcome obstacles in Cloud Migration? Simple solutions.</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="blog-footer">
                                    <a class="btn-md btn-primary cursor-pointer" [routerLink]="root.cloudMigration()"
                                        target="_top">Read more</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 blog-col">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <img [src]="root.imageUrl() + '/ensuring-safety-in-trucking-industry-img.png' ">
                                </div>
                                <div class="blog-body">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <div><span class="color-orange">Trucking Industry</span></div>
                                        <div class="ml-15"><span class="pipe color-black">|</span></div>
                                        <div class="ml-15"><span class="color-black">Mar 23, 2020</span></div>
                                    </div>
                                    <div class="mt-20">
                                        <div class="blog-heading"><span class="color-black font-600 blog-title">Ensuring
                                                Safety in the Trucking Industry. Regulations, Technology, and Advantages
                                                to business owners.</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="blog-footer">
                                    <a class="btn-md btn-primary cursor-pointer" [routerLink]="root.truckingindustry()"
                                        target="_top">Read more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row no-gutters blog-row">
                        <div class="col-xl-6 blog-col">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <img [src]="root.imageUrl() + '/loss-of-truck-driver-in-covid-pandemic.jpg' ">
                                </div>
                                <div class="blog-body">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <div><span class="color-orange">Truck Drivers</span></div>
                                        <div class="ml-15"><span class="pipe color-black">|</span></div>
                                        <div class="ml-15"><span class="color-black">June 05, 2020</span></div>
                                    </div>
                                    <div class="mt-20">
                                        <div class="blog-heading"><span class="color-black font-600 blog-title">Loss to
                                                Truck Drivers in Covid-19 Pandemic</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="blog-footer">
                                    <a class="btn-md btn-primary cursor-pointer" [routerLink]="root.losttruckdriver()"
                                        target="_top">Read more</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 blog-col">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <img [src]="root.imageUrl() + '/evolution-of-gps-main-img.jpg' ">
                                </div>
                                <div class="blog-body">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <div><span class="color-orange">GPS</span></div>
                                        <div class="ml-15"><span class="pipe color-black">|</span></div>
                                        <div class="ml-15"><span class="color-black">Aug 15, 2020</span></div>
                                    </div>
                                    <div class="mt-20">
                                        <div class="blog-heading"><span
                                                class="color-black font-600 blog-title">Evolution of GPS Technology in
                                                fleet operations</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="blog-footer">
                                    <a class="btn-md btn-primary cursor-pointer" [routerLink]="root.fleetoperations()"
                                        target="_top">Read more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row no-gutters blog-row">
                        <div class="col-xl-6 blog-col">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <img [src]="root.imageUrl() + '/selfdriving-blog-main-img.jpg' ">
                                </div>
                                <div class="blog-body">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <div><span class="color-orange">Trucking</span></div>
                                        <div class="ml-15"><span class="pipe color-black">|</span></div>
                                        <div class="ml-15"><span class="color-black">Oct 09, 2020</span></div>
                                    </div>
                                    <div class="mt-20">
                                        <div class="blog-heading"><span class="color-black font-600 blog-title">Will The
                                                Self-Driving Trucks Transform The Trucking And Logistic Industry?</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="blog-footer">
                                    <a class="btn-md btn-primary cursor-pointer" [routerLink]="root.logisticindustry()"
                                        target="_top">Read more</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 blog-col">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <img [src]="root.imageUrl() + '/skyrocket-your- association-img.png'">
                                </div>
                                <div class="blog-body">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <div><span class="color-orange">Employee</span></div>
                                        <div class="ml-15"><span class="pipe color-black">|</span></div>
                                        <div class="ml-15"><span class="color-black">Dec 25, 2020</span></div>
                                    </div>
                                    <div class="mt-20">
                                        <div class="blog-heading"><span
                                                class="color-black font-600 blog-title">Skyrocket Your Association
                                                Revenue By Employing Efficient Ways</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="blog-footer">
                                    <a class="btn-md btn-primary cursor-pointer" [routerLink]="root.efficientways()"
                                        target="_top">Read more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row no-gutters blog-row">
                        <div class="col-xl-6 blog-col">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <img [src]="root.imageUrl() + '/create-member-centric-culture-img.png'">
                                </div>
                                <div class="blog-body">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <div><span class="color-orange">Employee</span></div>
                                        <div class="ml-15"><span class="pipe color-black">|</span></div>
                                        <div class="ml-15"><span class="color-black">Jan 20, 2021</span></div>
                                    </div>
                                    <div class="mt-20">
                                        <div class="blog-heading"><span class="color-black font-600 blog-title">Create
                                                Member-Centric Culture for Your Association Employing AMS Simple
                                                Measures</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="blog-footer">
                                    <a class="btn-md btn-primary cursor-pointer" [routerLink]="root.membercentric()"
                                        target="_top">Read more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-5 col-12">
                <div class="right-content-panel">
                    <div class="content-body d-none">
                        <div class="search-block mb-20">
                            <div><span class="color-black font-18">Search our <span
                                        class="color-orange transform-capitalize font-18">Blogs</span></span></div>
                            <div class="mt-20">
                                <div class="search-bar">
                                    <input type="text" class="form-control search-input" placeholder="Search...">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-body">
                        <div class="mb-20">
                            <div><span class="color-black font-18">Recent <span
                                        class="color-orange transform-capitalize font-18">Posts</span></span></div>
                            <div class="mt-20">
                                <div class="collapsable-wrapper position-relative"
                                    [ngClass]="{'collapsable-active': isDisplay}">
                                    <div
                                        class="collapsable-header responsive-view align-items-center justify-content-between mt-30">
                                        <div><span class="color-black">Check Our Recent Posts</span></div>
                                        <div><a class="collapsable-icon" (click)="isDisplay = !isDisplay"></a></div>
                                    </div>
                                    <div class="blog-title-list blog-list collapsable-body -ms-scrollbar scrollable-body"
                                        [ngbCollapse]="!isDisplay">
                                        <div class="blog-title active"><a [routerLink]="root.whatiscloudcomputing()"
                                                target="_top">What is Cloud Computing? How it helps your business
                                                gain a competitive advantage.</a>
                                        </div>
                                        <div class="blog-title active"><a [routerLink]="root.mobileEssential()"
                                                target="_top">6 Essential Mobile App Design Steps to Take in 2022</a>
                                        </div>
                                        <div class="blog-title active"><a [routerLink]="root.nextPhaseOfInternet()"
                                                target="_top"> Evolution of Web 3.0. The next phase of the Internet!</a>
                                        </div>
                                        <div class="blog-title active"><a [routerLink]="root.customSoftware()"
                                                target="_top"> Custom Software Consulting: Should your startup go for
                                                it? </a></div>
                                        <div class="blog-title active"><a [routerLink]="root.cloudMigration()"
                                                target="_top">How do you overcome obstacles in Cloud Migration? Simple
                                                solutions.</a></div>
                                        <div class="blog-title"><a [routerLink]="root.truckingindustry()"
                                                target="_top">Ensuring Safety in the Trucking Industry. Regulations,
                                                Technology, and Advantages to business owners.</a></div>
                                        <div class="blog-title"><a [routerLink]="root.losttruckdriver()"
                                                target="_top">Loss to Truck Drivers in Covid-19 Pandemic.</a></div>
                                        <div class="blog-title"><a [routerLink]="root.fleetoperations()"
                                                target="_top">Evolution of GPS Technology in fleet operations.</a></div>
                                        <div class="blog-title"><a [routerLink]="root.logisticindustry()"
                                                target="_top">Will The Self-Driving Trucks Transform The Trucking And
                                                Logistic Industry?</a></div>
                                        <div class="blog-title"><a [routerLink]="root.efficientways()"
                                                target="_top">Skyrocket Your Association Revenue By Employing Efficient
                                                Ways.</a></div>
                                        <div class="blog-title"><a [routerLink]="root.membercentric()"
                                                target="_top">Create Member-Centric Culture for Your Association
                                                Employing AMS Simple Measures.</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-body">
                        <div class="mb-20">
                            <div><span class="color-black font-18">Categories </span></div>
                            <div class="mt-20">
                                <div class="collapsable-wrapper position-relative"
                                    [ngClass]="{'collapsable-active': isShow}">
                                    <div
                                        class="collapsable-header responsive-view align-items-center justify-content-between mt-30">
                                        <div><span class="color-black">Select Categories</span></div>
                                        <div><a class="collapsable-icon" (click)="isShow = !isShow"></a></div>
                                    </div>
                                    <div class="blog-category-list blog-list collapsable-body mt-20 -ms-scrollbar scrollable-body"
                                        [ngbCollapse]="!isShow">
                                        <div class="d-flex align-items-center flex-wrap">
                                            <div class="tag-item"><a [routerLink]="root.nextPhaseOfInternet()"
                                                    target="_top"> Technology</a></div>
                                            <div class="tag-item"><a [routerLink]="root.customSoftware()"
                                                    target="_top">Enterprise Software Solutions </a></div>
                                            <div class="tag-item"><a [routerLink]="root.cloudMigration()"
                                                    target="_top">Cloud Computing</a></div>
                                            <div class="tag-item"><a [routerLink]="root.truckingindustry()"
                                                    target="_top">Trucking Industry</a></div>
                                            <div class="tag-item"><a [routerLink]="root.losttruckdriver()"
                                                    target="_top">Truck Drivers</a></div>
                                            <div class="tag-item"><a [routerLink]="root.fleetoperations()"
                                                    target="_top">GPS</a></div>
                                            <div class="tag-item"><a [routerLink]="root.logisticindustry()"
                                                    target="_top">Trucking</a></div>
                                            <div class="tag-item"><a [routerLink]="root.efficientways()"
                                                    target="_top">Employee</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>