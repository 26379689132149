import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';

import { ProductsRoutingModule } from './products-routing.module';
import { ListProductsComponent } from './pages/list-products/list-products.component';
import { EkvegitComponent } from './components/ekvegit/ekvegit.component';
import { ProductsBannerComponent } from './components/products-banner/products-banner.component';


@NgModule({
  declarations: [ListProductsComponent, EkvegitComponent, ProductsBannerComponent],
  imports: [
    CommonModule,
    ProductsRoutingModule,
    RouterModule,
    SharedModule
  ]
})
export class ProductsModule { }
