<div class="page-banner">
    <div class="ez-menu" id="page-not-found-menu">
        <div class="menu-header" [ngClass]="{'active': isShow}">
            <div class="nav-bar-block">
                <div class="d-flex justify-content-between">
                    <div class="logo-block">
                        <a [routerLink]="root.home()"><img [src]="root.imageUrl() + '/ekzero-logo.png '"></a>
                    </div>
                    <div class="menubar-block">
                        <!-- <a (click)="isShow = !isShow"><img
                                [src]="root.imageUrl() + '/svg-icons/menu-bar-orange-icon.svg'"></a> -->
                        <a class="menu-icon" (click)="isShow =! isShow">
                            <!-- <img [src]="root.imageUrl() + '/svg-icons/menu-bar-icon.svg'"> -->
                            <div class="menu-line line1"></div>
                            <div class="menu-line line2"></div>
                            <div class="menu-line line3"></div>
                        </a>
                    </div>
                </div>
            </div>

            <!-- header-menu | ends -->
            <div>
                <div class="show-menu">
                    <div class="menu-content scrollable-body -ms-scrollbar">
                        <div class="d-flex">
                            <div class="left-menu"
                                [ngClass]="{'open-menu': isOpen || isOpenecs || isOpenAboutus || isOpenService || isOpenProduct || isOpenContact || isOpenCareers || isOpenfaq}">
                                <!-- <ul class="pl-0 mb-0"> -->
                                <div class="list-item-wrapper" [ngClass]="{'active': isOpen}">
                                    <div class="list-item">
                                        <div class="menu-pill">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div><a [routerLink]="root.home()" target="_top">HOME</a></div>
                                                <a class="right-arrow" (click)="isOpen = !isOpen"></a>
                                            </div>
                                        </div>
                                        <div class="d-none view-block responsive-view">
                                            <div class="d-flex align-items-center justify-content-center resp-view">
                                                <div><span>View All</span></div>
                                                <div class="view-arrow"></div>
                                            </div>
                                        </div>



                                        <div class="view-all-resp">
                                            <!-- <div class="d-none view-block">
                                                <div class="d-flex align-items-center justify-content-center">
                                                    <div class="view-arrow"></div>
                                                </div>
                                            </div> -->
                                            <!-- <div id="arrowAnim">
                                                <div class="arrowSliding">
                                                    <div class="arrow"></div>
                                                </div>
                                                <div class="arrowSliding delay1">
                                                    <div class="arrow"></div>
                                                </div>
                                            </div> -->

                                            <!-- <div class="round d-flex">
                                                <div class="primera bounceAlpha next"></div>
                                                <div class="segunda bounceAlpha next"></div>
                                            </div> -->

                                        </div>
                                    </div>
                                    <div class="overflow-hidden">
                                        <div class="sub-menu w-100">
                                            <div class="d-flex overflow-wrapper -ms-scrollbar scrollable-card">
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.home()"
                                                        fragment="who-we-are" target="_top">
                                                        <div class="mb-15 d-flex justify-content-center">
                                                            <img [src]="root.imageUrl() + '/svg-icons/who-we-are-default.svg' "
                                                                class="hover-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/who-we-are-hover.svg' "
                                                                class="default-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">Who we
                                                                are?</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">The who, what and why our
                                                                company
                                                                exists.</span></div>
                                                    </a>
                                                </div>
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.home()"
                                                        fragment="what-we-offer" target="_top">
                                                        <div class="mb-15 d-flex justify-content-center">
                                                            <img [src]="root.imageUrl() + '/svg-icons/what-we-offer-default.svg' "
                                                                class="hover-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/what-we-offer-hover.svg' "
                                                                class="default-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">What we
                                                                offer?</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">Investing into New start-up
                                                                Companies by giving theme Financial help &
                                                                Resources.</span>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div class="sub-menu-wrapper d-none">
                                                    <a class="sub-menu-item" [routerLink]="root.home()">
                                                        <div class="mb-15"><img
                                                                [src]="root.imageUrl() + '/svg-icons/banking-financial-service-default.svg'">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">ekZero Case
                                                                Study</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">Let us help you succeed by
                                                                taking
                                                                care of your digital infrastructure.</span></div>
                                                    </a>
                                                </div>
                                                <div class="sub-menu-wrapper d-none">
                                                    <a class="sub-menu-item" [routerLink]="root.home()">
                                                        <div class="mb-15"><img
                                                                [src]="root.imageUrl() + '/svg-icons/banking-financial-service-default.svg'">
                                                        </div>
                                                        <div class="mb-10"><span
                                                                class="sub-menu-title">TESTIMONIAL</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">Let us help you succeed by
                                                                taking
                                                                care of your digital infrastructure.</span></div>
                                                    </a>
                                                </div>
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.home()"
                                                        fragment="insights" target="_top">
                                                        <div class="mb-15 d-flex justify-content-center">
                                                            <img [src]="root.imageUrl() +'/svg-icons/insights-default.svg'"
                                                                class="hover-icon">
                                                            <img [src]="root.imageUrl() +'/svg-icons/insights-hover.svg'"
                                                                class="default-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">INSIGHTS</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">Let us help you succeed by
                                                                taking
                                                                care of your digital infrastructure.</span></div>
                                                    </a>
                                                </div>
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.home()"
                                                        fragment="contact-us" target="_top">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() +'/svg-icons/contact-default.svg'"
                                                                class="hover-icon">
                                                            <img [src]="root.imageUrl() +'/svg-icons/contact-hover.svg'"
                                                                class="default-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">Contact
                                                                us</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">A new way how you build and
                                                                grow
                                                                your
                                                                business.</span></div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="d-none view-block">
                                                <div class="d-flex align-items-center justify-content-center">
                                                    <div><span>View All</span></div>
                                                    <div class="view-arrow"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="list-item-wrapper" [ngClass]="{'active': isOpenecs}">
                                    <div class="list-item">
                                        <div class="menu-pill">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="mr-10"><a [routerLink]="root.ecs()" target="_top">ekZero
                                                        Consulting
                                                        Services</a></div>
                                                <a class="right-arrow" (click)="isOpenecs = !isOpenecs"></a>
                                            </div>
                                        </div>
                                        <div class="d-none view-block responsive-view">
                                            <div class="d-flex align-items-center justify-content-center resp-view">
                                                <div><span>View All</span></div>
                                                <div class="view-arrow"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="overflow-hidden">
                                        <div class="sub-menu w-100">
                                            <div class="d-flex overflow-wrapper -ms-scrollbar scrollable-card">
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.ecs()">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/ecs-technology-platform-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/ecs-technology-platform-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">HOME</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">Offering advanced services
                                                                to
                                                                achieve competitive value and intended business results.
                                                            </span></div>
                                                    </a>
                                                </div>
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.ess()">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/os-ecs-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/os-ecs-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">Enterprise
                                                                Software
                                                                Services
                                                            </span></div>
                                                        <div><span class="sub-menu-content">When it comes to software,
                                                                we
                                                                have
                                                                the expertise to provide end-to-end solutions.</span>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.mobileApp()">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/os-mobile-app-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/os-mobile-app-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">Mobile
                                                                Applications</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">Enterprise-grade mobile
                                                                application
                                                                for iOS and Android that connects your product to
                                                                clients.</span>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.cloud()">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/os-cloud-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/os-cloud-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">Cloud
                                                                Services</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">We are the best-in-class
                                                                provider of
                                                                cloud services.</span></div>
                                                    </a>
                                                </div>
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.devops()">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/os-devops-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/os-devops-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">DevOps
                                                                Services</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">Effective collaboration
                                                                between
                                                                the
                                                                development and operation team is key to a successful
                                                                software
                                                                product.</span></div>
                                                    </a>
                                                </div>
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.scs()">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/os-soft-consult-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/os-soft-consult-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">Software
                                                                Consulting</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">We know software development
                                                                in
                                                                and
                                                                out, no problem is too much problem for us.
                                                            </span></div>
                                                    </a>
                                                </div>
                                                <div class="sub-menu-wrapper d-none">
                                                    <a class="sub-menu-item" [routerLink]="root.ourServices()">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/os-extended-team-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/os-extended-team-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">Extended
                                                                Team</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">ekZero extended Team allows
                                                                you
                                                                to
                                                                easily scale your team strength with highly skilled and
                                                                experienced professionals.</span></div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="d-none view-block">
                                                <div class="d-flex align-items-center justify-content-center">
                                                    <div><span>View All</span></div>
                                                    <div class="view-arrow"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="list-item-wrapper" [ngClass]="{'active': isOpenAboutus}">
                                    <div class="list-item">
                                        <div class="menu-pill">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div><a [routerLink]="root.aboutus()" target="_top">ABOUT US</a></div>
                                                <a class="right-arrow" (click)="isOpenAboutus = !isOpenAboutus"></a>
                                            </div>
                                        </div>
                                        <div class="d-none view-block responsive-view">
                                            <div class="d-flex align-items-center justify-content-center resp-view">
                                                <div><span>View All</span></div>
                                                <div class="view-arrow"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="overflow-hidden">
                                        <div class="sub-menu w-100">
                                            <div class="d-flex overflow-wrapper -ms-scrollbar scrollable-card">
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.aboutus()"
                                                        fragment="ekzero-intro" target="_top">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/au-why-ekzero-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/au-why-ekzero-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">Why
                                                                ekZero</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">At ekZero, your goals become
                                                                ours.</span></div>
                                                    </a>
                                                </div>
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.aboutus()"
                                                        fragment="our-mission" target="_top">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/au-mission-vision-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/au-mission-vision-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">Our Mission &
                                                                Vision</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">A new way how you build and
                                                                grow
                                                                your
                                                                business.</span></div>
                                                    </a>
                                                </div>
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.aboutus()"
                                                        fragment="journey" target="_top">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/au-journey-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/au-journey-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">8 Years of
                                                                journey</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">A new way how you build and
                                                                grow
                                                                your
                                                                business.</span></div>
                                                    </a>
                                                </div>
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.aboutus()"
                                                        fragment="our-experts" target="_top">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/au-experts-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/au-experts-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">OUR
                                                                EXPERTS</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">A new way how you build and
                                                                grow
                                                                your
                                                                business.</span></div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="d-none view-block">
                                                <div class="d-flex align-items-center justify-content-center">
                                                    <div><span>View All</span></div>
                                                    <div class="view-arrow"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="list-item-wrapper d-none" [ngClass]="{'active': isOpenService}">
                                    <div class="list-item">
                                        <div class="menu-pill">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div><a [routerLink]="root.ourServices()" target="_top">OUR SERVICES</a>
                                                </div>
                                                <a class="right-arrow" (click)="isOpenService = !isOpenService"></a>
                                            </div>
                                        </div>
                                        <div class="d-none view-block responsive-view">
                                            <div class="d-flex align-items-center justify-content-center resp-view">
                                                <div><span>View All</span></div>
                                                <div class="view-arrow"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="overflow-hidden">
                                        <div class="sub-menu w-100">
                                            <div class="d-flex overflow-wrapper -ms-scrollbar scrollable-card">
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.ourServices()">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/os-ecs-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/os-ecs-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">Enterprise
                                                                Software
                                                                Services
                                                            </span></div>
                                                        <div><span class="sub-menu-content">When it comes to software,
                                                                we
                                                                have
                                                                the expertise to provide end-to-end solutions.</span>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.ourServices()">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/os-mobile-app-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/os-mobile-app-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">Mobile
                                                                Applications</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">Enterprise-grade mobile
                                                                application
                                                                for iOS and Android that connects your product to
                                                                clients.</span>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.ourServices()">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/os-cloud-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/os-cloud-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">Cloud
                                                                Services</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">We are the best-in-class
                                                                provider of
                                                                cloud services.</span></div>
                                                    </a>
                                                </div>
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.ourServices()">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/os-devops-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/os-devops-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">Dev Ops
                                                                Services</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">Effective collaboration
                                                                between
                                                                the
                                                                development and operation team is key to a successful
                                                                software
                                                                product.</span></div>
                                                    </a>
                                                </div>
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.ourServices()">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/os-soft-consult-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/os-soft-consult-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">Software
                                                                Consulting</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">We know software development
                                                                in
                                                                and
                                                                out, no problem is too much problem for us.
                                                            </span></div>
                                                    </a>
                                                </div>
                                                <div class="sub-menu-wrapper d-none">
                                                    <a class="sub-menu-item" [routerLink]="root.ourServices()">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/os-extended-team-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/os-extended-team-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">Extended
                                                                Team</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">ekZero extended Team allows
                                                                you
                                                                to
                                                                easily scale your team strength with highly skilled and
                                                                experienced professionals.</span></div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="d-none view-block">
                                                <div class="d-flex align-items-center justify-content-center">
                                                    <div><span>View All</span></div>
                                                    <div class="view-arrow"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="list-item-wrapper d-none">
                                    <div class="list-item">
                                        <div class="menu-pill">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div><a>INDUSTRIES</a></div>
                                                <!-- <a class="right-arrow"></a> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="list-item-wrapper" [ngClass]="{'active': isOpenProduct}">
                                    <div class="list-item">
                                        <div class="menu-pill">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div><a [routerLink]="root.productsList()" target="_top">OUR
                                                        PRODUCTS</a>
                                                </div>
                                                <a class="right-arrow" (click)="isOpenProduct = !isOpenProduct"></a>
                                            </div>
                                        </div>
                                        <div class="d-none view-block responsive-view">
                                            <div class="d-flex align-items-center justify-content-center resp-view">
                                                <div><span>View All</span></div>
                                                <div class="view-arrow"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="overflow-hidden">
                                        <div class="sub-menu w-100">
                                            <div class="d-flex overflow-wrapper -ms-scrollbar scrollable-card">
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.productsList()"
                                                        fragment="ekvegit" target="_top">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/op-ekvegit-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/op-ekvegit-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">ekVegit</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">ekZero's revolutionary
                                                                product
                                                                for
                                                                logistics and fleet management.</span></div>
                                                    </a>
                                                </div>
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.productsList()"
                                                        fragment="oneviz" target="_top">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/op-oneviz-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/op-oneviz-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">OneViz</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">OneViz is a next-gen
                                                                software
                                                                solution leveraging the power of IT.</span></div>
                                                    </a>
                                                </div>

                                            </div>
                                            <div class="d-none view-block">
                                                <div class="d-flex align-items-center justify-content-center">
                                                    <div><span>View All</span></div>
                                                    <div class="view-arrow"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="list-item-wrapper d-none">
                                    <div class="list-item">
                                        <div class="menu-pill">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div><a>OUR INSIGHTS</a></div>
                                                <!-- <a class="right-arrow"></a> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="list-item-wrapper" [ngClass]="{'active': isOpenContact}">
                                    <div class="list-item">
                                        <div class="menu-pill">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div><a [routerLink]="root.contact()" target="_top">CONTACT US</a></div>
                                                <a class="right-arrow" (click)="isOpenContact = !isOpenContact"></a>
                                            </div>
                                        </div>
                                        <div class="d-none view-block responsive-view">
                                            <div class="d-flex align-items-center justify-content-center resp-view">
                                                <div><span>View All</span></div>
                                                <div class="view-arrow"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="overflow-hidden">
                                        <div class="sub-menu w-100">
                                            <div class="d-flex overflow-wrapper -ms-scrollbar scrollable-card">
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.contact()"
                                                        fragment="support" target="_top">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/cu-support-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/cu-support-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">24 x 7
                                                                Support</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">Let us help you succeed by
                                                                taking
                                                                care of your digital infrastructure.</span></div>
                                                    </a>
                                                </div>
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.contact()"
                                                        fragment="talk-business" target="_top">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/cu-bussiness-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/cu-bussiness-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">Let's Talk
                                                                Business!</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">Let us help you succeed by
                                                                taking
                                                                care of your digital infrastructure.</span></div>
                                                    </a>
                                                </div>
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.contact()"
                                                        fragment="product-support" target="_top">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/cu-product-support-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/cu-product-support-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">Connect to our
                                                                Products
                                                                support</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">A new way how you build and
                                                                grow
                                                                your
                                                                business.</span></div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="d-none view-block">
                                                <div class="d-flex align-items-center justify-content-center">
                                                    <div><span>View All</span></div>
                                                    <div class="view-arrow"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="list-item-wrapper" [ngClass]="{'active': isOpenCareers}">
                                    <div class="list-item">
                                        <div class="menu-pill">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div><a [routerLink]="root.careers()" target="_top">CAREERS</a></div>
                                                <a class="right-arrow" (click)="isOpenCareers = !isOpenCareers"></a>
                                            </div>
                                        </div>
                                        <div class="d-none view-block responsive-view">
                                            <div class="d-flex align-items-center justify-content-center resp-view">
                                                <div><span>View All</span></div>
                                                <div class="view-arrow"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="overflow-hidden">
                                        <div class="sub-menu w-100">
                                            <div class="d-flex overflow-wrapper -ms-scrollbar scrollable-card">
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.careers()"
                                                        fragment="engineer-new-future" target="_top">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/career-future-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/career-future-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">Let’s Engineer
                                                                A New Future Together</span></div>
                                                        <div><span class="sub-menu-content">We are a bunch of geeky and
                                                                creative
                                                                people, who live, breathe and believe in the power of
                                                                technology.</span></div>
                                                    </a>
                                                </div>
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.careers()"
                                                        fragment="benefits" target="_top">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/career-benefits-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/career-benefits-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">Benefits @
                                                                ekZero</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">A new way how you build and
                                                                grow
                                                                your
                                                                business.</span></div>
                                                    </a>
                                                </div>
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.careers()"
                                                        fragment="career-specification" target="_top">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/career-look-for-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/career-look-for-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">What do we look
                                                                for?</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">A new way how you build and
                                                                grow
                                                                your
                                                                business.</span></div>
                                                    </a>
                                                </div>
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.careers()"
                                                        fragment="career-opening" target="_top">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/career-openings-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/career-openings-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">Current
                                                                Openings Us?</span>
                                                        </div>
                                                        <div><span class="sub-menu-content">A new way how you build and
                                                                grow
                                                                your
                                                                business.</span></div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="d-none view-block">
                                                <div class="d-flex align-items-center justify-content-center">
                                                    <div><span>View All</span></div>
                                                    <div class="view-arrow"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="list-item-wrapper d-none" [ngClass]="{'active': isOpenfaq}">
                                    <div class="list-item">
                                        <div class="menu-pill">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div><a [routerLink]="root.faq()" target="_top">FAQ</a></div>
                                                <a class="right-arrow" (click)="isOpenfaq = !isOpenfaq"></a>
                                            </div>
                                        </div>
                                        <div class="d-none view-block responsive-view">
                                            <div class="d-flex align-items-center justify-content-center resp-view">
                                                <div><span>View All</span></div>
                                                <div class="view-arrow"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="overflow-hidden">
                                        <div class="sub-menu w-100">
                                            <div class="d-flex overflow-wrapper -ms-scrollbar scrollable-card">
                                                <div class="sub-menu-wrapper">
                                                    <a class="sub-menu-item" [routerLink]="root.faq()">
                                                        <div class="mb-15 d-flex justify-content-center"><img
                                                                [src]="root.imageUrl() + '/svg-icons/faq-question-default.svg' "
                                                                class="default-icon">
                                                            <img [src]="root.imageUrl() + '/svg-icons/faq-question-hover.svg' "
                                                                class="hover-icon">
                                                        </div>
                                                        <div class="mb-10"><span class="sub-menu-title">Do you have Any
                                                                Questions?</span></div>
                                                        <div><span class="sub-menu-content">A new way how you build and
                                                                grow
                                                                your
                                                                business.</span></div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="d-none view-block">
                                                <div class="d-flex align-items-center justify-content-center">
                                                    <div><span>View All</span></div>
                                                    <div class="view-arrow"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- </ul> -->
                            </div>

                            <div class="right-menu"
                                *ngIf="!isOpen && !isOpenecs && !isOpenAboutus && !isOpenService && !isOpenProduct && !isOpenContact && !isOpenCareers && !isOpenfaq"
                                [ngClass]="{'right-0': isOpen && isOpenecs && isOpenAboutus && isOpenService && isOpenProduct && isOpenContact && isOpenCareers && isOpenfaq}">
                                <div class="right-menu-header">
                                    <span class="right-menu-header-text">our products</span>
                                </div>
                                <div class="gallery-block">
                                    <div class="image-gallery">
                                        <div class="row no-gutters">
                                            <div class="col-md-6">
                                                <a class="image-block h-100" href="https://gtrack.vegitone.com/">
                                                    <img [src]="root.imageUrl() + '/gtrack-header-banner.png' ">
                                                </a>
                                            </div>
                                            <div class="col-md-6">
                                                <a class="image-block" href="https://oneviz.co/home">
                                                    <img [src]="root.imageUrl() + '/oneviz-header-banner.png' ">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <a class="image-block h-100" href="https://www.vegitone.com/">
                                                    <img [src]="root.imageUrl() + '/vegitone-header-banner.png' ">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="menu-panel">
                                        <a class="navigate-menu-block" [routerLink]="root.contact()" target="_top">
                                            <div class="icon-block get-in-touch">
                                            </div>
                                            <div class="title-panel">
                                                <span class="title-panel-text">
                                                    Get In Touch
                                                </span>
                                            </div>
                                            <div class="subtitle-panel">
                                                <span class="subtitle-panel-text">
                                                    Let's talk more...
                                                </span>
                                            </div>
                                        </a>
                                        <a class="navigate-menu-block" [routerLink]="root.careers()" target="_top">
                                            <div class="icon-block job-opening">
                                            </div>
                                            <div class="title-panel">
                                                <span class="title-panel-text">
                                                    Job Openings
                                                </span>
                                            </div>
                                            <div class="subtitle-panel">
                                                <span class="subtitle-panel-text">
                                                    Work with us...
                                                </span>
                                            </div>
                                        </a>
                                        <a class="navigate-menu-block" [routerLink]="root.blogs()" target="_top">
                                            <div class="icon-block latest-news">
                                            </div>
                                            <div class="title-panel">
                                                <span class="title-panel-text">
                                                    Latest Blogs
                                                </span>
                                            </div>
                                            <div class="subtitle-panel">
                                                <span class="subtitle-panel-text">
                                                    Know more about us...
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="menu-footer-panel">
                            <div class="d-flex align-items-center justify-content-end">
                                <div class="mr-30"><a href="https://twitter.com/ekZeroMedia1" target="_blank"><img
                                            [src]="root.imageUrl() + '/svg-icons/twitter-orange-icon.svg'"></a></div>
                                <div class="mr-30"><a href="https://www.facebook.com/ekZeroCorp" target="_blank"><img
                                            [src]="root.imageUrl() + '/svg-icons/facebook-orange-icon.svg'"></a>
                                </div>
                                <div class="mr-30"><a href="https://www.linkedin.com/company/ekzero/"
                                        target="_blank"><img
                                           [src]="root.imageUrl() + '/svg-icons/linkedin-orange-icon.svg'"></a>
                                </div>
                                <div><a href="https://www.instagram.com/ekzerocorporation/" target="_blank"><img
                                            class="pt-5"
                                            [src]="root.imageUrl() + '/svg-icons/instagram-orange-icon.svg'"
                                            width="23"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="menu-footer">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-xl-3">
                            <div class="d-flex align-items-center">
                                <div><img [src]="root.imageUrl() + '/svg-icons/header-office-icon.svg'" alt=""></div>
                                <div><a class="font-18" href="#">Take Our office Virtual Tour</a></div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-xl-3">
                            <div class="d-flex align-items-center">
                                <div><img [src]="root.imageUrl() + '/svg-icons/header-telephone-icon.svg'" alt=""></div>
                                <div><a class="font-18" href="#">+1 (833) 359-3761</a></div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-xl-3">
                            <div class="d-flex align-items-center">
                                <div><img [src]="root.imageUrl() + '/svg-icons/header-e-mail-icon.svg'" alt=""></div>
                                <div><a class="font-18" href="#">contact@ekzero.com</a></div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-xl-3">
                            <div class="d-flex align-items-center">
                                <div><img [src]="root.imageUrl() + '/svg-icons/header-e-mail-icon.svg'" alt=""></div>
                                <div><a class="font-18" href="#">contact@ekzero.com</a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="color-line">
                    <div class="orenge-line"></div>
                    <div class="blue-line"></div>
                </div> -->
                </div>
            </div>
        </div>
    </div>
    <div class="position-relative">
        <div class="error-text">
            <div class="error-block center">
                <img [src]="root.imageUrl() + '/ez-page-not-found-img.png'">
            </div>
            <div class="text-center mt-50">
                <div class="mb-30"><span class="text-line1">OPPS!</span></div>
                <div><span class="text-line2">The page you requested could not be found</span></div>
            </div>
        </div>
        <div class="spinner left">
            <div>
                <div><img class="setting-img" [src]="root.imageUrl() + '/svg-icons/setting-lg-icon.svg'">
                </div>
            </div>
            <div>
                <div><img class="setting-img sm-img" [src]="root.imageUrl() + '/svg-icons/setting-sm-icon.svg'">
                </div>
            </div>
        </div>
        <div class="spinner right">
            <div>
                <div><img class="setting-img" [src]="root.imageUrl() + '/svg-icons/setting-lg-icon.svg'">
                </div>
            </div>
            <div>
                <div><img class="setting-img sm-img" [src]="root.imageUrl() + '/svg-icons/setting-sm-icon.svg'">
                </div>
            </div>
        </div>
    </div>
</div>