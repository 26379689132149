import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  newTitle: string = 'ekZero Corporation | Empowering Tomorrow';
  longDescription: string = ' Empowering tomorrow with advanced technological solutions and innovative products';
  longKeyword: string = 'ekZero, IT company, ekZero, Information Technology, Technology, Software Development, Digital solution, Software Consulting, App Solutions, best IT company in Toronto.';

  constructor(private title: Title,
    private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle(this.newTitle);
    this.meta.addTag({ name: "description", content: this.longDescription });
    this.meta.addTag({ name: "keywords", content: this.longKeyword });
  }

}
