import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'app-evolution-the-next-phase-of-internet',
  templateUrl: './evolution-the-next-phase-of-internet.component.html',
  styleUrls: ['./evolution-the-next-phase-of-internet.component.scss']
})
export class EvolutionTheNextPhaseOfInternetComponent implements OnInit {

  isDisplay: boolean = false;
  isShow: boolean = false;

  newTitle: string = 'Evolution of Web 3.0. The next phase of the Internet!';
  longDescription: string = 'Web 3 is based on the concept where you could have a control on who sees your data. Transactions and browser history won\'t be available to third parties or even the government.';
  longKeyword: string = 'Decentralized internet, web 3, web 3.0, future of internet, blockchain technology, cryptocurrency, crypto, metaverse, cybercrime, cyber security, data security, data intelligence, artificial intelligence, mixed reality, virtual reality, simulation, decentralized, Internet of things, augmented reality';

  constructor(public root: RootService, private meta: Meta, private title: Title) { }
  ngOnInit(): void {
    this.title.setTitle(this.newTitle);
    this.meta.addTag({ name: "description", content: this.longDescription });
    this.meta.addTag({ name: "keywords", content: this.longKeyword });
  }
}
