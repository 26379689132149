<div class="ez-faq-banner position-relative">
    <div class="">
        <div class="text-container">
            <div class="subheading-block position-relative mb-15">
                <span class="subheading-block-text">
                    FAQ
                </span>
            </div>
            <div class="heading1-block mb-15">
                <span class="heading1-block-text">
                    Your Success
                </span>
            </div>
            <div class="subheading2-block">
                <span class="subheading2-block-text">
                    A new way how you build
                </span>
            </div>
            <div class="subheading2-block">
                <span class="subheading2-block-text">
                    and grow your business.
                </span>
            </div>
        </div>
        <div class="overlay-img-block">
            <img [src]="root.imageUrl() + '/ez-faq-right-content-img.png'">
        </div>
        <div class="overflay-frame"></div>
    </div>
</div>