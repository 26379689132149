<!-- <testimonials></testimonials> -->
<div class="testimonial">
    <div class="testimonial-wrapper">
        <div class="row no-gutters">
            <div class="col-12 col-lg-8 col-xl-8 order-2">
                <div data-aos="fade-right" data-aos-duration="1500"><img
                        [src]="root.imageUrl() + '/ez-family-insurance.png'"
                        class="ez-family-insurance">
                </div>
                <div class="testimonial-profile center" data-aos="fade-up" data-aos-duration="1500">
                    <div class="testimonial-profile-arrow center">
                        <div class="default-arrow mr-15">
                            <img [src]="root.imageUrl() + '/svg-icons/left-orange-arrow.svg'"
                                class="default-icon">
                            <img [src]="root.imageUrl() + '/svg-icons/left-white-arrow.svg'"
                                class="active-icon">
                        </div>
                        <div class="active-arrow ml-15">
                            <img [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'"
                                class="default-icon">
                            <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'"
                                class="active-icon">
                        </div>
                    </div>
                    <div class="row no-gutters align-items-center">
                        <div class="col-4 flex-wrapper">
                            <div>
                                <img [src]="root.imageUrl() + '/testimonial-profilephotos.png ' "
                                    alt="" width="100%" height="100%">
                            </div>
                        </div>
                        <div class="col-8">
                            <div class="testimonial-profile-text">
                                <div class="mb-10 mt-20"><span class="font14">"Let us help you succeed by taking care of your digital infrastructure, software solution development, integration, and delivery while you focus on developing your business."</span>
                                </div>
                                <div class="owner-detail">
                                    <div><span class="font14">Lucy Stewart</span></div>
                                    <div><span class="font14">Owner of Logistic</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-12 col-lg-4 col-xl-4 order-1">
                <div>
                    <div class="text-right mb-10"><span class="color-black font-24">TESTIMONIAL</span></div>
                    <div class="color-orange font-34 text-right mb-15 font-900">
                        <div>What our</div>
                        <div>customers</div>
                        <div>are saying</div>
                    </div>
                    <div class="text-right"><span class="color-black font-14">Let us help you succeed by taking care of your digital infrastructure, software solution development, integration, and delivery while you focus on developing your business.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>