<blog-banner></blog-banner>
<div id="blog-detail">
    <div class="blog-detail-wrapper">
        <div class="row no-gutters">
            <div class="col-xl-8 col-lg-7 col-12 col-md-order">
                <div class="left-content-panel">
                    <div>
                        <img [src]="root.imageUrl() + '/next-phase-internet.jpg ' " class="blog-detail-img"
                            alt=" Evolution of web">
                    </div>
                    <div class="text-wrapper">
                        <div class="mb-20">
                            <span class="blog-title color-black"> Evolution of Web 3.0. The next phase of the Internet!
                            </span>
                        </div>
                        <div class="d-flex align-items-center justify-content-start blog-publish">
                            <div><span class="color-orange">Technology</span></div>
                            <div class="ml-15"><span class="pipe color-black">|</span></div>
                            <div class="ml-15"><span class="color-black">July 11, 2022</span></div>
                        </div>
                    </div>
                    <div class="blog-desc">
                        <p class="content-desc">We have all heard of the term web 3.0. Some of it has already been
                            adopted. You can see it in blockchains and cryptocurrency payments. .</p>
                        <p class="content-desc">
                            Some of you may already know that it is the future of the internet where everything will be
                            decentralized giving users the ability to take control of their data without any third party
                            interference.
                        </p>
                        <p class="content-desc">
                            Web 3.0 will also incorporate AI and ML technologies to increase data processing speeds and
                            problem solving.
                        </p>
                        <div>
                            <img [src]="root.imageUrl() + '/evolution-of-web.jpg'" class="blog-detail-inner-img">
                        </div>
                        <div class="title-panel">
                            <span class="title-text"> So why is it considered the next phase of the internet?
                            </span>
                        </div>
                        <p class="content-desc">Before web 3.0, we had web 1.0 and web 2.0 that we are currently using
                            in our day to day lives. </p>
                        <p class="content-desc"> Web 1.0 was basically a search engine that the end user would just
                            search or browse through to get information they needed to know.
                        </p>
                        <p class="content-desc">In web 2.0, we went from dialing the internet a few times a day to
                            continuously using it and always being connected. It consisted of user generated content
                            that users can share through blogs and social media. Not just information, but entertainment
                            based content as well. </p>
                        <p class="content-desc">
                            But, privacy was an issue for many here although some did not care. Some companies shared or
                            sold user data. </p>

                        <p class="content-desc">
                            Hence, the search for data controlled services began. And that is where the idea for web 3.0
                            came in.
                        </p>

                        <p class="content-desc">Gavin Wood, co-founder of ethereum introduced the concept of Web 3 in
                            the year 2014, although the term rose to popularity after a few years.
                        </p>
                        <p class="content-desc">Web 3 is based on the concept where you could have a control on who sees
                            your data. Transactions and browser history won't be available to third parties or even the
                            government.</p>
                        <p class="content-desc">
                            Simply put, web 3 removes the need for third parties while interacting with data. Web 3 was
                            earlier called semantic web by Tim-Berners Lee. Lee is a computer scientist who invented the
                            World Wide Web.
                        </p>
                        <p class="content-desc">
                            The internet is a space where data is controlled and monitored. And so, currently people are
                            looking for ways to own their data and create their own spaces in the blockchain.
                        </p>
                        <p class="content-desc">
                            Blockchain is a chain of little pieces of information stored together in blocks that cannot
                            be tampered with or changed. Each block contains a nonce with a hash, if when tampered with,
                            the hash number changes.
                        </p>
                        <p class="content-desc">
                            The working is similar to google docs, but in a much more complex way. In google docs two or
                            more members share a workspace and can collaborate with each other. If any change is made,
                            you could see it in real time or access history to reacquire the original data.
                        </p>
                        <p class="content-desc">
                            In web 3, each data block is sent to all users with a unique identification code. If anyone
                            changes data, the hash number changes and the remaining chain of blocks will not identify
                            the previous block as part of the chain.
                        </p>
                        <p class="content-desc">
                            This is how you could find the change made and this shows how secure blockchain technology
                            is.
                        </p>
                        <p class="content-desc">
                            The web 3 consists of few features that are handy to use. Any software on the web will be
                            open
                            source and easy to access since it was made for every user who is part of the blockchain.
                        </p>
                        <p class="content-desc">
                            Unlike vendor based software where each bug report or personal request had to go to the
                            vendors
                            first, open-source software can be edited by you provided you know how to make changes in
                            the
                            source code.
                        </p>
                        <p class="content-desc">
                            You could also outsource it to someone else to help make changes as per your requirements.
                            Both
                            users and suppliers can participate without authorization from a governing body.
                        </p>
                        <p class="content-desc">
                            These concepts of security and restricted third party access is what is known as
                            decentralized internet where no one can own or control other user’s data. Here the user’s
                            themselves are in control of their data and who sees it.
                        </p>
                        <p class="content-desc">
                            However good this looks, it still has some downfalls and may take several years to be fully
                            adopted across the globe. Mostly early users and venture capitalists will own it.
                        </p>
                        <p class="content-desc">
                            Businesses with normal websites will be forced to upgrade in order to avoid losing market
                            share. People would need to buy new devices or upgrade just to use web 3 since it requires
                            fast, processable network speeds.
                        </p>
                        <p class="content-desc">
                            Those with devices below that standard won’t be able to use web 3. It is also hard for new
                            people to grasp the concept of web 3 and blockchain technology making it a huge entry
                            barrier to the system. A decentralized internet may make regulations more difficult, that
                            may possibly lead to online abuse and cybercrime.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-5 col-12">
                <div class="right-content-panel">
                    <div class="content-body d-none">
                        <div class="search-block mb-20">
                            <div><span class="color-black font-18">Search our <span
                                        class="color-orange transform-capitalize font-18">Blogs</span></span></div>
                            <div class="mt-20">
                                <div class="search-bar">
                                    <input type="text" class="form-control search-input" placeholder="Search...">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-body">
                        <div class="mb-20">
                            <div><span class="color-black font-18">Recent <span
                                        class="color-orange transform-capitalize font-18">Posts</span></span></div>
                            <div class="mt-20">
                                <div class="collapsable-wrapper position-relative"
                                    [ngClass]="{'collapsable-active': isDisplay}">
                                    <div
                                        class="collapsable-header responsive-view align-items-center justify-content-between mt-30">
                                        <div><span class="color-black">Check Our Recent Posts</span></div>
                                        <div><a class="collapsable-icon" (click)="isDisplay = !isDisplay"></a></div>
                                    </div>

                                    <div class="blog-title-list blog-list collapsable-body -ms-scrollbar scrollable-body"
                                        [ngbCollapse]="!isDisplay">
                                        <div class="blog-title active"><a [routerLink]="root.whatiscloudcomputing()"
                                                target="_top">What is Cloud Computing? How it helps your business
                                                gain a competitive advantage.</a>
                                        </div>
                                        <div class="blog-title active"><a [routerLink]="root.mobileEssential()"
                                                target="_top">6 Essential Mobile App Design Steps to Take in 2022</a>
                                        </div>
                                        <div class="blog-title active"><a [routerLink]="root.nextPhaseOfInternet()"
                                                target="_top"> Evolution of Web 3.0. The next phase of the Internet!</a>
                                        </div>
                                        <div class="blog-title active"><a [routerLink]="root.customSoftware()"
                                                target="_top"> Custom Software Consulting: Should your startup go for
                                                it? </a></div>
                                        <div class="blog-title active"><a [routerLink]="root.cloudMigration()"
                                                target="_top">How do you overcome obstacles in Cloud Migration? Simple
                                                solutions.</a></div>
                                        <div class="blog-title"><a [routerLink]="root.truckingindustry()"
                                                target="_top">Ensuring Safety in the Trucking Industry. Regulations,
                                                Technology, and Advantages to business owners.</a></div>
                                        <div class="blog-title"><a [routerLink]="root.losttruckdriver()"
                                                target="_top">Loss to Truck Drivers in Covid-19 Pandemic.</a></div>
                                        <div class="blog-title"><a [routerLink]="root.fleetoperations()"
                                                target="_top">Evolution of GPS Technology in fleet operations.</a></div>
                                        <div class="blog-title"><a [routerLink]="root.logisticindustry()"
                                                target="_top">Will The Self-Driving Trucks Transform The Trucking And
                                                Logistic Industry?</a></div>
                                        <div class="blog-title"><a [routerLink]="root.efficientways()"
                                                target="_top">Skyrocket Your Association Revenue By Employing Efficient
                                                Ways.</a></div>
                                        <div class="blog-title"><a [routerLink]="root.membercentric()"
                                                target="_top">Create Member-Centric Culture for Your Association
                                                Employing AMS Simple Measures.</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-body">
                        <div class="mb-20">
                            <div><span class="color-black font-18">Categories </span></div>
                            <div class="mt-20">
                                <div class="collapsable-wrapper position-relative"
                                    [ngClass]="{'collapsable-active': isShow}">
                                    <div
                                        class="collapsable-header responsive-view align-items-center justify-content-between mt-30">
                                        <div><span class="color-black">Select Categories</span></div>
                                        <div><a class="collapsable-icon" (click)="isShow = !isShow"></a></div>
                                    </div>
                                    <div class="blog-category-list blog-list collapsable-body mt-20 -ms-scrollbar scrollable-body"
                                        [ngbCollapse]="!isShow">
                                        <div class="d-flex align-items-center flex-wrap">
                                            <div class="tag-item"><a [routerLink]="root.nextPhaseOfInternet()"
                                                    target="_top"> Technology</a></div>
                                            <div class="tag-item"><a [routerLink]="root.customSoftware()"
                                                    target="_top">Enterprise Software Solutions </a></div>
                                            <div class="tag-item"><a [routerLink]="root.cloudMigration()"
                                                    target="_top">Cloud Computing</a></div>
                                            <div class="tag-item"><a [routerLink]="root.truckingindustry()"
                                                    target="_top">Trucking Industry</a></div>
                                            <div class="tag-item"><a [routerLink]="root.losttruckdriver()"
                                                    target="_top">Truck Drivers</a></div>
                                            <div class="tag-item"><a [routerLink]="root.fleetoperations()"
                                                    target="_top">GPS</a></div>
                                            <div class="tag-item"><a [routerLink]="root.logisticindustry()"
                                                    target="_top">Trucking</a></div>
                                            <div class="tag-item"><a [routerLink]="root.efficientways()"
                                                    target="_top">Employee</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>