<service-banner></service-banner>
<div class="ez-section-card">
    <div class="ez-technology-platform-section">
        <div class="technologies-section">
            <app-software-detail-intro></app-software-detail-intro>
            <app-software-detail-offer></app-software-detail-offer>
            <app-software-detail-benefits></app-software-detail-benefits>
            <app-software-detail-services></app-software-detail-services>
            <sales></sales>
        </div>
    </div>
</div>
