import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'app-ess-detail-benefits',
  templateUrl: './ess-detail-benefits.component.html',
  styleUrls: ['./ess-detail-benefits.component.scss']
})
export class EssDetailBenefitsComponent implements OnInit {

  constructor(public root: RootService) { }

  ngOnInit(): void {
  }

}
