<div id="service-offer" data-aos="fade-up" data-aos-duration="1500">
    <div class="offfer-content-wrapper mb-50">
        <div class="offfer-content">
            <div class="mt-20 info-content"><span class="color-orange mb-5">Our Comprehensive</span></div>
            <div class="info-content"><span class="color-orange">Offerings</span></div>
            <div><span class="color-black content-text">We are a bunch of geeky and creative people, who live, breathe
                    and
                    believe in the power of technology to create a world with innovations - a better world. </span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-6 col-lg-7">
            <div class="left-content h-100">
                <div class="left-content-img position-relative">
                    <div class="offer-block">
                        <div class="offer-block-ribbon"></div>
                        <div class="text-center offer-text">
                            <p class="color-black">We are a bunch of geeky and creative people, who live, breathe and
                                believe in the power
                                of technology to create a world with innovations - a better world. We are in search of
                                energetic innovators, thinkers and doers who dare to learn fast, adapt faster and
                                execute in real time. The developers who passionately talk about the perfection in
                                design and the designers who talk about development trends with conviction or a marketer
                                who wants to build up their own sphere of stories. Think you have got what it takes?
                                With your heartfelt desire to be a pioneer in this digital realm, consider joining a
                                forward-thinking team.</p>
                        </div>
                        <div class="d-flex justify-content-end"><a class="btn-md btn-primary inquiry-btn"
                            [routerLink]="root.contact()" target="_top">INQUIRE NOW</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-5">
            <div class="right-content">
                <div class="offer-block-wrapper">
                    <div class="offer-pills mb-30">
                        <div class="d-flex align-items-center">
                            <div class="tech-icon center">
                                <img [src]="root.imageUrl() + '/svg-icons/enterprise-solution-default.svg'"
                                    class="default-icon">
                                <img [src]="root.imageUrl() + '/svg-icons/enterprise-solution-hover.svg'"
                                    class="hover-icon">
                            </div>
                            <div class="info-content ml-20"><span class="color-black mb-0">Enterprise web
                                    solutions</span>
                            </div>
                        </div>
                    </div>
                    <div class="offer-pills mb-30">
                        <div class="d-flex align-items-center">
                            <div class="tech-icon center">
                                <img [src]="root.imageUrl() + '/svg-icons/cloud-service-default.svg'"
                                    class="default-icon">
                                <img [src]="root.imageUrl() + '/svg-icons/cloud-service-hover.svg'" class="hover-icon">
                            </div>
                            <div class="info-content ml-20"><span class="color-black mb-0">Cloud Services</span>
                            </div>
                        </div>
                    </div>
                    <div class="offer-pills mb-30">
                        <div class="d-flex align-items-center">
                            <div class="tech-icon center">
                                <img [src]="root.imageUrl() + '/svg-icons/mobile-service-default.svg'"
                                    class="default-icon">
                                <img [src]="root.imageUrl() + '/svg-icons/mobile-service-hover.svg'" class="hover-icon">
                            </div>
                            <div class="info-content ml-20"><span class="color-black mb-0">Mobile Application</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-none nav-arrows justify-content-end mr-50 pb-30">
                    <!-- d-flex -->
                    <div class="left-arrow mr-30 center"><a><img
                                [src]="root.imageUrl() + '/svg-icons/left-orange-arrow.svg'"></a>
                    </div>
                    <div class="right-arrow center"><a><img
                                [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>