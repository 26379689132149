<div id="explore-services">
    <div class="explore-block">
        <div class="row">
            <div class="col-xl-8 col-lg-order-2">
                <div class="left-content">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="mb-30">
                                <div><img class="service-img"
                                        [src]="root.imageUrl() + '/svg-icons/conceptualization.svg'">
                                </div>
                                <div class="mt-15"><span class="color-black service-title font-600">Conceptualization
                                    </span></div>
                                <div class="mt-15"><span class="color-black content-text">Before jumping on the development of any IT software, it is important to understand the market and competitor landscape. This helps in getting an overview of what features your competitors are offering and to which audience they are interacting. The team of ekZero identifies their weaknesses and determines what functionality they are lacking behind so that you can address those soft points and provide a superior product with your software by making it better, appealing, and engaging to your target user base. With superior IT code infrastructure in particular, we conduct a feasibility study to verify the proper functioning of your application before the design begins.
                                    </span></div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-30">
                                <div><img class="service-img"
                                        [src]="root.imageUrl() + '/svg-icons/design.svg'">
                                </div>
                                <div class="mt-15"><span class="color-black service-title font-600">Design and Architecture</span></div>
                                <div class="mt-15"><span class="color-black content-text">Once ekZero is done with determining the scope of your project, special instructions from stakeholders, our teams head towards the wireframing about the idea, how the concept will be presented to the audience, what framework we will work on, how the UX of application will look like and so on. As a highly streamlined software development company, we don’t waste time reinventing the wheel. Our experts dedicatedly follow the industry best practices, just to be on the edge with the current competition.
                                    </span></div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="mb-30">
                                <div><img class="service-img"
                                        [src]="root.imageUrl() + '/svg-icons/prototyping.svg'">
                                </div>
                                <div class="mt-15"><span class="color-black service-title font-600">Prototyping
                                    </span></div>
                                <div class="mt-15"><span class="color-black content-text">In order to communicate the idea in a presentable way, prototyping is a must. Our experts connect all the wireframes and bring the idea into reality. We create a perfect series of static images combined together with various use cases to provide a live scenario of the application before starting the actual development. This adds interactivity to wireframes to visualize the workflow of the system and analyze important business scenarios. ekZero becomes a partner in prototyping from concept to development, removing all expected roadblocks and putting the purpose of the application together.
                                    </span></div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-30">
                                <div><img class="service-img"
                                        [src]="root.imageUrl() + '/svg-icons/dt.svg'">
                                </div>
                                <div class="mt-15"><span class="color-black service-title font-600">Development and Testing
                                    </span></div>
                                <div class="mt-15"><span class="color-black content-text">With having a well-defined process and infrastructure ready at ekzero, we offer a full-cycle development system. We ensure the delivery of ready-to-use, competitive, maintainable software solutions aimed at enhancing the ground business of organizations and increasing their ROI. We employ hands-on experience with IoT, artificial intelligence, augmented and virtual reality, big data, and machine learning, to assist our clients to get the utmost of modern technology capabilities.
                                    </span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-order-1">
                <div class="right-content">
                    <div class="right-bg">
                        <div class="mt-20 info-content"><span>Our Software Consulting Offerings
                        </span></div>
                        <div class="mt-20"><span class="content-text">ekZero provides customers with software designing services through market research, end-user survey, and industry trend analysis.</span></div>
                        <div><a class="btn-news btn-md" [routerLink]="root.ourServices()"  target="_top">VIEW ALL</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>