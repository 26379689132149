<div id="service-benefits" data-aos="fade-up" data-aos-duration="1500">
    <div class="text-center ml-10 mr-10">
        <div class=""><span class="font-20 color-black">Benefits of</span></div>
        <div class="mt-20 info-content"><span class="color-orange">ekZero's Software Consulting</span></div>
    </div>
    <div class="benefit-content">
        <div class="row no-gutters">
            <div class="col-xl-6 col-lg-7">
                <div class="left-content">
                    <div class="benefit-pill mb-30">
                        <div class="d-flex flex-column-wrapper">
                            <div><img [src]="root.imageUrl() + '/svg-icons/software-expertise-knowledge.svg'"
                                    class="benefit-icon"></div>
                            <div class="ml-20">
                                <div class="mb-10"><span class="color-black font-18">Expertise Knowledge
                                    </span></div>
                                <div><span class="color-black content-text mb-0">Using software consulting services allows you to obtain expert advice throughout the span of your project ensuring efficiency and success. 
                                    </span></div>
                            </div>
                        </div>
                    </div>
                    <div class="benefit-pill mb-30">
                        <div class="d-flex flex-column-wrapper">
                            <div><img [src]="root.imageUrl() + '/svg-icons/software-match-needs.svg'"
                                    class="benefit-icon"></div>
                            <div class="ml-20">
                                <div class="mb-10"><span class="color-black font-18">Match Your Needs
                                    </span></div>
                                <div><span class="color-black content-text mb-0">Software Consulting assists in bringing your vision to life and creating something tailored to your specific business requirements. 
                                    </span></div>
                            </div>
                        </div>
                    </div>
                    <div class="benefit-pill mb-30">
                        <div class="d-flex flex-column-wrapper">
                            <div><img [src]="root.imageUrl() + '/svg-icons/software-cost-saving.svg'"
                                    class="benefit-icon"></div>
                            <div class="ml-20">
                                <div class="mb-10"><span class="color-black font-18">Cost-Saving
                                    </span></div>
                                <div><span class="color-black content-text mb-0">Save money by working with a team of specialists that are already up and running. Raise profits quicker while cutting costs associated with hiring and employing full-time employees.
                                    </span></div>
                            </div>
                        </div>
                    </div>
                    <div class="benefit-pill mb-30">
                        <div class="d-flex flex-column-wrapper">
                            <div><img [src]="root.imageUrl() + '/svg-icons/software-increase-speed.svg'"
                                    class="benefit-icon"></div>
                            <div class="ml-20">
                                <div class="mb-10"><span class="color-black font-18">Increase Speed</span>
                                </div>
                                <div><span class="color-black content-text mb-0"> The consulting team of experts puts a high focus on your project without any distractions. Importantly, experience guarantees that your bespoke software development team will be able to recognize potential hazards and adjust promptly.</span></div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="benefit-pill mb-30">
                        <div class="d-flex flex-column-wrapper">
                            <div><img [src]="root.imageUrl() + '/svg-icons/service-benefit-icon.svg'"
                                    class="benefit-icon"></div>
                            <div class="ml-20">
                                <div class="mb-10"><span class="color-black font-18">Order Management</span></div>
                                <div><span class="color-black content-text mb-0">Experience the real-time tracker that
                                        fulfils every customer order flawlessly
                                        & effectively, managing all your orders from a single platform</span></div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-xl-6 col-lg-5">
                <div class="right-content">
                    <img [src]="root.imageUrl() + '/ez-service-benefit-right-bg.png'">
                </div>
            </div>
        </div>
    </div>
</div>