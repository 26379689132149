<div class="career">
    <div class="round">
        <div class="small-round">
        </div>
    </div>
    <div class="panel-wrapper panel-wrapper-bg" id="engineer-new-future">
        <div class="row no-gutters row-wrapper">
            <div class="col-12 col-md-6">
                <div class="new-future-together">
                    <div>
                        <div class="mb-15"><span class="font-22">Excited to</span></div>
                    </div>
                    <div class="font-orange font-32 font-600">
                        <div><span>Let’s Engineer</span></div>
                        <div><span>A New Future Together</span></div>
                    </div>
                    <div class="mt-20">
                        <p class="font-14">
                            We are a bunch of geeky and creative people, who live, breathe and believe in the power of
                            technology to create a world with innovations - a better world. We are in search of
                            energetic innovators, thinkers and doers who dare to learn fast, adapt faster and execute in
                            real-time. The developers who passionately talk about the perfection in design and the
                            designers who talk about development trends with conviction or a marketer who wants to build
                            up their own sphere of stories. Think you have got what it takes? With your heartfelt desire
                            to be a pioneer in this digital realm, consider joining a forward-thinking team.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="margin-top img-width-center">
                    <img [src]="root.imageUrl() + '/ez-career-new-future-together.png '" alt="" class="img-width-resp"
                        data-aos="fade-down" data-aos-duration="1500">
                </div>
            </div>
        </div>
    </div>
    <div class="Benefits-ekZero" id="benefits">
        <div class="Benefits-ez-row-1 panel-wrapper">
            <div class="row no-gutters">
                <div class="col-2 col-md-6 col-xl-7"></div>
                <div class="col-10 col-md-6 col-xl-5">
                    <div class=" text-right">
                        <div>
                            <div class="mb-15"><span class="font-22">Take a look at</span></div>
                        </div>
                        <div class="font-orange font-32 font-600">
                            <div><span>Benefits @</span></div>
                            <div><span>ekZero</span></div>
                        </div>
                        <div class="mt-20">
                            <p class="font-14">
                                We are a bunch of geeky and creative people, who live, breathe and believe in the power
                                of technology to create a world with innovations - a better world.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="Benefits-ez-row-2">
            <div class="row no-gutters">
                <!-- <div class="col-0 col-xl-1"></div> -->
                <div class="col-12 col-lg-7 col-xl-7 flex-wrapper">
                    <div class="resp-center"><img [src]="root.imageUrl() + '/benefit-image1.png'" alt=""
                            class="img-width left" data-aos="fade-right" data-aos-duration="1500"></div>
                </div>
                <div class="col-12 col-lg-5">
                    <div class="m-top-15 margin-top">
                        <div class="d-flex benefit-points">
                            <div class="m-x-25">
                                <span class="font-light-orange font-600 font-44">01</span>
                            </div>
                            <div class="m-top-15">
                                <div class="mb-10">
                                    <span class="font-16 font-600">Never Stop Learning</span>
                                </div>
                                <div>
                                    <span class="font-14">Constant and never-ending improvement is our core culture. We
                                        give you whatever is needed to grow at a 3x pace. Learning is a growth catalyst.

                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex benefit-points">
                            <div class="m-x-25">
                                <span class="font-light-orange font-600 font-44">02</span>
                            </div>
                            <div class="m-top-15">
                                <div class="mb-10">
                                    <span class="font-16 font-600">Work On The Next Big Thing</span>
                                </div>
                                <div>
                                    <span class="font-14">We highly encourage new ideas and insights. Become a part of
                                        our team and share your expertise on our next big projects.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex benefit-points">
                            <div class="m-x-25">
                                <span class="font-light-orange font-600 font-44">03</span>
                            </div>
                            <div class="m-top-15">
                                <div class="mb-10">
                                    <span class="font-16 font-600">Diversity and Growth</span>
                                </div>
                                <div>
                                    <span class="font-14">We're striving to maintain a work environment that's open,
                                        vibrant, and diverse.

                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="Benefits-ez-row-3">
            <div class="row no-gutters">
                <div class="col-12 col-lg-5 order-1">
                    <div class="m-top-15 margin-top text-right">
                        <div class="d-flex benefit-points right">
                            <div class="m-top-15">
                                <div class="mb-10">
                                    <span class="font-16 font-600">Exploring Opportunities
                                    </span>
                                </div>
                                <div>
                                    <span class="font-14">Innovation comes from the start of something new! We support
                                        creative concepts and aren't afraid to explore new ideas because we learn from
                                        every experience.
                                    </span>
                                </div>
                            </div>
                            <div class="m-x-25">
                                <span class="font-blue font-600 font-44">04</span>
                            </div>
                        </div>
                        <div class="d-flex benefit-points right">
                            <div class="m-top-15">
                                <div class="mb-10">
                                    <span class="font-16 font-600">Healthy Work Environment
                                    </span>
                                </div>
                                <div>
                                    <span class="font-14">We provide a flexible workspace and believe in creatively
                                        solving problems with the trust and team support.
                                    </span>
                                </div>
                            </div>
                            <div class="m-x-25">
                                <span class="font-blue font-600 font-44">05</span>
                            </div>
                        </div>
                        <div class="d-flex benefit-points right">
                            <div class="m-top-15">
                                <div class="mb-10">
                                    <span class="font-16 font-600">Shape you Career
                                    </span>
                                </div>
                                <div>
                                    <span class="font-14">Bring your ideas to life and explore new career paths,
                                        opportunities, and job rotations.</span>
                                </div>
                            </div>
                            <div class="m-x-25">
                                <span class="font-blue font-600 font-44">06</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-7 col-xl-7 order-2 flex-wrapper">
                    <div class="text-right resp-center"><img [src]="root.imageUrl() + '/benefit-image2.png'" alt=""
                            class="img-width right" data-aos="fade-left" data-aos-duration="1500"></div>
                </div>
                <!-- <div class="col-0 col-xl-1"></div> -->
            </div>
        </div>
    </div>
</div>