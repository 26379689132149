import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'journey',
  templateUrl: './journey.component.html',
  styleUrls: ['./journey.component.scss']
})
export class JourneyComponent implements OnInit {

  block1: boolean = true;
  block2: boolean = false;
  block3: boolean = false;

  constructor(public root: RootService) { }

  ngOnInit(): void {
  }
  onBlock1() {
    this.block1 = true;
    this.block2 = false;
    this.block3 = false;
  }
  onBlock2() {
    this.block1 = false;
    this.block2 = true;
    this.block3 = false;
  }
  onBlock3() {
    this.block1 = false;
    this.block2 = false;
    this.block3 = true;
  }

}
