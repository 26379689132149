<div id="explore-services">
    <div class="explore-block">
        <div class="row">
            <div class="col-xl-8 col-lg-order-2">
                <div class="left-content">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="mb-30">
                                <div><img class="service-img" [src]="root.imageUrl() + '/svg-icons/native-mobile.svg'">
                                </div>
                                <div class="mt-15"><span class="color-black service-title font-600">Native Mobile App
                                        Development

                                    </span></div>
                                <div class="mt-15"><span class="color-black content-text">Native apps are generally
                                        single-platform apps. These apps are built with programming languages and tools
                                        that are specific to one platform only. These applications are very fast and
                                        responsive as they are built using platform core programming languages and API’s
                                        which results in more efficiency in the functioning of the app. ekZero mobile
                                        app developers have a rich experience in building high-quality native
                                        applications for both Android and iOS systems aligned with all the business and
                                        security requirements.
                                    </span></div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-30">
                                <div><img class="service-img" [src]="root.imageUrl() + '/svg-icons/hybrid.svg'">
                                </div>
                                <div class="mt-15"><span class="color-black service-title font-600">Hybrid Mobile App
                                        Development</span></div>
                                <div class="mt-15"><span class="color-black content-text">Hybrid applications are those
                                        which beautifully combine the elements of both native apps and web applications.
                                        These applications are built with the leverage of HTML, CSS, or JavaScript and
                                        run in a web view. Compared to native applications, hybrid apps provide
                                        portability, streamline access to various hardware/software capabilities,
                                        cheaper origination costs, and faster speed to market. Our expert delivers
                                        top-quality hybrid app development services that can go live without hiccups.
                                    </span></div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="mb-30">
                                <div><img class="service-img" [src]="root.imageUrl() + '/svg-icons/web.svg'">
                                </div>
                                <div class="mt-15"><span class="color-black service-title font-600">Progressive Web App
                                        Development
                                    </span></div>
                                <div class="mt-15"><span class="color-black content-text">The progressive web apps are
                                        relatively modern in the mobile application domain. These applications are built
                                        using the web technology that runs in the browser and may be added to the home
                                        screen. They generally don’t need to be distributed via native app stores but
                                        can be included in them. We at ekZero give our clients flexibility through our
                                        progressive web app development services that deliver native-like capabilities
                                        and installation while reaching anyone, anywhere, on any device with a single
                                        codebase.
                                    </span></div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-30">
                                <div><img class="service-img" [src]="root.imageUrl() + '/svg-icons/wearable.svg'">
                                </div>
                                <div class="mt-15"><span class="color-black service-title font-600">Wearable and
                                        Embedded App Development
                                    </span></div>
                                <div class="mt-15"><span class="color-black content-text">Wearable application
                                        development is a sweeping change in the technology market. The perfect balance
                                        between a simple user interface (UI) and powerful functionality leads to a great
                                        wearable device user experience (UX). ekZero has great teams that are advanced
                                        and skilled with the development of wearable and embedded applications that can
                                        be integrated with smart devices or proprietary peripherals.
                                    </span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-order-1">
                <div class="right-content">
                    <div class="right-bg">
                        <div class="mt-20 info-content"><span>Our Mobile Application Offerings</span></div>
                        <div class="mt-20"><span class="content-text">ekZero provides customers with a full cycle of
                                application design, development, integration, and management services.</span></div>
                        <div><a class="btn-news btn-md" [routerLink]="root.ourServices()" target="_top">VIEW ALL</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>