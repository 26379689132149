<!-- -------------harsh------------timezone----------start--------- -->
<!-- <div class="timezone mt-100 color-black">
    <div class="row">
        <div class="col-4 d-flex align-items-center flex-column">
            <div><img [src]="root.imageUrl() + '/india-time.png '" alt=""></div>
            <div><span>India</span></div>
        </div>
        <div class="col-4 d-flex align-items-center flex-column">
            <div><img [src]="root.imageUrl() + '/canada-time.png '" alt=""></div>
            <div><span>Canada</span></div>
        </div>
        <div class="col-4 d-flex align-items-center flex-column">
            <div><img [src]="root.imageUrl() + '/usa-time.png '" alt=""></div>
            <div><span>USA</span></div>
        </div>

    </div>
</div> -->
<!-- ---------------------timezone---------------end------- -->
<!-- -------------harsh------------timezone----------start--------- -->
<div class="timezone">
    <div class="row no-gutters">
        <div class="col-12 col-sm-4">
            <div class="clock-wrapper center flex-column">
                <div class="clock-bg center">
                    <div class="clock-body orange-clock center">
                        <div class="time-label time-label-12"></div>
                        <div class="time-label time-label-3"></div>
                        <div class="time-label time-label-6"></div>
                        <div class="time-label time-label-9"></div>
                        <div class="time-label-2 time-label-1"></div>
                        <div class="time-label-2 time-label-4"></div>
                        <div class="time-label-2 time-label-7"></div>
                        <div class="time-label-2 time-label-10"></div>
                        <div class="clock-center">
                            <div class="second bg-orange"></div>
                            <div class="minute"></div>
                            <div class="hour"></div>
                        </div>
                    </div>
                </div>
                <div><span class="color-black">India</span></div>
            </div>
        </div>
        <div class="col-12 col-sm-4">
            <div class="clock-wrapper center flex-column">
                <div class="clock-bg center">
                    <div class="clock-body blue-clock center">
                        <div class="time-label time-label-12"></div>
                        <div class="time-label time-label-3"></div>
                        <div class="time-label time-label-6"></div>
                        <div class="time-label time-label-9"></div>
                        <div class="time-label-2 time-label-1"></div>
                        <div class="time-label-2 time-label-4"></div>
                        <div class="time-label-2 time-label-7"></div>
                        <div class="time-label-2 time-label-10"></div>
                        <div class="clock-center">
                            <div class="second bg-blue"></div>
                            <div class="minute"></div>
                            <div class="hour"></div>
                        </div>
                    </div>
                </div>
                <div><span class="color-black">Canada</span></div>
            </div>
        </div>
        <div class="col-12 col-sm-4">
            <div class="clock-wrapper center flex-column">
                <div class="clock-bg center">
                    <div class="clock-body orange-clock center">
                        <div class="time-label time-label-12"></div>
                        <div class="time-label time-label-3"></div>
                        <div class="time-label time-label-6"></div>
                        <div class="time-label time-label-9"></div>
                        <div class="time-label-2 time-label-1"></div>
                        <div class="time-label-2 time-label-4"></div>
                        <div class="time-label-2 time-label-7"></div>
                        <div class="time-label-2 time-label-10"></div>
                        <div class="clock-center">
                            <div class="second bg-orange"></div>
                            <div class="minute"></div>
                            <div class="hour"></div>
                        </div>
                    </div>
                </div>
                <div><span class="color-black">USA</span></div>
            </div>
        </div>
    </div>
</div>
<!-- ---------------------timezone---------------end------- -->