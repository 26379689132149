<app-home-banner></app-home-banner>
<div class="ez-section-card">
    <div class="ez-technology-platform-section">
        <div class="technologies-section">
            <div class="" id="home-banner">
                <!-- technologies-section-bg -->
                <!-- <technology-platforms></technology-platforms> -->
                <offer-section></offer-section>
                <!-- <case-study></case-study>
                <testimonials></testimonials> -->
                <insights></insights>
                <home-contact-us></home-contact-us>
            </div>
        </div>
    </div>
</div>