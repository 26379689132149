<div id="product-feature">
    <div class="feature-section">
        <div class="feature-wrapper">
            <div class="left-content">
                <div class="mt-20 info-content"><span class="color-orange">Dynamic Features of VegitOne</span></div>
                <div><span class="color-black content-text mt-15">Explore a rich set of facilities tailored to meet your fleet operation requirements.
                </span>
                </div>
            </div>
            <div class="key-feature-wrapper">
                <div class="row no-gutters">
                    <div class="col-xl-6">
                        <div class="left-panel">
                            <div class="img-wrapper">
                                <img [src]="root.imageUrl() + '/feat-vegitone.png'">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="overflow-wrapper -ms-scrollbar scrollable-body">
                            <div class="card-panel-wrapper">
                                <div class="right-panel row no-gutters">
                                    <div class="feature-list col-xl-12 col-lg-4 col-4">
                                        <div class="d-flex flex-wrapper">
                                            <div class="mgmt-icon center"><img
                                                   [src]="root.imageUrl() + '/svg-icons/order-mgmt-icon.svg'">
                                            </div>
                                            <div class="feature-content">
                                                <div class="mb-15"><span class="color-black feature-title">Order
                                                        Management</span>
                                                </div>
                                                <div>
                                                    <p class="color-black feature-info">Experience the real-time tracker
                                                        that fulfills every customer order flawlessly & effectively,
                                                        managing all your orders from a single platform.

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="feature-list col-xl-12 col-lg-4 col-4">
                                        <div class="d-flex flex-wrapper">
                                            <div class="mgmt-icon center"><img
                                                   [src]="root.imageUrl() + '/svg-icons/dispatch-mgmt-icon.svg'">
                                            </div>
                                            <div class="feature-content">
                                                <div class="mb-15"><span class="color-black feature-title">Dispatch
                                                        Management</span></div>
                                                <div>
                                                    <p class="color-black feature-info">Increase the operational
                                                        efficiency of your business by enhancing the service delivery
                                                        time, fleet visibility through instant and accurate data in
                                                        real-time.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="feature-list col-xl-12 col-lg-4 col-4">
                                        <div class="d-flex flex-wrapper">
                                            <div class="mgmt-icon center"><img
                                                   [src]="root.imageUrl() + '/svg-icons/fleet-mgmt-icon.svg'">
                                            </div>
                                            <div class="feature-content">
                                                <div class="mb-15"><span class="color-black feature-title">Fleet
                                                        Management</span>
                                                </div>
                                                <div>
                                                    <p class="color-black feature-info">Optimize your business by
                                                        employing advanced communications to increase fleet performance
                                                        and industry intelligence tools to maximize your business
                                                        efficiency and profitability.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="slider-block" id="blue-slider">
                            <div class="slider-component active"></div>
                            <div class="slider-component"></div>
                            <div class="slider-component"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>