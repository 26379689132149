import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'gtrack',
  templateUrl: './gtrack.component.html',
  styleUrls: ['./gtrack.component.scss']
})
export class GtrackComponent implements OnInit {

  newTitle: string = 'A Real-Time GPS Tracking System | GTrack | ekZero';
  longDescription: string = 'ekZero, GTrack by ekZero is a full-fledged GPS tracking solution suitable for both personal & professional use, to track your assets & valuables in real-time.';
  longKeyword: string = 'GTrack, real-time tracking, tracking location, tracking solution, GPS, GPS Tracking, GPS Solution, vehicle tracking, asset tracking, GTrack by ekZero, ekZero Corporation, ekZero Toronto';


  constructor(private title: Title,
    private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle(this.newTitle);
    this.meta.addTag({ name: "description", content: this.longDescription });
    this.meta.addTag({ name: "keywords", content: this.longKeyword });

  }

}
