<service-banner></service-banner>
<div class="ez-section-card">
    <div class="ez-technology-platform-section">
        <div class="technologies-section">
            <app-cloud-detail-intro></app-cloud-detail-intro>
            <app-cloud-detail-offer></app-cloud-detail-offer>
            <app-cloud-detail-benefit></app-cloud-detail-benefit>
            <app-cloud-detail-services></app-cloud-detail-services>
            <sales></sales>
        </div>
    </div>
</div>

