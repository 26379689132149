import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'oneviz',
  templateUrl: './oneviz.component.html',
  styleUrls: ['./oneviz.component.scss']
})
export class OnevizComponent implements OnInit {

  newTitle: string = 'Association Management Software | OneViz by ekZero';
  longDescription: string = 'ekZero’s comprehensive AMS solution to manage every type of Societies, Associations, Clubs, and any member based community | OneViz';
  longKeyword: string = 'ekZero, Association Management, AMS, Member management, Event Management, Community management, OneViz by ekZero, ekZero Solutions';


  constructor(private title: Title,
    private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle(this.newTitle);
    this.meta.addTag({ name: "description", content: this.longDescription });
    this.meta.addTag({ name: "keywords", content: this.longKeyword });

  }
}
