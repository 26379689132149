<div id="service-offer" data-aos="fade-up" data-aos-duration="1500">
    <div class="offfer-content-wrapper mb-50">
        <div class="offfer-content">
            <div class="mt-20 info-content"><span class="color-orange mb-5">Our Comprehensive</span></div>
            <div class="info-content"><span class="color-orange">Services</span></div>
            <div><span class="color-black content-text">Redesign antiquated processes with enterprise services.
                </span>
            </div>
        </div>
    </div>
    <div class="row no-gutters">
        <div class="col-xl-6 col-lg-7">
            <div class="left-content h-100">
                <div class="left-content-img position-relative">
                    <div class="offer-block">
                        <div class="offer-block-ribbon"></div>
                        <div class="text-center offer-text">
                            <p class="color-black">To streamline and automate workflows, ekZero assists you in achieving
                                digital self-service capabilities, combining disparate processes, and reconfiguring your
                                operating models. Our Enterprise Software Services provide end-to-end IT development and
                                support services including enterprise web solutions, process automation, product
                                enhancement, software testing, ERP and solution consultation. Boost your operations
                                speed, productivity, and innovation with solutions tailored to meet seamlessly automate
                                your operations with user-friendly interface and cross-functional methodology.
                            </p>
                        </div>
                        <div class="d-flex justify-content-end"><a class="btn-md btn-primary inquiry-btn"
                                [routerLink]="root.contact()" target="_top">INQUIRE NOW</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-5">
            <div class="right-content">
                <div class="offer-block-wrapper">
                    <div class="offer-pills mb-30">
                        <div class="d-flex align-items-center">
                            <div class="tech-icon center">
                                <img [src]="root.imageUrl() + '/svg-icons/enterprise-solution-default.svg'"
                                    class="default-icon">
                                <img [src]="root.imageUrl() + '/svg-icons/enterprise-solution-hover.svg'"
                                    class="hover-icon">
                            </div>
                            <div class="info-content ml-20"><span class="color-black mb-0">Enterprise web
                                    solutions</span>
                            </div>
                        </div>
                    </div>
                    <div class="offer-pills mb-30">
                        <div class="d-flex align-items-center">
                            <div class="tech-icon center">
                                <img [src]="root.imageUrl() + '/svg-icons/mobile-service-default.svg'"
                                    class="default-icon">
                                <img [src]="root.imageUrl() + '/svg-icons/mobile-service-hover.svg'" class="hover-icon">
                            </div>
                            <div class="info-content ml-20"><span class="color-black mb-0">Mobile Application</span>
                            </div>
                        </div>
                    </div>
                    <div class="offer-pills mb-30">
                        <div class="d-flex align-items-center">
                            <div class="tech-icon center">
                                <img [src]="root.imageUrl() + '/svg-icons/cloud-service-default.svg' "
                                    class="default-icon">
                                <img [src]="root.imageUrl() + '/svg-icons/cloud-service-hover.svg' " class="hover-icon">
                            </div>
                            <div class="info-content ml-20"><span class="color-black mb-0">Cloud Services</span>
                            </div>
                        </div>
                    </div>
                    <div class="offer-pills mb-30">
                        <div class="d-flex align-items-center">
                            <div class="tech-icon center">
                                <img [src]="root.imageUrl() + '/svg-icons/software-consulting-default.svg' "
                                    class="default-icon">
                                <img [src]="root.imageUrl() + '/svg-icons/software-consulting-hover.svg' "class="hover-icon">
                            </div>
                            <div class="info-content ml-20"><span class="color-black mb-0">Software Consulting</span>
                            </div>
                        </div>
                    </div>
                    
                  
                </div>
                <div class="d-none nav-arrows justify-content-end mr-50 pb-30">
                    <!-- d-flex -->
                    <div class="left-arrow mr-30 center"><a><img
                                [src]="root.imageUrl() + '/svg-icons/left-orange-arrow.svg'"></a>
                    </div>
                    <div class="right-arrow center"><a><img
                                [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>