<div class="incubation-benefit" data-aos="fade-up" data-aos-duration="1500">
    <div class="title-panel">
        <div class="row no-gutters">
            <div class="col-lg-6"></div>
            <div class="col-lg-6">
                <div class="left-content text-right">
                    <div class="mt-20 info-content">
                        <div><span class="color-orange">Benefits of Incubating</span>
                        </div>
                        <div><span class="color-orange">with ekZero</span>
                        </div>
                    </div>
                    <div class="mt-20">
                        <span class="color-black content-text">We design solutions and provide services that accelerate
                            innovation based on years of
                            industry knowledge
                            and demonstrated experience.</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="benefit-panel">
            <div class="row no-gutters">
                <div class="col-xl-3 col-md-6">
                    <div class="benefit-block">
                        <div class="icon-block">
                            <img [src]="root.imageUrl() + '/svg-icons/benefit-cost-flexibility.svg'">
                        </div>
                        <div class="title-panel">
                            <span class="title-text">
                                Low Costs & Flexibility
                            </span>
                        </div>
                        <div class="desc-panel">
                            <span class="desc-text">
                                Offering flexible options to suit your needs and budget.
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6">
                    <div class="benefit-block">
                        <div class="icon-block">
                            <img [src]="root.imageUrl() + '/svg-icons/benefit-access-to-capital.svg'">
                        </div>
                        <div class="title-panel">
                            <span class="title-text">
                                Access to Capital
                            </span>
                        </div>
                        <div class="desc-panel">
                            <span class="desc-text">
                                Access to early-stage capital, which emerging enterprises critically require.
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6">
                    <div class="benefit-block">
                        <div class="icon-block">
                            <img [src]="root.imageUrl() + '/svg-icons/benefit-administrative.svg'">
                        </div>
                        <div class="title-panel">
                            <span class="title-text">
                                Administrative Assistance
                            </span>
                        </div>
                        <div class="desc-panel">
                            <span class="desc-text">
                                Gain administrative guidance and consulting from experts with industrial know-how.
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6">
                    <div class="benefit-block">
                        <div class="icon-block">
                            <img [src]="root.imageUrl() + '/svg-icons/benefit-sophisticated-solution.svg'">
                        </div>
                        <div class="title-panel">
                            <span class="title-text">
                                Access to Sophisticated Solutions
                            </span>
                        </div>
                        <div class="desc-panel">
                            <span class="desc-text">
                                Leverage business solutions essential for industrial growth.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>