<life-banner></life-banner>
<div class="ez-section-card">
    <div class="ez-technology-platform-section">
        <div class="technologies-section">
            <div>
                <life-ekzero></life-ekzero>
            </div>           
            <app-life-footer></app-life-footer>
        </div>
    </div>
</div>
