import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RootService } from 'src/app/shared/services/root.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplyNowService } from '../../../../shared/services/apply-now.service';
import { CareersService } from '../../../../shared/services/careers.service';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { departmentsEnum, rolesEnum } from '../../../../shared/constants/careerlist';
@Component({
  selector: 'career-openings',
  templateUrl: './career-openings.component.html',
  styleUrls: ['./career-openings.component.scss']
})
export class CareerOpeningsComponent implements OnInit {

  oneAtATime = true;
  careerPopup: boolean = false;
  applyNow: FormGroup;
  base64StringValue: string;
  fileName: string = '';
  filePreview: string = '';
  imgName: string = '';
  imgType: string = '';
  imgURL: any;
  fileControl = new FormControl(null);
  showErrorMsg: boolean = false;
  showFileMsg: boolean = false;
  showSuccessMsg: boolean = false;
  deptControl = new FormControl(null);
  roleControl = new FormControl(null);
  subDeptControl = new FormControl(null);
  private fragment: string = 'application';
  departMents: object[] = [];
  subDepartments: object[] = [];
  jobLevels: object[] = [];
  departEnum = departmentsEnum;
  roleEnum = rolesEnum;
  public isLoading: boolean = false;

  constructor(public root: RootService, private modalService: NgbModal, public applyService: ApplyNowService,
    private careerService: CareersService, private route: ActivatedRoute) {
    this.getApplyNow();
    this.route.fragment.subscribe(
      fragment => { this.fragment = fragment; console.log(this.fragment) }
    );

  }

  ngAfterViewInit(): void {
    try {
      const anchor = document.querySelector<HTMLAnchorElement>('#' + this.fragment);
      console.log(anchor)
      anchor.focus();
      anchor.scrollIntoView();
    } catch (e) { }
  }

  ngOnInit(): void {

    this.getAllRoles()

  }


  getApplyNow() {
    this.applyNow = new FormGroup({
      "id": new FormControl(null),
      "name": new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(25),
        Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z ]+$'),
      ])),
      "email": new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      "phone": new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]{3}[0-9]{4}[0-9]{3}$')
      ])),
      "currentCompany": new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(25),
      ])),
      "description": new FormControl(null, Validators.required),
      "uploadDTO": new FormGroup({
        "name": new FormControl(null, Validators.required),
        "encodedData": new FormControl(null, Validators.required),
        "fieldName": new FormControl(null, Validators.required),
      }),
      "fileType": new FormControl('PDF'),
      "jobRoleId": new FormControl(null, Validators.required),
      "yearsOfExp": new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.maxLength(2)
      ]),
    })
  }


  openModal(Content) {
    this.modalService.open(Content, { size: 'lg', centered: true, windowClass: 'popup' });
  }

  getdemo_validation_messages = {
    'name': [
      { type: 'required', message: 'First name is required' },
      { type: 'maxlength', message: 'First name cannot be more than 25 characters long' },
      { type: 'pattern', message: 'First name must only contain alphabets' }
    ],
    'organizationName': [
      { type: 'required', message: 'Company is required' },
      { type: 'maxlength', message: 'Company cannot be more than 25 characters long' },
    ],
    'email': [
      { type: 'required', message: 'Email is required' },
      { type: 'pattern', message: 'Enter a valid email' }
    ],
    'phone': [
      { type: 'required', message: 'Contact Number is required' },
      { type: 'pattern', message: 'Enter a valid Contact Number' },
    ],
    'yearsOfExp': [
      { type: 'required', message: 'Experience is required' },
    ],
    'description': [
      { type: 'required', message: 'Description message is required' },
    ]
  }
  des() {
    console.log(this.applyNow)
  }

  careerSubmit() {
    const uploadValue = this.applyNow.get('uploadDTO').value

    const val = this.applyNow.value;
    this.isLoading = true;

    this.careerService.career(val)
      .subscribe((res) => {
        this.showSuccessMsg = true;
        this.applyNow.reset();
        setTimeout(() => {
          this.showSuccessMsg = false;
        }, 5000);
        this.isLoading = false;

      }, (error) => {
        console.log(error)
        this.showErrorMsg = true;
        setTimeout(() => {
          this.showErrorMsg = false;
        }, 5000);
        this.isLoading = false;
      }
      );
  }

  experience() {
    console.log(this.applyNow)
  }

  preview(event, index) {
    let reader = new FileReader();
    let file = event.target.files[0];
    if (file) {
      const fileType = ["application/pdf"].includes(file.type)
      if (!fileType) {
        return;
      }
      const size = this.binaryBytes(file['size']);
      if (size > 5) {
        this.showFileMsg = true;
        setTimeout(() => {
          this.showFileMsg = false;
        }, 5000);
        return;
      }
      reader.readAsDataURL(file);
      var binaryString = event.target.result;
      this.base64StringValue = btoa(binaryString);
      const ref = this;
      reader.onload = (_event) => {
        this.imgURL = reader.result;
        ref.imgName = file.name;
        ref.imgType = file.type;

        ref.fileName = file.name;
        ref.filePreview = 'data:' + file.type + ';base64,' + (<string>reader.result).split(',')[1];
        const uploadDTO = {
          fieldName: "EKZERO_CAREERS_RESUME",
          name: file.name,
          encodedData: this.imgURL
        };
        this.applyNow.get('uploadDTO').setValue(uploadDTO);
      }
    }

  }

  binaryBytes(size) {
    let mb = size / 1048576
    return mb;
  }

  departmentChange() {
    const deptValue = this.deptControl.value;
    let subDepts = _.find(this.departMents, { department: deptValue })
    if (deptValue === 'QA') {
      this.subDepartments = [];
      this.jobLevels = subDepts['subDepartments'].filter((el) => el['active'])
      console.log(this.applyNow.value)
    } else {
      this.jobLevels = [];
      this.subDeptControl.reset();
      this.applyNow.get('jobRoleId').reset();
      subDepts['subDepartments'] = subDepts['subDepartments'].filter((el) => el['active'])
      this.subDepartments = _.uniqBy(subDepts['subDepartments'], 'subDepartment');
    }
  }

  positionChange() {
    const deptValue = this.deptControl.value;
    console.log(this.subDeptControl.value);
    const subDeptValue = this.subDeptControl.value;
    const subDepts = _.find(this.departMents, { department: deptValue })
    this.jobLevels = subDepts['subDepartments'].filter((el) => {
      if (el['active'] && el['subDepartment'] === subDeptValue) {
        return el;
      }
    })
  }

  roleChange() {
    const roleValue = this.roleControl.value;
    console.log(roleValue)
    this.applyNow.get('jobRoleId').setValue(+roleValue);
    console.log(this.applyNow.value)
  }

  getAllRoles() {
    this.careerService.getAllRoles().subscribe((res) => {
      console.log(res)
      if (res['code'] === 200) {
        this.departMents = _.cloneDeep(res['result']);
        this.departMents = this.departMents.filter((el) => el['departmentActive'])
      }
    }, (error) => {
      console.log(error)
    })
  }


}
