<blog-banner></blog-banner>
<div id="blog-detail">
    <div class="blog-detail-wrapper">
        <div class="row no-gutters">
            <div class="col-xl-8 col-lg-7 col-12 col-md-order">
                <div class="left-content-panel">
                    <div>
                        <img [src]="root.imageUrl() + '/loss-of-truck-driver-in-covid-pandemic.jpg ' "
                            class="blog-detail-img">
                    </div>
                    <div class="text-wrapper">
                        <div class="mb-20">
                            <span class="blog-title color-black">Loss to Truck Drivers in Covid-19 Pandemic</span>
                        </div>
                        <div class="d-flex align-items-center justify-content-start blog-publish">
                            <div><span class="color-orange">Truck Drivers</span></div>
                            <div class="ml-15"><span class="pipe color-black">|</span></div>
                            <div class="ml-15"><span class="color-black">June 05, 2020</span></div>
                        </div>
                    </div>
                    <div class="blog-desc">
                        <p class="content-desc">Nearly 88,300 truck drivers lost their jobs due to Covid-19 in April.
                            It’s the largest single-month loss of trucking jobs till date. It nullified all trucking
                            employments gained during the last five years, bringing the industry back to its employment
                            rates in November 2014.</p>
                        <p class="content-desc">According to the Bureau of Labor Statistics, almost 20.5 million
                            payrolls were cut in April, which is 25 times bigger than the worst monthly decline seen
                            during the recession in the 2000s. In the U.S., the unemployment rate is now 14.7%, its
                            highest since the Great Depression. Even though truck drivers have been deemed as essential
                            workers, freight rates and volumes have collapsed this year.</p>
                        <p class="content-desc">Due to the economic slowdown, the manufacturing sector is at a
                            standstill. As a result, trucks aren’t moving. About three-quarters of all freight by weight
                            is moved by truck in the U.S., so if goods aren’t being bought or moved, truck drivers are
                            out of work. Because of the Covid-19 lockdown, the rate of moving goods via truck has fallen
                            to the lowest levels since 2009.</p>
                        <p class="content-desc">Covid-19 is just the latest in challenges for the trucking industry. The
                            trucking industry had a rough 2019, with around 640 trucking companies going bankrupt.
                            That’s more than thrice the number of bankruptcies from the year before. In the august of
                            2019, trucking companies slashed some 5,100 jobs. In April, 16 times as many truck drivers
                            lost their payrolls.</p>
                        <p class="content-desc">With several factories shut and port traffic down, U.S. freight numbers
                            have plummeted as truck drivers battle over jobs to try to stay afloat through the pandemic.
                            If prolonged, smaller U.S. trucking companies who cannot spread their costs across a large
                            fleet could shut down, pushing skilled drivers out of the business and accelerating a
                            longer-term shortage of truckers.
                        </p>
                        <p class="content-desc">According to the American Trucking Associations, about 97% of trucking
                            companies in the United States operate lesser than 20 trucks, and 91% have six or lesser.
                            Often, these workers rely more on one-off jobs than long-term contracts.</p>
                        <p class="content-desc">
                            Few routes are paying just 75 to 80 cents a mile. This is less than half of what’s required
                            to pay for fuel, insurance and other operating costs. Pay is generally determined by
                            distance driven and they have also declined. Bids in the spot market have fallen to their
                            lowest in years as shut factories, malls and schools have left several truckers that
                            generally have long-term contracts searching for new cargo to haul.</p>

                        <p class="content-desc">Every driver and carrier in the trucking industry is competing for the
                            80% of business that’s open, causing a plunge in pricing within the spot market that can’t
                            sustain a carrier’s costs. Smaller trucking companies often need advances on their payment
                            to buy fuel for the shipment they’re carrying.</p>

                        <p class="content-desc">Amid this pandemic, several manufacturers have been expanding their
                            payment terms from 60 to 90 days, or 90 to 120 days. With the carriers dealing with the
                            plummeting spot market, we’re looking at a significant reduction in capacity if these
                            carriers go out of business at the rate we expect.</p>
                        <p class="content-desc">The speed of getting people back to work and getting the economy going
                            will depend on how fast we get a health solution.</p>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-5 col-12">
                <div class="right-content-panel">
                    <div class="content-body d-none">
                        <div class="search-block mb-20">
                            <div><span class="color-black font-18">Search our <span
                                        class="color-orange transform-capitalize font-18">Blogs</span></span></div>
                            <div class="mt-20">
                                <div class="search-bar">
                                    <input type="text" class="form-control search-input" placeholder="Search...">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-body">
                        <div class="mb-20">
                            <div><span class="color-black font-18">Recent <span
                                        class="color-orange transform-capitalize font-18">Posts</span></span></div>
                            <div class="mt-20">
                                <div class="collapsable-wrapper position-relative"
                                    [ngClass]="{'collapsable-active': isDisplay}">
                                    <div
                                        class="collapsable-header responsive-view align-items-center justify-content-between mt-30">
                                        <div><span class="color-black">Check Our Recent Posts</span></div>
                                        <div><a class="collapsable-icon" (click)="isDisplay = !isDisplay"></a></div>
                                    </div>
                                    <div class="blog-title-list blog-list collapsable-body -ms-scrollbar scrollable-body"
                                        [ngbCollapse]="!isDisplay">
                                        <div class="blog-title active"><a [routerLink]="root.whatiscloudcomputing()"
                                                target="_top">What is Cloud Computing? How it helps your business
                                                gain a competitive advantage.</a>
                                        </div>
                                        <div class="blog-title active"><a [routerLink]="root.mobileEssential()"
                                                target="_top">6 Essential Mobile App Design Steps to Take in 2022</a>
                                        </div>
                                        <div class="blog-title active"><a [routerLink]="root.nextPhaseOfInternet()"
                                                target="_top"> Evolution of Web 3.0. The next phase of the Internet!</a>
                                        </div>
                                        <div class="blog-title active"><a [routerLink]="root.customSoftware()"
                                                target="_top"> Custom Software Consulting: Should your startup go for
                                                it? </a></div>
                                        <div class="blog-title active"><a [routerLink]="root.cloudMigration()"
                                                target="_top">How do you overcome obstacles in Cloud Migration? Simple
                                                solutions.</a></div>
                                        <div class="blog-title"><a [routerLink]="root.truckingindustry()"
                                                target="_top">Ensuring Safety in the Trucking Industry. Regulations,
                                                Technology, and Advantages to business owners.</a></div>
                                        <div class="blog-title"><a [routerLink]="root.losttruckdriver()"
                                                target="_top">Loss to Truck Drivers in Covid-19 Pandemic.</a></div>
                                        <div class="blog-title"><a [routerLink]="root.fleetoperations()"
                                                target="_top">Evolution of GPS Technology in fleet operations.</a></div>
                                        <div class="blog-title"><a [routerLink]="root.logisticindustry()"
                                                target="_top">Will The Self-Driving Trucks Transform The Trucking And
                                                Logistic Industry?</a></div>
                                        <div class="blog-title"><a [routerLink]="root.efficientways()"
                                                target="_top">Skyrocket Your Association Revenue By Employing Efficient
                                                Ways.</a></div>
                                        <div class="blog-title"><a [routerLink]="root.membercentric()"
                                                target="_top">Create Member-Centric Culture for Your Association
                                                Employing AMS Simple Measures.</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-body">
                        <div class="mb-20">
                            <div><span class="color-black font-18">Categories </span></div>
                            <div class="mt-20">
                                <div class="collapsable-wrapper position-relative"
                                    [ngClass]="{'collapsable-active': isShow}">
                                    <div
                                        class="collapsable-header responsive-view align-items-center justify-content-between mt-30">
                                        <div><span class="color-black">Select Categories</span></div>
                                        <div><a class="collapsable-icon" (click)="isShow = !isShow"></a></div>
                                    </div>
                                    <div class="blog-category-list blog-list collapsable-body mt-20 -ms-scrollbar scrollable-body"
                                        [ngbCollapse]="!isShow">
                                        <div class="d-flex align-items-center flex-wrap">
                                            <div class="tag-item"><a [routerLink]="root.nextPhaseOfInternet()"
                                                    target="_top"> Technology</a></div>
                                            <div class="tag-item"><a [routerLink]="root.customSoftware()"
                                                    target="_top">Enterprise Software Solutions </a></div>
                                            <div class="tag-item"><a [routerLink]="root.cloudMigration()"
                                                    target="_top">Cloud Computing</a></div>
                                            <div class="tag-item"><a [routerLink]="root.truckingindustry()"
                                                    target="_top">Trucking Industry</a></div>
                                            <div class="tag-item"><a [routerLink]="root.losttruckdriver()"
                                                    target="_top">Truck Drivers</a></div>
                                            <div class="tag-item"><a [routerLink]="root.fleetoperations()"
                                                    target="_top">GPS</a></div>
                                            <div class="tag-item"><a [routerLink]="root.logisticindustry()"
                                                    target="_top">Trucking</a></div>
                                            <div class="tag-item"><a [routerLink]="root.efficientways()"
                                                    target="_top">Employee</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>