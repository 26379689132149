import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';

import { IncubationRoutingModule } from './incubation-routing.module';
import { IncubationComponent } from './pages/incubation/incubation.component';
import { IncubationBannerComponent } from './components/incubation-banner/incubation-banner.component';
import { IncubationIntroComponent } from './components/incubation-intro/incubation-intro.component';
import { IncubationIndustriesComponent } from './components/incubation-industries/incubation-industries.component';
import { IncubationSupportComponent } from './components/incubation-support/incubation-support.component';
import { IncubationBenefitsComponent } from './components/incubation-benefits/incubation-benefits.component';


@NgModule({
  declarations: [IncubationComponent, IncubationBannerComponent, IncubationIntroComponent, IncubationIndustriesComponent, IncubationSupportComponent, IncubationBenefitsComponent],
  imports: [
    CommonModule,
    SharedModule,
    IncubationRoutingModule
  ]
})
export class IncubationModule { }
