import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'about-us-banner',
  templateUrl: './about-us-banner.component.html',
  styleUrls: ['./about-us-banner.component.scss']
})
export class AboutUsBannerComponent implements OnInit {

  constructor(public root: RootService) { }

  ngOnInit(): void {
  }

}
