<div id="product-vegitone">
    <div class="content-wrapper">
        <div class="row no-gutters">
            <div class="col-lg-9 col-md-order2">
                <div class="left-wrapper">
                    <div class="info-wrapper col-md-order2">
                        <div class="flex-wrapper">
                            <div class="mb-20"><span class="title-text">Why use <span class="font-600">OneViz?</span>
                                </span></div>
                            <div class="content-text-wrapper">
                                <p class="content-text">Providing you with bright solutions and profitable results,
                                    OneViz promises you to be your partner in growth. Choose OneViz & choose effortless
                                    growth for your organization.
                                </p>
                            </div>
                        </div>
                        <div class="key-feature-panel">
                            <div class="row no-gutters">
                                <div class="col-sm-3 col-6">
                                    <div class="feature-pill">
                                        <div class="feature-icon center mb-15"><img
                                                [src]="root.imageUrl() + '/svg-icons/oneviz-public-website-builder.svg'"></div>
                                        <div><span>Public Website </span></div>
                                        <div><span>Builder</span></div>
                                    </div>
                                </div>
                                <div class="col-sm-3 col-6">
                                    <div class="feature-pill">
                                        <div class="feature-icon center mb-15"><img
                                                [src]="root.imageUrl() + '/svg-icons/oneviz-event-planning.svg'"></div>
                                        <div><span>Event 
                                        </span></div>
                                        <div><span>Planning</span></div>
                                    </div>
                                </div>
                                <div class="col-sm-3 col-6">
                                    <div class="feature-pill">
                                        <div class="feature-icon center mb-15"><img
                                                [src]="root.imageUrl() + '/svg-icons/oneviz-document-center.svg'"></div>
                                        <div><span>Document </span></div>
                                        <div><span>Center</span></div>
                                    </div>
                                </div>
                                <div class="col-sm-3 col-6">
                                    <div class="feature-pill">
                                        <div class="feature-icon center mb-15"><img
                                                [src]="root.imageUrl() + '/svg-icons/oneviz-member-directory.svg'"></div>
                                        <div><span>Member</span></div>
                                        <div><span>Directory</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-none btn-grp">
                            <button class="btn-lg btn-white btn-demo mb-10"><span>WATCH</span><span
                                    class="font-600">DEMO</span></button>
                            <button class="btn-lg btn-white btn-download mb-10"><span>DOWNLOAD</span><span
                                    class="font-600">BROCHURE</span></button>
                        </div>
                    </div>
                    <div class="talk-section center flex-column col-md-order1">
                        <div class="mb-15"><img [src]="root.imageUrl() + '/svg-icons/user-talk-icon.svg'"></div>
                        <div><span class="content-text">Want to consult with a
                                <span class="font-600"> SPECIALIST ?</span></span></div>
                        <div><span class="font-600 talk-text">Let's Talk</span></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-order1">
                <div class="right-wrapper">
                    <div class="right-wrapper-img"></div>
                </div>
            </div>
        </div>
    </div>
</div>