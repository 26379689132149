import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RootService } from 'src/app/shared/services/root.service';
import { ContactService } from '../../../../shared/services/contact.service';
@Component({
  selector: 'home-contact-us',
  templateUrl: './home-contact-us.component.html',
  styleUrls: ['./home-contact-us.component.scss']
})
export class HomeContactUsComponent implements OnInit {

  contactUs: FormGroup;

  showErrorMsg: boolean = false;
  showSuccessMsg: boolean = false;

  ngOnInit(): void {
  }


  constructor(public root: RootService, public contactService: ContactService) {
    this.contactUs = new FormGroup({
      firstName: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(25),
        Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z ]+$'),
      ])),
      lastName: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(25),
        Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z ]+$'),
      ])),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      phone: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]{3}[0-9]{4}[0-9]{3}$')
      ])),

      message: new FormControl(null)

    })
  }

  contactUs_validation_messages = {
    'firstName': [
      { type: 'required', message: 'First name is required' },
      { type: 'maxlength', message: 'First name cannot be more than 25 characters long' },
      { type: 'pattern', message: 'First name must only contain alphabets' }
    ],
    'lastName': [
      { type: 'required', message: 'Last name is required' },
      { type: 'maxlength', message: 'Last name cannot be more than 25 characters long' },
      { type: 'pattern', message: 'Last name must only contain alphabets' }
    ],
    'email': [
      { type: 'required', message: 'Email is required' },
      { type: 'pattern', message: 'Enter a valid email' }
    ],
    'phone': [
      { type: 'required', message: 'Contact Number is required' },
      { type: 'pattern', message: 'Enter a valid Contact Number' },
    ],
    'message': [
      { type: 'required', message: 'Message is required' }
    ]
  }

  contactUsSubmit() {
    // const formValue = this.getdemo.value;

    const object =
    {
      "id": null,
      "firstName": this.contactUs.get('firstName').value,
      "lastName": this.contactUs.get('lastName').value,
      "email": this.contactUs.get('email').value,
      "phone": this.contactUs.get('phone').value,
      "organizationName": null,
      "jobRole": null,
      "jobTitle": null,
      "solutionArea": null,
      "industry": null,
      "country": null,
      "enquiringFor": null,
      "message": this.contactUs.get('message').value,
      "formType": "CONTACT_US"
    }
    console.log(object)
    this.contactService.contact(object)
      .subscribe((res) => {
        if (res['code'] === 200) {

          this.showSuccessMsg = true;
          this.contactUs.reset();
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 5000);
          
        }
        console.log(res)

      }, (error) => {
        this.showErrorMsg = true;
        setTimeout(() => {
          this.showErrorMsg = false;
        }, 5000);
      }
      );
  }


}
