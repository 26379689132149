<div class="incubation-industries">
    <div class="title-panel">
        <div class="row">
            <div class="col-lg-8">
            </div>
            <div class="col-lg-4">
                <div class="left-content text-right">
                    <div class="info-title"><span class="color-black">Industries</span></div>
                    <div class="mt-20 info-content">
                        <div><span class="color-orange">Industries</span>
                        </div>
                        <div><span class="color-orange">we expertise in</span>
                        </div>
                    </div>
                    <div class="mt-20">
                        <span class="color-black content-text">With a growing global network of clients, we expertise and partner with exceptional businesses from various industries.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="industries-panel">
        <div class="industries-block-container">
            <div class="row">
                <div class="col-xl-3 col-lg-4 col-sm-6 mb-20" data-aos="fade-right" data-aos-duration="1500">
                    <div class="industry-block">
                        <div class="icon-block">
                            <img [src]="root.imageUrl() + '/svg-icons/industry-logistic.svg'"/>
                        </div>
                        <div class="d-flex text-arrow-block">
                            <div class="text-block">
                                <span class="title-text">
                                    Logistics & Transportation
                                </span>
                            </div>                 
                            <!-- <div class="arrow-direction position-relative center">
                                <a class="orange-arrow arrow-norms position-absolute center">
                                    <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" alt="arrow">
                                </a>
                            </div>                     -->
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6 mb-20" data-aos="fade-right" data-aos-duration="1500">
                    <div class="industry-block">
                        <div class="icon-block">
                            <img [src]="root.imageUrl() + '/svg-icons/industry-entertainment.svg'"/>
                        </div>
                        <div class="d-flex text-arrow-block">
                            <div class="text-block">
                                <span class="title-text">
                                    Entertainment
                                </span>
                            </div>                 
                            <!-- <div class="arrow-direction position-relative center">
                                <a class="orange-arrow arrow-norms position-absolute center">
                                    <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" alt="arrow">
                                </a>
                            </div>                     -->
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6 mb-20" data-aos="fade-right" data-aos-duration="1500"> 
                    <div class="industry-block">
                        <div class="icon-block">
                            <img [src]="root.imageUrl() + '/svg-icons/industry-energy.svg'"/>
                        </div>
                        <div class="d-flex text-arrow-block">
                            <div class="text-block">
                                <span class="title-text">
                                    Energy & Utilities
                                </span>
                            </div>                 
                            <!-- <div class="arrow-direction position-relative center">
                                <a class="orange-arrow arrow-norms position-absolute center">
                                    <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" alt="arrow">
                                </a>
                            </div>                     -->
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6 mb-20" data-aos="fade-right" data-aos-duration="1500">
                    <div class="industry-block">
                        <div class="icon-block">
                            <img [src]="root.imageUrl() + '/svg-icons/industry-healthcare.svg'"/>
                        </div>
                        <div class="d-flex text-arrow-block">
                            <div class="text-block">
                                <span class="title-text">
                                    Healthcare
                                </span>
                            </div>                 
                            <!-- <div class="arrow-direction position-relative center">
                                <a class="orange-arrow arrow-norms position-absolute center">
                                    <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" alt="arrow">
                                </a>
                            </div>                     -->
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6 mb-20" data-aos="fade-right" data-aos-duration="1500">
                    <div class="industry-block">
                        <div class="icon-block">
                            <img [src]="root.imageUrl() + '/svg-icons/industry-banking.svg'"/>
                        </div>
                        <div class="d-flex text-arrow-block">
                            <div class="text-block">
                                <span class="title-text">
                                    Banking & Finance
                                </span>
                            </div>                 
                            <!-- <div class="arrow-direction position-relative center">
                                <a class="orange-arrow arrow-norms position-absolute center">
                                    <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" alt="arrow">
                                </a>
                            </div>                     -->
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6 mb-20" data-aos="fade-right" data-aos-duration="1500">
                    <div class="industry-block">
                        <div class="icon-block">
                            <img [src]="root.imageUrl() + '/svg-icons/industry-education.svg'"/>
                        </div>
                        <div class="d-flex text-arrow-block">
                            <div class="text-block">
                                <span class="title-text">
                                    Education
                                </span>
                            </div>                 
                            <!-- <div class="arrow-direction position-relative center">
                                <a class="orange-arrow arrow-norms position-absolute center">
                                    <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" alt="arrow">
                                </a>
                            </div>                     -->
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6 mb-20" data-aos="fade-right" data-aos-duration="1500">
                    <div class="industry-block">
                        <div class="icon-block">
                            <img [src]="root.imageUrl() + '/svg-icons/industry-iot.svg'"/>
                        </div>
                        <div class="d-flex text-arrow-block">
                            <div class="text-block">
                                <span class="title-text">
                                    Artificial Intelligence & IoT
                                </span>
                            </div>                 
                            <!-- <div class="arrow-direction position-relative center">
                                <a class="orange-arrow arrow-norms position-absolute center">
                                    <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" alt="arrow">
                                </a>
                            </div>                     -->
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6 mb-20" data-aos="fade-right" data-aos-duration="1500">
                    <div class="industry-block">
                        <div class="icon-block">
                            <img [src]="root.imageUrl() + '/svg-icons/industry-retail.svg'"/>
                        </div>
                        <div class="d-flex text-arrow-block">
                            <div class="text-block">
                                <span class="title-text">
                                    Retail
                                </span>
                            </div>                 
                            <!-- <div class="arrow-direction position-relative center">
                                <a class="orange-arrow arrow-norms position-absolute center">
                                    <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" alt="arrow">
                                </a>
                            </div>                     -->
                        </div>
                    </div>
                </div>    
            </div>
        </div>
    </div>
</div>