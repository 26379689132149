<about-us-banner></about-us-banner>
<div class="ez-section-card" id="about-us">
    <div class="ez-technology-platform-section">
        <div class="technologies-section">
            <div class="technologies-section-bg">
                <ekzero-intro></ekzero-intro>
                <div class="content-wrapper">
                    <our-mission></our-mission>
                </div>
                <journey></journey>
                <our-experts></our-experts>
                <work-process></work-process>
            </div>
        </div>
    </div>
</div>