import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'app-ensuring-safety-in-the-trucking-industry',
  templateUrl: './ensuring-safety-in-the-trucking-industry.component.html',
  styleUrls: ['./ensuring-safety-in-the-trucking-industry.component.scss']
})
export class EnsuringSafetyInTheTruckingIndustryComponent implements OnInit {

  isDisplay: boolean = false;
  isShow: boolean = false;
  constructor(public root: RootService) { }

  ngOnInit(): void {
  }

}
