import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'goverment',
  templateUrl: './goverment.component.html',
  styleUrls: ['./goverment.component.scss']
})
export class GovermentComponent implements OnInit {

  constructor(public root: RootService) { }

  ngOnInit(): void {
  }

}
