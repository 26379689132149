import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'app-loss-truck-drivers-in-covid-pandemic',
  templateUrl: './loss-truck-drivers-in-covid-pandemic.component.html',
  styleUrls: ['./loss-truck-drivers-in-covid-pandemic.component.scss']
})
export class LossTruckDriversInCovidPandemicComponent implements OnInit {

  isDisplay: boolean = false;
  isShow: boolean = false;
  constructor(public root: RootService) { }

  ngOnInit(): void {
  }

}
