import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'devops-service',
  templateUrl: './devops-service.component.html',
  styleUrls: ['./devops-service.component.scss']
})
export class DevopsServiceComponent implements OnInit {

  newTitle: string = 'DevOps Services & Consultation | ekZero';
  longDescription: string = 'Achieve high-volume application delivery and operational efficiency with ekZero DevOps Services';
  longKeyword: string = 'ekZero, ekZero DevOps Services, DevOps Automation, DevOps Maintenance, DevOps on Cloud, DevOps Planning, DevOps Assessment, DevOps Management, ekZero Toronto';

  constructor(private title: Title,
    private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle(this.newTitle);
    this.meta.addTag({ name: "description", content: this.longDescription });
    this.meta.addTag({ name: "keywords", content: this.longKeyword });
  }

}
