import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AboutUsRoutingModule } from './about-us-routing.module';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { AboutUsBannerComponent } from './components/about-us-banner/about-us-banner.component';
import { EkzeroIntroComponent } from './components/ekzero-intro/ekzero-intro.component';
import { OurMissionComponent } from './components/our-mission/our-mission.component';
import { OurExpertsComponent } from './components/our-experts/our-experts.component';
import { WorkProcessComponent } from './components/work-process/work-process.component';
import { JourneyComponent } from './components/journey/journey.component';


@NgModule({
  declarations: [AboutUsComponent, AboutUsBannerComponent, EkzeroIntroComponent, OurMissionComponent, OurExpertsComponent, WorkProcessComponent, JourneyComponent],
  imports: [
    CommonModule,
    AboutUsRoutingModule
  ]
})
export class AboutUsModule { }
