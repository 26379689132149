import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'product-vegitone',
  templateUrl: './product-vegitone.component.html',
  styleUrls: ['./product-vegitone.component.scss']
})
export class ProductVegitoneComponent implements OnInit {

  constructor(public root: RootService) { }

  ngOnInit(): void {
  }

}
