<div
  id="our-mission"
  data-aos="fade-up"
  data-aos-duration="1500"
  data-aos-easing="ease-in-out"
>
  <div class="heading-wrapper">
    <div class="mt-20 info-content">
      <span class="color-orange">Our Mission & Vision</span>
    </div>
  </div>
  <div class="card-wrapper">
    <div class="row no-gutters">
      <div class="col-lg-6">
        <div class="title-block mission">
          <div class="mb-20">
            <img [src]="root.imageUrl() + '/svg-icons/mission-icon.svg'">                
          </div>
          <div><span>Our Mission</span></div>
        </div>
        <div class="mission-card">
          <div class="title-content">
            <div>
              <span class="content-text">Focus on what matters to you. </span>
            </div>
            <div>
              <span class="content-text"
                >We, at ekZero, make sure that our clients and customers are
                always ahead of the curve when it comes to technology, while
                they continue focusing on their core business and success.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="title-block vision">
          <div class="mb-20">
            <img [src]="root.imageUrl() + '/svg-icons/vision-icon.svg'">
          </div>
          <div><span>Our Vision</span></div>
        </div>
        <div class="mission-card">
          <div class="title-content">
            <div>
              <span class="content-text"
                >Empowering tomorrow with advanced technological solutions and
                innovative products.
              </span>
            </div>
            <div>
              <span class="content-text"
                >We are working towards making technologies affordable and
                reachable to a vast spectrum of populations and businesses
                globally.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
