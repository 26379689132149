import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'ecs-work-process',
  templateUrl: './ecs-work-process.component.html',
  styleUrls: ['./ecs-work-process.component.scss']
})
export class EcsWorkProcessComponent implements OnInit {

  constructor(public root: RootService) { }

  ngOnInit(): void {
  }

}
