<div class="ez-service-panel mb-40" id="ecs-services">
    <div class="row no-gutters">
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <div class="service-left-content">
                <div class="info-title"><span class="color-black">SERVICES</span></div>
                <div class="mt-20 info-content">
                    <div><span class="color-orange">We Provide</span></div>
                    <div><span class="color-orange">Awesome</span></div>
                    <div><span class="color-orange">Services</span></div>
                </div>
                <div class="mt-20">
                    <span class="color-black content-text">We assist you in engaging customers by providing services and
                        experiences, as well as transforming your applications, data, and infrastructure to meet the
                        demands of a modern enterprise.
                    </span>
                </div>
                <div>
                    <a target="_top" class="btn-secondary btn-lg cursor-pointer" [routerLink]="root.ourServices()">Explore Services</a>
                </div>
                <!-- <div class="nav-arrows d-flex">
                    <div class="left-arrow mr-30 center"><a (click)="scrollLeft()"><img
                                [src]="root.imageUrl() + '/svg-icons/left-orange-arrow.svg'"></a>
                    </div>
                    <div class="right-arrow center"><a (click)="scrollRight()"><img
                                [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'"></a>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
            <div class="service-right-content">
                <div class="position-relative">
                    <div class="card-bg">
                        <div class="card-panel">
                            <div class="row">
                                <!--class="overflow-auto-panel -ms-scrollbar scrollable-card" #widgetsContent-->
                                <div class="col-xl-6 col-md-6 col-sm-12 mb-30">
                                    <div class="card-wrapper position-relative" data-aos="flip-left"
                                        data-aos-duration="1700">
                                        <div class="content-card position-relative text-center">
                                            <div class="center center-panel">
                                                <div class="service-icon center">
                                                    <img
                                                        [src]="root.imageUrl() + '/svg-icons/enterprise-service-icon.svg'">
                                                </div>
                                                <div>
                                                    <div class="mb-15">
                                                        <span class="color-black service-title">Enterprise
                                                            Software
                                                            Services</span>
                                                    </div>
                                                    <div class="card-content"><span class="color-black content-text">You
                                                            name
                                                            the
                                                            enterprise software and we build it! Offering a range of
                                                            services
                                                            including ERP, service integration, and many others.</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="right-arrow center arrow-icon">
                                            <a [routerLink]="root.ess()" target="_top"><img
                                                    [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'"
                                                    class=""></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-md-6 col-sm-12 mb-30">
                                    <div class="card-wrapper position-relative" data-aos="flip-left"
                                        data-aos-duration="1700">
                                        <div class="content-card position-relative text-center">
                                            <div class="center center-panel">
                                                <div class="service-icon center">
                                                    <img [src]="root.imageUrl() + '/svg-icons/mobile-service-icon.svg'">
                                                </div>
                                                <div>
                                                    <div class="mb-15"><span class="color-black service-title">Mobile
                                                            Application </span></div>
                                                    <div class="card-content"><span class="color-black content-text">An
                                                            enterprise-grade mobile application gives your customers
                                                            another
                                                            option
                                                            to interact with your products and services.</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="right-arrow center arrow-icon">
                                            <a [routerLink]="root.mobileApp()" target="_top"><img
                                                    [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">


                                <div class="col-xl-6 col-md-6 col-sm-12 mb-30">
                                    <div class="card-wrapper position-relative" data-aos="flip-left"
                                        data-aos-duration="1700">
                                        <div class="content-card position-relative text-center">
                                            <div class="center center-panel">
                                                <div class="service-icon center">
                                                    <img [src]="root.imageUrl() + '/svg-icons/cloud-service-icon.svg'">
                                                </div>
                                                <div>
                                                    <div class="mb-15"><span class="color-black service-title">Cloud
                                                            Services</span></div>
                                                    <div class="card-content"><span
                                                            class="color-black content-text">Utilize
                                                            our
                                                            cloud services to accelerate time to market and provide
                                                            amazing
                                                            customer
                                                            experiences.
                                                        </span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="right-arrow center arrow-icon">
                                            <a [routerLink]="root.cloud()" target="_top"><img
                                                    [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'"></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-md-6 col-sm-12 mb-30">
                                    <div class="card-wrapper position-relative" data-aos="flip-left"
                                        data-aos-duration="1700">
                                        <div class="content-card position-relative text-center">
                                            <div class="center center-panel">
                                                <div class="service-icon center">
                                                    <!-- <img [src]="root.imageUrl() + '/svg-icons/devops-service-icon.svg'"> -->
                                                    <img [src]="root.imageUrl() + '/svg-icons/devops-service-icon.svg'" alt="devops-icon">
                                                </div>
                                                <div>
                                                    <div class="mb-15"><span
                                                            class="color-black service-title">Devops</span>
                                                    </div>
                                                    <div class="card-content"><span
                                                            class="color-black content-text">With
                                                            our
                                                            extensive DevOps and Cloud services, you can deliver apps at
                                                            the
                                                            speed
                                                            of business.</span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="right-arrow center arrow-icon">
                                            <a [routerLink]="root.devops()" target="_top"><img
                                                    [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-6 col-md-6 col-sm-12">
                                    <div class="card-wrapper position-relative" data-aos="flip-left"
                                        data-aos-duration="1700">
                                        <div class="content-card position-relative text-center">
                                            <div class="center center-panel">
                                                <div class="service-icon center">
                                                    <!-- <img
                                                        [src]="root.imageUrl() + '/svg-icons/software-consulting-service-icon.svg'"> -->
                                                        <img [src]="root.imageUrl() + '/svg-icons/software-consulting-service-icon.svg'" alt="software-consulting">
                                                </div>
                                                <div>
                                                    <div class="mb-15"><span class="color-black service-title">Software
                                                            Consulting</span></div>
                                                    <div class="card-content"><span class="color-black content-text">We
                                                            offer
                                                            all sophisticated technologies
                                                            such as artificial intelligence, machine learning, the
                                                            internet
                                                            of
                                                            things,and many others.
                                                        </span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="right-arrow center arrow-icon">
                                            <a [routerLink]="root.scs()" target="_top"><img
                                                    [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>






</div>