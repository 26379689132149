<div id="product-feature">
    <div class="feature-section">
        <div class="feature-wrapper">
            <div class="left-content">
                <div class="mt-20 info-content"><span class="color-orange">Amazing Features by OneViz</span></div>
                <div><span class="color-black content-text mt-15">A comprehensive solution to manage every type of Societies,
                        Associations </span>
                </div>
            </div>
            <div class="key-feature-wrapper">
                <div class="row no-gutters">
                    <div class="col-xl-6">
                        <div class="left-panel">
                            <div class="img-wrapper">
                                <img [src]="root.imageUrl() + '/feat-oneviz.png'">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="overflow-wrapper -ms-scrollbar scrollable-body">
                            <div class="card-panel-wrapper">
                                <div class="right-panel row no-gutters">
                                    <div class="feature-list col-xl-12 col-lg-4 col-4">
                                        <div class="d-flex flex-wrapper">
                                            <div class="mgmt-icon center"><img
                                                    [src]="root.imageUrl() + '/svg-icons/oneviz-election-management.svg'">
                                            </div>
                                            <div class="feature-content">
                                                <div class="mb-15"><span class="color-black feature-title">Election
                                                        Management</span>
                                                </div>
                                                <div>
                                                    <p class="color-black feature-info">Run and manage your society
                                                        elections smartly and efficiently with OneViz Amity. OneViz
                                                        Amity will help you provide security, transparency,
                                                        auditability, and efficiency across each election phase.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="feature-list col-xl-12 col-lg-4 col-4">
                                        <div class="d-flex flex-wrapper">
                                            <div class="mgmt-icon center"><img
                                                    [src]="root.imageUrl() + '/svg-icons/oneviz-access-management.svg'">
                                            </div>
                                            <div class="feature-content">
                                                <div class="mb-15"><span class="color-black feature-title">Access
                                                        Management
                                                    </span></div>
                                                <div>
                                                    <p class="color-black feature-info">OneViz's Access Management
                                                        feature allows you to customize, control and manage every access
                                                        given to the members enabling you to control the flow of
                                                        critical data and information.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="feature-list col-xl-12 col-lg-4 col-4">
                                        <div class="d-flex flex-wrapper">
                                            <div class="mgmt-icon center"><img
                                                    [src]="root.imageUrl() + '/svg-icons/oneviz-payment.svg'">
                                            </div>
                                            <div class="feature-content">
                                                <div class="mb-15"><span class="color-black feature-title">Payments &
                                                        Settlements</span>
                                                </div>
                                                <div>
                                                    <p class="color-black feature-info">Set up online payments via
                                                        OneViz to automate membership fees, renewals, schedule payments,
                                                        and send reminders.

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="slider-block" id="blue-slider">
                            <div class="slider-component active"></div>
                            <div class="slider-component"></div>
                            <div class="slider-component"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>