import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'our-product',
  templateUrl: './our-product.component.html',
  styleUrls: ['./our-product.component.scss']
})
export class OurProductComponent implements OnInit {

  newTitle: string = 'ekZero Products | Business Solutions | ekZero';
  longDescription: string = 'Empowering small businesses with advanced IT products';
  longKeyword: string = 'ekZero, Fleet Management Solution, Telematics, AMS, Event management solution, mobile applications, Tracking device, GPS Tracking, ekZero, digital business platforms, VegitOne, OneViz, GTrack, ekZero';

  constructor(private title: Title,
    private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle(this.newTitle);
    this.meta.addTag({ name: "description", content: this.longDescription });
    this.meta.addTag({ name: "keywords", content: this.longKeyword });
  }
}
