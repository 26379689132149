import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'timezone',
  templateUrl: './timezone.component.html',
  styleUrls: ['./timezone.component.scss']
})
export class TimezoneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
