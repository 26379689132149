import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'ecs-case-study',
  templateUrl: './ecs-case-study.component.html',
  styleUrls: ['./ecs-case-study.component.scss']
})
export class EcsCaseStudyComponent implements OnInit {

  constructor(public root: RootService) { }

  ngOnInit(): void {
  }

}
