<div id="product-vegitone">
    <div class="content-wrapper">
        <div class="row no-gutters">
            <div class="col-lg-9 col-md-order2">
                <div class="left-wrapper">
                    <div class="info-wrapper col-md-order2">
                        <div class="flex-wrapper">
                            <div class="mb-20"><span class="title-text">Why use <span class="font-600">GTrack?</span>
                                </span></div>
                            <div class="content-text-wrapper">
                                <p class="content-text">GTrack can seamlessly track the location of your assets &
                                    valuables and offers practical features that makes it a one-stop tracking solution.
                                </p>
                            </div>
                        </div>
                        <div class="key-feature-panel">
                            <div class="row no-gutters">
                                <div class="col-sm-3 col-6">
                                    <div class="feature-pill">
                                        <div class="feature-icon center mb-15"><img
                                                [src]="root.imageUrl() + '/svg-icons/gtrack-data-security.svg'"></div>
                                        <div><span>Data</span></div>
                                        <div><span>Security</span></div>
                                    </div>
                                </div>
                                <div class="col-sm-3 col-6">
                                    <div class="feature-pill">
                                        <div class="feature-icon center mb-15"><img
                                            [src]="root.imageUrl() + '/svg-icons/gtrack-offline-gps.svg'"></div>
                                        <div><span>Offline GPS
                                            </span></div>
                                        <div><span>Data Recovery
                                            </span></div>
                                    </div>
                                </div>
                                <div class="col-sm-3 col-6">
                                    <div class="feature-pill">
                                        <div class="feature-icon center mb-15"><img
                                            [src]="root.imageUrl() + '/svg-icons/gtrack-summerized-center.svg'"></div>
                                        <div><span>Summarized</span></div>
                                        <div><span>Center</span></div>
                                    </div>
                                </div>
                                <div class="col-sm-3 col-6">
                                    <div class="feature-pill">
                                        <div class="feature-icon center mb-15"><img
                                            [src]="root.imageUrl() + '/svg-icons/gtrack-member-dashboard.svg'"></div>
                                        <div><span>Member</span></div>
                                        <div><span>Dashboards</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-none btn-grp">
                            <button class="btn-lg btn-white btn-demo mb-10"><span>WATCH</span><span
                                    class="font-600">DEMO</span></button>
                            <button class="btn-lg btn-white btn-download mb-10"><span>DOWNLOAD</span><span
                                    class="font-600">BROCHURE</span></button>
                        </div>
                    </div>
                    <div class="talk-section center flex-column col-md-order1">
                        <div class="mb-15"><img [src]="root.imageUrl() + '/svg-icons/user-talk-icon.svg'"></div>
                        <div><span class="content-text">Want to consult with
                                <span class="font-600"> SPECIALIST ?</span></span></div>
                        <div><span class="font-600 talk-text">Let's Talk</span></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-order1">
                <div class="right-wrapper">
                    <div class="right-wrapper-img"></div>
                </div>
            </div>
        </div>
    </div>
</div>