<div class="ez-service-banner">    
    <div class="text-container">
        <div class="subheading-block position-relative mb-15">
            <span class="subheading-block-text">
               Our Services
            </span>
        </div>
        <div class="heading1-block mb-15">
            <span class="heading1-block-text">
                Your Success
            </span>
        </div>
        <div class="subheading2-block">
            <span class="subheading2-block-text">
                A new way how you build
            </span>
        </div>
        <div class="subheading2-block">
            <span class="subheading2-block-text">
                and grow your business.
            </span>
        </div>
    </div>
</div>