import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  newTitle: string = 'Let’s Connect  | ekZero Corporation';
  longDescription: string = 'ekZero is here to help you succeed. Contact us today.';
  longKeyword: string = 'ekZero, Connect, Contact us, ekZero contact, IT company, Best IT company in Toronto, Leading IT Firm, Contact ekZero Toronto';


  constructor(private title: Title,
    private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle(this.newTitle);
    this.meta.addTag({ name: "description", content: this.longDescription });
    this.meta.addTag({ name: "keywords", content: this.longKeyword });

  }

}
