import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OurProductComponent } from './pages/our-product/our-product.component';
import { EkvegitComponent } from './pages/ekvegit/ekvegit.component';
import { OnevizComponent } from './pages/oneviz/oneviz.component';
import { GtrackComponent } from './pages/gtrack/gtrack.component';
import { EducationClassesComponent } from './pages/education-classes/education-classes.component';
import { AmityComponent } from './pages/amity/amity.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'our-products',
    pathMatch: 'full'
  },
  {
    path: 'detail',
    component: OurProductComponent,
  },
  {
    path: 'ekvegit',
    component: EkvegitComponent,
  },
  {
    path: 'oneviz',
    component: OnevizComponent,
  },
  {
    path: 'gtrack',
    component: GtrackComponent,
  },
  {
    path: 'education-classes',
    component: EducationClassesComponent,
  },
  {
    path: 'amity',
    component: AmityComponent,
  },
];

 @NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OurProductsRoutingModule { }
