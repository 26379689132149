<div class="ez-incubation-banner">
    <div class="text-container">
        <div class="subheading-block position-relative mb-15">
            <span class="subheading-block-text">
                ekZero Incubation
            </span>
        </div>
        <div class="subheading2-block">
            <span class="subheading2-block-text">
                Incubate and Innovate with ekZero
            </span>
        </div>
    </div>
</div>