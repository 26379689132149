<div id="service-detail-intro">
    <div class="our-services">
        <!-- ------------Software Consulting------------start -->
        <div class="our-services-wrapper">
            <div class="blue-bg position-relative">
                <div class="row">
                    <div class="col-12">
                        <div class="text-align-right">
                            <div class="width-50 mb-50 pl-10">
                                <div class="mb-30">
                                    <h2 class="font-600">Software Consulting</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-lg-4 col-order-2">
                        <div class="justify-content-sb h-100">
                            <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                                <div class="logo-block">
                                    <img [src]="root.imageUrl() + '/svg-icons/conceptualization.svg'" class="wifi-logo">
                                    <!-- <img src="assets/images/svg-icon/conceptualization.svg" class="wifi-logo"> -->
                                </div>
                                <div>
                                    <div class="mb-10">
                                        <h5 class="font-black">Conceptualization</h5>
                                    </div>
                                    <div><span class="font-14 font-black">Converting your thoughts and ideas into a well
                                            thought, well designed and nicely written set of codes.
                                        </span></div>
                                </div>
                            </div>
                            <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                                <div class="logo-block">
                                    <img [src]="root.imageUrl() + '/svg-icons/design.svg'" class="wifi-logo">
                                    <!-- <img src="assets/images/svg-icon/design.svg" class="wifi-logo"> -->
                                </div>
                                <div>
                                    <div class="mb-10">
                                        <h5 class="font-black">Design and Architecture
                                        </h5>
                                    </div>
                                    <div><span class="font-14 font-black">Get a unique and attractive UX design for your
                                            IT
                                            product. Develop a comprehensive software architecture from scratch.
                                        </span></div>
                                </div>
                            </div>
                            <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                                <div class="logo-block">
                                    <img [src]="root.imageUrl() + '/svg-icons/prototyping.svg'" class="wifi-logo">
                                    <!-- <img src="assets/images/svg-icon/prototyping.svg" class="wifi-logo"> -->
                                </div>
                                <div>
                                    <div class="mb-10">
                                        <h5 class="font-black">Prototyping</h5>
                                    </div>
                                    <div><span class="font-14 font-black">One-stop-shop for the production of high
                                            quality,
                                            low-cost prototypes. Let your ideas communicate with the world.
                                        </span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" col-md-12 col-lg-4 col-order-1">
                        <div class="center h-100">
                            <img [src]="root.imageUrl() + '/software-condulting-service-img.png'" class="center-img"
                                data-aos="flip-left" data-aos-duration="1500">
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 col-order-2 ">
                        <div class="justify-content-sb h-100 text-align">
                            <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                                <div class="change-order">
                                    <div class="mb-10">
                                        <h5>Development and Testing
                                        </h5>
                                    </div>
                                    <div><span class="font-14"> The entire development of software on a range of IT
                                            technologies and
                                            rapid testing infrastructure for a faster launch.
                                        </span>
                                    </div>
                                </div>
                                <div class="logo-block right-block">
                                    <img [src]="root.imageUrl() + '/svg-icons/dt.svg'" class="wifi-logo">
                                    <!-- <img src="assets/images/svg-icon/dt.svg" class="wifi-logo"> -->
                                </div>
                            </div>
                            <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                                <div class="change-order">
                                    <div class="mb-10">
                                        <h5>Maintenance and Support</h5>
                                    </div>
                                    <div><span class="font-14">End to end server maintenance and tech support for your
                                            software. From DevOps to going live on production
                                        </span></div>
                                </div>
                                <div class="logo-block right-block">
                                    <img [src]="root.imageUrl() + '/svg-icons/ms.svg'" class="wifi-logo">
                                    <!-- <img src="assets/images/svg-icon/ms.svg" class="wifi-logo"> -->
                                </div>
                            </div>
                            <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                                <div class="change-order">
                                    <div class="mb-10">
                                        <h5>Deployment</h5>
                                    </div>
                                    <div><span class="font-14">Get your software live hassle-free with thorough
                                            development
                                            and expert quality support services.
                                        </span></div>
                                </div>
                                <div class="logo-block right-block">
                                    <img [src]="root.imageUrl() + '/svg-icons/deployment.svg'" class="wifi-logo">
                                    <!-- <img src="assets/images/svg-icon/deployment.svg" class="wifi-logo"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="dots">
                    <div class="point bg-white"></div>
                    <div class="point bg-white"></div>
                    <div class="point bg-white"></div>
                </div> -->
            </div>
        </div>

        <!-- ------------Software Consulting------------end -->
    </div>
    <div class="detail-intro" data-aos="fade-up" data-aos-duration="1500">
        <div class="row">
            <div class="col-lg-6">
                <div class="left-content">
                    <div class="info-content mb-30"><span class="color-orange font-">Get 360° round software
                            consultation on all enterprise and commercial web solutions.</span></div>
                    <div class="detail-info">
                        <p class="color-black content-text">Every successful software evolves from an idea that is
                            conceptualized or enhanced by developers, designers, or programmers. ekZero’s teams are
                            fully equipped with research and feasibility study in various domains and have prepared
                            well-versed processes to ensure that you receive the appropriate advice supporting the idea.
                            Our team of to-notch researchers gathers all relevant data which includes platforms your end
                            users will use, current market trends in the technology development, hardware requirements,
                            and operating system advancements needed for success. ekZero developers take software
                            specifications and turn them into models which shows the complete system architecture, user
                            interface, specifications of the data structures and other components.
                        </p>
                    </div>
                    <div class="btn-grp"><a class="btn-primary btn-xlg" [routerLink]="root.contact()"
                            target="_top">Connect with ekZero</a></div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="right-content">
                    <img [src]="root.imageUrl() + '/service-software-img.png'">
                </div>
            </div>
        </div>
    </div>
</div>