<div id="product-list">
    <div class="list-wrapper">
        <div class="d-flex align-items-center flex-wrapper w-100">
            <div class="brand-img"><img [src]="root.imageUrl() + '/svg-icons/brand-img.svg'"></div>
            <div class="brand-img"><img [src]="root.imageUrl() + '/svg-icons/brand-img.svg'"></div>
            <div class="brand-img"><img [src]="root.imageUrl() + '/svg-icons/brand-img.svg'"></div>
            <div class="brand-img"><img [src]="root.imageUrl() + '/svg-icons/brand-img.svg'"></div>
            <div class="brand-img"><img [src]="root.imageUrl() + '/svg-icons/brand-img.svg'"></div>
        </div>
    </div>
</div>
