import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'app-skyrocket-your-association-revenue-by-employing-efficient-ways',
  templateUrl: './skyrocket-your-association-revenue-by-employing-efficient-ways.component.html',
  styleUrls: ['./skyrocket-your-association-revenue-by-employing-efficient-ways.component.scss']
})
export class SkyrocketYourAssociationRevenueByEmployingEfficientWaysComponent implements OnInit {
  isDisplay: boolean = false;
  isShow: boolean = false;
  constructor(public root: RootService) { }

  ngOnInit(): void {
  }

}
