<div class="ez-our-story" id="incubation-intro">
    <div class="row no-gutters">
        <div class="col-md-7 order-12 order-md--1">
            <div class="our-story-right-panel">
                <div class="uppar-content">
                    <div><span>Lets make your dream business the next big thing!</span>
                    </div>
                </div>
                <div class="lower-content">
                    <div><span class="content-text">We empower daring entrepreneurs who are creating game-changing solutions. With over a decade of experience, ekZero has everything in place to assist you build a business that is inspired by technology and innovation. We seek to assist entrepreneurs in validating their business ideas into a well-thought-out roadmap with well-planned fundamental assistance to develop a strong basis for a digital startup that one has dreamed of by combining our incubation and consultancy services. We make sure that everyone has access to the proper technology and technical skills on time, every time!

                        </span></div>
                </div>
                <div class="btn-grp mt-30">
                    <a class="btn-primary btn-xlg" [routerLink]="root.contact()" target="_top">Connect with ekZero</a>
                </div>
            </div>
        </div>
        <div class="col-md-5 order-md-12 order-2">
            <div class="intro-left-panel" data-aos="fade-down" data-aos-duration="1700">
                <div class="intro-img">
                    <div class="intro-content" data-aos="flip-left" data-aos-duration="1700">
                        <div class="info-title"><span class="color-white">Helping Establish</span></div>
                        <div class="mt-20 left-content">
                            <div><span class="color-white text-line1">Exceptional StartUp Ventures </span></div>
                        </div>
                    </div>
                    <div class="our-story-right-content"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="support-technology-section" data-aos="fade-up" data-aos-duration="1500">
    <img [src]="root.imageUrl() + '/incubation-support-technology.png'" class="web-img">
    <img [src]="root.imageUrl() + '/incubation-support-technology-responsive.png'" class="mobile-img">
</div>