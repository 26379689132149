<div id="product-intro">
    <div class="intro-wrapper">
        <div class="row no-gutters align-items-center">
            <div class="col-md-6">
                <div class="left-panel">
                    <div class="product-img">
                        <!-- <div class="play-icon center">
                            <div class="play-bg center">
                                <div class="play"></div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="right-panel">
                    <div class="info-section">
                        <div class="info-content mb-30"><span class="color-orange">VegitOne Overview</span></div>
                        <div>
                            <p class="content-text">VegitOne - One that delivers !!
                                ekZero's revolutionary product for logistics and fleet management. VegitOne is serving e
                                small to medium transport enterprises in North America.

                            </p>
                        </div>
                        <div>
                            <p class="content-text">VegitOne has a range of IoT devices and offers multiple features and
                                specifications that ensure efficient management of the fleet along with accurate and
                                detailed data collection.

                            </p>
                        </div>
                        <div>
                            <p class="content-text">Its robust AI algorithm structure suggests improvements at each
                                stage in the fleet operation process.

                            </p>
                        </div>
                        <div>
                            <p class="content-text">VegitOne allows you and your customers to take advantage of the data
                                flow by enabling you to improve production and product lifetime, reduce downtime, report
                                equipment position, usage, status, and more.

                            </p>
                        </div>
                        <div class="visit-button-panel">
                            <a class="btn-lg btn-primary visit-button" target="_blank"
                                href="https://www.vegitone.com/">Visit to vegitone</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>