<div class="technology-section">
    <div class="ez-our-story" id="who-we-are">
        <div class="row no-gutters">
            <div class="col-md-5">
                <div class="our-story-left-panel">
                    <div class="our-story-img">
                        <div class="our-story-left-content" data-aos="zoom-in" data-aos-duration="1500">
                            <div class="info-title"><span class="color-white">OUR STORY</span></div>
                            <div class="mt-20 left-content">
                                <div><span class="color-white text-line1">Who</span></div>
                                <div class="mt-10"><span class="color-white text-line2">We Are?</span></div>
                                <div class="mt-20"><span class="color-white text-line3">The who, what and
                                        why our company exists</span></div>
                            </div>
                        </div>
                        <div class="our-story-right-content"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-7">
                <div class="our-story-right-panel">
                    <div class="uppar-content">
                        <div><span>Empowering tomorrow with advanced technological solutions and innovative
                                products</span>
                        </div>
                    </div>
                    <div class="lower-content">
                        <div><span class="content-text">ekZero believes in serving solutions that empower the business
                                needs of the future. Our extensive knowledge of application and data management
                                transforms IT into a tactical advantage. At ekZero, we believe in collaboration,
                                teamwork, and an ever-learning approach that propels improvement by developing and
                                transmitting digital skills, experience, and ideas from our institutional
                                framework.</span></div>
                        <div><span class="content-text">Our clients are at the heart of our organization and we aim to
                                professionally guide them through their technological journey with our years of
                                experience in providing innovative solutions. We assist our partners in overcoming
                                barriers and creating linked business operations that grow in real-time.
                            </span></div>

                    </div>
                    <div class="btn-grp">
                        <a class="btn-primary btn-lg" [routerLink]="root.aboutus()" target="_top">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--we offer-->
    <div class="ez-we-offer d-none" id="home-banner">
        <div class="row no-gutters">
            <div class="col-xl-9 col-sm-12 col-lg-order">
                <div class="wo-offer-wrapper">
                    <div class="row offer-block no-gutters">
                        <div class="col-xl-5 col-md-6 col-sm-12 offer-card" data-aos="fade-up" data-aos-duration="1500">
                            <div class="wo-offer-panel top-left">
                                <div class="wo-offer-divider position-relative">
                                    <div class="wo-offer-title-panel ml-40 center">
                                        <span class="wo-offer-title-blue wo-offer-title">
                                            ekZero CONSULTING SERVICES</span>
                                    </div>
                                    <div class="wo-offer-content">
                                        <p>
                                            No matter you need an advice or helping hand in any phase of your product
                                            development. We are always there to help you. Our expert in each phase of
                                            the software development lifecycle can provide you the best way to achieve
                                            your objective by striking the right balance between cost, time and
                                            quality.Through our comprehensive consulting services, we are helping our
                                            clients.
                                        </p>
                                    </div>
                                </div>
                                <div class="arrow-direction position-relative center">
                                    <div class="blue-arrow arrow-norms position-absolute center">
                                        <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" alt="arrow">
                                    </div>
                                </div>
                                <div class="wo-offer-icon wo-offer-left-icon wo-offer-icon-orange position-absolute">
                                    <img [src]="root.imageUrl() + '/svg-icons/ekzero-logo-white.svg'" alt="logo">
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-5 col-md-6 col-sm-12 offer-card" data-aos="fade-up" data-aos-duration="1500">
                            <div class="wo-offer-panel top-right">
                                <div class="wo-offer-divider position-relative">
                                    <div class="wo-offer-title-panel center">
                                        <span class="wo-offer-title-orange wo-offer-title">
                                            ekZero PRODUCTS</span>
                                    </div>
                                    <div class="wo-offer-content">
                                        <p>
                                            Let us help you succeed by taking care of your digital infrastructure,
                                            software solution development, integration, and delivery while you focus on
                                            developing your business.
                                        </p>
                                    </div>
                                </div>
                                <div class="arrow-direction position-relative center">
                                    <div class="orange-arrow arrow-norms position-absolute center">
                                        <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" alt="arrow">
                                    </div>
                                </div>
                                <div class="wo-offer-icon wo-offer-right-icon wo-offer-icon-blue position-absolute">
                                    <img [src]="root.imageUrl() + '/svg-icons/product.svg'" alt="logo">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-2"></div>

                    </div>
                    <div class="row logo-section no-gutters">
                        <div class="col-xl-4 col-sm-5"></div>
                        <div class="col-xl-2 col-sm-2 position-relative center">
                            <div class="center-logo position-absolute center">
                                <div class="center-logo-panel">
                                    <img [src]="root.imageUrl() + '/logo.png '" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-3"></div>

                    </div>
                    <div class="row no-gutters">
                        <div class="col-xl-5 col-md-6 col-sm-12 offer-card" data-aos="fade-up" data-aos-duration="1500">
                            <div class="wo-offer-panel bottom-left">
                                <div class="wo-offer-divider position-relative">
                                    <div class="wo-offer-title-panel">
                                        <span class="wo-offer-title-orange wo-offer-title center">ekZero
                                            RESEARCH
                                            & DEVELOPMENT</span>
                                    </div>
                                    <div class="wo-offer-content">
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since the
                                            1500s, when an unknown printer took a galley of type and scrambled it to
                                            make a type specimen book. It has survived not only five centuries, but also
                                            the leap into electronic typesetting, remaining essentially unchanged.
                                        </p>
                                    </div>
                                </div>
                                <div class="arrow-direction position-relative center">
                                    <div class="orange-arrow arrow-norms position-absolute center">
                                        <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" alt="arrow">
                                    </div>
                                </div>
                                <div
                                    class="wo-offer-icon wo-offer-right-icon wo-offer-icon-blue wo-bottom-left position-absolute">
                                    <img [src]="root.imageUrl() + '/svg-icons/research.svg'" alt="logo">
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-5 col-md-6 col-sm-12 offer-card" data-aos="fade-up" data-aos-duration="1500">
                            <div class="wo-offer-panel bottom-right">
                                <div class="wo-offer-divider position-relative">
                                    <div class="wo-offer-title-panel">
                                        <span class="wo-offer-title-blue wo-offer-title center">ekZero
                                            INCUBATION</span>
                                    </div>
                                    <div class="wo-offer-content">
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since the
                                            1500s, when an unknown printer took a galley of type and scrambled it to
                                            make a type specimen book. It has survived not only five centuries, but also
                                            the leap into electronic typesetting, remaining essentially unchanged.
                                        </p>
                                    </div>
                                </div>
                                <div class="arrow-direction position-relative center">
                                    <div class="blue-arrow arrow-norms position-absolute center">
                                        <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" alt="arrow">
                                    </div>
                                </div>
                                <div
                                    class="wo-offer-icon wo-offer-left-icon wo-offer-icon-orange wo-bottom-right position-absolute">
                                    <img [src]="root.imageUrl() + '/svg-icons/incubation.svg'" alt="logo">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-2"></div>

                    </div>


                </div>
            </div>
            <div class="col-xl-3 col-sm-12">
                <div class="wo-right-content">
                    <div class="info-title"><span class="color-black">WHAT</span></div>
                    <div class="mt-20 info-content">
                        <div><span class="color-orange">We offer?</span></div>
                    </div>
                    <div class="mt-40">
                        <span class="color-black content-text">Investing into New start-up Companies by giving
                            theme Financial help & Resources.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- offer new design -->
    <div class="ez-offer-new-design d-none" data-aos="fade-up" data-aos-duration="1500">
        <div class="row">
            <div class="col-xl-9 col-sm-12"></div>
            <div class="col-xl-3 col-sm-12">
                <div class="wo-right-content">
                    <div class="info-title"><span class="color-black">WHAT</span></div>
                    <div class="mt-10 info-content">
                        <div><span class="color-orange">We offer?</span></div>
                    </div>
                    <div class="mt-10">
                        <span class="color-black content-text">Offering Value & Innovation.</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="offer-property-section">
            <div class="row offer-align-item">
                <div class="col-xl-4 col-lg-3 col-12">
                    <div class="left-panel" data-aos="fade-right" data-aos-duration="1500">
                        <img [src]="root.imageUrl() + '/we-offer-image.png' " />
                    </div>
                </div>
                <div class="col-xl-8 col-lg-9 col-12">
                    <div class="right-panel">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="content-block offer-consulting">
                                    <div class="heading-panel">
                                        <span class="heading-panel-text">
                                            ekZero Consulting Services
                                        </span>
                                    </div>
                                    <div class="subheading-panel">
                                        <span class="subheading-panel-text">
                                            Our professionals will help you in every stage of product and software
                                            development, assisting you in finding the best method to reach your goals
                                            while balancing cost, time, and quality. We assist our clients in developing
                                            solutions that make a significant difference by offering our comprehensive
                                            consulting services.
                                        </span>
                                    </div>
                                    <div class="mt-20">
                                        <a [routerLink]="root.ecs()" target="_top"
                                            class="btn-secondary btn-md cursor-pointer">Read More</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="content-block offer-product">
                                    <div class="heading-panel">
                                        <span class="heading-panel-text">
                                            ekZero Products
                                        </span>
                                    </div>
                                    <div class="subheading-panel">
                                        <span class="subheading-panel-text">
                                            ekZero's vision is to empower small to big businesses with advanced
                                            technical solutions. We work on exceptional products that our customer
                                            loves. Learn more about some of our existing and upcoming products and how
                                            the technology is helping our customers to achieve success.

                                        </span>
                                    </div>
                                    <div class="mt-20">
                                        <a [routerLink]="root.productsList()" target="_top"
                                            class="btn-secondary btn-md cursor-pointer">Read More</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="content-block offer-incubation">
                                    <div class="heading-panel">
                                        <span class="heading-panel-text">
                                            ekZero Incubation
                                        </span>
                                    </div>
                                    <div class="subheading-panel">
                                        <span class="subheading-panel-text">
                                            Young innovators need mentoring and resources to turn their ideas into
                                            reality. With the ekZero incubation initiative, we support young minds and
                                            projects to achieve the accelerated growth and success by providing
                                            technical and financial resources.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="content-block offer-research">
                                    <div class="heading-panel">
                                        <span class="heading-panel-text">
                                            ekZero Research & Development
                                        </span>
                                    </div>
                                    <div class="subheading-panel">
                                        <span class="subheading-panel-text">
                                            ekZero strives to research and develop next-generation technologies. With an
                                            ever learning approach, we aim to drive a high focus on machine learning,
                                            artificial intelligence, and IoT.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <!--we offer-->
    <div class="ez-we-offer d-none" id="what-we-offer">
        <div class="row no-gutters">
            <div class="col-xl-4 col-lg-12 col-12 we-offer-left-section center">
                <div class="left-panel" data-aos="fade-right" data-aos-duration="1500">
                    <img [src]="root.imageUrl() + '/we-offer-image.png' " width="100%">
                </div>
            </div>
            <div class="col-xl-8 col-lg-12 col-12">
                <div class="wo-offer-wrapper">
                    <div class="row no-gutters">
                        <div class="col-sm-12">
                            <div class="wo-right-content">
                                <div class="info-title"><span class="color-black">WHAT</span></div>
                                <div class="mt-10 info-content">
                                    <div><span class="color-orange">We offer?</span></div>
                                </div>
                                <div class="mt-10">
                                    <span class="color-black content-text">Offering Value & Innovation.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row offer-block no-gutters">
                        <div class="col-sm-2 free-space"></div>
                        <div class="col-xl-5 col-md-6 col-sm-12 offer-card" data-aos="flip-left"
                            data-aos-duration="1500">
                            <div class="wo-offer-panel top-left">
                                <div class="wo-offer-divider position-relative">
                                    <div class="wo-offer-title-panel center">
                                        <span class="wo-offer-title-blue wo-offer-title">
                                            ekZero Consulting Services</span>
                                    </div>
                                    <div class="wo-offer-content">
                                        <p>
                                            Our professionals will help you in every stage of product and software
                                            development, assisting you in finding the best method to reach your goals
                                            while balancing cost, time, and quality. We assist our clients in developing
                                            solutions that make a significant difference by offering our comprehensive
                                            consulting services.
                                        </p>
                                    </div>
                                </div>
                                <div class="arrow-direction position-relative center">
                                    <a class="orange-arrow arrow-norms position-absolute center"
                                        [routerLink]="root.ecs()" target="_top">
                                        <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" alt="arrow">
                                    </a>
                                </div>
                                <div class="wo-offer-icon wo-offer-left-icon wo-offer-icon-orange position-absolute">
                                    <img [src]="root.imageUrl() + '/svg-icons/ekzero-logo-white.svg'" alt="logo">
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-5 col-md-6 col-sm-12 offer-card" data-aos="flip-left"
                            data-aos-duration="1500">
                            <div class="wo-offer-panel top-right">
                                <div class="wo-offer-divider position-relative">
                                    <div class="wo-offer-title-panel center">
                                        <span class="wo-offer-title-orange wo-offer-title">
                                            ekZero Products</span>
                                    </div>
                                    <div class="wo-offer-content">
                                        <p>
                                            ekZero's vision is to empower small to big businesses with advanced
                                            technical solutions. We work on exceptional products that our customer
                                            loves. Learn more about some of our existing and upcoming products and how
                                            the technology is helping our customers to achieve success.
                                        </p>
                                    </div>
                                </div>
                                <div class="arrow-direction position-relative center">
                                    <a class="blue-arrow arrow-norms position-absolute center"
                                        [routerLink]="root.productsList()" target="_top">
                                        <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" alt="arrow">
                                    </a>
                                </div>
                                <div class="wo-offer-icon wo-offer-left-icon wo-offer-icon-blue position-absolute">
                                    <img [src]="root.imageUrl() + '/svg-icons/product.svg'" alt="logo">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col-sm-2 free-space"></div>
                        <div class="col-xl-5 col-md-6 col-sm-12 offer-card" data-aos="flip-left"
                            data-aos-duration="1500">
                            <div class="wo-offer-panel bottom-right">
                                <div class="wo-offer-divider position-relative">
                                    <div class="wo-offer-title-panel">
                                        <span class="wo-offer-title-blue wo-offer-title center">ekZero
                                            Incubation</span>
                                    </div>
                                    <div class="wo-offer-content">
                                        <p>
                                            Young innovators need mentoring and resources to turn their ideas into
                                            reality. With the ekZero incubation initiative, we support young minds and
                                            projects to achieve the accelerated growth and success by providing
                                            technical and financial resources.
                                        </p>
                                    </div>
                                </div>
                                <div class="arrow-direction position-relative center">
                                    <a class="blue-arrow arrow-norms position-absolute center"
                                        [routerLink]="root.incubation()" target="_top">
                                        <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" alt="arrow">
                                    </a>
                                </div>
                                <div class="wo-offer-icon wo-offer-left-icon wo-offer-icon-blue position-absolute">
                                    <img [src]="root.imageUrl() + '/svg-icons/incubation.svg'" alt="logo">
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-5 col-md-6 col-sm-12 offer-card" data-aos="flip-left"
                            data-aos-duration="1500">
                            <div class="wo-offer-panel top-right">
                                <div class="wo-offer-divider position-relative">
                                    <div class="wo-offer-title-panel">
                                        <span class="wo-offer-title-orange wo-offer-title center">ekZero
                                            Research & Development</span>
                                    </div>
                                    <div class="wo-offer-content">
                                        <p>
                                            ekZero strives to research and develop next-generation technologies. With an
                                            ever learning approach, we aim to drive a high focus on machine learning,
                                            artificial intelligence, and IoT.
                                        </p>
                                    </div>
                                </div>
                                <div class="arrow-direction position-relative center">
                                    <a class="blue-arrow arrow-norms position-absolute center"
                                        [routerLink]="root.researchdevelopment()" target="_top">
                                        <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" alt="arrow">
                                    </a>
                                </div>
                                <div class="wo-offer-icon wo-offer-left-icon wo-offer-icon-orange position-absolute">
                                    <img [src]="root.imageUrl() + '/svg-icons/research.svg'" alt="logo">
                                </div>
                            </div>
                        </div>


                    </div>


                </div>
            </div>

        </div>
    </div>


    <!--we offer design-->
    <div class="ez-we-offer ez-offer-design-col" id="what-we-offer">
        <div class="row no-gutters">
            <div class="col-xl-4 col-lg-12 col-12">               
            </div>
            <div class="col-xl-8 col-lg-12 col-12">
                <div class="wo-offer-wrapper">
                    <div class="row no-gutters">
                        <div class="col-sm-12">
                            <div class="wo-right-content">
                                <div class="info-title"><span class="color-black">WHAT</span></div>
                                <div class="mt-10 info-content">
                                    <div><span class="color-orange">We offer?</span></div>
                                </div>
                                <div class="mt-10">
                                    <span class="color-black content-text">Offering Value & Innovation.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-3 col-md-6">
                <div class="offer-wrapper">
                    <div class="image-block">
                        <img [src]="root.imageUrl() + '/offer-ecs-img.png'"/>
                    </div>
                    <div class="content-block">
                        <div class="icon-panel orange-panel">
                            <img [src]="root.imageUrl() + '/svg-icons/offer-ecs-icon.svg'"/>
                        </div>
                        <div class="title-panel">
                            <span class="title-text">
                                ekZero Consulting Services (ECS)
                            </span>
                        </div>
                        <div class="desc-panel">
                            <span class="desc-text">
                                Our professionals will help you in every stage of product and software development, assisting you in finding the best method to reach your goals while balancing cost, time, and quality. We assist our clients in developing solutions that make a significant difference by offering our comprehensive consulting services.
                            </span>
                        </div>
                        <div class="arrow-direction">
                            <a class="orange-arrow arrow-norms position-absolute center"
                                [routerLink]="root.ecs()" target="_top">
                                <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" alt="arrow">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6">                
                <div class="offer-wrapper">
                    <div class="image-block">
                        <img [src]="root.imageUrl() + '/offer-product-img.png'"/>
                    </div>
                    <div class="content-block">
                        <div class="icon-panel blue-panel">
                            <img [src]="root.imageUrl() + '/svg-icons/offer-product-icon.svg'"/>
                        </div>
                        <div class="title-panel">
                            <span class="title-text">
                                ekZero Products
                            </span>
                        </div>
                        <div class="desc-panel">
                            <span class="desc-text">
                                ekZero's vision is to empower small to big businesses with advanced technical solutions. We work on exceptional products that our customer loves. Learn more about some of our existing and upcoming products and how the technology is helping our customers to achieve success.
                            </span>
                        </div>
                        <div class="arrow-direction">
                            <a class="blue-arrow arrow-norms position-absolute center"
                                [routerLink]="root.productsList()" target="_top">
                                <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" alt="arrow">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6">
                <div class="offer-wrapper">
                    <div class="image-block">
                        <img [src]="root.imageUrl() + '/offer-incubation-img.png'"/>
                    </div>
                    <div class="content-block">
                        <div class="icon-panel orange-panel">
                            <img [src]="root.imageUrl() + '/svg-icons/offer-incubation-icon.svg'"/>
                        </div>
                        <div class="title-panel">
                            <span class="title-text">
                                ekZero Incubation
                            </span>
                        </div>
                        <div class="desc-panel">
                            <span class="desc-text">
                                Young innovators need mentoring and resources to turn their ideas into reality. With the ekZero incubation initiative, we support young minds and projects to achieve the accelerated growth and success by providing technical and financial resources.
                            </span>
                        </div>
                        <div class="arrow-direction">
                            <a class="orange-arrow arrow-norms position-absolute center"
                                [routerLink]="root.incubation()" target="_top">
                                <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" alt="arrow">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6">
                <div class="offer-wrapper">
                    <div class="image-block">
                        <img [src]="root.imageUrl() + '/offer-development-img.png'"/>
                    </div>
                    <div class="content-block">
                        <div class="icon-panel blue-panel">
                            <img [src]="root.imageUrl() + '/svg-icons/offer-development-icon.svg'"/>
                        </div>
                        <div class="title-panel">
                            <span class="title-text">
                                ekZero Research & Development
                            </span>
                        </div>
                        <div class="desc-panel">
                            <span class="desc-text">
                                ekZero strives to research and develop next-generation technologies. With an ever learning approach, we aim to drive a high focus on machine learning, artificial intelligence, and IoT.
                            </span>
                        </div>
                        <div class="arrow-direction">
                            <a class="blue-arrow arrow-norms position-absolute center"
                                [routerLink]="root.researchdevelopment()" target="_top">
                                <img [src]="root.imageUrl() + '/svg-icons/right-white-arrow.svg'" alt="arrow">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>     
</div>
<!--we offer-->