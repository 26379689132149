<!--Contact Us-->
<div class="ez-contact-us" data-aos="fade-up" data-aos-duration="1500">
    <div class="row no-gutters" id="contact-us">
        <div class="col-xl-6 col-lg-5 col-sm-12 col-md-order">
            <div class="conatct-us-wrapper position-relative">
                <div class="contact-us-panel">
                    <button class="btn-contact btn-lg">Contact us</button>
                </div>
                <form action="" [formGroup]="contactUs">
                    <div class="form-wrapper">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="First Name"
                                formControlName="firstName">
                            <div *ngIf="contactUs.touched">
                                <div class="errorBlock"
                                    *ngFor="let validation of contactUs_validation_messages.firstName">
                                    <span
                                        *ngIf="contactUs.get('firstName').hasError(validation.type) && (contactUs.get('firstName').dirty || contactUs.get('firstName').touched )">{{validation.message}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Last Name" formControlName="lastName">
                            <div *ngIf="contactUs.touched">
                                <div class="errorBlock"
                                    *ngFor="let validation of contactUs_validation_messages.lastName">
                                    <span
                                        *ngIf="contactUs.get('lastName').hasError(validation.type) && (contactUs.get('lastName').dirty || contactUs.get('lastName').touched )">{{validation.message}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <input type="email" class="form-control" placeholder="Email" formControlName="email">
                            <div *ngIf="contactUs.touched">
                                <div class="errorBlock" *ngFor="let validation of contactUs_validation_messages.email">
                                    <span
                                        *ngIf="contactUs.get('email').hasError(validation.type) && (contactUs.get('email').dirty || contactUs.get('email').touched )">{{validation.message}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Contact No" formControlName="phone"
                                mask="000-000-0000">
                            <div *ngIf="contactUs.touched">
                                <div class="errorBlock" *ngFor="let validation of contactUs_validation_messages.phone">
                                    <span
                                        *ngIf="contactUs.get('phone').hasError(validation.type) && (contactUs.get('phone').dirty || contactUs.get('phone').touched )">{{validation.message}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <textarea class="form-control" placeholder="Message" rows="4" cols="50"
                                formControlName="message"></textarea>
                            <div *ngIf="contactUs.touched">
                                <div class="errorBlock"
                                    *ngFor="let validation of contactUs_validation_messages.message">
                                    <span
                                        *ngIf="contactUs.get('message').hasError(validation.type) && (contactUs.get('message').dirty || contactUs.get('message').touched )">{{validation.message}}</span>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-end button-wrapper">
                            <a class="btn-primary btn-lg" target="_top" (click)="contactUsSubmit()">SUBMIT</a>
                        </div>
                        <div class="error-msg-block" *ngIf="showErrorMsg">
                            <div class="error-icon">
                                <img [src]="root.imageUrl() + '/svg-icons/error-red-icon.svg' " />
                            </div>
                            <div>
                                <div class="error-title">
                                    <span class="error-title-text">
                                        Error
                                    </span>
                                </div>
                                <div class="error-msg-panel">
                                    <span class="error-msg-panel-text">
                                        Error sending Message
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="error-msg-block success" *ngIf="showSuccessMsg">
                            <div class="error-icon">
                                <img [src]="root.imageUrl() + '/svg-icons/success-check-icon.svg' " />
                            </div>
                            <div>
                                <div class="error-title">
                                    <span class="error-title-text">
                                        Success
                                    </span>
                                </div>
                                <div class="error-msg-panel">
                                    <span class="error-msg-panel-text">
                                        Thank you. We will get back your request.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-xl-6 col-lg-7 col-sm-12 contact-form">
            <div class="contact-block">
                <div class="contact-us-banner">
                    <img [src]="root.imageUrl() + '/contact-banner-1.png'" alt="banner">
                </div>

                <div class="contact-details-wrapper">
                    <div class="pl-20">
                        <h1 class="contact-title">Please Contact !</h1>
                        <div class="contact-subtitle">
                            <span>Let us help you provide solutions that flourish your business with innovation.
                            </span>
                        </div>
                    </div>
                    <div class="contact-details pb-20">
                        <div class="d-flex">
                            <div class="pl-15 pr-15">
                                <div class="contact-icon center"><img
                                        [src]="root.imageUrl() + '/svg-icons/phone-white-icon.svg'" alt="phone">
                                </div>
                            </div>
                            <div class="pl-15 pr-15">
                                <div class="contact-content">
                                    <a href="tel:+1 (833) 359-3761">
                                        <span>+1 (833) 359-3761</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex mt-30">
                            <div class="pl-15 pr-15">
                                <div class="contact-icon center"><img
                                        [src]="root.imageUrl() + '/svg-icons/email-white-icon.svg'" alt="email">
                                </div>
                            </div>
                            <div class="pl-15 pr-15">
                                <div class="contact-content">
                                    <a href="mailto:contact@ekzero.com">
                                        <span>contact@ekzero.com</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>