import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'obstacles-of-cloud-migration',
  templateUrl: './obstacles-of-cloud-migration.component.html',
  styleUrls: ['./obstacles-of-cloud-migration.component.scss']
})
export class ObstaclesOfCloudMigrationComponent implements OnInit {

   public isDisplay: boolean = false;
   public isShow:boolean=false;

   newTitle: string = 'How to overcome obstacles in Cloud Migration';
   longDescription: string = 'Migrating to the cloud is certainly beneficial, but it is no easy task. Companies that migrate to the cloud face numerous challenges and need to follow the steps below to successfully adapt to changes and overcome challenges.';
   longKeyword: string = 'Cloud migration challenges, cloud migration steps, cloud migration problems, cloud migration in cloud computing, cloud migration, cloud service, cloud migration solutions, cloud computing,';

  constructor(private title: Title,public root:RootService, private meta: Meta) { }

  ngOnInit(): void {

    this.title.setTitle(this.newTitle);
    this.meta.addTag({ name: "description", content: this.longDescription });
    this.meta.addTag({ name: "keywords", content: this.longKeyword });
  }

}
