<div id="product-tech">
    <div class="tech-wrapper">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="left-panel">
                    <img [src]="root.imageUrl() + '/gtrack-business-process.png'">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="right-panel">
                    <div class="info-content mb-30"><span class="color-orange">Suitability for Business processes</span></div>
                    <div>
                        <p class="color-black content-text">GTrack is a real-time GPS monitoring system that offers multiple important features suitable for business needs including, live location tracking, summarized dashboards, data security, offline data recovery, global coverage, geofencing, and much more.
                        </p>
                    </div>
                    <div>
                        <p class="color-black content-text">Enjoy high efficiency and quick decision-making with the help of GTrack’s automated reports and summarized dashboards. Keep track of your vehicles, manage your fleet and monitor your assets well with the help of GTrack. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 tech-section col-md-order2">
                <div class="right-panel">
                    <div class="info-content mb-30"><span class="color-orange">Eligible for Consumer Usage
                        </span></div>
                    <div>
                        <p class="color-black content-text">GTrack integrates into your vehicle's sensors, processes the information it receives and allows you to track your vehicle from anywhere, anytime. GTrack is an efficient solution that provides an added security to your family.
                        </p>
                    </div>
                    <div>
                        <p class="color-black content-text">GTrack can be incredibly beneficial in a variety of situations, especially during emergencies, by enabling real-time tracking, delivering the right information at the right time, and sending alerts. Be it taking care of elderly ones, your kid, pet, or simply your personal vehicle, GTrack is the best solution to ensure the safety and security of your loved ones.  </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 tech-section col-md-order1">
                <div class="left-panel">
                    <img [src]="root.imageUrl() + '/gtrack-customer-usage.png'">
                </div>
            </div>
        </div>
    </div>
</div>