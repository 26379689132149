import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListProductsComponent } from '../products/pages/list-products/list-products.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'our-products',
    pathMatch: 'full'
  },
  {
    path: 'list',
    component: ListProductsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }
