<div class="body-panel position-relative">
    <div class="tech-icon center"><img [src]="root.imageUrl() + '/svg-icons/health-default-lg.svg'"></div>
    <div class="row no-gutters">
        <div class="col-lg-8">
            <div class="left-wrapper">
                <div class="left-content">
                    <div class="info-content mb-30"><span>HEALTH</span></div>
                    <div>
                        <span class="content-text">Provide best-in-class health service to your clients by going
                            digital. Let us help you design solutions and applications that work best for you and your
                            clients.
                        </span>
                    </div>
                </div>
                <div class="d-none pagination-block">
                    <div class="slider active"></div>
                    <div class="slider"></div>
                    <div class="slider"></div>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="right-content position-relative" data-aos="fade-up" data-aos-duration="1500">
                <img [src]="root.imageUrl() + '/ez-health-info.png'">
            </div>
        </div>
    </div>
</div>