import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'app-cloud-detail-benefit',
  templateUrl: './cloud-detail-benefit.component.html',
  styleUrls: ['./cloud-detail-benefit.component.scss']
})
export class CloudDetailBenefitComponent implements OnInit {

  constructor(public root: RootService) { }

  ngOnInit(): void {
  }

}
