import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'app-what-is-cloud-computing',
  templateUrl: './what-is-cloud-computing.component.html',
  styleUrls: ['./what-is-cloud-computing.component.scss']
})
export class WhatIsCloudComputingComponent implements OnInit {
  public isDisplay: boolean = false;
  public isShow: boolean = false;
  newTitle: string = 'What is Cloud computing?';
  longDescription: string = 'Learn what cloud computing is and how it reduces the daily operating costs exponentially making  it convenient for data access and storage.';
  longKeyword: string = 'Cloud computing, cloud computing benefits, cloud computing models, cloud computing uses, cloud computing deployment types';

  constructor(public root: RootService) { }

  ngOnInit(): void {
  }

}
