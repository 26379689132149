<div class="ez-industry-panel" id="ecs-industry">
    <div class="row align-items-end justify-content-end no-gutters">
        <div class="col-xl-3 col-lg-6 col-md-8">
            <div class="industry-right-panel">
                <div class="info-title"><span class="color-black">INDUSTRIES</span></div>
                <div class="mt-20 info-content">
                    <div><span class="color-orange">what Industries</span></div>
                    <div><span class="color-orange">we work with</span></div>
                </div>
                <div class="mt-40">
                    <span class="color-black content-text">ekZero takes pride in serving their clients with our
                        comprehensive range of services, ekZero Corporation has its root deep into the technology space
                        with multiple initiatives.</span>
                    <span class="color-black content-text">Through our comprehensive range of products and services, we
                        ensure our customers and clients stay ahead in the ever-changing technology landscape and
                        maximize their overall success.
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row no-gutters">
        <div class="col-xl-9 col-lg-12">
            <div class="industry-left-panel">
                <div class="item-panel">
                    <div class="d-flex align-items-center">
                        <a class="pagination-icon center" (click)="scrollLeft()"><img
                                [src]="root.imageUrl() + '/svg-icons/prev-arrow-icon.svg'"></a>
                    </div>
                    <div class="content-overflow" #widgetsContent>
                        <ul ngbNav #nav="ngbNav" class="nav-tabs">
                            <li ngbNavItem (click)="onActiveTelecom()" [ngClass]="{active: isActiveTelecom}">
                                <div>
                                    <a ngbNavLink class="center flex-column">
                                        <div class="tech-icon center mb-20">
                                            <img [src]="root.imageUrl() + '/svg-icons/it-telecome-default.svg'"
                                                class="default-icon">
                                            <img [src]="root.imageUrl() + '/svg-icons/it-telecome-hover.svg'"
                                                class="hover-icon">
                                        </div>
                                        IT & TELECOM
                                    </a>
                                </div>
                                <ng-template ngbNavContent>
                                    <div class="tech-body">
                                        <it-and-telecome></it-and-telecome>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem (click)="onActiveBanking()" [ngClass]="{active: isActiveBanking}">
                                <div>
                                    <a ngbNavLink class="center flex-column">
                                        <div class="tech-icon center mb-20">
                                            <img [src]="root.imageUrl() + '/svg-icons/banking-financial-service-default.svg'"
                                            class="default-icon">
                                        <img [src]="root.imageUrl() + '/svg-icons/banking-financial-service-hover.svg'"
                                            class="hover-icon">
                                        </div>
                                        BANKING & FINANCIAL SERVICES
                                    </a>
                                </div>
                                <ng-template ngbNavContent>
                                    <div class="tech-body">
                                        <banking-and-financial-service></banking-and-financial-service>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem (click)="onActiveEducation()" [ngClass]="{active: isActiveEducation}">
                                <div>
                                    <a class="center flex-column" ngbNavLink>
                                        <div class="tech-icon center mb-20">
                                            <img [src]="root.imageUrl() + '/svg-icons/education-default.svg'"
                                            class="default-icon">
                                        <img [src]="root.imageUrl() + '/svg-icons/education-hover.svg'"
                                            class="hover-icon">
                                        </div>
                                        EDUCATION
                                    </a>
                                </div>
                                <ng-template ngbNavContent>
                                    <div class="tech-body">
                                        <education></education>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem (click)="onActiveEnergy()" [ngClass]="{active: isActiveEnergy}">
                                <div>
                                    <a class="center flex-column" ngbNavLink>
                                        <div class="tech-icon center mb-20">
                                            <img [src]="root.imageUrl() + '/svg-icons/energy-utilities-default.svg'"
                                            class="default-icon">
                                        <img [src]="root.imageUrl() + '/svg-icons/energy-utilities-hover.svg'"
                                            class="hover-icon">
                                        </div>
                                        ENERGY & UTILITIES
                                    </a>
                                </div>
                                <ng-template ngbNavContent>
                                    <div class="tech-body">
                                        <enery-and-utilities></enery-and-utilities>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem (click)="onActiveGovt()" [ngClass]="{active: isActiveGovt}">
                                <div>
                                    <a class="center flex-column" ngbNavLink>
                                        <div class="tech-icon center mb-20">
                                            <img [src]="root.imageUrl() + '/svg-icons/goverment-default.svg'"
                                            class="default-icon">
                                        <img [src]="root.imageUrl() + '/svg-icons/goverment-default.svg'"
                                            class="hover-icon">
                                        </div>
                                        GOVERNMENT
                                    </a>
                                </div>
                                <ng-template ngbNavContent>
                                    <div class="tech-body">
                                        <goverment></goverment>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem (click)="onActiveHealth()" [ngClass]="{active: isActiveHealth}">
                                <div>
                                    <a class="center flex-column" ngbNavLink>
                                        <div class="tech-icon center mb-20">
                                            <img [src]="root.imageUrl() + '/svg-icons/health-default.svg'"
                                            class="default-icon">
                                        <img [src]="root.imageUrl() + '/svg-icons/health-hover.svg'"
                                            class="hover-icon">
                                        </div>
                                        HEALTH
                                    </a>
                                </div>
                                <ng-template ngbNavContent>
                                    <div class="tech-body">
                                        <health></health>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem (click)="onActiveService()" [ngClass]="{active: isActiveService}">
                                <div>
                                    <a class="center flex-column" ngbNavLink>
                                        <div class="tech-icon center mb-20">
                                            <img [src]="root.imageUrl() + '/svg-icons/service-entertainment-default.svg'"
                                            class="default-icon">
                                        <img [src]="root.imageUrl() + '/svg-icons/service-entertainment-hover.svg'"
                                            class="hover-icon">
                                        </div>
                                        SERVICE & ENTERTAINMENT
                                    </a>
                                </div>
                                <ng-template ngbNavContent>
                                    <div class="tech-body">
                                        <service-and-entertainment></service-and-entertainment>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                    </div>
                    <div class="d-flex align-items-center">
                        <a class="pagination-icon center" (click)="scrollRight()"><img class="next-icon"
                                [src]="root.imageUrl() + '/svg-icons/prev-arrow-icon.svg'"></a>
                    </div>
                </div>
                <div [ngbNavOutlet]="nav"></div>
            </div>
        </div>
    </div>
</div>