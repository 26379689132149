import { Component, OnInit } from '@angular/core';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'app-essential-mobile-app-design',
  templateUrl: './essential-mobile-app-design.component.html',
  styleUrls: ['./essential-mobile-app-design.component.scss']
})
export class EssentialMobileAppDesignComponent implements OnInit {
  public isDisplay: boolean = false;
  public isShow: boolean = false;
  newTitle: string = '6 Essential Mobile App Design Steps to Take in 2022';
  longDescription: string = 'It all comes down to the preferences of the end-user. And the UI and UX of the app are responsible for making sure the end-user gets a good experience out of the app.';
  longKeyword: string = 'Mobile App design 2022, end-user, mobile, mobile app, app design, UI, UX, code, designer, application';

  constructor(public root: RootService) { }

  ngOnInit(): void {
  }

}
