<div id="service-detail-intro">
    <div class="our-services">
     <!-- -----------Mobile Applications---------------start -->
     <div class="our-services-wrapper">
        <div class="white-bg position-relative">
            <div class="row">
                <div class="col-12">
                    <div class="text-center  mb-50">
                        <div class="mb-30">
                            <h2 class="font-600 font-orange">Mobile Applications</h2>
                        </div>
                        <div>
                            <p class="font-14">We conceptualize, design, and develop user-friendly web and mobile
                                applications from the ground up to digitally transform your business. ekZero has a
                                decade long expertise in building apps and solutions that directly fits with the market
                                needs and empowers companies’ brand identity.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-lg-4 col-order-2">
                    <div class=" justify-content-sb h-100">
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="logo-block">
                                <img [src]="root.imageUrl() + '/svg-icons/native-mobile.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/native-mobile.svg" class="wifi-logo"> -->

                            </div>
                            <div>
                                <div class="mb-10">
                                    <h5>Native Mobile App Development
                                    </h5>
                                </div>
                                <div><span class="font-14">Build high-quality native apps which are dedicatedly made for
                                        specific mobile OS and are installed directly onto the device itself.
                                    </span></div>
                            </div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="logo-block">
                                <img [src]="root.imageUrl() + '/svg-icons/hybrid.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/hybrid.svg" class="wifi-logo"> -->
                            </div>
                            <div>
                                <div class="mb-10">
                                    <h5>Hybrid Mobile App Development</h5>
                                </div>
                                <div><span class="font-14">Give your business a twist of expansion with hybrid mobile
                                        apps that are cross-platform and easily work in different environments.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="logo-block">
                                <img [src]="root.imageUrl() + '/svg-icons/web.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/web.svg" class="wifi-logo"> -->
                            </div>
                            <div>
                                <div class="mb-10">
                                    <h5>Progressive Web App Development
                                    </h5>
                                </div>
                                <div><span class="font-14">Reach to anyone and anywhere on any device with high-end
                                        single code-based progressive web apps that deliver native-like
                                        capabilities.</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-4 col-order-1">
                    <div class="center h-100">
                        <img [src]="root.imageUrl() + '/ez-services-mobile-applications.png'" class="center-img"
                            data-aos="flip-left" data-aos-duration="1500">
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 col-order-2 ">
                    <div class="justify-content-sb h-100 text-align">
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="change-order">
                                <div class="mb-10">
                                    <h5>Wearable and Embedded App Development
                                    </h5>
                                </div>
                                <div><span class="font-14">Scale up your existing business technology with companion apps
                                        our developers create for a number of wearables devices.
                                    </span></div>
                            </div>
                            <div class="logo-block right-block">
                                <img [src]="root.imageUrl() + '/svg-icons/wearable.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/wearable.svg" class="wifi-logo"> -->

                            </div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="change-order">
                                <div class="mb-10">
                                    <h5>IoT App Development</h5>
                                </div>
                                <div><span class="font-14">Gain full potential of Internet of Things with a wide range
                                        of our IoT software development offerings.
                                    </span>
                                </div>
                            </div>
                            <div class="logo-block right-block">
                                <img [src]="root.imageUrl() + '/svg-icons/iot.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/iot.svg" class="wifi-logo"> -->

                            </div>
                        </div>
                        <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                            <div class="change-order">
                                <div class="mb-10">
                                    <h5>Business Applications</h5>
                                </div>
                                <div><span class="font-14">ekZero has expertise in turning your android tablet or iPad
                                        into a business pad which you can use to track the differences in and
                                        out-house activities of your business.</span></div>
                            </div>
                            <div class="logo-block right-block">
                                <img [src]="root.imageUrl() + '/svg-icons/business.svg'" class="wifi-logo">
                                <!-- <img src="assets/images/svg-icon/business.svg" class="wifi-logo"> -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="dots">
                <div class="point bg-blue"></div>
                <div class="point bg-blue"></div>
                <div class="point bg-blue"></div>
            </div> -->

        </div>
    </div>

    </div>
    <!-- -----------Mobile Applications---------------end -->
    <div class="detail-intro" data-aos="fade-up" data-aos-duration="1500">
        <div class="row">
            <div class="col-lg-6">
                <div class="left-content">
                    <div class="info-content mb-30"><span class="color-orange font-">Scale Up Your Business Revenue With Mobile Applications Empowered With Innovation And Design Thinking.</span></div>
                    <div class="detail-info">
                        <p class="color-black content-text">Mobile applications allow businesses to directly communicate with their customers.  With the growing number of people getting access to the internet via smartphones and tablets, mobile apps have become a strong revenue generator in businesses. ekZero creates custom enterprise-class applications to provide users with a 360-degree view of all their processes and flows. We ensure all the business objectives are achieved by covering every aspect of your enterprise application development and keeping the project within budget. We conceptualize, design, and develop user-friendly web and mobile applications from the ground up to digitally transform your business. ekZero has decade-long expertise in building apps and solutions that directly fits with the market needs and empowers companies’ brand identity.
                        </p>
                    </div>
                    <div class="btn-grp"><a class="btn-primary btn-xlg" [routerLink]="root.contact()"
                            target="_top">Connect with ekZero</a></div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="right-content">
                    <img [src]="root.imageUrl() + '/service-mobile-img.png'">
                </div>
            </div>
        </div>
    </div>
</div>