import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'service-home',
  templateUrl: './service-home.component.html',
  styleUrls: ['./service-home.component.scss']
})
export class ServiceHomeComponent implements OnInit {

  newTitle: string = 'Strategic IT Consultation Services | ekZero';
  longDescription: string = 'ekZero Consulting services to develop and execute strategic IT plans that enhance growth and help achieve business goals.';
  longKeyword: string = 'ekZero, Enterprise Software Services, Mobile application, Software Consulting, DevOps, Cloud Services, Digital Solutions, Incubation, ERP, ekZero Consulting Services, ECS, Product Development, POC, MVP';

  constructor(private title: Title,
    private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle(this.newTitle);
    this.meta.addTag({ name: "description", content: this.longDescription });
    this.meta.addTag({ name: "keywords", content: this.longKeyword });
  }

}
