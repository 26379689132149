<div id="product-footer">
    <div class="footer-wrapper">
        <div class="row no-gutters">
            <div class="col-lg-5">
                <div class="left-content">
                    <img [src]="root.imageUrl() + '/ez-product-footer-img.png'">
                </div>
            </div>
            <div class="col-lg-7">
                <div class="footer-content">
                    <div class="right-content">
                        <div class="footer-title">
                            <span>Explore Other <span class="title-info"> PRODUCTS </span> </span>
                        </div>
                        <div class="mt-20"><span class="content-text">Explore dynamic solutions supporting in growth.
                            </span></div>
                        <div class="btn-grp mt-30">
                            <a class="btn-md btn-primary" target="_top" [routerLink]="root.productsList()">Read
                                more</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>