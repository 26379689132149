import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';

import { ResearchDevelopmentRoutingModule } from './research-development-routing.module';
import { ResearchDevelopmentComponent } from './pages/research-development/research-development.component';
import { ResearchDevelopmentBannerComponent } from './components/research-development-banner/research-development-banner.component';
import { ResearchDevelopmentIntroComponent } from './components/research-development-intro/research-development-intro.component';
import { ResearchDevelopmentProcessComponent } from './components/research-development-process/research-development-process.component';
import { ResearchDevelopmentInnovationComponent } from './components/research-development-innovation/research-development-innovation.component';
import { ResearchDevelopmentTechnologyComponent } from './components/research-development-technology/research-development-technology.component';


@NgModule({
  declarations: [ResearchDevelopmentComponent, ResearchDevelopmentBannerComponent, ResearchDevelopmentIntroComponent, ResearchDevelopmentProcessComponent, ResearchDevelopmentInnovationComponent, ResearchDevelopmentTechnologyComponent],
  imports: [
    CommonModule,
    SharedModule,
    ResearchDevelopmentRoutingModule
  ]
})
export class ResearchDevelopmentModule { }
