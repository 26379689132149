import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrivacyPolicyRoutingModule } from './privacy-policy-routing.module';
import { PrivacyPolicyListComponent } from './components/privacy-policy-list/privacy-policy-list.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { PrivacyPolicyBannerComponent } from './components/privacy-policy-banner/privacy-policy-banner.component';
import { SharedModule } from '../../shared/shared.module';
import { NgbDropdownModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [PrivacyPolicyListComponent, PrivacyPolicyComponent, PrivacyPolicyBannerComponent],
  imports: [
    CommonModule,
    PrivacyPolicyRoutingModule,
    SharedModule,
    NgbDropdownModule,
    NgbCollapseModule
  ]
})
export class PrivacyPolicyModule { }
