import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './pages/home/home.component';
import { HomeBannerComponent } from './components/home-banner/home-banner.component';
import { TechnologyPlatformsComponent } from './components/technology-platforms/technology-platforms.component';
import { OfferSectionComponent } from './components/offer-section/offer-section.component';
import { InsightsComponent } from './components/insights/insights.component';
import { HomeContactUsComponent } from './components/home-contact-us/home-contact-us.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
@NgModule({
  declarations: [HomeComponent, HomeBannerComponent, TechnologyPlatformsComponent, OfferSectionComponent, InsightsComponent, HomeContactUsComponent],
  imports: [
    CommonModule,
    HomeRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot()
  ]
})
export class HomeModule { }
