<div class="research-development-innovation">
    <div class="row no-gutters">
        <div class="col-12">
            <div class="left-panel h-100">
                <div class="title-panel">
                    <span class="title-text">
                        Leading Innovation with Extensive Research & Development
                    </span>
                </div>
                <div class="innovation-progression-panel">
                    <div class="wow animated animate__fadeIn animate__animated animate__delay-0s">
                        <img [src]="root.imageUrl() + '/progression-img1.png'"/>
                    </div>
                    <div class="wow animated animate__fadeIn animate__animated animate__delay-0.7s">
                        <img [src]="root.imageUrl() + '/progression-img2.png'"/>
                    </div>
                    <div class="wow animated animate__fadeIn animate__animated animate__delay-1s">
                        <img [src]="root.imageUrl() + '/progression-img3.png'"/>
                    </div>
                    <div class="wow animated animate__fadeIn animate__animated animate__delay-2s">
                        <img [src]="root.imageUrl() + '/progression-img4.png'"/>
                    </div>
                    <div class="wow animated animate__fadeIn animate__animated animate__delay-3s">
                        <img [src]="root.imageUrl() + '/progression-img5.png'"/>
                    </div>
                    <div class="wow animated animate__fadeIn animate__animated animate__delay-4s">
                        <img [src]="root.imageUrl() + '/progression-img6.png'"/>
                    </div>
                    <div class="wow animated animate__fadeIn animate__animated animate__delay-5s">
                        <img [src]="root.imageUrl() + '/progression-img7.png'"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="right-panel h-100">
                <div class="content-panel">
                    <span class="content-text">
                        At ekZero, we empower an ever-learning approach backed by research that anticipates future needs and brings forth innovative ideas and solutions for the modern world. By creating innovative solutions, we hope to unlock value across industries and business functions through research and development. If you have a unique business idea, team up with us and take advantage of our technological knowledge to create something amazing
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
