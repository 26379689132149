import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LifeRoutingModule } from './life-routing.module';
import { LifeatekzeroComponent } from './pages/lifeatekzero/lifeatekzero.component';
import { LifeBannerComponent } from './components/life-banner/life-banner.component';
import { LifeEkzeroComponent } from './components/life-ekzero/life-ekzero.component';
import { LifeFooterComponent } from './components/life-footer/life-footer.component';

@NgModule({
  declarations: [LifeatekzeroComponent, LifeBannerComponent, LifeEkzeroComponent, LifeFooterComponent, ],
  imports: [
    CommonModule,
    LifeRoutingModule
  ]
})
export class LifeModule { }
