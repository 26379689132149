<div id="service-benefits" data-aos="fade-up" data-aos-duration="1500">
    <div class="text-center ml-10 mr-10">
        <div class=""><span class="font-20 color-black">Benefits of</span></div>
        <div class="mt-20 info-content"><span class="color-orange">ekZero's Enterprise Solution Services</span></div>
    </div>
    <div class="benefit-content">
        <div class="row no-gutters">
            <div class="col-xl-6 col-lg-7">
                <div class="left-content">
                    <div class="benefit-pill mb-30">
                        <div class="d-flex flex-column-wrapper">
                            <div><img [src]="root.imageUrl() + '/svg-icons/ecs-enhanced-effectiveness.svg'"
                                    class="benefit-icon"></div>
                            <div class="ml-20">
                                <div class="mb-10"><span class="color-black font-18">Enhanced Effectiveness
                                    </span></div>
                                <div><span class="color-black content-text mb-0">The enterprise solution service can assist in ensuring that various issues and requests are handled efficiently and to agreed-upon service quality.
                                    </span></div>
                            </div>
                        </div>
                    </div>
                    <div class="benefit-pill mb-30">
                        <div class="d-flex flex-column-wrapper">
                            <div><img [src]="root.imageUrl() + '/svg-icons/ecs-increased-control.svg'"
                                    class="benefit-icon"></div>
                            <div class="ml-20">
                                <div class="mb-10"><span class="color-black font-18">Increased Control and Governance
                                    </span></div>
                                <div><span class="color-black content-text mb-0">Enterprise service solutions can be leveraged to gain much higher internal controls, as well as higher-level analysis and information into who did what and when can be known. 
                                    </span></div>
                            </div>
                        </div>
                    </div>
                    <div class="benefit-pill mb-30">
                        <div class="d-flex flex-column-wrapper">
                            <div><img [src]="root.imageUrl() + '/svg-icons/ecs-improved-efficiency.svg'"
                                    class="benefit-icon"></div>
                            <div class="ml-20">
                                <div class="mb-10"><span class="color-black font-18">Improved efficiency and reduced operational costs
                                    </span></div>
                                <div><span class="color-black content-text mb-0">Enterprise service management eliminates unnecessary manual effort by optimizing and automating workflow resulting in high effectiveness of processes as well as reduced costs.
                                    </span></div>
                            </div>
                        </div>
                    </div>
                    <div class="benefit-pill mb-30">
                        <div class="d-flex flex-column-wrapper">
                            <div><img [src]="root.imageUrl() + '/svg-icons/ecs-better-service.svg'"
                                    class="benefit-icon"></div>
                            <div class="ml-20">
                                <div class="mb-10"><span class="color-black font-18">Better service and customer experience </span>
                                </div>
                                <div><span class="color-black content-text mb-0">Internal requests between business divisions can be made more swiftly with enterprise solution services, which results in a better customer experience.</span></div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="benefit-pill mb-30">
                        <div class="d-flex flex-column-wrapper">
                            <div><img [src]="root.imageUrl() + '/svg-icons/service-benefit-icon.svg'"
                                    class="benefit-icon"></div>
                            <div class="ml-20">
                                <div class="mb-10"><span class="color-black font-18">Order Management</span></div>
                                <div><span class="color-black content-text mb-0">Experience the real-time tracker that
                                        fulfils every customer order flawlessly
                                        & effectively, managing all your orders from a single platform</span></div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-xl-6 col-lg-5">
                <div class="right-content">
                    <img [src]="root.imageUrl() + '/ez-service-benefit-right-bg.png'">
                </div>
            </div>
        </div>
    </div>
</div>