import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { PageNotFoundService } from 'src/app/shared/services/page-not-found.service';
import { RootService } from 'src/app/shared/services/root.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  isShow:boolean = false;
  isOpen:boolean = false;
  isOpenecs:boolean = false;
  isOpenAboutus:boolean = false;
  isOpenService:boolean = false;
  isOpenProduct:boolean = false;
  isOpenContact:boolean = false;
  isOpenCareers:boolean = false;
  isOpenfaq:boolean = false;
  demoPath: string;

  constructor(private router: Router, public pageNotFound: PageNotFoundService, public root: RootService) {     
  }

  ngOnInit(): void {
    
  }

}
