<div id="service-detail-intro">
    <div class="our-services">
        <!-- -------------DevOps Services-----------start--- -->
        <div class="our-services-wrapper">
            <div class="white-bg position-relative">
                <div class="row">
                    <div class="col-12">
                        <div class="text-center mb-50">
                            <div class="mb-30">
                                <h2 class="font-600 font-orange">DevOps Services</h2>
                            </div>
                            <div>
                                <p class="font-14">ekZero’s decade-long expertise in DevOps helps the businesses with
                                    services encompassing several capabilities such as iterative and incremental
                                    development, workload management on-demand, lightweight architecture, security and
                                    automated testing techniques.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-lg-4 col-order-2">
                        <div class="justify-content-sb h-100">
                            <div class="d-flex mb-30 " data-aos="fade-up" data-aos-duration="1500">
                                <div class="logo-block">
                                    <img [src]="root.imageUrl() + '/svg-icons/automation.svg'" class="wifi-logo">
                                    <!-- <img src="assets/images/svg-icon/automation.svg" class="wifi-logo"> -->
                                </div>
                                <div>
                                    <div class="mb-10">
                                        <h5>DevOps Automation</h5>
                                    </div>
                                    <div><span class="font-14">Automate your entire process pipeline from code
                                            generation to
                                            production deployment, test cases, quality checks and more.

                                        </span></div>
                                </div>
                            </div>
                            <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                                <div class="logo-block">
                                    <img [src]="root.imageUrl() + '/svg-icons/dev-cloud.svg'" class="wifi-logo">
                                    <!-- <img src="assets/images/svg-icon/dev-cloud.svg" class="wifi-logo"> -->
                                </div>
                                <div>
                                    <div class="mb-10">
                                        <h5>DevOps on Cloud
                                        </h5>
                                    </div>
                                    <div><span class="font-14">Completely streamlined developer processes on the cloud
                                            that
                                            enables faster access to development environments.
                                        </span></div>
                                </div>
                            </div>
                            <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                                <div class="logo-block">
                                    <img [src]="root.imageUrl() + '/svg-icons/assessment.svg'" class="wifi-logo">
                                    <!-- <img src="assets/images/svg-icon/assessment.svg" class="wifi-logo"> -->
                                </div>
                                <div>
                                    <div class="mb-10">
                                        <h5>DevOps Assessment</h5>
                                    </div>
                                    <div><span class="font-14">A robust assessment and consulting service which scales
                                            up
                                            your development infrastructure so that you can focus on faster delivery.
                                        </span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" col-md-12 col-lg-4 col-order-1">
                        <div class="center h-100">
                            <img [src]="root.imageUrl() + '/devops-service-img.png'" class="center-img"
                                data-aos="flip-left" data-aos-duration="1500">
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 col-order-2 ">
                        <div class="justify-content-sb h-100 text-align">
                            <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                                <div class="change-order">
                                    <div class="mb-10">
                                        <h5>DevOps Maintenance</h5>
                                    </div>
                                    <div><span class="font-14">Experience continuous delivery and deployment. Discover
                                            correct vulnerabilities in your application, infrastructure and integration.

                                        </span></div>
                                </div>
                                <div class="logo-block right-block">
                                    <img [src]="root.imageUrl() + '/svg-icons/dev-maintenance.svg'" class="wifi-logo">
                                    <!-- <img src="assets/images/svg-icon/dev-maintenance.svg" class="wifi-logo"> -->
                                </div>
                            </div>
                            <div class="d-flex mb-30" data-aos="fade-up" data-aos-duration="1500">
                                <div class="change-order">
                                    <div class="mb-10">
                                        <h5>DevOps Planning</h5>
                                    </div>
                                    <div><span class="font-14">Develop a necessary actionable plan that addresses the
                                            current state of your DevOps culture, processes and toolchain.
                                        </span></div>
                                </div>
                                <div class="logo-block right-block">
                                    <img [src]="root.imageUrl() + '/svg-icons/dev-planning.svg'" class="wifi-logo">
                                    <!-- <img src="assets/images/svg-icon/dev-planning.svg" class="wifi-logo"> -->
                                </div>
                            </div>
                            <div class="d-flex" data-aos="fade-up" data-aos-duration="1500">
                                <div class="change-order">
                                    <div class="mb-10">
                                        <h5>DevOps Management</h5>
                                    </div>
                                    <div><span class="font-14">A complete managed service that configures web hosting,
                                            meets
                                            delivery requirements and performs automatic deployment.
                                        </span></div>
                                </div>
                                <div class="logo-block right-block">
                                    <img [src]="root.imageUrl() + '/svg-icons/dev-manage.svg'" class="wifi-logo">
                                    <!-- <img src="assets/images/svg-icon/dev-manage.svg" class="wifi-logo"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="dots">
                    <div class="point bg-blue"></div>
                    <div class="point bg-blue"></div>
                    <div class="point bg-blue"></div>
                </div> -->
            </div>
        </div>

        <!-- -------------DevOps Services-----------end--- -->
    </div>
    <div class="detail-intro" data-aos="fade-up" data-aos-duration="1500">
        <div class="row">
            <div class="col-lg-6">
                <div class="left-content">
                    <div class="info-content mb-30"><span class="color-orange font-">Increase Collaboration & Enhanced
                            Productivity To Get Fast-Paced Development And Quicker Time-to-Market.
                        </span></div>
                    <div class="detail-info">
                        <p class="color-black content-text">DevOps ideally bridges gaps between the core operating teams
                            i.e., Developers (Dev) and Operation (Ops), which in turn allows you to make your production
                            environment stable and faster, enabled with risk-free multiple deployments throughout the
                            day without having any hiccups. The experts at ekZero thoroughly review your current state
                            of production, identify any potential gaps and bottlenecks in the process, make actionable
                            recommendations for long-term success and execute them live. DevOps transforms your regular
                            quality testing by automating the process, minimizing the risks, and accelerating your SDLC.
                        </p>
                    </div>
                    <div class="btn-grp"><a class="btn-primary btn-xlg" [routerLink]="root.contact()"
                            target="_top">Connect with ekZero</a></div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="right-content">
                    <img [src]="root.imageUrl() + '/service-devops-img.png'">
                </div>
            </div>
        </div>
    </div>
</div>