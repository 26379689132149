<!--case study-->
<div class="ez-case-study ez-resource">
    <div class="left-panel">
        <div class="row no-gutters">
            <div class="col-lg-3">
                <div class="cs-left-content">
                    <div class="ekzero-title"><span class="color-black">ekZero</span></div>
                    <div class="mt-20 info-content">
                        <div><span class="color-orange">Case</span></div>
                        <div><span class="color-orange">Study</span></div>
                    </div>
                    <div class="mt-40">
                        <span class="color-black content-text">Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua.</span>
                    </div>
                    <div class="mt-30">
                        <a class="btn-primary btn-md" target="_top">VIEW ALL</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-9 d-none">
                <div class="cs-right-content">
                    <div class="position-relative">
                        <div class="card-bg">
                            <div class="card-panel">
                                <div class="overflow-auto-panel -ms-scrollbar scrollable-body">
                                    <div class="content-card position-relative">
                                        <!-- <div class="service-icon">
                                                <img [src]="root.imageUrl() + '/case-study-card-img.png '">
                                            </div> -->
                                        <div class="content-info">
                                            <div class="mb-20"><span class="color-black service-title">Flexible
                                                    Shipping Are Increasing Day By Day Gradually</span></div>
                                            <div class="d-flex justify-content-between align-items-center flex-wrap">
                                                <div class="d-flex align-items-center">
                                                    <div><img
                                                        [src]="root.imageUrl() + '/svg-icons/user-icon.svg'">
                                                    </div>
                                                    <div class="pl-20"><span class="color-black">Lara
                                                            Jackson</span></div>
                                                </div>
                                                <div>
                                                    <span class="card-date">August 20, 2020</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content-card position-relative">
                                        <!-- <div class="service-icon">
                                                <img [src]="root.imageUrl() + '/case-study-card-img.png '">
                                            </div> -->
                                        <div class="content-info">
                                            <div class="mb-20"><span class="color-black service-title">Flexible
                                                    Shipping Are Increasing Day By Day Gradually</span></div>

                                            <div class="d-flex justify-content-between align-items-center flex-wrap">
                                                <div class="d-flex align-items-center">
                                                    <div><img
                                                        [src]="root.imageUrl() + '/svg-icons/user-icon.svg'">
                                                    </div>
                                                    <div class="pl-20"><span class="color-black">Lara
                                                            Jackson</span></div>
                                                </div>
                                                <div>
                                                    <span class="card-date">August 20, 2020</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="content-card content-card-active">

                                            <div class="content-info">
                                                <div class="mb-20 title-height"><span class="service-title">Flexible
                                                        Shipping Are Increasing Day By Day Gradually</span>
                                                </div>
                                                <div class="mb-40">
                                                    <p class="info-text">To satisfy customer-specific wishes, we
                                                        supplements its
                                                        own ultra-modern fleet and network with an extensive
                                                        network
                                                        of subcontractors and partners who work under the
                                                        responsibility and control of our company. </p>
                                                </div>
                                                <div
                                                    class="d-flex justify-content-between align-items-center flex-wrap">
                                                    <div class="d-flex align-items-center">
                                                        <div><img
                                                            [src]="root.imageUrl() + '/svg-icons/user-icon.svg'">
                                                        </div>
                                                        <div class="pl-20"><span>Lara Jackson</span></div>
                                                    </div>
                                                    <div>
                                                        <span>August 20, 2020</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content-card position-relative">
                                        <div class="service-icon center">
                                            <img [src]="root.imageUrl() + '/case-study-card-img.png '">
                                        </div>
                                        <div class="content-info">
                                            <div class="mb-20"><span class="color-black service-title">Flexible
                                                    Shipping Are Increasing Day By Day Gradually</span></div>
                                            <div class="d-flex justify-content-between align-items-center flex-wrap">
                                                <div class="d-flex align-items-center">
                                                    <div><img
                                                        [src]="root.imageUrl() + '/svg-icons/user-icon.svg'">
                                                    </div>
                                                    <div class="pl-20"><span class="color-black">Lara
                                                            Jackson</span></div>
                                                </div>
                                                <div>
                                                    <span class="card-date">August 20, 2020</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="nav-arrows d-flex justify-content-center">
                                    <div class="left-arrow mr-10"><a><img
                                        [src]="root.imageUrl() + '/svg-icons/left-arrow-icon.svg'"></a>
                                    </div>
                                    <div class="left-arrow"><a><img
                                        [src]="root.imageUrl() + '/svg-icons/right-arrow-icon.svg'"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="ez-cs-blogs">
        <div class="d-none row">
            <div class="col-lg-12">
                <div class="mt-60">
                    <div class="row">
                        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 res-col">
                            <div>
                                <div>
                                    <div class="preserve-flip">
                                        <div class="fl">
                                            <div class="fl2">
                                                <div>
                                                    <img [src]="root.imageUrl() + '/blog.png '" alt="blog" class="w-100">
                                                </div>
                                                <div class="position-relative case-study-content">
                                                    <div class="casestudy-category">
                                                        <span>IOT</span>
                                                    </div>
                                                    <div class="blog-card-content">
                                                        <div class="mb-20">
                                                            <div class="date-panel"><span>August 20,
                                                                    2020</span>
                                                            </div>
                                                            <div class="blog-title">
                                                                <span>Flexible Shipping Are Increasing Day
                                                                    By
                                                                    Day
                                                                    Gradually</span>
                                                            </div>
                                                            <div class="blog-content">
                                                                <span> Whether you are a service provider or
                                                                    someone
                                                                    who
                                                                    provides end to...</span>
                                                            </div>
                                                        </div>
                                                        <div class="blog-footer">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div class="blog-avtar center">
                                                                    <div class="pr-20">
                                                                        <img [src]="root.imageUrl() + '/avtar2.png '" alt="">
                                                                    </div>
                                                                    <div>
                                                                        <span class="font-16"> Dario Smith
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="text-right">
                                                                    <img [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'"
                                                                        alt="arrow">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!--active hover caard-->
                                            <div class="content-card content-card-active">
                                                <div class="content-info">
                                                    <div class="mb-20 title-height"><span class="service-title">Flexible
                                                            Shipping Are Increasing Day By Day
                                                            Gradually</span>
                                                    </div>
                                                    <div class="mb-40">
                                                        <p class="info-text">To satisfy customer-specific
                                                            wishes, we
                                                            supplements its
                                                            own ultra-modern fleet and network with an
                                                            extensive
                                                            network
                                                            of subcontractors and partners who work under
                                                            the
                                                            responsibility and control of our company. </p>
                                                    </div>
                                                    <div
                                                        class="d-flex justify-content-between align-items-center flex-wrap">
                                                        <div class="d-flex align-items-center">
                                                            <div><img
                                                                [src]="root.imageUrl() + '/svg-icons/user-icon.svg'">
                                                            </div>
                                                            <div class="pl-20"><span>Lara Jackson</span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span>August 20, 2020</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 res-col">
                            <div>
                                <div>
                                    <div class="preserve-flip">
                                        <div class="fl">
                                            <div class="fl2">
                                                <div>
                                                    <img [src]="root.imageUrl() + '/blog.png '" alt="blog" class="w-100">
                                                </div>
                                                <div class="position-relative case-study-content">
                                                    <div class="casestudy-category">
                                                        <span>IOT</span>
                                                    </div>
                                                    <div class="blog-card-content">
                                                        <div class="mb-20">
                                                            <div class="date-panel"><span>August 20,
                                                                    2020</span>
                                                            </div>
                                                            <div class="blog-title">
                                                                <span>Flexible Shipping Are Increasing Day
                                                                    By
                                                                    Day
                                                                    Gradually</span>
                                                            </div>
                                                            <div class="blog-content">
                                                                <span> Whether you are a service provider or
                                                                    someone
                                                                    who
                                                                    provides end to...</span>
                                                            </div>
                                                        </div>
                                                        <div class="blog-footer">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div class="blog-avtar center">
                                                                    <div class="pr-20">
                                                                        <img [src]="root.imageUrl() + '/avtar2.png '" alt="">
                                                                    </div>
                                                                    <div>
                                                                        <span class="font-16"> Dario Smith
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="text-right">
                                                                    <img [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'"
                                                                        alt="arrow">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!--active hover caard-->
                                            <div class="content-card content-card-active">
                                                <div class="content-info">
                                                    <div class="mb-20 title-height"><span class="service-title">Flexible
                                                            Shipping Are Increasing Day By Day
                                                            Gradually</span>
                                                    </div>
                                                    <div class="mb-40">
                                                        <p class="info-text">To satisfy customer-specific
                                                            wishes, we
                                                            supplements its
                                                            own ultra-modern fleet and network with an
                                                            extensive
                                                            network
                                                            of subcontractors and partners who work under
                                                            the
                                                            responsibility and control of our company. </p>
                                                    </div>
                                                    <div
                                                        class="d-flex justify-content-between align-items-center flex-wrap">
                                                        <div class="d-flex align-items-center">
                                                            <div><img
                                                                [src]="root.imageUrl() + '/svg-icons/user-icon.svg'">
                                                            </div>
                                                            <div class="pl-20"><span>Lara Jackson</span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span>August 20, 2020</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 res-col">
                            <div>
                                <div>
                                    <div class="preserve-flip">
                                        <div class="fl">
                                            <div class="fl2">
                                                <div>
                                                    <img [src]="root.imageUrl() + '/blog.png '" alt="blog" class="w-100">
                                                </div>
                                                <div class="position-relative case-study-content">
                                                    <div class="casestudy-category">
                                                        <span>IOT</span>
                                                    </div>
                                                    <div class="blog-card-content">
                                                        <div class="mb-20">
                                                            <div class="date-panel"><span>August 20,
                                                                    2020</span>
                                                            </div>
                                                            <div class="blog-title">
                                                                <span>Flexible Shipping Are Increasing Day
                                                                    By
                                                                    Day
                                                                    Gradually</span>
                                                            </div>
                                                            <div class="blog-content">
                                                                <span> Whether you are a service provider or
                                                                    someone
                                                                    who
                                                                    provides end to...</span>
                                                            </div>
                                                        </div>
                                                        <div class="blog-footer">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div class="blog-avtar center">
                                                                    <div class="pr-20">
                                                                        <img [src]="root.imageUrl() + '/avtar2.png '" alt="">
                                                                    </div>
                                                                    <div>
                                                                        <span class="font-16"> Dario Smith
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="text-right">
                                                                    <img [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'"
                                                                        alt="arrow">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!--active hover caard-->
                                            <div class="content-card content-card-active">
                                                <div class="content-info">
                                                    <div class="mb-20 title-height"><span class="service-title">Flexible
                                                            Shipping Are Increasing Day By Day
                                                            Gradually</span>
                                                    </div>
                                                    <div class="mb-40">
                                                        <p class="info-text">To satisfy customer-specific
                                                            wishes, we
                                                            supplements its
                                                            own ultra-modern fleet and network with an
                                                            extensive
                                                            network
                                                            of subcontractors and partners who work under
                                                            the
                                                            responsibility and control of our company. </p>
                                                    </div>
                                                    <div
                                                        class="d-flex justify-content-between align-items-center flex-wrap">
                                                        <div class="d-flex align-items-center">
                                                            <div><img
                                                                [src]="root.imageUrl() + '/svg-icons/user-icon.svg'">
                                                            </div>
                                                            <div class="pl-20"><span>Lara Jackson</span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span>August 20, 2020</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 res-col">
                            <div>
                                <div>
                                    <div class="preserve-flip">
                                        <div class="fl">
                                            <div class="fl2">
                                                <div>
                                                    <img [src]="root.imageUrl() + '/blog.png '" alt="blog" class="w-100">
                                                </div>
                                                <div class="position-relative case-study-content">
                                                    <div class="casestudy-category">
                                                        <span>IOT</span>
                                                    </div>
                                                    <div class="blog-card-content">
                                                        <div class="mb-20">
                                                            <div class="date-panel"><span>August 20,
                                                                    2020</span>
                                                            </div>
                                                            <div class="blog-title">
                                                                <span>Flexible Shipping Are Increasing Day
                                                                    By
                                                                    Day
                                                                    Gradually</span>
                                                            </div>
                                                            <div class="blog-content">
                                                                <span> Whether you are a service provider or
                                                                    someone
                                                                    who
                                                                    provides end to...</span>
                                                            </div>
                                                        </div>
                                                        <div class="blog-footer">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div class="blog-avtar center">
                                                                    <div class="pr-20">
                                                                        <img [src]="root.imageUrl() + '/avtar2.png '" alt="">
                                                                    </div>
                                                                    <div>
                                                                        <span class="font-16"> Dario Smith
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="text-right">
                                                                    <img [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'"
                                                                        alt="arrow">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!--active hover caard-->
                                            <div class="content-card content-card-active">
                                                <div class="content-info">
                                                    <div class="mb-20 title-height"><span class="service-title">Flexible
                                                            Shipping Are Increasing Day By Day
                                                            Gradually</span>
                                                    </div>
                                                    <div class="mb-40">
                                                        <p class="info-text">To satisfy customer-specific
                                                            wishes, we
                                                            supplements its
                                                            own ultra-modern fleet and network with an
                                                            extensive
                                                            network
                                                            of subcontractors and partners who work under
                                                            the
                                                            responsibility and control of our company. </p>
                                                    </div>
                                                    <div
                                                        class="d-flex justify-content-between align-items-center flex-wrap">
                                                        <div class="d-flex align-items-center">
                                                            <div><img
                                                                [src]="root.imageUrl() + '/svg-icons/user-icon.svg'">
                                                            </div>
                                                            <div class="pl-20"><span>Lara Jackson</span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span>August 20, 2020</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


        <!-- new structure | starts -->
        <div class="row">
            <div class="col-lg-12">
                <div class="mt-60">
                    <div class="overflow-wrapper -ms-scrollbar scrollable-body">
                        <div class="card-panel-wrapper">
                            <div class="row card-wrapper">
                                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 res-col">
                                    <div class="card-container">
                                        <div class="card-block">
                                            <div class="side">
                                                <div><img [src]="root.imageUrl() + '/blog.png '" alt="blog"
                                                        class="w-100 blog-img"></div>

                                                <div class="position-relative case-study-content">
                                                    <!-- <div class="casestudy-category"><span>IOT</span>
                                                    </div> -->
                                                    <div class="blog-card-content">
                                                        <div class="mb-20">
                                                            <div class="date-panel"><span>August 20,
                                                                    2020</span></div>
                                                            <div class="blog-title"><span>Flexible
                                                                    Shipping Are Increasing Day By Day Gradually</span>
                                                            </div>
                                                            <div class="blog-content"><span> Whether
                                                                    you are a service provider or someone who provides
                                                                    end
                                                                    to...</span></div>
                                                        </div>
                                                        <div class="blog-footer">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div class="blog-avtar center">
                                                                    <div class="pr-20"><img
                                                                            [src]="root.imageUrl() + '/avtar2.png '" alt=""></div>
                                                                    <div><span class="font-16"> Dario
                                                                            Smith </span></div>
                                                                </div>
                                                                <!-- <div class="text-right"><img
                                                                        [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'"
                                                                        alt="arrow"></div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="content-card content-card-active">
                                                <div class="content-info">
                                                    <div class="mb-20 title-height"><span class="service-title">Flexible
                                                            Shipping Are Increasing Day
                                                            By Day Gradually</span></div>
                                                    <div class="middle-content">
                                                        <p class="info-text">To satisfy customer-specific wishes, we
                                                            supplements its own ultra-modern fleet and network with an
                                                            extensive
                                                            network of
                                                            subcontractors and partners who work under the
                                                            responsibility and
                                                            control of our
                                                            company. </p>
                                                    </div>
                                                    <div
                                                        class="d-flex justify-content-between align-items-center flex-wrap">
                                                        <div class="d-flex align-items-center">
                                                            <div><img
                                                                [src]="root.imageUrl() + '/svg-icons/user-icon.svg'">
                                                            </div>
                                                            <div class="pl-20"><span>Lara
                                                                    Jackson</span></div>
                                                        </div>
                                                        <div><span>August 20, 2020</span></div>
                                                    </div>
                                                    <div class="text-right"><img
                                                        [src]="root.imageUrl() + '/svg-icons/white-arrow.svg'"
                                                        alt="arrow" width="18.229" height="14.04"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 res-col">
                                    <div class="card-container">
                                        <div class="card-block">
                                            <div class="side">
                                                <div><img [src]="root.imageUrl() + '/blog.png '" alt="blog"
                                                        class="w-100 blog-img"></div>

                                                <div class="position-relative case-study-content">
                                                    <!-- <div class="casestudy-category"><span>IOT</span>
                                                    </div> -->
                                                    <div class="blog-card-content">
                                                        <div class="mb-20">
                                                            <div class="date-panel"><span>August 20,
                                                                    2020</span></div>
                                                            <div class="blog-title"><span>Flexible
                                                                    Shipping Are Increasing Day By Day Gradually</span>
                                                            </div>
                                                            <div class="blog-content"><span> Whether
                                                                    you are a service provider or someone who provides
                                                                    end
                                                                    to...</span></div>
                                                        </div>
                                                        <div class="blog-footer">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div class="blog-avtar center">
                                                                    <div class="pr-20"><img
                                                                            [src]="root.imageUrl() + '/avtar2.png '" alt=""></div>
                                                                    <div><span class="font-16"> Dario
                                                                            Smith </span></div>
                                                                </div>
                                                                <!-- <div class="text-right"><img
                                                                        [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'"
                                                                        alt="arrow"></div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="content-card content-card-active">
                                                <div class="content-info">
                                                    <div class="mb-20 title-height"><span class="service-title">Flexible
                                                            Shipping Are Increasing Day
                                                            By Day Gradually</span></div>
                                                    <div class="middle-content">
                                                        <p class="info-text">To satisfy customer-specific wishes, we
                                                            supplements its own ultra-modern fleet and network with an
                                                            extensive
                                                            network of
                                                            subcontractors and partners who work under the
                                                            responsibility and
                                                            control of our
                                                            company. </p>
                                                    </div>
                                                    <div
                                                        class="d-flex justify-content-between align-items-center flex-wrap">
                                                        <div class="d-flex align-items-center">
                                                            <div><img
                                                                [src]="root.imageUrl() + '/svg-icons/user-icon.svg'">
                                                            </div>
                                                            <div class="pl-20"><span>Lara
                                                                    Jackson</span></div>
                                                        </div>
                                                        <div><span>August 20, 2020</span></div>
                                                    </div>
                                                    <div class="text-right"><img
                                                        [src]="root.imageUrl() + '/svg-icons/white-arrow.svg'"
                                                        alt="arrow" width="18.229" height="14.04"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 res-col">
                                    <div class="card-container">
                                        <div class="card-block">
                                            <div class="side">
                                                <div><img [src]="root.imageUrl() + '/blog.png '" alt="blog"
                                                        class="w-100 blog-img"></div>

                                                <div class="position-relative case-study-content">
                                                    <!-- <div class="casestudy-category"><span>IOT</span>
                                                    </div> -->
                                                    <div class="blog-card-content">
                                                        <div class="mb-20">
                                                            <div class="date-panel"><span>August 20,
                                                                    2020</span></div>
                                                            <div class="blog-title"><span>Flexible
                                                                    Shipping Are Increasing Day By Day Gradually</span>
                                                            </div>
                                                            <div class="blog-content"><span> Whether
                                                                    you are a service provider or someone who provides
                                                                    end
                                                                    to...</span></div>
                                                        </div>
                                                        <div class="blog-footer">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div class="blog-avtar center">
                                                                    <div class="pr-20"><img
                                                                            [src]="root.imageUrl() + '/avtar2.png '" alt=""></div>
                                                                    <div><span class="font-16"> Dario
                                                                            Smith </span></div>
                                                                </div>
                                                                <!-- <div class="text-right"><img
                                                                        [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'"
                                                                        alt="arrow"></div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="content-card content-card-active">
                                                <div class="content-info">
                                                    <div class="mb-20 title-height"><span class="service-title">Flexible
                                                            Shipping Are Increasing Day
                                                            By Day Gradually</span></div>
                                                    <div class="middle-content">
                                                        <p class="info-text">To satisfy customer-specific wishes, we
                                                            supplements its own ultra-modern fleet and network with an
                                                            extensive
                                                            network of
                                                            subcontractors and partners who work under the
                                                            responsibility and
                                                            control of our
                                                            company. </p>
                                                    </div>
                                                    <div
                                                        class="d-flex justify-content-between align-items-center flex-wrap">
                                                        <div class="d-flex align-items-center">
                                                            <div><img
                                                                [src]="root.imageUrl() + '/svg-icons/user-icon.svg'">
                                                            </div>
                                                            <div class="pl-20"><span>Lara
                                                                    Jackson</span></div>
                                                        </div>
                                                        <div><span>August 20, 2020</span></div>
                                                    </div>
                                                    <div class="text-right"><img
                                                        [src]="root.imageUrl() + '/svg-icons/white-arrow.svg'"
                                                        alt="arrow" width="18.229" height="14.04"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 res-col">
                                    <div class="card-container">
                                        <div class="card-block">
                                            <div class="side">
                                                <div><img [src]="root.imageUrl() + '/blog.png '" alt="blog"
                                                        class="w-100 blog-img"></div>

                                                <div class="position-relative case-study-content">
                                                    <!-- <div class="casestudy-category"><span>IOT</span>
                                                    </div> -->
                                                    <div class="blog-card-content">
                                                        <div class="mb-20">
                                                            <div class="date-panel"><span>August 20,
                                                                    2020</span></div>
                                                            <div class="blog-title"><span>Flexible
                                                                    Shipping Are Increasing Day By Day Gradually</span>
                                                            </div>
                                                            <div class="blog-content"><span> Whether
                                                                    you are a service provider or someone who provides
                                                                    end
                                                                    to...</span></div>
                                                        </div>
                                                        <div class="blog-footer">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div class="blog-avtar center">
                                                                    <div class="pr-20"><img
                                                                            [src]="root.imageUrl() + '/avtar2.png '" alt=""></div>
                                                                    <div><span class="font-16"> Dario
                                                                            Smith </span></div>
                                                                </div>
                                                                <!-- <div class="text-right"><img
                                                                        [src]="root.imageUrl() + '/svg-icons/right-orange-arrow.svg'"
                                                                        alt="arrow"></div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="content-card content-card-active">
                                                <div class="content-info">
                                                    <div class="mb-20 title-height"><span class="service-title">Flexible
                                                            Shipping Are Increasing Day
                                                            By Day Gradually</span></div>
                                                    <div class="middle-content">
                                                        <p class="info-text">To satisfy customer-specific wishes, we
                                                            supplements its own ultra-modern fleet and network with an
                                                            extensive
                                                            network of
                                                            subcontractors and partners who work under the
                                                            responsibility and
                                                            control of our
                                                            company. </p>
                                                    </div>
                                                    <div
                                                        class="d-flex justify-content-between align-items-center flex-wrap">
                                                        <div class="d-flex align-items-center">
                                                            <div><img
                                                                [src]="root.imageUrl() + '/svg-icons/user-icon.svg'">
                                                            </div>
                                                            <div class="pl-20"><span>Lara
                                                                    Jackson</span></div>
                                                        </div>
                                                        <div><span>August 20, 2020</span></div>
                                                    </div>
                                                    <div class="text-right"><img
                                                        [src]="root.imageUrl() + '/svg-icons/white-arrow.svg'"
                                                        alt="arrow" width="18.229" height="14.04"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- new structure | ends -->
    </div>
</div>